import altText from './static-content/altText'
import benefits from './static-content/benefits'
import { defaults as bu } from './campaigns/_defaults'
import subscribe from './static-content/subscribe'
import tabsIndoor from './static-content/tabsIndoor'
import tabsIntelliHeat from './static-content/tabsIntelliHeat'

// const buPhone = '866-419-2513'

const hero = {
	class: 'hero',
	form: 'standard',
	staticCampaign: true,
	title: 'Make Your Home a Hybrid',
	blurb: '',
	detailsText: 'Are you tired of constantly adjusting your thermostat to find that perfect balance between comfort and savings? Our revolutionary Hybrid HVAC System is here to transform the way you experience indoor comfort.',
	formInstructions: bu.formInstructions,
	footnote: null,
	backgroundSrc: '/images/smart-hvac.webp',
	whatsNext: '',
	leadCampaignId: '7013q000001rcofAAA',
	subscriberCampaignId: bu.subscriberCampaignId,
	hideContactMethodSelector: false,
	hideContractorNumSelector: false,
	hideTimeframeSelector: false,
	hideLocationSelectors: false,
	hideSystemSelector: false,
	hideCommunicationsSelector: false,
	hideDownArrow: false,
}

const sections = [
    {
		id: 'hybrid-home',
		class: 'two-column-img-left',
		title: 'Introducing\nintelli-HEAT™',
		blurb: 'Intelligent Hybrid Home Tech',
		detailsText: 'Upgrade your current HVAC system to a Hybrid Dual Fuel Heat Pump and significantly reduce gas usage and CO2 emissions. Mitsubishi Electric’s intelli-HEAT system consists of 2 units: an exterior heat pump, which replaces your existing air conditioning condenser, and an interior unit that is installed on your existing furnace*. This smart system not only improves air conditioning efficiency on hot days, but also determines the best source of heat (gas or electricity) on cold days, so your HVAC system is always running at peak efficiency and comfort.',
		imgSrc: '/images/product/intelli-HEAT-on-furnace.webp',
		imgAlt: `${altText} diagram of intelli-heat cased coil installed on top of existing gas-burning furnace`,
		ctaText: 'Watch Video',
		videoSrc: 'U54nJnoVmy4',
		backgroundCol: 'transparent',
        footnote: '*Mitsubishi Electric air conditioner and heat pump systems should only be connected with ANSI-Z21.47/CSA2.3 certified furnaces.',
        hasNavAnchor: true,
	},
    {
        id: 'Features',
        class: 'tabs',
        tabs: tabsIntelliHeat
    },
	{
        id: 'Awards',
        class: 'three-column-cards-w-image',
        title: 'Winner of Several Awards',
        hasNavAnchor: true,
        contentBoxes: [
            {
                // title: '',
                blurb: 'Built Design Awards 2023 Winner',
                detailsText: 'October 2023',
                imgSrc: '/images/logos/intelliheat_2023_bda.webp',
                imgAlt: `${altText} - logo for Built Design Awards 2023`,
                containImg: true,
                ctaText: 'Learn More',
                ctaUrl: 'https://bltawards.com/winner/blt/2023/21558/0/',
            },
            {
                // title: '',
                blurb: '2023 NY Product Design Gold Award',
                detailsText: 'June 2023',
                imgSrc: '/images/logos/intelliheat_2023_nypda_gold.webp',
                imgAlt: `${altText} - logo for 2023 NY Product Design Gold Award`,
                containImg: true,
                ctaText: 'Learn More',
                ctaUrl: 'https://nydesignawards.com/winner-info.php?id=1204',
            },
            {
                // title: '',
                blurb: '2022 PRODUCTS Most Valuable Product (MVP) Awards - Gold Category',
                detailsText: 'January 2023',
                imgSrc: '/images/logos/intelliheat_2022_mvp_gold.webp',
                imgAlt: `${altText} - logo for 2022 PRODUCTS Most Valuable Product (MVP) Awards - Gold Category`,
                containImg: true,
                ctaText: 'Learn More',
                ctaUrl: 'https://residentialproductsonline.com/2022-mvp-awards-%E2%80%94-hvac',
            },
            {
                // title: '',
                blurb: 'Architectural Products 2022 Product Innovation Awards ',
                detailsText:'December 2022',
                imgSrc: '/images/logos/intelliheat_2022_pia.webp',
                imgUrl: '',
                imgAlt: `${altText} - logo for Architectural Products 2022 Product Innovation Awards`,
                containImg: true,
                ctaText: 'Learn More',
                ctaUrl: 'https://issuu.com/constructionbusinessmedia/docs/2211_archprod/58',
            },
        ],
        backgroundCol: 'purple',
    },
	{
        id: 'solutions',
		class: 'tabs',
		title: 'No Gas Furnace? No Problem.',
        blurb: 'Explore our variety of ductless and ducted indoor product styles.',
        tabs: tabsIndoor,
        hasNavAnchor: true,
	},
    benefits({ color: 'yellow-purple' }),
	subscribe({ campaignId: hero.subscriberCampaignId })
]

const header = {
	// phone: buPhone,
	pageTitle: 'Mitsubishi Electric Heat Pumps - Save with Rebates & Tax Credits',
	pageDesc: 'Experience year-round comfort and energy efficiency with Mitsubishi Electric\'s heat pump systems. Take advantage of exclusive utility rebates and tax credits now!',
	pageImg: '/images/discover-site-preview.webp',
	ctaText: 'Get Started',
	ctaUrl: 'form-section',
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
