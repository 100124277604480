import campaign from './campaigns/national'
// import altText from './static-content/altText'
import benefits from './static-content/benefits'
import control from './static-content/control'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import rebates from './static-content/contentBoxRebates'
import ira25C from './static-content/ira-25c'
import subscribe from './static-content/subscribe'

// const buPhone = '866-419-2513'

const hero = campaign()

const sections = [
	ira25C({ hasNavAnchor: true }),
	{
		id: 'explore-solutions',
		class: 'tabs-indoor-solutions',
		title: 'Explore Indoor Solutions',
		hasNavAnchor: true 
	},
	control({}),
	benefits({ id:'Why Mitsubishi?', hasNavAnchor: true, color: 'transparent' }),
	{
		id: 'support',
		class: 'three-column-cards-w-image',
		title: 'The Support You Need',
		contentBoxes: [
			financing,
			warranty,
			rebates,
		],
		backgroundCol: 'yellow-purple',
	},
	subscribe({ campaignId: hero.subscriberCampaignId })
]

const header = {
	// phone: buPhone,
	pageTitle: 'Mitsubishi Electric Heat Pumps - Save with Rebates & Tax Credits',
	pageDesc: 'Experience year-round comfort and energy efficiency with Mitsubishi Electric\'s heat pump systems. Take advantage of exclusive utility rebates and tax credits now!',
	pageImg: '/images/discover-site-preview.webp',
	ctaText: 'Get Started',
	ctaUrl: 'form-section',
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
