import campaign from './campaigns/mabu-ferguson'
import altText from './static-content/altText'
import svz from './static-content/svz-ducted'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import download from './static-content/download'
import ira25C from './static-content/ira-25c'
import kumo from './static-content/control'
import solutions from './static-content/solutions'
import benefits from './static-content/benefits'

const buPhone = '855-745-4749'

const hero = {
    ...campaign(),
    formInstructions:
        'Fill out this form and a qualified HVAC contractor in your area will contact you to answer your questions and schedule an on-site consultation.',
    thankYou: 'Ask your contractor to help you stack up the rebates from Mitsubishi and from your local utilities.',
}

const sections = [
    ira25C({ hasNavAnchor: true }),
    {
        id: 'all-electric',
        class: 'two-column-img-left',
        title: 'All-electric Solutions',
        // blurb: 'For Fuel-free Heating and Air Conditioning',
        detailsText:
            'The world is gradually moving to cleaner electrical energy to run HVAC systems. HVAC systems powered by electricity provide more comfort and flexibility for your home. Thanks to Mitsubishi Electric, you now have options to help make your home safer and more energy efficient, reduce your carbon footprint and impact on the climate.',
        imgSrc: '/images/earth-heat-pump-1000px.webp',
        imgAlt: `${altText} earth and outdoor heat pump unit`,
        ctaText: 'How heat pumps work',
        videoSrc: 'ljkDJbnvwqs',
        backgroundCol: 'gray',
    },
    svz({}),
    {
        id: 'Applications',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        // title: 'Going Ductless',
        blurb: 'A Solution For\nAny Space',
        // detailsText: 'Experience ultimate comfort and control in any room.',
        iconList: {
            title: 'Experience ultimate control in any room, whether ducted or ductless, by easily customizing your comfort settings based on your needs and lifestyle. Heat pumps are perfect for',
            items: [
                {
                    iconId: 'check',
                    text: 'Over-garage rooms',
                },
                {
                    iconId: 'check',
                    text: 'Workshops and Outbuildings',
                },
                {
                    iconId: 'check',
                    text: 'Attics',
                },
                {
                    iconId: 'check',
                    text: 'Additions',
                },
                {
                    iconId: 'check',
                    text: 'Garages or conversions',
                },
                {
                    iconId: 'check',
                    text: 'Bedrooms with hot/cold spots',
                },
                {
                    iconId: 'check',
                    text: 'Home offices',
                },
                {
                    iconId: 'check',
                    text: 'Whole-home heating and cooling',
                },
                {
                    iconId: 'check',
                    text: 'Basements',
                },
            ],
        },
        imgSrc: '/images/res-applications.webp',
        imgAlt: `${altText} 4 examples of interior applications for ductless heat pump systems, top left is a man in a home office, top right is a woman exercising in her home gym, bottom left is a garage/workshop, and bottom left is the interior of a tiny home.`,
    },
    solutions({ ctsText: 'Get Started', id: 'solutions2' }),
    benefits({ color: 'transparent', hasNavAnchor: true }),
    kumo({ color: 'transparent' }),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Support You Need',
        contentBoxes: [
            financing,
            warranty,
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} call us for support`,
                blurb: 'Contact Us',
                detailsText:
                    "Have questions? Need support? Whether you're just doing research, or are ready to purchase, we are here to help. Reach out to our Customer Care team at:",
                phone: buPhone,
            },
        ],
        backgroundCol: 'blue',
    },
    download({}),
]

const header = {
    pageTitle: "Stay cozy and save energy in the Northeast with Mitsubishi Electric's all-climate heat pumps",
    pageDesc:
        "Find year-round comfort with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where performance meets energy efficiency. Also take advantage of a $2,000 tax credit for a limited time!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: hero.ctaText,
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
