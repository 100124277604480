import { useState, useEffect } from 'react'

export default function LoadingSpinner({
    stop,
    loadingMessage,
    errorMessage = 'Error loading content',
    hideMessage,
    infinite,
    timeout
}) {
    const [spinnerIsVisible, setSpinnerIsVisible] = useState(true)

    useEffect(() => {
        if (stop) setSpinnerIsVisible(false)
        if (!infinite) {
            setTimeout(() => {
                setSpinnerIsVisible(false)
            }, timeout || 5000)
        }
    }, [stop, infinite, timeout])

    return (
        <section className="spinner-component-wrapper" style={{ gridArea: 'unset' }}>
            {!hideMessage && spinnerIsVisible && <h5 className="center">{loadingMessage}</h5>}
            {!spinnerIsVisible && <h6 className="center">{errorMessage}</h6>}
            <div className="spinner-symbols-wrapper">
                {spinnerIsVisible && (
                    <>
                        <div id="spinner1"></div>
                        <div id="spinner2"></div>
                        <div id="spinner3"></div>
                        <div></div>
                    </>
                )}
            </div>
        </section>
    )
}
