import { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import SectionFrame from './SectionFrame'
import Button from './modular/Button'
import Code from './modular/Code'

export default function PageFrames() {
    const brands = ['mitsubishi-electric', 'trane', 'american-standard']
    const tabs =['general', 'applications', 'indoor-models', 'intelli-heat']
    const codeBtns = ['html', 'jsx']

    const [brandSelected, setBrandSelected] = useState('mitsubishi-electric')
    const [contentTypeSelected, setContentTypeSelected] = useState('select')
    const [codeBtnSelected, setCodeBtnSelected] = useState('select')

    const handleBrandClick = (e) => {
        setCodeBtnSelected('select')
        setBrandSelected(brands[brands.findIndex((brand) => brand === e.target.id)])
    }

    const handleTabClick = (e) => {
        setCodeBtnSelected('select')
        setContentTypeSelected(tabs[tabs.findIndex((tab) => tab === e.target.id)])
    }
    const handleCodeBtnClick = (e) => setCodeBtnSelected(codeBtns[codeBtns.findIndex((btn) => btn === e.target.id)])

    const capitalize = (text) => text.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    const makeTitle = (text) => capitalize(text.replace('-', ' '))
    const makeJsxTitle = (text) => capitalize(text.replace('-', ' ')).replace(' ', '')

    const htmlCode =
`<section id='metus-${contentTypeSelected}-content' data='${brandSelected}'></section><script src='https://iframe-scripts.s3.us-east-2.amazonaws.com/iframe-${contentTypeSelected}.min.js' defer></script>`

    const jsxCode =
`import react from 'react';
export default function Frame${makeJsxTitle(contentTypeSelected)}() {
    return (
        <section style={{margin:'0',padding:'0',box-sizing:'border-box',height:'1060px'}}>
            <div style={{position:'relative',height:'1060px',overflow:'hidden'}}>
            <iframe
                name='Mitsubishi Electric HVAC'
                style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',borderWidth:'0'}}
                src='https://discover.mitsubishicomfort.com/iframe-${contentTypeSelected}?branding=${brandSelected}'
                width='100%'
                height='1000px'
            />
            </div>
        </section>
    )
};`

    const [codeDisplayed, setCodeDisplayed] = useState(htmlCode)

    useEffect(() => {
        switch (codeBtnSelected) {
            case 'html': return setCodeDisplayed(htmlCode)
            case 'jsx': return setCodeDisplayed(jsxCode)
            default: return
        }
    }, [codeBtnSelected, htmlCode, jsxCode])

    return (
        <>
            <Header branding='metus' noCta/>
            <main>
                <section className='two-column iframe-instructions-hero'>
					<div className="section-content-wrapper">
						<div>
							<h1 className='h4 mar-btm-xs'>Mitsubishi Electric Trane</h1>
							<h2 className='h1 mar-top-0'>Embeddable Web Content</h2>
						</div>
						<div>
							<h2 className='h3'>Installation Guide</h2>
							<p>Follow the steps below to install embeddable METUS content on your website. For additional help, please contact your METUS Regional Marketing Manager.</p>
						</div>
						
					</div>
                </section>
                <section id='step-1'>
                    <div style={{margin: 'var(--xl2) 0', width: '100%', backgroundColor:'var(--text-col)', color:'var(--site-bg)'}}>
                        <h3 style={{padding: 'var(--sm)', textAlign: 'center', color:'var(--site-bg)'}}>STEP 1</h3>
                    </div>
                    <div className='section-content-wrapper'>
                        <div>
                            <div style={{margin: '0 auto', maxWidth: 'var(--content-width)'}}>
                                <h4>Select Your Content</h4>
                                <p>Each content block has a different focus, including products, applications, and technology. Choose content that best fits the page you will embed it on.</p>
                            </div>
                            <h5 className='center'>First, choose a brand:</h5>
                            <section className='tabs' style={{marginTop:'0'}}>
                                <nav>
                                    {brands.map((brand, index) =>
                                        <Button
                                            key={index}
                                            id={brand}
                                            className={brand===brandSelected ? `btn-tab selected` : `btn-tab`}
                                            onClick={handleBrandClick}
                                            text={makeTitle(brand).toUpperCase()}
                                        />
                                    )}
                                </nav>
                            </section>
                            <h5 className='center'>Then choose the type of content:</h5>
                            <section className='tabs' style={{marginTop:'0'}}>
                                <nav>
                                    {tabs.map((tab, index) =>
                                        <Button
                                            key={index}
                                            id={tab}
                                            className={tab === contentTypeSelected ? `btn-tab selected` : `btn-tab`}
                                            onClick={handleTabClick}
                                            text={makeTitle(tab).toUpperCase()}
                                        />
                                    )}
                                </nav>
                            </section>
                        
                        </div>
                    </div>
                    {contentTypeSelected === 'select' && 
                        <div className='iframe-preview-wrapper' >
                            <h5>Preview content here by choosing a brand and the type of content above.</h5>
                        </div>
                    }
                    {contentTypeSelected !== 'select' && 
                        <>
                            <h5 style={{textAlign: 'center', margin: 'auto'}}>
                                Now preview the content below:
                            </h5>
                            <div className='iframe-preview-wrapper' style={{position: 'relative'}}>
                                
                                { contentTypeSelected !== 'rebates'
                                    ? 	<SectionFrame 
                                            title={`${brandSelected} iframe content`}
                                            url={`https://discover.mitsubishicomfort.com/iframe-${contentTypeSelected}?branding=${brandSelected}`}
                                            pxHeight='1060'
                                        />
                                    : 	<h5 style={{color: 'var(--accent)', textAlign:'center'}}>
                                            {makeTitle(contentTypeSelected)} Content Coming Soon
                                        </h5>
                                }
                            </div>
                        </>
                    }
                </section>

                <section id='step-2' style={{marginBottom: 'var(--xl6)'}}>
                    <div style={{margin: 'var(--xl2) 0', width: '100%', backgroundColor:'var(--text-col)', color:'var(--site-bg)'}}>
                        <h3 style={{padding: 'var(--sm)', textAlign: 'center', color:'var(--site-bg)'}}>STEP 2</h3>
                    </div>
                    <div className="section-content-wrapper" >
                        <div>
                            <h4>Select a code format below and then copy the displayed code</h4>
                            <p>After the code is generated below, click on the <em>clipboard</em> button at the top right of the code box below to copy it to your device's clipboard.</p>
                            <h5>HTML</h5>
                            <p>In most case, you should select the <strong>HTML</strong> button below. As with most modern web pages, JavaScript must be enabled on users' computers in order to view this dynamic Mitsubishi Electric content.</p>
                            <h5>JSX</h5>
                            <p>If you're not sure what <strong>JSX</strong> is, you should probably use <strong>HTML</strong> instead. Only websites built using React.js should use <strong>JSX</strong> code to embed Mitsubishi Electric content.</p>
                        </div>
                        <div className='single-select-btn-row'>
                            {codeBtns.map((btn, i) => 
                                <Button 
                                    key={i+btn} 
                                    id={btn} 
                                    className={btn === codeBtnSelected ? 'selected' : undefined} 
                                    onClick={handleCodeBtnClick}
                                    text={btn.toUpperCase()}
                                />
                            )}
                        </div>

                        {contentTypeSelected === 'select'
                            ? 	<div className='iframe-code-placeholder'>
                                    <h5>Select an option from Step 1 first.</h5>
                                </div>
                                
                            : codeBtnSelected ==='select'
                                ? 	<div className='iframe-code-placeholder'>
                                        <h5>Select a code format above.</h5>
                                    </div>
                                : contentTypeSelected !== 'rebates'
                                    ? 	<Code
                                            text={codeDisplayed}
                                            language={codeBtnSelected}
                                        />
                                    : 	<div className='iframe-code-placeholder'>
                                            <h5>Coming Soon</h5>
                                        </div>
                        }
                        
                    </div>
                </section>

                <section id='step-3' style={{marginBottom: 'var(--xl6)'}}>
                    <div style={{margin: 'var(--xl2) 0', width: '100%', backgroundColor:'var(--text-col)', color:'var(--site-bg)'}}>
                        <h3 style={{padding: 'var(--sm)', textAlign: 'center', color:'var(--site-bg)'}}>
                            STEP 3
                        </h3>
                    </div>
                    <div className="section-content-wrapper" >
                        <div>
                            <h4>Paste the code onto your page</h4>
                            <p>Here's where you may need help from a web developer.</p>
                            <h5>Wordpress</h5>
                            <p>For most Wordpress pages, the above <strong>HTML</strong> code can simply be pasted directly into the page's <em>.php</em> file in the location you want the content to appear.</p>
                            <p>Due to the abundance of Wordpress themes and drag-and-drop plugins, the steps for adding custom HTML can vary. Here is a common way to <a href='https://wordpress.org/support/article/custom-html/' target='_blank' rel='noreferrer'>embed custom HTML on a Wordpress page</a>.</p>
                            <h5>Wix, SquareSpace, and Other Site Builders</h5>
                            <p>Most "code-free", drag-and-drop website builders have the option of inserting custom HTML code. Please see the documentation provided by your site builder. Here are links to some of the most common site builders' support articles.</p>
                            <ul>
                                <li><a href='https://support.wix.com/en/article/wix-editor-adding-code-to-your-site' target='_blank' rel='noreferrer'>WIX</a> - or download our <a href='/METUSiFrameForWixGuide.pdf'>step-by-step PDF guide</a> for WIX websites.</li>
                                <li><a href='https://support.squarespace.com/hc/en-us/articles/205815928-Adding-custom-code-to-your-site' target='_blank' rel='noreferrer'>SquareSpace</a></li>
                                <li><a href='http://help.neo.registeredsite.com/Default.htm#HTML%20Snippet%20Code/How_Do_I_Set_Up_the_HTML_Code_Snippet.htm?TocPath%3DApps%7cWorking%2520with%2520HTML%2520Snippets%7c_____2' target='_blank' rel='noreferrer'>web.com</a></li>
                                <li><a href='https://www.godaddy.com/help/add-html-or-custom-code-to-my-site-27252' target='_blank' rel='noreferrer'>GoDaddy</a></li>
                                <li><a href='https://www.weebly.com/app/help/us/en/topics/create-widgets-embed-code-and-add-external-content' target='_blank' rel='noreferrer'>Weebly</a></li>
                                <li><a href='https://support.zyro.com/en/articles/5139975-embed-a-custom-code' target='_blank' rel='noreferrer'>Zyro</a></li>
                            </ul>
                            <h5>React.js Pages</h5>
                            <p>Treat the above <strong>JSX</strong> code as its own component, i.e., saved in it's own file, then imported into the page/parent component.</p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}