import { Helmet } from 'react-helmet'
import Logo from './modular/Logo'
import Footer from './Footer'
import ScrollLink from './modular/ScrollLink'
import SectionProduct from './SectionProduct'
import { hero, sections } from './page content/pageNewProductsContent'

export default function PageNewProducts() {
	const year = new Date().getFullYear()
	const title = `The latest HVAC products in ${year} from Mitsubishi Electric`
	const description = `Learn about our newest residential and commercial products in ${year}`

	return (
		<>
			<Helmet>
				<title>{title}</title>
                <meta name='description' content={description} />
                <meta property="og:image" content='/images/new-products-preview.webp' />
				<meta property="twitter:card" content="summary_large_image" />

				{/* DESCRIPTION */}
				<meta name="description" content={description} />
				<meta property="og:description" content={description} />

				{/* TITLE */}
				<meta property="og:title" content={title} />
				<title>{title}</title>
			</Helmet>
			<main>
				<section
					id={hero.id}
					className={`section ${hero.class}`}
					style={{backgroundImage: `var(--bg-dk-overlay), url(${hero.backgroundSrc})`}}
				>
					<div className="section-content-wrapper dark-mode">
						<Logo
							darkMode
							metusLogo='true'
							width='clamp(250px, 30vw, 450px)'
							height='clamp(70px, 8vw, 110px)'
							style={{borderRadius: '0'}}
						/>
						<div className='title-box'>
							<h1>Designed for life,</h1>
							<h1>inside and out.</h1>
						</div>
						<h4>{year} New Products</h4>
					</div>
					
				</section>
				<nav className='nav-bar below'>
					<ScrollLink className='h6' to={`#Residential`}>Residential</ScrollLink>
					<ScrollLink className='h6' to={`#Commercial`}>Commercial</ScrollLink>
					<ScrollLink className='h6' to={`#Controls`}>Controls</ScrollLink>
				</nav>
				{ sections.map((section) => 
					<SectionProduct 
						key={section.id}
						id={section.id}
						className={section.class}
						title={section.title}
						products={section.products}
					/>
				)}
			</main>
			<Footer metusLogo='true'/>
		</>
	)
}