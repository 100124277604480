import ScrollLink from './modular/ScrollLink'

export default function SectionFourColumn(props) {
	return (
		<>
			<h2>{props.title}</h2>
			<div className="four-column-wrapper">
				{props.columns.map((column, index) =>
					<div key={index} className="column-wrapper">
						<img className={props.imgBg ? 'image-background' : undefined} src={column.imgSrc} alt={column.imgAlt}/>
						<div className="column-text">
							<h3 className='h5'>{column.title}</h3>
							<p>{column.text}</p>
						</div>
					</div>
				)}
			</div>
			{props.ctaText &&
				<ScrollLink
					className='btn'
					to={`#form-section`}
					style={{ textTransform:'capitalize' }}
				>
					{props.ctaText}
				</ScrollLink>}
		</>
	)
}

