import campaign from './campaigns/nebu'
import altText from './static-content/altText'
import productRange from './static-content/product-range'
import hotelMarcel from './static-content/hotel-marcel'

const bu = campaign()
const buPhone = '855-897-9524'
const buHours = 'Mon-Fri, 8AM-5PM EST'

export const hero = {
    ...bu,
	form: 'webinar',
	staticCampaign: false,
	title: '',
	blurb: 'Future-Proven Innovation and Mass Save® Incentives',
	detailsText: 'Register to attend our FREE webinar Wednesday, August 9, 2023, at 1:00 p.m. EST to learn about how you can optimize your commercial building’s potential, and take advantage of Massachusetts’ VRF incentives, providing a remarkable $3,500 per ton.',
	footnote: '',
	ctaText: '',
    formTitle: 'Register Here',
	whatsNext: '',
	backgroundSrc: '/images/lifestyle/interior/ME_CM_PLA-w-sens_Gym-2000px.webp',
	leadCampaignId: '7013q000001okTDAAY',
}

export const sections = [
    {
        id: 'Case Studies',
        class: 'three-column-cards-w-image',
        hasNavAnchor: true,
        title: 'How Others Are Saving',
        detailsText: `Review some of our recent case studies below to see how Mitsubishi Electric HVAC products are benefitting owners, facility managers, and residents in commercial applications.`,
        contentBoxes: [
            {
                title: 'Capitol Seniors Housing',
                blurb: 'NY, NJ, IL, MA, and MD',
                detailsText: '"Improved comfort, low operating costs and support from Mitsubishi Electric and their Diamond Commercial Contractors are clear positives."',
                quoteSource: 'Jim May, Director of Construction, Capitol Seniors Housing',
                imgSrc: '/images/capitol_seniors_housing.webp',
                imgAlt: `${altText} Capitol Seniors Housing senior assisted living building exterior`,
                // ctaText: `Download Case Study`,
                // downloadUrl: '/_____________.pdf'
            },
            {
                title: 'Willow School',
                blurb: 'Gladstone, New Jersey',
                detailsText: `"We had to remain net-zero or net positive. With solar panels and the Mitsubishi Electric system we were able to achieve that. When people look at this building, they’re looking at one of the most energy-efficient buildings in the country."`,
                imgSrc: '/images/willow-school.webp',
                imgAlt: `${altText} case study - Willow School exterior`,
                quoteSource: 'Mark Biedron, Co-founder'
            },
            {
                title: 'Stackhouse Apartments',
                blurb: 'Seattle, WA',
                detailsText: `"Beyond just achieving LEED Platinum, these apartments are among the most energy-efficient in the Pacific Northwest. It’s an important, ambitious program, and we’re proud to have met it. The Mitsubishi Electric system was of course a part of that success."`,
                quoteSource: 'Jon Heller, PE, president, Ecotope (Engineering)',
                imgSrc: '/images/stackhouse-apts.webp',
                imgAlt: `${altText} Stackhouse Apartments multi-family project building exterior`,
            },
        ],
        backgroundCol: 'gray',
    },
    hotelMarcel({}),
    {
        id: 'Why VRF?',
		class: 'one-column-list',
        hasNavAnchor: true,
		title: `Why Use Mitsubishi Electric VRF?`,
		listItems: [
            {
                iconId: 'money',
				title: 'Lower Lifecycle Cost',
				description: 'With ease of design and installation, minimal required maintenance, superior energy efficiency, and long-lasting equipment, your client\'s total lifecycle costs are less than they might have thought.'
			},
			{
                iconId: 'fresh-air-intake',
				title: 'Outside Air and Ventilation',
				description: 'Energy recovery ventilators (ERVs) exhaust outside air to rid your building of toxins, odors and viruses, bacteria and other potentially harmful contaminants. They also improve HVAC system efficiency by preheating or cooling incoming outside air with available energy from the exhaust air.'
			},
			{
                iconId: 'thermostat',
				title: 'Precision Control',
				description: 'Precise zoning with independent control gives building managers, residents and staff control over their own comfort. Indoor units can sense temperature, humidity, lighting, and occupancy to improve comfort control and system efficiency.'
			},
			{
                iconId: 'sustainable',
				title: 'Quiet Operation',
				description: 'Even though your building managers, residents, and staff will barely hear them, Mitsubishi Electric Trane HVAC US VRF systems are working hard to maintain the ideal comfort conditions. Indoor units are whisper quiet and outdoor units operate at levels as low as 58 dB(A).'
			},
		],
		backgroundCol: 'gray'
	},
    productRange({}),
    {
		id: 'massSave',
		class: 'two-column-img-left',
		title: 'Cut Heating and Cooling Costs',
		blurb: 'By up to 30% with air source heat pumps',
		detailsText: '',
		imgSrc: '/images/mass-save-vector-logo.webp',
		imgAlt: `${altText} mass save program logo`,
		ctaText: 'Learn More',
        ctaUrl: 'https://www.masssave.com/business/rebates-and-incentives/heating-and-cooling/heat-pumps/air-source-heat-pumps',
		backgroundCol: 'transparent'
	},
]

export const products = [
    {
        id: 'Products',
        class: 'new-products-section',
        title: 'Innovative Commercial Products',
        products: [
            {
                id: 'hvrf',
                title: 'Hybrid VRF™',
                productLogo: 'hybrid-verf-logo.png',
                logoHeight: '100px',
                name: null,
                model: null,
                topImageSrc: '/images/new-products/Hybrid_VRF_3.webp',
                imageSrc: '/images/new-products/Hybrid_VRF_2.webp',
                description:
                    'The world’s only two-pipe Hybrid Variable Refrigerant Flow (HVRF) system that heats and cools simultaneously without the need for refrigerant in occupied spaces. Hybrid VRF can be tailored to fit many use requirements and is utilized in a wide variety of applications that require individual settings such as hotels, offices, hospitals, multi-family, schools, and senior living facilities.',
                features: [
                    {
                        icon: 'Hybrid_VRF_4',
                        title: 'Simultaneous Heating and Cooling',
                        description:
                            'Hybrid VRF uses CITY MULTI® N-Generation outdoor units. Air or water source units connect to the Hybrid Branch Controller (HBC) by a two-pipe refrigerant system, allowing for simultaneous heating and cooling.',
                    },
                    {
                        icon: 'Hybrid_VRF_5',
                        title: 'Hybrid BC Controller (HBC)',
                        description:
                            'HYBRID VRF exchanges heat between refrigerant and water using our unique HBC, which reduces overall refrigerant charge in the system and keeps the piping in the indoor space refrigerant-free.',
                    },
                    {
                        icon: 'Hybrid_VRF_6',
                        title: 'Air Source or Water Source',
                        description: 'Choose between air or water source units to achieve the highest efficiency.',
                    },
                    {
                        icon: 'Hybrid_VRF_7',
                        title: 'Exponentially Scalable',
                        description: 'Similar to traditional VRF, HVRF is scalable, making it a perfect fit for any size building.',
                    },
                ],
            },
            {
                id: 'heat2o',
                title: 'HEAT₂O™',
                productLogo: 'heat2o-logo.png',
                model: 'QAHV-N136TAU-HPB-(-BS)',
                topImageSrc: '/images/new-products/Heat2o_3.webp',
                imageSrc: '/images/new-products/Heat2o_1.webp',
                description:
                    'HEAT₂O is an all-electric heat pump water heater designed to produce high volume DHW for commercial facilities in any climate. Energy-efficient and environmentally friendly, HEAT₂O uses a natural CO2 refrigerant with a global warming potential (GWP) of one and an ozone depletion potential (ODP) of zero.',
                features: [
                    {
                        icon: 'Heat20_4',
                        title: 'Zero Emissions',
                        description: 'The perfect zero emission commercial solution for domestic hot water.',
                    },
                    {
                        icon: 'Heat20_5',
                        title: 'Twisted Spiral Gas Cooler',
                        description:
                            'Our patented twisted spiral gas cooler lives at the heart of HEAT₂O. Three connected refrigerant pipes are wound around a twisted water pipe, which maximizes heat transfer.',
                    },
                    {
                        icon: 'Heat20_6',
                        title: 'Natural Refrigerant',
                        description:
                            'CO₂ based, the HEAT₂O has a GWP of one, providing a reduced carbon footprint and a highly efficient means of producing 176° F temperature water in outdoor temperatures as low as -13° F.',
                    },
                    {
                        icon: 'Heat20_7',
                        title: 'High Efficiency',
                        description: 'COP up to 4.52, making this the most efficient water heating source in the industry.',
                    },
                ],
            },
            {
                id: 'bcp200',
                title: 'Building Connect+ 200',
                productLogo: 'bcp-200-logo.png',
                logoHeight: '120px',
                name: null,
                model: 'BCP-200',
                topImageSrc: '/images/new-products/BCP_3.webp',
                imageSrc: '/images/new-products/BCP_2.webp',
                description:
                    'Our easy and reliable cloud-based platform allows you to monitor and control your CITY MULTI® and third-party equipment in one convenient web portal. Seamlessly connect up to 4 centralized controllers, 200 indoor units and 10 BACnet® devices. This Contractor-focused integration package reduces initial costs and commissioning times.',
                features: [
                    {
                        icon: 'BCP_4',
                        title: 'Self Discovery',
                        description: 'Auto-import functionality identifies and adds new units during startup and commissioning.',
                    },
                    {
                        icon: 'BCP_5',
                        title: 'Easy Access',
                        description: 'Local and remote user connectivity using computer, tablets, or mobile devices.',
                    },
                    {
                        icon: 'BCP_6',
                        title: 'Maintenance Tool Data',
                        description: 'Full set of Mitsubishi Electric CITY MULTI maintenance tool data for monitoring, troubleshooting, and analytics.',
                    },
                    {
                        icon: 'BCP_7',
                        title: 'Up to 200 Units',
                        description: 'Seamlessly connect up to 4 Central Controllers, 200 VRF indoor units, and 10 BACnet devices.',
                    },
                ],
            },
        ],
    },
]

const navItems = sections.map((section) => section)
navItems.push('Products')

export const header = {
	phone: buPhone,
	hours: buHours,
	ctaText: 'Get Registered',
	// ctaUrl: 'subscriber-form',
	pageSections: navItems,
}
