const IEMessage = () => {
  return (
    <h4 style={{textAlign: 'left', maxWidth: '60%', margin: '120px auto'}}>
			This webpage has modern features not supported by your current web browser, Internet Explorer.
			<br></br>
			<br></br>
			Please consider opening this webpage in <a target='_blank' rel='noreferrer' href='https://www.microsoft.com/en-us/edge'>Edge</a>, <a target='_blank' rel='noreferrer' href='https://www.google.com/chrome/'>Chrome</a>, or <a target='_blank' rel='noreferrer' href='https://www.mozilla.org/en-US/firefox/windows/'>Firefox</a>. 
			<br></br>
			<br></br>
			On July 15, 2022, Internet Explorer will no longer be supported by Microsoft, and will be replaced by Microsoft <a target='_blank' rel='noreferrer' href='https://www.microsoft.com/en-us/edge'>Edge</a> as Windows' default web browser <a target='_blank' rel='noreferrer' href='https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/'>(read more)</a>.
		</h4>
  )
}

export default IEMessage