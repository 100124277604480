import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start =  '3/1/2024'
    const end =  '12/31/2024'
    const amount = 0

    const data = {
        // defaults
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000002FARxAAO',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/nebu-spring-hero-img.webp',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: defaults.footnote,
        defaultWhatsNext: defaults.whatsNext,
        utilityProduct: defaults.utilityProduct,
        // ME_Residential_NYSERDA_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        staticCampaign: true,
        campaignId: '701Pd000005ttyfIAA',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignWhatsNext: '',
        campaignImage: defaults.backgroundSrc,
        campaignTitle: 'Designed for life, inside and out.',
        campaignBlurb: '',
        campaignDetails:
        'All-electric, all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs.\n\nNew York homeowners may be eligible to receive up to $12,000 in local electric utility rebates and federal tax credits for installing qualified Mitsubishi Electric Heating & Air Conditioning systems. Read on to discover how to elevate your home\'s comfort.',
        campaignFootnote: `Utility rebates and incentives are provided, while funds are available, through New York State utility companies/providers and vary by location, product purchased, purchase date, and other conditions. Your local utility provider can provide more detailed rebate information. Consult your tax advisor about federal tax credits available.`,
        showFootnote: true,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}