import altText from './static-content/altText'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import ira25C from './static-content/ira-25c'
import allElectric from './static-content/allElectric'
import benefits from './static-content/benefits'
import lorem from './static-content/lorem'

const buPhone = '123-456-7890'

const hero = {
    class: 'hero',
    form: 'ira',
	staticCampaign: true,
    title: 'How Much Could You Save on All-Electric HVAC?',
    // blurb: 'See What You Qualify For',
    detailsText: 'Find out if you may be eligible for a heat pump system at little or no cost to you',
    // ctaText: bu.ctaText,
    formInstructions: 'Find out what incentives and credits you may qualify for by entering your information here.',
    whatsNext: '',
    thankYou: lorem.two,
    backgroundSrc: '/images/ira-rebates-credits.webp',
    leadCampaignId: '',
    subscriberCampaignId: '',
    hideDownArrow: true,
	footnote: 'Pricing for heat pump systems is determined by qualified installing contractors, and any incentives you qualify for would be offset against the price. Only qualified installing contractors are eligible to administer these incentives; if you use an installing contractor who is not so qualified, you cannot utilize any incentives you would otherwise be qualified for. The incentives displayed are based on the Inflation Reduction Act\'s current legislation, do not constitute a legally binding agreement, and may be subject to change. By submitting your information, you attest to its accuracy and recognize that your final qualification status will be determined by the federal government.'
}

const sections = [
	ira25C({ hasNavAnchor: true }),
	allElectric({ color:'gray', title: 'Electric Solutions', hasNavAnchor: true}),
	benefits({ color: 'transparent', hasNavAnchor: true }),
	{
		id: 'Support',
		class: 'three-column-cards-w-image',
		title: 'The Support You Need',
		contentBoxes: [
		  financing,
		  warranty,
			{
				iconSrc: 'handshake',
				iconAlt: `${altText} call us for support`,
				blurb: 'Contact Us',
				detailsText: 'Have questions? Need support? Whether you\'re just doing research, or are ready to purchase, we are here to help. Reach out to our Customer Care team at:',
				phone: buPhone
			},
		],
		backgroundCol: 'blue',
	},
]

const header = {
	pageTitle: hero.title,
	pageDesc: 'Under the Inflation Reduction Act, you may be eligible for substantial savings when you switch to energy-efficient heat pumps. Use our calculator to see what savings you could qualify for.',
	pageImg: hero.backgroundSrc,
	phone: buPhone,
	ctaText: 'Get Pre-Qualified',
  	ctaUrl: 'form-section',
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
