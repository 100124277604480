import { useState, useEffect, useRef } from 'react'

export default function InputSelect(props) {
    const [inputClassName, setInputClassName] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)

    const inputRef = useRef()
    const isRequired = props.required
    const labelClassName = `${isRequired ? 'required' : ''} offset-label ${props.className ? props.className : ''}`

    // listen for autofill changes 
	useEffect(() => {
		let interval
		if (!isFocused) {
			interval = setInterval(() => {
				if (inputRef.current && inputRef.current.value !== '') {
					setWasFocused(true)
					clearInterval(interval)
				}
			}, 500) // polling every second
		}
		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [isFocused])

    useEffect(() => {
        if (wasFocused) {
            if (!props.isValid && isRequired) {
                setInputClassName('invalid capitalize')
            } else {
                setInputClassName('valid capitalize')
            }
        }
    }, [props.errorMsg, props.label, props.isValid, wasFocused, isRequired])

    const handleFocus = (e) => {
		setIsFocused(true)
		props.onFocus && props.onFocus(e)
	}

	const handleBlur = (e) => {
		setIsFocused(false)
		setWasFocused(true)
		props.onBlur && props.onBlur(e)
	}

    const handleChange = (e) => {
        props.onChange && props.onChange(e)
        if (props.isValid && isRequired) {
            setInputClassName('valid capitalize')
        } else {
            setInputClassName('invalid capitalize')
        }
    }

    return (
        <div id={`${props.id}-wrapper`} className='input-wrapper'>
            <select
                ref={inputRef}
                id={props.id}
                className={inputClassName}
                name={props.name}
                placeholder={props.placeholder}
                aria-labelledby={`${props.id}-label`}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyPress={props.onKeyPress}
                onPointerDown={props.onPointerDown}
                onClick={props.onClick}
                required={isRequired}
                aria-required={isRequired}
                value={props.value}
                // defaultValue={props.options[0]}
            >
                <option value='' disabled></option>
                {props.options.map((option, i) => 
                    <option key={i} value={option}>
                        {props.displayedOptions[i]}
                    </option>
                )}
            </select>
            <label 
                id={`${props.id}-label`}
                htmlFor={props.id}
                className={labelClassName}
            >
                {props.label}
            </label>
        </div>
    )
}
