import { useState, useEffect } from 'react'

import PanelHero from './PanelHero'
import LeadFormRebate from './LeadFormRebate'
import LeadFormTest from './LeadFormTest'
import LeadFormStd from './LeadFormStd'
import LeadFormWebinar from './LeadFormWebinar'
import LeadFormIRA from './LeadFormIRA'
import SubscriberForm from './SubscriberForm'
import DownArrow from './modular/DownArrow'
import YouTube from './modular/YouTube'

export default function HeroSection({ content }) {
    const [heroSectionID, setHeroSectionID] = useState('hero')

    useEffect(() => {
        // console.log(content)
        content.GTMTrigger='hero_form'
        content.ctaText=!content.form && content.ctaText
        content.subscriberCta=content.class?.includes('no-form') && content.subscriberCta
        if(content.form) {
            setHeroSectionID('form-section')
        }
    }, [content])

    const backgroundAlignment = content.imageAlign ? `${content.imageAlign} center` : 'top center'
    
    const heroBackground = content.backgroundSrc
        ? { backgroundImage: `var(--text-bg), url(${content.backgroundSrc})`, backgroundPosition: backgroundAlignment  }
        : { background: `var(--bg-gradient-${content.backgroundCol})`}

    return (
        <section
            id={heroSectionID}
            className={`section hero`}
            style={heroBackground}
        >
            {!content.backgroundSrc.includes('webp') && 
                <YouTube
                    isBackground
                    overlay={heroBackground}
                    autoPlay
                    loop
                    videoSrc={content.backgroundSrc}
                />
            }
            <div className="section-content-wrapper">
                <PanelHero
                    content={content}
                    panelClass='no-glass'
                />
                {!content.hideDownArrow && <DownArrow scrollTo={content.scrollTo} />}
                {content.form === 'standard' &&
                    <LeadFormStd
                        content={content}
                        formClass='glass-panel standard-form-wrapper'
                    />
                }
                {content.form === 'rebate' &&
                    <LeadFormRebate
                        content={content}
                        formClass='glass-panel rebate-form-wrapper'
                    />
                }
                {content.form === 'ira' &&
                    <LeadFormIRA
                        content={content}
                        formClass='glass-panel rebate-form-wrapper'
                    />
                }
                {content.form === 'webinar' &&
                    <LeadFormWebinar
                        content={content}
                        formClass='glass-panel rebate-form-wrapper'
                    />
                }
                {content.form === 'test' &&
                    <LeadFormTest
                        content={content}
                        formClass='glass-panel standard-form-wrapper'
                    />
                }

                {content.form === 'subscriber' &&
                    <SubscriberForm
                        content={content}
                        formClass='glass-panel'
                        hasTitle
                    />
                }
                {content.footnote &&
                    <div className="footnote-bar">
                        <p className="footnote"><span className='superscript big'>1</span> {content.footnote}</p>
                    </div>
                }
            </div>
        </section>
    )
}