import Icon from './modular/Icon'

export default function ThankYou(props) {	
	return (
		<div className={props.formClass} style={{gridArea: 'unset'}}>
			<div style={{display: 'grid', gridTemplateColumns: '40px auto', gridGap: 'var(--spc-xs-w)'}}>
				<Icon id='check' style={{gridArea: 'unset', boxShadow: 'none'}} />
				<h5 onClick={props.handleStorageReset}>{props.thankYouHeadline}</h5>
			</div>
			<p>Thank you for subscribing. We’ll keep you notified you of any news or heat pump incentive updates in your area.</p>
			<a download href={props.imgUrl} >
				Click here to download our brochure.
			</a>
	  </div>
	)
}
