import { useState, useEffect } from 'react'

const LogoProduct = (props) => {
  const [logoPath, setLogoPath] = useState(null)
  const logoHeight = (height) => height ? height : '70px'

  useEffect(() => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? setLogoPath('/images/new-products/dark-mode/')
    : setLogoPath('/images/new-products/light-mode/'), [])

  return (
    <img 
      src={logoPath+props.productLogo} 
      style={{width: 'unset', maxHeight:logoHeight(props.logoHeight)}}
      alt={props.altText}
    />
  )
}

export default LogoProduct