import altText from './altText'
import { defaults } from '../campaigns/_defaults'

export default function subscribe({
	id = 'subscribe', 
	campaignId = defaults.subscriberCampaignId,
	color = 'transparent'
}) {
	return {
		id: id,
		subscriberCampaignId: campaignId,
		class: 'download-section subscribe',
		blurb: 'Stay up to date and discover how our high-efficiency heat pumps are improving the way we live and the communities we live in.',
		imgSrc: '/images/brochure-download-poster.webp',
		imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: color,
	}
}