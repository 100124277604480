import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from "react-router-dom"
import LoadingSpinner from './modular/LoadingSpinner'
import InputText from './modular/InputText'
import InputRadioBtnGroup from './modular/InputRadioBtnGroup'
import validateField from '../lib/validateField'
import Button from './modular/Button'
import { formatPhone, formatZip } from '../lib/helpers'
import { submitFormData, submitToSandbox } from '../lib/queries'
import ThankYouLead from './ThankYouLead'
import conversions from '../lib/conversions'

export default function LeadFormStd({ content, formClass }) {
	const [leadFormSubmitted, setLeadFormSubmitted] = useState(false) 
	const sandbox = content.sandbox

	/* ---------------- FORM DATA ---------------- */
	const [formData, setFormData] = useState({
		FirstName: {value: ''},
		LastName: {value: sandbox ? 'Sandbox Test' : '', valid: sandbox},
		Phone: {value: sandbox ? '(123) 456-7890' : '', valid: sandbox},
		Email: {value: sandbox ? 'test@test.com' : '', valid: sandbox},
		ZipCode: {value: sandbox ? '00112' : '', valid: sandbox || content.hideLocationSelectors},
		Street: {value: sandbox ? '1234 Sandbox St' : '', valid: sandbox || content.hideLocationSelectors},
		I_Am_A: {value: '', valid: content.hideSystemSelector},
		ServiceNeeds: {value: '', valid: true},
		HVACneeds: {value: '', valid: true},
		Rooms: {value: '', valid: true},
		PlanToPurchase: {value: '', valid: content.hideTimeframeSelector},
		NoOfContractor: {value: '', valid: content.hideContractorNumSelector},
		BestWayToReachYou: {value: '', valid: content.hideContactMethodSelector},
		CustomerComments: {value: '', valid: true},
		ReceiveEmailCommunication: {value: '', valid: true},
	})
	const [formIsValid, setFormIsValid] = useState(false)
	const [countrySelected, setCountrySelected] = useState('United States')
	
	const [formInstructions, setFormInstructions] = useState('')
	const [thankYouHeadline, setThankYouHeadline] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const tokenField = useRef()

	const GTMTrigger = content.GTMTrigger ? content.GTMTrigger : 'bottom_form'

	const currentPage = window.location.pathname.substring(1)

	useEffect(() => {
		content.formInstructions
			? setFormInstructions(content.formInstructions)
			: setFormInstructions('Please fill out this form and up to 3 qualified HVAC contractors in your area will contact you to answer your questions and schedule an on-site consultation.')
	},[content.formInstructions])

	/* ---------------- FIELD VALUES HANDLER ---------------- */
	const handleChange = (e) => {
		const field = e.target
		let { name, type, value, required } = field
		let checked

		
		if (name === 'Phone' && value) {
			value = formatPhone(value)
		}
		
		if (name === 'ZipCode' && value) {
			value = formatZip(value)
		}
		
		if (type === 'checkbox' && field instanceof HTMLInputElement) {
			checked = field.checked
        }
		
		const isValid = validateField(type, name, required, value)
		// console.log('changed:', name, value, 'valid:', isValid)

		setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: type === 'checkbox' ? checked : value,
                    valid: isValid,
                }
            }
        })
	}

	useEffect(() => {
		const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
		console.log('all fields valid?', allFieldsValid, formData)
		setFormIsValid(allFieldsValid)
	}, [formData])

	/* ---------------- LOCAL STORAGE HANDLER ---------------- */
	const handleStorageReset = () => {
		console.log(`--> lead form resetting `)
		// handle conversion and storage
		conversions('lead', null, true)
		setThankYouHeadline('Form reset, now reload page')
	}

	useEffect(() => {
		// handle local storage items and thank-you
		const userName = localStorage.getItem('userName')
		const isSubmitted = localStorage.getItem(`${currentPage}_leadFormSubmitted`)
		const Email = localStorage.getItem('Email')

		if (Email && (!formData.Email || formData.Email.value !== Email)) {
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					Email: {
						value: Email,
						valid: true,
					}
				}
			})
		}

		isSubmitted === 'true' && setLeadFormSubmitted(true)

		if (userName && (!formData.FirstName || formData.FirstName.value !== userName)) {
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					FirstName: {
						value: userName,
						valid: true,
					}
				}
			})
		}
	}, [formData, currentPage])

	/* ---------------- UTM PARAMETERS ---------------- */
	const currentUrl = new URL(document.location)
	const params = currentUrl.searchParams
	let newId = params.get('campaign_id')
	let newSource = params.get('utm_source')

	/* ---------------- COUNTRY FORM FIELDS LOGIC ---------------- */
	const changeCountry = (e) => {
		setCountrySelected(e.target.value)
	}
	const updateCountry = (e) => setCountrySelected(`${countrySelected}, ${e.target.value}`)

	/* ---------------- RECAPTCHA + CALLBACKS ---------------- */
	const productForm = useRef() // parent <form>

	/* ---------------- SUBMIT FORM VIA FETCH API ---------------- */
	const handleFormSubmit = (e) => {
		e.preventDefault();
		
		formIsValid && window.grecaptcha.ready(() => {
			window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', {action: 'lead_form_submit'})
			.then((token) => {
				tokenField.current.value = token
				setIsSubmitting(true)

				// reconstruct form data
				const data = new FormData(e.target)
				data.append('CampaignID', newId ? newId : content.leadCampaignId)
				data.append('LeadSource', newSource ? newSource : content.leadSource ? content.leadSource : 'Landing Page')
				data.append("Journey", true)
				data.append("LeadType", 'Lead')
				if (content.hideLocationSelectors) {
					data.append('Country', 'United States')
				}
				if (content.hideCommunicationsSelector) {
					data.append("ReceiveEmailCommunication", true)
				}
				if (content.hideSystemSelector) {
					data.append('I_Am_A', 'New Customer')
				}
				// auto-assign contractors:
				// data.append("00N3100000GpFau", 'true')
				data.append("RecordType", '012i0000000xpJ6')
				data.append("Company", "[not provided]")
				console.log(data)

				// submit form data
				const response = async() => {
					if (content.sandbox) {
						return submitToSandbox(data)
					}
					return submitFormData(data)
				}
				
				if (response()) {
					// handle google Enhanced Conversions
					conversions(
						'lead', 
						{ 
							name:formData.FirstName.value, 
							email:formData.Email.value,
							zip:formData.ZipCode.value
						}, 
						null
					)
					// append URL with "thank-you" param
					const params = Object.fromEntries(searchParams)

					// Add the new parameter.
					params['thank-you'] = true

					// Set the search parameters to the new object.
					setSearchParams(params)
	
					// replace form with thank you message
					setThankYouHeadline(`Thank you, ${formData.FirstName.value}!`)
					setLeadFormSubmitted(true)
					setIsSubmitting(false)
				}
			})
		})
	}

	if (isSubmitting) return (
		<div className={formClass} >
			<LoadingSpinner loadingMessage={'Sending your information...'} />
		</div>
	)

	if (leadFormSubmitted) return (
		<ThankYouLead
			onClick={handleStorageReset}
			headline={thankYouHeadline}
			firstName={formData.FirstName.value}
			userEmail={formData.Email.value}
			thankYou={content.thankYou}
			className='glass-panel'
			showResetButton={content.showResetButton}
		/>
	)

	return (
		<form
			onSubmit={e => handleFormSubmit(e)}
			action={GTMTrigger}
			id='form'
			className='standard-form-wrapper glass-panel'
			autoComplete="on"
			ref={productForm}
		>
		
			{/* recaptcha token field - GTM triggers submit only if value is NOT 'token' */}
			<input type="hidden" id='token' name='token' value='token' ref={tokenField}/>

			<h2 
				className={formClass && formClass.includes('glass') ? 'h4' : undefined}
				style={{textAlign: 'center', gridArea: 'unset', marginBottom: '0'}}
			>
				{content.formTitle ? content.formTitle : 'Request a Consultation'}
			</h2>
			<p className='form-instructions' style={{maxWidth: '1000px', textAlign: 'center', margin: 'auto'}}>{formInstructions}</p>
			<div className="contact-info-wrapper">
				<InputText
					id='first-name'
					name='FirstName'
					label='First Name'
					type='text'
					placeholder='given name'
					value={formData.FirstName.value}
					onChange={handleChange}
					isValid={formData.FirstName.valid}
					required
				/>
				
				<InputText
					id='last-name'
					name='LastName'
					label='Last Name'
					type='text'
					placeholder='surname'
					value={formData.LastName.value}
					onChange={handleChange}
					isValid={formData.LastName.valid}
					required
				/>
				
				<InputText
					id='lead-email'
					name='Email'
					label='Email'
					type='email'
					placeholder='name@domain.com'
					maxLength='42'
					value={formData.Email.value}
					onChange={handleChange}
					isValid={formData.Email.valid}
					required
				/>
				
				<InputText
					id='phone'
					name='Phone'
					label='Phone No.'
					type='tel'
					placeholder='10-digit, U.S. numbers only'
					value={formData.Phone.value}
					onChange={handleChange}
					isValid={formData.Phone.valid}
					required
				/>
			</div>
			{ content.hideLocationSelectors &&
				<div id="zip-wrapper" className="input-wrapper" style={{display: 'grid', justifyItems: 'center'}}>
					<InputText
						id='zip'
						name='ZipCode'
						label='Zip Code'
						type='text'
						maxLength='5'
						placeholder='U.S. Only'
						center
						onChange={handleChange} 
						value={formData.ZipCode.value}
						isValid={formData.ZipCode.valid}
						errorMsg='Invalid Zip'
						required
					/>
					<p className="footnotes mar-y-sm">Must be a zip code covered by this contractor</p>
				</div>
			}

			{!content.hideLocationSelectors &&
				<div id="location-info-wrapper">
					<InputText
						id='street'
						name='Street'
						label='Street Address'
						type='text'
						placeholder='House Number & Street'
						value={formData.Street.value}
						onChange={handleChange}
						isValid={formData.Street.valid}
						required
					/>

					{ countrySelected === 'United States' &&
						<div id="zip-wrapper" className="input-wrapper">
							<InputText
								id='zip'
								name='ZipCode'
								label='Zip Code'
								type='text'
								maxLength='5'
								placeholder='U.S. Only'
								onChange={handleChange} 
								value={formData.ZipCode.value}
								isValid={formData.ZipCode.valid}
								errorMsg='Invalid Zip'
								required
							/>
						</div>
					}
					{ countrySelected.includes('Mexico') &&
						<div id="state-wrapper" className="input-wrapper" >
							<select id="mex-state-input" name="mex-state-input" onChange={updateCountry} defaultValue=''>
							<option value='' disabled> </option>
							<option value="Federal District">Federal District</option>
							<option value="Oaxaca">Oaxaca</option>
							<option value="Guerrero">Guerrero</option>
							<option value="Puebla">Puebla</option>
							<option value="Morelos">Morelos</option>
							<option value="Tlaxcala">Tlaxcala</option>
							</select>
							<label id="state_label" htmlFor="mex-state-input" className="required offset-label">State</label>
						</div>
					}
					<div id="country-wrapper" className="input-wrapper">
						<select id="country" className='valid' name="Country" required aria-required="true" onChange={changeCountry} defaultValue='United States'>
							<option value="United States">United States</option>
							<option value="Mexico">Mexico</option>
							<option value="Virgin Islands, British">Virgin Islands, British</option>
							<option value="Turks and Caicos Islands">Turks &amp; Caicos Islands</option>
							<option value="Bahamas">Bahamas</option>
							<option value="Saint Martin (French part)">Saint Martin (French part)</option>
							<option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
						</select>
						<label id="country_label" htmlFor="country" className="required offset-label">Country</label>
					</div>
				</div>
			}

			<InputRadioBtnGroup 
				id='input-wrapper'
				title='I am interested in'
				name='I_Am_A'
				options={['New Customer', 'Existing Customer']}
				labels={['Purchasing a new system', 'Servicing an existing system']}
				handleChange={handleChange}
				selectedValue={formData.I_Am_A.value}
				required
			/>

			{/* NEW CUSTOMER ONLY: */}
			{ formData.I_Am_A.value === 'New Customer' &&
				<div id='new-system-wrapper'>
					<InputRadioBtnGroup 
						id='needs-wrapper'
						title='What is the scope of your HVAC needs?'
						name='Rooms'
						options={['Single Room', 'Multiple Rooms', 'Entire System Upgrade', 'Other']}
						handleChange={handleChange}
						selectedValue={formData.Rooms.value}
						required
					/>
					{ formData.Rooms.value === 'Other' &&
						<InputText 
							id='needs-other' 
							name='HVACneeds' 
							label='Describe your HVAC needs'
							textArea
							placeholder='Describe your current HVAC system'
							onChange={handleChange}
							value={formData.HVACneeds.value}
							isValid={formData.HVACneeds.valid}
							required
						/>
					}

					{!content.hideTimeframeSelector &&
						<InputRadioBtnGroup 
							id='timeframe-wrapper'
							title='When are you planning to purchase?'
							name='PlanToPurchase'
							options={['Immediately', '0-3 months', '3-6 months', '6-12 months', '12+ months', 'Not sure - please contact me']}
							labels={['Immediately', '0-3 Months', '3-6 Months', '6-12 Months', '12+ Months', 'Not sure']}
							handleChange={handleChange}
							selectedValue={formData.PlanToPurchase.value}
							required
						/>
					}
					
					{!content.hideContractorNumSelector &&
						<InputRadioBtnGroup 
							id='contractors-wrapper'
							title='How many contractors would you like to be matched with?'
							name='NoOfContractor'
							options={['1', '2', '3']}
							handleChange={handleChange}
							selectedValue={formData.NoOfContractor.value}
							required
						/>
					}

					{!content.hideContactMethodSelector &&
						<InputRadioBtnGroup 
							id='contact-wrapper'
							title='How would you prefer to be contacted?'
							name='BestWayToReachYou'
							options={['Email','Call', 'Text']}
							handleChange={handleChange}
							selectedValue={formData.BestWayToReachYou.value}
							footnote='Data rates from your wireless provider may apply'
							required
						/>
					}
				</div>
			}

			{/* EXISTING CUSTOMER ONLY: */}
			{ formData.I_Am_A.value === 'Existing Customer' &&
				<div id='service-wrapper' className='input-wrapper'>
					<textarea 
						id='ServiceNeeds' 
						maxLength='254' 
						name='ServiceNeeds' 
						rows='2' 
						placeholder='Describe your current HVAC system'
						aria-required='true'
						required
					/>
					<label htmlFor='ServiceNeeds' className='offset-label'>
						Describe your system and service needed
					</label>
				</div>
			}

			{/* EITHER NEW OR EXISTING CUSTOMER: */}
			{ formData.I_Am_A.value
				? 	<div>
						<InputText 
							id='CustomerComments' 
							name='CustomerComments' 
							label='Additional Comments?'
							textArea
							placeholder='Please add any additional information here'
							onChange={handleChange}
							value={formData.CustomerComments.value}
							isValid={formData.CustomerComments.valid}
						/>
						
						<div className='final-form-checkbox-wrapper'>
							<div id='communications-wrapper'>
								<input id='ReceiveEmailCommunication' name='ReceiveEmailCommunication' type='checkbox' value={true} onChange={handleChange} />
								<label className='visible' htmlFor='ReceiveEmailCommunication'>
									I would like to receive email and communications from Mitsubishi Electric Trane HVAC in the future
								</label>
							</div>
							<Button
								className='g-recaptcha'
								type='submit'
								id='bu-form-submit'
								text='Submit Form'
								disabled={!formIsValid || isSubmitting}
							/>
						</div>
					</div>
				: 	null
			}
			<p className='footnote mar-0'>
				*Asterisk Denotes a Required Field
			</p>
			
		</form>
	)
}
