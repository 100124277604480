import { useState, useEffect } from 'react'
import { fetchLocalData } from '../lib/queries'

export default function useRegions() {
	const [regions, setRegions] = useState(null)

	useEffect(() => {
		const setRegionsData = async() => {
			const regionsData = await fetchLocalData('bu-regions.json')
			setRegions(regionsData)
		}
		setRegionsData()
	}, [])
	
	return regions
}