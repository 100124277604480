import { header, hero, sections } from './page content/pageLightCommercialContent'
import Page from './Page'

export default function PageLightCommercial() {
    const finalHero = {
        ...hero,
        scrollTo: sections[0].id,
    }

    return <Page header={header} hero={finalHero} sections={sections} />
}
