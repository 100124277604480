import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '6/15/2024'
    const end = '8/15/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'national',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: defaults.subscriberCampaignId,
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/cbu-spring.webp',
        defaultTitle: defaults.title,
        defaultBlurb: '',
        defaultDetails: defaults.detailsText,
        defaultFootnote: defaults.footnote,
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        // ME_Residential_National_Summer_2024_Meta Advantage_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        staticCampaign: true,
        campaignId: '701Pd00000BjrhmIAB',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/lifestyle/interior/cbu-spring.webp',
        campaignTitle: defaults.title,
        campaignBlurb: '',
        campaignDetails: defaults.detailsText,
        campaignFootnote: defaults.footnote,
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }
    return getCampaignData(data)
}
