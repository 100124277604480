import altText from './static-content/altText'
// import lorem from './static-content/lorem'
import hybridVrf from './static-content/hybridVrf'
import heat2O from './static-content/heat2O'
import productRange from './static-content/product-range'
import innovativeProducts from './static-content/innovative-products'
import downloadIra from './static-content/download-ira'

const vertical = 'Education'
const mainImg = '/images/lifestyle/interior/education_hero.webp'

const hero = {
    class: 'two-column-img-left dark-mode',
    backgroundSrc: mainImg,
    title: `HVAC Solutions For ${vertical} Applications`,
    // blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
    detailsText: 'Mitsubishi Electric\'s HVAC products and control systems create comfortable and healthy learning environments, optimizing student performance and reducing energy costs. Our control systems allow for easy management of these systems, even remotely, providing educational institutions with a hassle-free way to reduce energy waste and maintain optimal comfort and indoor air quality.',
}

const sideBar = [
    {
        vertical: vertical,
        imgSrc: '/images/brian_wright_headshot.webp',
        firstName: 'Brian',
        lastName: 'Wright',
        jobTitle: 'Director, Educational Sales',
        phone: '(210) 376-7633',
        email: 'bwright@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/brian-wright-34802971'
    },
    {
        vertical: vertical,
        bu: 'swbu',
        imgSrc: '/images/jennifer-fisher-headshot.webp',
        firstName: 'Jennifer',
        lastName: 'Fisher',
        jobTitle: 'Regional Sales Mgr, Educational',
        phone: '(469) 678-0185',
        email: 'jfisher@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/jennifer-fisher-0538a468/'
    },
    {
        vertical: vertical,
        bu: 'wbu',
        imgSrc: '/images/john_deluca_headshot.webp',
        firstName: 'John',
        lastName: 'DeLuca',
        jobTitle: 'Regional Sales Mgr, Educational',
        phone: '(484) 885-0710',
        email: 'jdeluca@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/john-deluca-91590659/'
    },
    {
        vertical: vertical,
        bu: 'sbu',
        imgSrc: '/images/preston_headshot.webp',
        firstName: 'Preston',
        lastName: 'Holmbroek',
        jobTitle: 'Regional Sales Mgr, Educational',
        phone: '(704) 962-7280',
        email: 'phombroek@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/prestonhombroek'
    },
    {
        vertical: vertical,
        bu: ['cbu', 'mabu', 'nebu'],
        imgSrc: '/images/john_brooks_headshot.webp',
        firstName: 'John',
        lastName: 'Brooks',
        jobTitle: 'Regional Sales Mgr, Educational',
        phone: '(612) 360-3630',
        email: 'jbrooks@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/john-brooks-075254171'
    },
]

const sections = [
    {
		id: 'why-vrf',
		class: 'one-column-list',
		title: `Why Use VRF In ${vertical}?`,
		listItems: [
            {
                iconId: 'city-multi',
                title: 'Proven Technology',
                description: 'Mitsubishi Electric VRF technologies have been utilized throughout the world for decades, a true indication to lowering overall Operating Costs compared to other traditional cooling & heating systems.'
            },
            {
                iconId: 'service',
                title: 'Ease of Maintenance',
                description: 'Facility Managers are unencumbered due to the ease of maintaining CITY-MULTI VRF systems.'
            },
            {
                iconId: 'thermostat',
                title: 'Precision Control',
                description: 'Administrators are able to control ONLY the portion of the school that is being used – avoiding the cost of heating & cooling the entire building.'
            },
            {
                iconId: 'sustainable',
                title: 'Minimal Noise',
                description: 'VRF systems are extremely quiet… allowing your students and faculty to stay focused on what\'s important.'
            },
		],
		backgroundCol: 'transparent',
        hasNavAnchor: true 
	},
    {
        id: 'projects',
        class: 'mapped-projects',
        title: `${vertical} Projects By Region`,
        vertical: vertical,
        projects: `${vertical.toLowerCase()}-projects`,
        hasNavAnchor: true 
    },
    innovativeProducts({}),
    hybridVrf({ hasNavAnchor: true }),
    heat2O({}),
    productRange({}),
    {
        id: `Testimonials`,
        class: 'three-column-cards-w-image',
        hasNavAnchor: true,
        title: `Testimonials\n- ${vertical} Applications -`,
        detailsText: `Review recent testimonials on how Mitsubishi Electric HVAC products are benefitting the ${vertical} industry.`,
        contentBoxes: [
            {
                title: 'Willow School',
                blurb: 'Gladstone, New Jersey',
                detailsText: `"We had to remain net-zero or net positive. With solar panels and the Mitsubishi Electric system we were able to achieve that. When people look at this building, they’re looking at one of the most energy-efficient buildings in the country."`,
                imgSrc: '/images/willow-school.webp',
                imgAlt: `${altText} ${vertical} case study - Willow School exterior`,
                quoteSource: 'Mark Biedron, Co-founder'
            },
            {
                title: 'Miami U\'s Elliot & Stoddard Halls',
                blurb: 'Oxford, Ohio',
                detailsText: `"Renovation of any historic building is a complex undertaking... Mitsubishi Electric’s superior VRF engineering allowed us to preserve this historic architecture and control energy efficiency."`,
                imgSrc: '/images/stoddard-hall.webp',
                imgAlt: `${altText} ${vertical} case study - Miami University's Elliot Hall and Stoddard Hall building exterior`,
                quoteSource: 'Alec R. Carnes, Senior Principal, Heapy Engineering'
            },
            {
                title: 'Primrose School',
                blurb: 'South Tampa, Florida',
                detailsText: `"Everybody I talked to had the highest recommendations for Mitsubishi Electric. Contractors said it’s the manufacturer they have to service least often. We’re just absolutely in love with the system."`,
                imgSrc: '/images/primrose-school-tampa.webp',
                imgAlt: `${altText} ${vertical} case study - Primrose School building exterior`,
                quoteSource: 'Rick Radtke, Co-owner'
            },
        ],
        backgroundCol: 'gray',
    },
    downloadIra({ color: 'transparent' }),
]

const header = {
	pageTitle: 'HVAC Solutions For Education Applications',
	pageDesc: 'Mitsubishi Electric\'s HVAC products and control systems create comfortable and healthy learning environments, optimizing student performance and reducing energy costs, all while allowing for easy management, even remotely.',
    pageImg: mainImg,
    class: 'side-bar dark-mode-header',
    branding: 'metus',
    pageSections: sections.map((section) => section),
    accentCol: 'blue',
    vertical: vertical,
}

export { header, sideBar, hero, sections }
