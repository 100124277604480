import altText from "./altText"

export default function r2({
    id = 'r2-series',
    hasNavAnchor = false,
    title = 'R2-Series',
    color = 'transparent'
}) {
    return {
        id: id,
        class: 'two-column-img-left no-padding-bottom',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'An Industry-First',
        detailsText: `Mitsubishi Electric's R2-Series features a two–pipe heat recovery system that simultaneously cools and heats different zones within a building while saving energy. H2i® liquid injection technology, standard on high-efficiency models, means improved heating performance for comfort in any climate.`,
        imgSrc: '/images/product/ME_N-Generation-small_LC.webp',
        imgAlt: `${altText} R2-Series VRF unit`,
        iconList: {
            title: 'An Upgrade Over Previous Generations',
            items: [
                {
                    iconId: 'check',
                    text: 'Up to a 30% smaller footprint'
                },
                {
                    iconId: 'check',
                    text: '13% less refrigerant charge'
                },
                {
                    iconId: 'check',
                    text: 'Improved efficiencies up to 27%'
                },
                {
                    iconId: 'check',
                    text: 'Five air flow settings'
                },
                {
                    iconId: 'check',
                    text: 'Improved, ultra-quiet noise levels'
                }
            ]
        },
        backgroundCol: color
    }
} 