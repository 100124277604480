import ScrollLink from './ScrollLink'
import Icon from './Icon'

export default function DownArrow({ scrollTo }) {
	return (
		<div id='down-arrow-wrapper'>
			<ScrollLink to={scrollTo ? `#${scrollTo}` : '#Subscribe'}>
				<h6 className='down-arrow-title'>
				</h6>
				<Icon id='down-arrow'/>
			</ScrollLink>
		</div>
	)
}