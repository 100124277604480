const general = [
	{
		id: 'products',
		title: 'Personalized Indoor Solutions',
		detailsText: '$ heat pump systems provide modular zone-by-zone comfort using ductless mini-split systems that can handle up to eight zones at a time. Heat or cool only the rooms you need, improving efficiency and reducing energy costs. Stylish indoor units that can fit anywhere, including wall-mounted units, floor units, ceiling recessed and ducted HVAC systems. Only a small opening is needed to connect indoor and outdoor units.',
		slider: [
			{
				title: 'Wall-Mounted',
				imageName: 'FS.webp',
				detailsText: 'These sleek indoor units provide both air conditioning and heating to your room, monitor room conditions, and adjust their fan speed to fine-tune your comfort.'
			},
			{
				title: 'Recessed Ceiling Cassette',
				imageName: 'MLZ.webp',
				detailsText: 'One-Way and Four-Way ceiling cassettes feature a sleek, modern design, blend well with your ceilings, and let you direct the airflow to where it is most needed.'
			},
			{
				title: 'Horizontal-Ducted',
				imageName: 'SEZ.webp',
				detailsText: 'Our low-profile horizontal-ducted units use less ductwork than traditional systems and can be hidden either above the ceiling or below the floor.'
			},
			{
				title: 'Ducted Air Handler',
				imageName: 'SVZ.webp',
				detailsText: 'Our multi-position air handler provides a powerful, quiet and efficient heating and air conditioning solution for your home or business. Perfect for new builds or replacing your old furnace.'
			},
			{
				title: 'Floor-Mounted',
				imageName: 'KJ.webp',
				detailsText: 'Featuring Rapid Heating, our floor unit\'s innovative design incorporates two air streams which in tandem work to quickly heat the room.'
			},
		]
	},
	{
		id: 'comfort',
		title: 'Comfort In Any Situation',
		detailsText: '$ has a mini-split heat pump solution that will work for you, whether you have a single room that’s too hot or too cold, you are building a new home, or anything in between. Our heating and air conditioning systems are quicker and easier to install than traditional systems and offer advanced multi-stage air filtration and lower energy costs.',
		slider: [
			{
				title: 'Hot/Cold Spots',
				imageSrc: '/images/lifestyle/interior/summer-hero.webp',
				detailsText: 'The most efficient way to set and maintain the ideal temperature to a room that is too hot or too cold.'
			},
			{
				title: 'Renovation',
				imageSrc: '/images/lifestyle/interior/sunroom.webp',
				detailsText: 'Our mini-split heat pump systems have compact equipment and modern designs, making it easy to install a unit in any space while personalizing your home comfort.'
			},
			{
				title: 'Replacement',
				imageSrc: '/images/lifestyle/interior/wbu-fall-hero.webp',
				detailsText: 'Upgrade your outdated technology to an all-electric Mitsubishi Electric mini-split heat pump system that\'s energy-efficient, whisper-quiet, and easy to install.'
			},
			{
				title: 'New Construction',
				imageSrc: '/images/lifestyle/interior/construction-1000px.webp',
				detailsText: 'Make sure your new home is 100% comfortable, 100% of the time. Our indoor and outdoor units are modern and compact, giving you more usable space in your home.'
			},
			{
				title: 'Light Commercial',
				imageSrc: '/images/lifestyle/interior/COMM-office-ceiling-unit.webp',
				detailsText: 'Add comfort to light commercial spaces with our robust professional line of modular systems that make it easy to adapt to expansion or reconfiguration of your spaces.'
			},
		]
	},
	{
		id: 'control',
		title: 'Smart System Control',
		detailsText: '$ heat pump systems allow you to take control of your comfort and energy bills with programmable schedules, customized temperatures and fan speeds, and air filter monitoring. Monitor your system’s performance using handheld and wall-mounted controllers. With the kumo cloud® mobile app, it’s easy to adjust and manage your home’s heating and air conditioning, anytime and anywhere.',
		slider: [
			{
				title: 'kumo cloud® App',
				imageName: 'smartphone-app-kumo.webp',
				detailsText: 'Adjust room temperature, set schedules, view alarms, and much more, all from a smart device.'
			},
			{
				title: 'Wall-Mounted Wired',
				imageName: 'control-wall-mounted.webp',
				detailsText: 'Manage your home\'s heating and air conditioning with easy-to-use wall-mounted controllers that manage one to 16 indoor units.'
			},
			{
				title: 'Wall-Mounted Wireless',
				imageName: 'MHK2 kumo touch.webp',
				detailsText: 'Manage your home\'s heating and air conditioning with user-friendly wireless controls that can mount anywhere and make installation a snap.'
			},
			{
				title: 'Handheld Controller',
				imageName: 'FS-Remote_Full_Closed.webp',
				detailsText: 'Manage air flow, fan speed and room temperature from the palm of your hand.'
			},
		]
	},
	{
		id: 'features',
		title: 'For a Better Environment,\nInside and Out.',
		detailsText: '$ heat pump systems feature advanced technologies that allow you to enjoy more precise comfort and healthier air in one room or your whole home - while saving on your energy bills and lowering your carbon footprint.',
		slider: [
			{
				title: '3D i-see Sensor®',
				iconId: '3d-isee-sensor',
				detailsText: 'Scans the room, monitoring occupancy in individual zones and adjusting airflow and temperature to maintain the desired temperature.'
			},
			{
				title: 'Instant Hot Start',
				iconId: 'hot-start',
				detailsText: 'Prevents the fan from moving cold air into your space. By waiting until the heat exchanger is warm, you receive warm air immediately for enhanced comfort.'
			},
			{
				title: 'H2i® Technology',
				iconId: 'hyper-heat',
				detailsText: 'Hyper-heating technology provides 100% heating capacity down to 5° F ambient outdoor temperature and reliable heating as temperatures drop to -13° F all while saving energy and money.'
			},
			{
				title: 'Whisper Quiet',
				iconId: 'check',
				detailsText: 'Our indoor units are quieter than a human whisper, working in harmony with ductless air vents and vibration dampeners.'
			},
			{
				title: 'Air Filtration',
				iconId: 'air-filter',
				detailsText: 'Experience cleaner, healthier air with our filtration systems that capture odors, allergens, bacteria, and other contaminants that impact air quality.'
			},
		]
	},
]

export default general