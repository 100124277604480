import tabs from './page content/static-content/tabsIntelliHeat'
import SectionTabs from './SectionTabs'

export default function PageFrame() {
	return (			
		<main className='align-center'>
			<SectionTabs
				title={'intelli-HEAT™ Hybrid HVAC'}
				tabs={tabs}
				containImg
				iframe
			/>
		</main>
	)
}