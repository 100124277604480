import { useState } from 'react';
import Icon from './modular/Icon'
import Modal from 'react-modal'

Modal.setAppElement('#root')

const ContentBox = ({ id, content }) => {
	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() { setIsOpen(true) }

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeModal() { setIsOpen(false) }

	return (
		<div
			id={id}
			key={id}
			className="glass-panel"
			style={content.iconList && {backgroundColor:'var(--bg-50)', padding: 0}}
		>
			{content.iconSrc && <Icon id={content.iconSrc} alt={content.iconAlt} />}
			{content.imgSrc && 
				<img 
					className={id.includes('brands') ? 'brand' : 'img'} 
					style={
						(content.imgSrc.includes('.svg') || content.containImg) 
							? {objectFit:'contain'} 
							: null
					} 
					src={content.imgSrc} 
					alt={content.imgAlt}
				/>
			}
			{content.title && <h3 className={content.iconList ? 'h3' : 'h4'}>{content.title}</h3>}
			{content.blurb && <h4 className={content.iconList ? 'h4' : 'h5'}>{content.blurb}</h4>}
			{content.iconList && 
				<div className='icon-list-wrapper' style={{padding: 'var(--xl)'}}>
					<h5 style={{color: 'var(--accent)', textAlign: 'center'}}>
						{content.iconList.title}:
					</h5>
					{content.iconList.items.map((item, index) =>
						<div key={index + ' ' + item.iconId} id={item.iconId} className='icon-list-item'>
							<Icon id={item.iconId} alt={`${item.id} mini-split feature`} />
							<p>{item.text}</p>
						</div>
					)}
				</div>
			}

			{content.footerText &&
				<h4 className={content.iconList && 'system-estimate'}>
					{content.footerText}{content.iconList && '*'}
				</h4>
			}

			{content.detailsText && 
				<p style={content.quoteSource ? {fontFamily: 'var(--main-font-italic)'} : undefined}>	{content.detailsText}
				</p>
			}

			{content.quoteSource &&
				<p className='bold' style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '300px'}}>
					-{content.quoteSource}
				</p>
			}

			{content.phone &&
				<a className='simple-link' href={`tel:+1-${content.phone}`}>
					<span>{content.phone}</span>
				</a>
			}

			{content.ctaText && content.downloadUrl &&
				<a className='simple-link' style={{paddingTop: 'var(--lg)'}} download href={content.downloadUrl}>
					{content.ctaText}
				</a>
			}

			{content.ctaText && content.ctaUrl &&
				<a className='simple-link' target='_blank' rel='noreferrer' href={content.ctaUrl}>
					{content.ctaText}
				</a>
			}
			
			{content.ctaText && content.modalContent &&
				<>
					<div
						id='modal-btn'
						className='simple-link'
						onClick={openModal}
					>
						{content.ctaText}
					</div>
					<Modal
						className='modal glass-panel financing-modal'
						overlayClassName="modal-overlay"
						isOpen={modalIsOpen}
						onAfterOpen={afterOpenModal}
						onRequestClose={closeModal}
						contentLabel="Modal"
					>
						<Icon id='x' alt='close button' onClick={closeModal}>X</Icon>
						<h3 className='modal-title'>
							{content.modalContent.title}{content.modalContent.footnote && '*'}
						</h3>
						<div className="modal-details">
							<div>
								<p>{content.modalContent.text}</p>
								{content.modalContent.ctaText &&
									<div style={{display: 'flex', placeContent: 'center'}}>
										<a className='btn' target='_blank' rel='noreferrer' href={content.modalContent.ctaUrl}>
										{content.modalContent.ctaText}
										</a>
									</div>
								}
							</div>
							<img style={{maxWidth: '250px'}} src={content.modalContent.imgSrc} alt={content.modalContent.imgAlt} />
						</div>
						{content.modalContent.footnote &&
							<p className="footnote" style={{marginTop: 'var(--md)'}}>
								*{content.modalContent.footnote}
							</p>
						}
					</Modal>
				</>
			}
		</div>
	)
}

export default ContentBox
