import altText from './altText'

export default function health({
    id = 'health',
    hasNavAnchor = false,
    title = 'Cleaner Air',
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-right',
		hasNavAnchor: hasNavAnchor,
		title: title,
		blurb: 'For a Healthier Home ',
		detailsText: 'With our ductless mini-split systems, the air is heated and cooled in the room where the unit is located, so your air doesn\'t travel through ductwork which can be dirty and leak into the environment over time. Eliminating or reducing ductwork with ductless heating and cooling can result in better indoor air quality, room-by-room comfort and energy savings.',
		imgSrc: '/images/lifestyle/interior/breathe-easy.webp',
		imgAlt: `${altText} father daughter mini-split living room clean healthy air`,
		backgroundCol: color
	}
}