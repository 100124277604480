import altText from './altText'

export default function designedForLife({
    id = 'solutions',
    title = 'Designed for life, inside and out.',
    color = 'transparent'
}) {
    return {
        id: id,
        class: 'two-column-img-left',
        title: title,
        // blurb: 'For Fuel-free Heating and Air Conditioning',
        detailsText:
            'As a leader in advanced HVAC technologies, including Ductless and Ducted Mini-split and all-climate heat pumps, we have a solution for any home, any building, anywhere. We continually innovate around efficiency, comfort and wellness in buildings or homes of all shapes and sizes by providing industry-leading products, design and technical training, and unmatched end-to-end support.',
        imgSrc: '/images/me-slz-lobby-waiting-area.webp',
        imgAlt: `${altText} waiting room lobby with ceiling-recessed heat pump indoor units`,
        ctaText: '',
        videoSrc: '',
        backgroundCol: color,
    }
}
