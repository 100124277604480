import altText from './static-content/altText'
import lorem from './static-content/lorem'
import download from './static-content/section-layouts/downloadSection'
import subscribe from './static-content/section-layouts/subscribeSection'
import control from './static-content/control'
import { defaults as bu } from './campaigns/_defaults'
import oneColumnInfo from './static-content/section-layouts/oneColumnInfo'
import oneColumnImage from './static-content/section-layouts/oneColumnImage'
import twoColumnImgLeft from './static-content/section-layouts/twoColumnImgLeft'
import twoColumnImgLeft2 from './static-content/section-layouts/twoColumnImgLeft2'
import twoColumnImgRight from './static-content/section-layouts/twoColumnImgRight'
import threeColumnInfo from './static-content/section-layouts/threeColumnInfo'
import threeColumnInfo2 from './static-content/section-layouts/threeColumnInfo3'
import threeColumnInfo3 from './static-content/section-layouts/threeColumnInfo2'
import fourColumn from './static-content/section-layouts/fourColumn'

const buPhone = '800-433-4822'

const hero = {
  class: 'hero',
  form: 'rebate',
  title: 'Hero Section Headline',
  // blurb: lorem.blurb,
  detailsText: lorem.two,
  formInstructions: bu.formInstructions,
  footnote: null,
  backgroundSrc: '/images/lifestyle/interior/RES-EF-white-living-room.jpg',
  formTitle: 'Rebate Lead Form',
  whatsNext: bu.whatsNext,
  leadCampaignId: bu.leadCampaignId,
  subscriberCampaignId: bu.subscriberCampaignId,
  imgSrc: '/images/brochure-download-poster.webp',
  imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
  imgAlt: `${altText} click to download our brochure`,
}

let sections = [
  download({}),
  subscribe,
  oneColumnInfo,
  oneColumnImage,
  twoColumnImgLeft,
  twoColumnImgLeft2,
  twoColumnImgRight,
  threeColumnInfo,
  threeColumnInfo2,
  threeColumnInfo3,
  fourColumn,
  control({}),
]

let header = {
  phone: buPhone,
  // ctaText: 'So Cool!',
  pageSections: sections.map((section) => section),
}

export { header, hero, sections }
