import { useState, useEffect, memo, useRef } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import { mapStylesLt, mapStylesDk } from './page content/static-content/mapStyles'
import pin from '../graphics/location-pin-option-check.svg'
import { fetchLocalData } from '../lib/queries'
import Button from './modular/Button'

const SectionMap = ({ data, options }) => {
	const {
		centerCoords,
		zoomLevel,
		zoomControl,
		mapTypeControl,
		scaleControl,
		streetViewControl,
		rotateControl,
		fullscreenControl,
		clustering
	} = options

	const key = 'AIzaSyBIhDeeV41JP1Ao70W4cgrUBnrXciUBu9g'
	const [mapPins, setMapPins] = useState([])
	const [selectedMapPin, setSelectedMapPin] = useState(null)
	const [markerMap, setMarkerMap] = useState({})
	const [center, setCenter] = useState(centerCoords)
	const [infoOpen, setInfoOpen] = useState(false)
	const [mapStyles, setMapStyles] = useState(null)
	const toggleInfoRef = useRef(null)

	useEffect(() => {
		const fetchPins = async() => {
			const rawData = await fetchLocalData(`${data}.json`)
			const parsedData = await rawData.map((record) => {
				if (!record.coords) {
					return record
				}
				const coords = record.coords.split(',')

				return {
					...record,
					lat: Number(coords[0]),
					lng: Number(coords[1])
				}
			})

			setMapPins(parsedData)
		}
		fetchPins()
	}, [data])
	
	// Load the Google maps scripts
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: key
	})
	
	// size, center, and zoom map to contain all markers
	// const fitBounds = (map) => {
	// 	const bounds = new window.google.maps.LatLngBounds()
	// 	mapPins.map(mapPin => {
	// 		bounds.extend({lat: mapPin.lat, lng: mapPin.lat})
	// 		return mapPin.name
	// 	})
	// 	map.fitBounds(bounds)
	// }
	
	const loadHandler = (map) => {
		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			// dark mode
			setMapStyles(mapStylesDk)
		} else {
			// light mode
			setMapStyles(mapStylesLt)
		}
		// Fit map bounds to contain all markers
		// fitBounds(map)
	}
	
	//map mapPins to actual Marker objects
	const markerLoadHandler = (marker, mapPin) => {
		return setMarkerMap(prevState => {
			return { ...prevState, [mapPin.name]: marker }
		})
	}

	const markerClickHandler = (e, mapPin) => {
		// Remember which mapPin was clicked
		setSelectedMapPin(mapPin)

		// clicking a marker closes previous marker
		infoOpen && setInfoOpen(false)

		// zoom in on marker click
		// if (zoom < 9) setZoom(zoom+2)

		// center the selected Marker
		setCenter({lat: mapPin.lat, lng: mapPin.lng})
	}
	
	const mapOptions = {
		// disableDefaultUI: true,
		styles: mapStyles,
		zoomControl: zoomControl || true,
		mapTypeControl: mapTypeControl || false,
		scaleControl: scaleControl || true,
		streetViewControl: streetViewControl || false,
		rotateControl: rotateControl || false,
		fullscreenControl: fullscreenControl || true,
		clustering: clustering !== undefined ? clustering : true
	}
	
	const clustererOptions = {
		imagePath: '/icons/location-pin-cluster',
		imageExtension: 'svg'
	}
	
	const mapContainerStyle = {
		width: '100%',
		height: '700px',
		margin: 'var(--spc-lg-h) auto',
		borderRadius: 'var(--rnd-md)'
	}

	// when a marker is clicked, open new info window
	toggleInfoRef.current = () => {
        setInfoOpen(!infoOpen)
    }
	// using ref prevents infinite rerenders
	useEffect(() => {
        if (toggleInfoRef.current) {
            toggleInfoRef.current()
        }
    }, [center])
	
	const renderMap = () => 
		<>
			<GoogleMap
				onLoad={loadHandler}
				mapContainerStyle={mapContainerStyle}
				center={center}
				zoom={zoomLevel}
				options={mapOptions}
			>
				<MarkerClusterer options={clustererOptions}>
					{(clusterer) => mapPins.map((mapPin, index) => 
						<Marker 
							key={index}
							icon={pin}
							position={{lat: mapPin.lat, lng: mapPin.lng}}
							clusterer={clustering && clusterer}
							onLoad={(marker) => markerLoadHandler(marker, mapPin)}
							onClick={(event) => markerClickHandler(event, mapPin)}
							title={mapPin.name}
						/>
					)}
				</MarkerClusterer>
				{infoOpen && selectedMapPin && 
					<InfoWindow
						anchor={markerMap[selectedMapPin.name]}
						clickable={true}
						onCloseClick={() => setInfoOpen(false)}
					>
						<div style={{padding: 'var(--spc-sm)'}}>
							{selectedMapPin.logo &&
								<div style={{maxWidth: '250px', maxHeight: '250px', margin: '0 auto'}}>
									<img 
										src={`/images/logos/contractors/${selectedMapPin.logo}`}
										alt={`company logo for ${selectedMapPin.name}`}
										title={selectedMapPin.name}
									/>
								</div>
							}
							{!selectedMapPin.logo &&
								<h5 style={{color: 'var(--black)', textAlign: 'center'}}>
									{selectedMapPin.name}
								</h5>
							}
							{/* <h6 style={{margin: '0', marginBottom:'var(--spc-lg-h)', color: 'var(--black)'}}>
								{selectedMapPin.city}, {selectedMapPin.state}
							</h6> */}
							{selectedMapPin.website &&
								<Button 
									className='simple-link' 
									text='Visit Website' 
									href={selectedMapPin.website} 
								/>
							}
						</div>
					</InfoWindow>
				}
			</GoogleMap>
		</>
	
	return isLoaded && renderMap()
}

export default memo(SectionMap)