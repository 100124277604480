const Tooltip = (props) => {
  const style = props.style && props.style
  const defaultClassName = 'product-selector-tooltip'
  const className = props.className ? `${props.className} ${defaultClassName}` : defaultClassName
  return (
    <span 
      style={style} 
      data-tooltip={props.tooltip} 
      className={className}
    >
      <span>{props.symbol ? props.symbol : 'i'}</span>
    </span>
  )
}

export default Tooltip