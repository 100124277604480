import useBranding from '../hooks/useBranding'
import Footer from './Footer'
import Header from './Header'

import RebateFinder from './modular/RebateFinder'

export default function PageRebates() {
	const logo = useBranding()

	return (
		<>
			<Header 
				pageTitle='Search for rebates, tax credits, and other heat pump incentives'
				pageDesc='Mitsubishi Electric systems are so efficient they often qualify for federal and local incentives. Enter your zip code for incentives on qualifying heat pump systems installed by a licensed HVAC contractor.'
				pageImg=''
				logo={logo}
				noCta
			/>
			<main>
				<RebateFinder userZip=''/>
			</main>
			<Footer />
		</>
	)
}