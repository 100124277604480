import { applications } from './page content/static-content/rollerApplications'
import SectionRoller from './SectionRoller'

export default function PageFrameApplications() {
	return (
		<main>
			<SectionRoller 
				content={applications}
				title={'Comfort Solutions'}
				iframe
			/>
		</main>
	)
}