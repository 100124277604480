import { useState, useEffect } from 'react'
import convertToTitle from '../lib/convertToTitle'
import ScrollLink from './modular/ScrollLink'

export default function NavBu(props) {
    const [headerBtnClass, setHeaderBtnClass] = useState('')

    useEffect(() => {
        setHeaderBtnClass('btn')
        // When the user scrolls passed hero, show nav CTA
        if (props.scrollPos > 1000) {
            setHeaderBtnClass('btn fade-down')
        }
    }, [props.scrollPos])

    return (
        <nav id={props.id}>
            {!props.noCta && (
                <ScrollLink
                    id="header-scroll-btn"
                    to={props.ctaUrl ? `#${props.ctaUrl}` : `#form-section`}
                    style={props.style && props.style}
                    className={props.id === 'mobile-nav' ? 'simple-link' : headerBtnClass}
                >
                    {props.ctaText ? props.ctaText : 'Get Started'}
                </ScrollLink>
            )}
            {props.sections &&
                props.sections
                    .filter((section) => section.hasNavAnchor)
                    .map((section, index) => {
                        return (
                            <ScrollLink 
                                key={index + section}
                                to={`#${section.id}`}
                                style={{ textTransform: 'capitalize', color: props.color }}
                            >
                                {convertToTitle(section.id)}
                            </ScrollLink>
                        )
                    })}
        </nav>
    )
}
