import altText from './altText'

export default function control({
    id = 'control',
    hasNavAnchor = false,
    title = '',
    color = 'blue'
}) {
	return {
		id: id,
		class: 'two-column-img-left',
		hasNavAnchor: hasNavAnchor,
		title: title,
		blurb: '"Set temperature to 73 degrees"',
		detailsText: 'The kumo cloud smart phone app allows you to control your home\'s temperature no matter where you are. Monitor your home\'s heating and cooling while you\'re away, or even adjust it on your way home, for max comfort on arrival.',
		detailsImgSrc: '/images/alexa-kumo.png',
		detailsImgAlt: `${altText} - kumo cloud works with voice control services like amazon alexa and google assistant`,
		imgSrc: '/images/kumo-iPhoneX.webp',
		imgAlt: `${altText} - kumo cloud app on smartphones`,
		footnote: 'Amazon, Alexa, and all related logos are trademarks of Amazon.com, Inc. or its affiliates.',
		backgroundCol: color
	}
}