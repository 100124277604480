import { useState, useEffect, useRef } from 'react'
import { fetchIpData } from '../lib/queries'
import { formatZip } from '../lib/helpers'
import validateField from '../lib/validateField'
import ThankYouSubscriber from './ThankYouSubscriber'
import LoadingSpinner from './modular/LoadingSpinner'
import InputText from './modular/InputText'
import { submitFormData, submitToSandbox } from '../lib/queries'
import conversions from '../lib/conversions'

export default function SubscriberForm({ content, formClass, hasTitle }) {
    const campaignId = content.subscriberCampaignId ? content.subscriberCampaignId : '7013q0000020To2AAE'

    const [formData, setFormData] = useState({
        Email: { value: '', valid: false },
        ZipCode: { value: '', valid: false },
    })
    const [formIsValid, setFormIsValid] = useState(false)
    const [leadSource, setLeadSource] = useState('Landing Page')
    const [userCity, setUserCity] = useState('')
    const [userState, setUserState] = useState('')
    const [userCountry, setUserCountry] = useState('United States')
    const [userInfo, setUserInfo] = useState('')
    const [gotEmail, setGotEmail] = useState(false)
    const [gotIp, setGotIp] = useState(false)
    const [thankYouHeadline, setThankYouHeadline] = useState("You're Connected!")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const GTMTrigger = content.GTMTrigger ? content.GTMTrigger : 'bottom_form'

    const currentPage = window.location.pathname.substring(1)

    /* ---------------- FIELD VALUES HANDLER ---------------- */
    const handleChange = (e) => {
        const field = e.target
        let { name, type, value, required } = field

        if (name === 'ZipCode' && value) {
            value = formatZip(value)
        }

        const isValid = validateField(type, name, required, value)

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: value,
                    valid: isValid,
                },
            }
        })
    }

    useEffect(() => {
        const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
        setFormIsValid(allFieldsValid)
    }, [formData])

    /* ---------------- UTM PARAMETERS ---------------- */
    const currentUrlObject = new URL(document.location)
    const params = currentUrlObject.searchParams

    useEffect(() => {
        const newLeadSource = params.get('utm_source')
        newLeadSource && setLeadSource(newLeadSource)
    }, [params])

    /* ---------------- CONNECT TO FORM FIELDS VIA USEREF ---------------- */
    const tokenField = useRef()
    const submitBtn = useRef()

    const handleInfo = async () => {
        const handleUserIpData = (data) => {
            setUserCity(data.city)
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    ZipCode: {
                        value: data.postal,
                        valid: true,
                    },
                }
            })
            setUserState(data.region)
            setUserCountry(data.country_name)
            setUserInfo(`${data.ip} - ${data.asn.name}`)
            setGotIp(true)
            console.log(data)
        }
        // get ISP information
        if (!userCity && !gotIp) {
            const ipData = await fetchIpData()
            console.log(ipData)
            handleUserIpData(ipData)
        }
    }

    /* ---------------- LOCAL STORAGE HANDLER ---------------- */
    const handleStorageReset = () => {
        console.log(`--> subscriber form resetting `)
        // handle conversion and storage
        conversions('subscriber', null, true)
        console.log(window.dataLayer)
        setThankYouHeadline('Form reset, now reload page')
    }

    useEffect(() => {
        // handle local storage and form state
        const userEmail = localStorage.getItem('userEmail')
        const isSubmitted = localStorage.getItem(`${currentPage}_subscriberFormSubmitted`)
        const userState = localStorage.getItem('userState')
        isSubmitted === 'true' && setGotEmail(true)

        if (userEmail && (!formData.Email.value || formData.Email.value !== userEmail)) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    Email: {
                        value: userEmail,
                        valid: true,
                    },
                }
            })
        }

        userState && setUserState(userState)
    }, [formData, currentPage])

    /* ---------------- POST + RECAPTCHA V3 ---------------- */
    const handleFormSubmit = (e) => {
        e.preventDefault()

        formIsValid &&
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', { action: 'subscriber_form_submit' }).then((token) => {
                    tokenField.current.value = token
                    submitBtn.current.setAttribute('disabled', true)

                    // reconstruct form data
                    const data = new FormData(e.target)
                    data.set('token', token)
                    data.append('LastName', '[not provided]')
                    data.append('CampaignID', campaignId)
                    data.append('Journey', true)
                    data.append('ReceiveEmailCommunication', true)
                    data.append('LeadType', 'Subscriber')
                    // data.append("00N3100000GpFb9", 'Hot Spot / Cold Spot')
                    data.append('recordType', '012i0000000xpJ6')
                    data.append('Company', '[not provided]')
                    userCity && data.append('City', userCity)
                    userState && data.append('State', userState)
                    userInfo && data.append('CustomerComments', userInfo)

                    // submit form data
                    const response = async () => {
                        if (content.sandbox) {
                            return submitToSandbox(data)
                        }
                        return submitFormData(data)
                    }

                    if (response()) {
                        // handle google Enhanced Conversions
                        conversions('lead', {
                            email: formData.Email.value,
                            city: userCity,
                            state: userState,
                            zip: formData.ZipCode.value,
                        })

                        // replace form with thank you message
                        setGotEmail(true)
                        setIsSubmitting(false)
                    }
                })
            })
    }

    if (isSubmitting)
        return (
            <div className={formClass}>
                <LoadingSpinner loadingMessage={'Getting you connected...'} />
            </div>
        )

    if (gotEmail)
        return (
            <ThankYouSubscriber
                imgUrl={content.imgUrl}
                userEmail={formData.Email.value}
                formClass={formClass}
                handleStorageReset={handleStorageReset}
                thankYouHeadline={thankYouHeadline}
            />
        )

    if (!gotEmail)
        return (
            <form
                onSubmit={handleFormSubmit}
                action={GTMTrigger}
                id="subscriber-form"
                className={formClass}
                style={hasTitle && { paddingTop: 'var(--xl)', paddingBottom: 'var(--xl)' }}
                method="POST"
                autoComplete="on"
            >
                <input type="hidden" name="token" value="token" ref={tokenField} />
                <input type="hidden" name="LeadSource" value={leadSource} />
                <input type="hidden" id="country-input" name="Country" value={userCountry} />
                {hasTitle && (
                    <div id="rebate-wrapper">
                        <h3 className="h4" style={{ textAlign: 'center', marginTop: '0' }}>
                            Stay Connected!
                        </h3>
                        <h4 className="h5" style={{ textAlign: 'center', marginBottom: 'var(--md)' }}>
                            Be the first to receive the latest information on heat pump incentives, news, and product updates.
                        </h4>
                    </div>
                )}

                <InputText
                    name="Email"
                    type="email"
                    label="Email"
                    maxLength="42"
                    id="lead-email"
                    value={formData.Email.value}
                    isValid={formData.Email.valid}
                    onFocus={handleInfo}
                    onChange={handleChange}
                    placeholder="name@domain.com"
                    required
                />
                <InputText
                    name="ZipCode"
                    type="text"
                    label="Zip Code"
                    maxLength="5"
                    id="lead-zip"
                    value={formData.ZipCode.value}
                    isValid={formData.ZipCode.valid}
                    onChange={handleChange}
                    placeholder="U.S. Only"
                    required
                />
                <input ref={submitBtn} className="g-recaptcha" type="submit" id="bu-form-submit2" value="Get Connected" disabled={!formIsValid} />
                <p className="footnote" style={{ textAlign: 'center', fontStyle: 'italic' }}>
                    "By submitting my email address, I agree I would like to receive email communications from Mitsubishi Electric Trane HVAC in the future."
                </p>
            </form>
        )
}
