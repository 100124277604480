import Header from './Header'
import Footer from './Footer'
import Section from './Section'
import NavBu from './NavBu'
import LeadFormStd from './LeadFormStd'
import {header, hero, sections} from './page content/pageSectionLayoutsContent'
import HeroSection from './HeroSection'

export default function PageSectionLayouts() {
	return (
		<>
			<Header
				phone={header.phone}
				ctaText={header.ctaText}
				sections={header.pageSections}
			/>
			<main>
				<HeroSection 
					content={hero}
				/>

				{sections.map((section, index) =>
					<Section
						key={index+section.id}
						content={section}
					/>
				)}
				<section style={{placeContent:'center', background: 'var(--bg-gradient-gray)'}} id='form-section'  className='form-section'>
					<LeadFormStd
						formClass='standard-form-wrapper'
						formTitle='Standard Lead Form'
						formInstructions='Fill out this form to request a consultation with a licensed independent HVAC contractor. Up to three contractors may contact you to answer your questions and schedule a consultation.'
						thankYou='Up to three contractors may contact you to answer your questions and schedule a consultation.'
						campaignId='7013q000001kj0XAAQ'
						GTMTrigger='bottom_form'
					/>
				</section>
			</main>
			<NavBu
				id='mobile-nav'
				sections={header.pageSections}
			/>
			<Footer darkMode />
		</>
	)
}