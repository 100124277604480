import { useRef, useState, useEffect } from 'react'
import LoadingSpinner from './LoadingSpinner'
import useRegions from '../../hooks/useRegions'

export default function MapBu ({ vertical, handleClick, allProjects, selectedRegion }) {
	const [regionDataReady, setRegionDataReady] = useState(false)
	const detailsBox = useRef()

	const regionsData = useRegions()

	useEffect(() => {
		if (regionsData && allProjects) {
			// console.log('allProjects:', allProjects)
			regionsData.forEach((region) => {
				const id = region.id
				const buProject = allProjects[id]
				region.numOfProjects = buProject.length
			})
			setRegionDataReady(true)
		}
	}, [regionsData, allProjects])

	useEffect(() => {
		if(regionDataReady) {
			document.addEventListener('mousemove', (e) => {
				const x = e.clientX, y = e.clientY
				detailsBox.current.style.top = (y + 20) + 'px'
				detailsBox.current.style.left = (x) + 'px'
			})

			document.addEventListener('mouseover', (e) => {
				detailsBox.current.style.opacity = '0%'
				const el = e.target.tagName
				if (el === 'path' || el ==='polygon') {
					const content = e.target.dataset.title;
					detailsBox.current.innerHTML = content
					detailsBox.current.style.opacity = '100%'
				}
			})

			document.addEventListener('scroll', () => {
				detailsBox.current.style.opacity = '0'
			})
		}
	}, [regionDataReady])

	const getClass = (id) => {
		let className = 'bu-region'
		if (id === selectedRegion) {className = 'bu-region selected'}
		return className
	}

	
	if (!regionDataReady) return <LoadingSpinner leadingMessage={'Loading map'} errorMsg='Error loading map' />
	return (
		<>
			<div id='details-box' ref={detailsBox}></div>
			<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 835.3 523.4">
				<g id="us-map">
					{regionsData.map((region, i) => 
						<path 
							key={`${i}-${region.id}`} 
							onClick={handleClick} 
							className={getClass(region.id)} 
							id={region.id} 
							data-title={`${region.name} Region\n${region.verticals_projects[vertical]} Projects`} 
							data-name={region.name}
							d={region.path_data} 
						/>
					)}
				</g>
			</svg>
		</>
	)
}