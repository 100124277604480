import parseGeoData from './parseGeoData'

export default async function getGeoData(location) {
    const path = 'https://master.d28mnh9p7akn7p.amplifyapp.com/api/geoservices/v1.0/geocode'

    let params = undefined

    if (location.zip) {
        params = `method=zip&q=${location.zip}`
    }

    if (location.lat && location.lng) {
        params = `method=latlong&q=${location.lat},${location.lng}`
    }

    if (params) {
        const url = `${path}?${params}`
        const res = await fetch(url)
        const data = await res.json()

        if (res.ok && data.data.length > 0) {
            // geodata is an array of address variations, we just need to parse the first
            const parsedGeoData = parseGeoData(data.data[0])
            return {
                success: true,
                data: parsedGeoData
            }
        } else {
            console.error('Failed to fetch geodata', res.status, data)
            return { success: false, data: data }
        }
    }
    console.error('Location data must be an object formatted as either {zip: "######"} or {lat: ##.#####, lng: ##.#####}')
}