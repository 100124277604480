import altText from './static-content/altText'
import contractor from './static-content/contractor'

const buPhone = '888-495-0283'
const mainImg = '/images/METUS-contractor-fade-1500.webp'

const hero = {
	class: 'two-column-img-right',
	title: 'Join Our Community',
	blurb: 'Differentiate Your Business',
	detailsText: 'From electric cars to electric water heaters to electric heating and cooling, the future is electric. The demand for our all-electric heat pumps has never been greater. So there is no better time to join our community of premier contractors and grow your business.',
	backgroundCol: 'blue-dp',
	// has unique parent campaign ID: 7013q000002F8HZAA0
	// for testing only: 7013q000002FFyyAAG
	leadCampaignId: '7013q000002F8HZAA0',
	// but uses mabu subscriber ID
	subscriberCampaignId: '7013q000001kmXZAAY',
	ctaText: 'Get Started',
	ctaUrl: 'form-section',
	imgSrc: mainImg,
	imgAlt: `${altText}professional contractor installation exterior heat pump unit`
}

const sections = [
	{
		id: 'Why Partner?',
		class: 'one-column-list',
		title: 'Why Partner With Us?',
		listItems: [
			{
				title: '#1 Selling Heat Pump In the U.S.',
				description: '“I want a Mitsubishi.” If you\'ve heard that before, you know we are #1 in homeowners\' minds for our ductless technology. Our extensive regional and national advertising efforts raise brand awareness across the country.'
			},
			{
				title: '35+ Years As the Industry Leader',
				description: 'Mitsubishi Electric brought air-source heat pumps to the United States in 1982 and has been the market leader ever since.'
			},
			{
				title: 'Local Technical Support',
				description: 'Headquartered in Suwanee, GA, our Customer Care Team and 3 tier technical support is experienced and ready to help on a job, when needed.'
			},
			{
				title: 'Industry Leader In HVAC Innovation',
				description: 'We manufacture the widest range of indoor unit styles and size capacities, ensuring every customer’s unique application, aesthetic preference and design challenge can be met.'
			},
			{
				title: 'Regional Sales & Marketing Support',
				description: 'Throughout our 6 regional markets, we have dedicated Sales Managers and Marketing Managers to promote your business every step of the way, from lead generation advertising campaigns to sales and training support.'
			},
		],
		backgroundCol: 'gray',
	},
	// {
	//   id: 'Download',
	//   class: 'download-section contractors',
	//   blurb: 'Download our 2021 program guide to learn more.',
	//   imgSrc: '/images/diamond-contractor-program-guide-thumbnail.webp',
	//   imgUrl: '/Diamond-Contractor-Program-Guide-2021.pdf',
	//   imgAlt: `${altText} click to download our Contractor Program Guide`,
	//   backgroundCol: 'gray',
	// },
	contractor,
	{
		id: 'form-section',
		class: 'two-column-img-right form dark',
		form:'recruitment',
		leadCampaignId: hero.leadCampaignId,
		blurb: 'Electric Solutions, Exclusive Rewards',
		detailsText: 'Take advantage of our hands-on training courses, from beginner to advanced. Receive world-class marketing support and presence on our MitsubishiComfort.com dealer locator. Join our lead generation and consumer financing programs and utilize our in-home selling tools. Gain access to our premier points program featuring a varied catalog of merchandise, event tickets, and travel options.',
		licensedQuestion: 'Are you licensed to install and service HVAC equipment',
		backgroundCol: 'blue-dp',
		subBlurb: {
			title: 'Ready to get started?',
			listItems: [
				'Complete This Survey',
				'Meet Your Local Distributor Partner',
				'Attend Training'
			]
		}
	},
	{
		id: 'Our Distribution',
		class: 'map',
		title: 'Nationwide Distribution',
		data: 'distributors',
		options: {
            centerCoords: { lat: 44.133715, lng:  -115.357053 },
            zoomLevel: 4,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clustering: true
        }
	},
	{
		id: 'Our Brands',
		class: 'three-column-cards-w-image brands',
		title: 'Family of Brands Supported By Mitsubishi Electric Trane HVAC US',
		detailsText: 'Established in 2018, Mitsubishi Electric Trane HVAC US (METUS) is a leading provider of ductless and VRF systems in the United States and Latin America. As a 50/50 joint venture between Trane Technologies and Mitsubishi Electric US, Inc., the company provides innovative products, systems and solutions capable of cooling and heating any application from a home to a large commercial building.',
		contentBoxes: [
			{
				imgSrc: '/images/logos/ME-logo-primary.svg',
				imgAlt: `${altText}brand logo`,
			},
			{
				imgSrc: '/images/logos/Trane-ME-vt-primary.svg',
				imgAlt: `${altText}brand trane logo`,
			},
			{
				imgSrc: '/images/logos/AS-ME-vt-primary.svg',
				imgAlt: `${altText}american standard brand logo`,
			},
		],
		backgroundCol: 'gray',
	}
]

const header = {
	pageTitle: 'Join the Mitsubishi Electric HVAC Contractor Community',
	pageDesc: 'The demand for our all-electric heat pumps has never been greater. So there is no better time to join our community of premier contractors and grow your business.',
	pageImg: mainImg,
	class: 'dark-mode-header',
	branding: 'metus',
	phone: buPhone,
	ctaUrl: hero.ctaUrl,
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
