import altText from './altText'

export default function benefits({
    id = 'benefits',
    hasNavAnchor = false,
    title = 'Why Mitsubishi Electric?',
    color = 'yellow-purple'
}) {
    return {
        id: id,
        class: 'three-column-cards-w-image',
        hasNavAnchor: hasNavAnchor,
        title: title,
        contentBoxes: [
            {
                title: 'Comfort',
                blurb: 'For Everyone In Your Home',
                detailsText:
                    'Since our systems focus on individual living spaces rather than treating every room the same, you can make each room in your home the perfect temperature.',
                imgSrc: '/images/house_of_comfort_with_people_temp.webp',
                imgAlt: `${altText} interior bedroom mini-split wall unit heat pump flow`,
            },
            {
                title: 'Efficiency',
                blurb: 'Can Lead to Savings',
                detailsText:
                    'Our variable-speed, INVERTER-driven compressors operate on less energy and with superior energy-efficiency compared to conventional HVAC systems.',
                imgSrc: '/images/lifestyle/interior/home-office-view-sm.webp',
                imgAlt: `${altText} indoor heat pump mini split home office man working at desk with view of greenery outside large windows`,
            },
            {
                title: 'Sustainability',
                blurb: 'For a Cleaner Future',
                detailsText:
                    'Our all-electric heat pump systems do not burn fossil fuels which is safer for your home and better for the environment.',
                imgSrc: '/images/lifestyle/interior/older-couple-dog.webp',
                imgUrl: '',
                imgAlt: `${altText} older couple with dog mini-split living room clean, healthy air`,
            },
        ],
        backgroundCol: color,
    }
}
