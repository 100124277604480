import altText from './altText'

const warranty = {
	iconSrc: 'warranty',
	iconAlt: `${altText} 12 and 12 warranty icon`,
	blurb: 'Warranty',
	detailsText: 'Work with an independent Diamond Contractor or Ductless Pro to receive a 12 year parts/12 year compressor warranty, the best in the industry.',
	ctaText: 'Warranty Information',
	modalContent: {
		title: 'Our Absolute BEST Warranty',
		text: 'We stand behind our systems and want you to be 100% satisfied. When you work with a participating authorized contractor - independent contractors who are certified by Mitsubishi Electric to find and install the best solution for your home - you are entitled to our limited 12 years parts/12 years compressor warranty.',
		imgSrc: '/icons/12icon-red.png',
		imgAlt: `${altText} 12 and 12 warranty icon`
	}
}

export default warranty