import lorem from "./lorem"

const testProjects = {
	wbu: [
		{
		name: 'L.A. Project',
		location: 'Los Angeles, CA',
		image: '/images/image-here.webp',
		summary: lorem.one,
		url: ''
		},
		// {
		// name: 'Tucson Project',
		// location: 'Tucson, AZ',
		// summary: lorem.two
		// },
		// {
		// name: 'Portland Project',
		// location: 'Portland, OR',
		// summary: lorem.two
		// },
		// {
		// name: 'Seattle Project',
		// location: 'Seattle, WA',
		// summary: lorem.two
		// },
	],
	swbu: [
		{
		name: 'Austin Project',
		location: 'Austin, TX',
		image: '/images/image-here.webp',
		summary: lorem.two,
		url: ''
		},
		// {
		// name: 'Dallas Project',
		// location: 'Dallas, TX',
		// summary: lorem.two
		// },
		// {
		// name: 'New Orleans Project',
		// location: 'New Orleans, LA',
		// summary: lorem.two
		// },
	],
	cbu: [
		{
		name: 'Chicago Project',
		location: 'Chicago, IL',
		image: '/images/image-here.webp',
		summary: lorem.two,
		url: ''
		},
		// {
		// name: 'Des Moine Project',
		// location: 'Des Moine, IA',
		// summary: lorem.two
		// },
		// {
		// name: 'Omaha Project',
		// location: 'Omaha, NE',
		// summary: lorem.two
		// },
	],
	nebu: [
		{
		name: 'Utica Project',
		location: 'Utica, NY',
		image: '/images/image-here.webp',
		summary: lorem.two,
		url: ''
		},
		// {
		// name: 'Albany Project',
		// location: 'Albany, NY',
		// summary: lorem.one
		// },
		// {
		// name: 'Boston Project',
		// location: 'Boston, MA',
		// summary: lorem.three
		// },
		// {
		// name: 'Hartford Project',
		// location: 'Hartford, CT',
		// summary: lorem.two
		// },
		// {
		// name: 'Springfield Project',
		// location: 'Springfield, MA',
		// summary: lorem.one
		// },
	],
	sbu: [
		{
		name: 'Atlanta Project',
		location: 'Atlanta, GA',
		image: '/images/image-here.webp',
		summary: lorem.two,
		url: ''
		},
		// {
		// name: 'Birmingham Project',
		// location: 'Birmingham, AL',
		// summary: lorem.one
		// },
		// {
		// name: 'Jackson Project',
		// location: 'Jackson, MS',
		// summary: lorem.two
		// },
	],
	mabu: [
		{
		name: 'NYC Project',
		location: 'Brooklyn, NY',
		image: '/images/image-here.webp',
		summary: lorem.two,
		url: ''
		},
		// {
		// name: 'Philadelphia Project',
		// location: 'Philadelphia, PA',
		// summary: lorem.two
		// },
		// {
		// name: 'Pittsburgh Project',
		// location: 'Pittsburgh, PA',
		// summary: lorem.two
		// },
		// {
		// name: 'D.C. Project',
		// location: 'Washington, D.C.',
		// summary: lorem.two
		// },
	],
}

export default testProjects