import { header, hero, sections } from './page content/pageGeneratorContent'
import Page from './Page'

export default function PageGenerator() {
	const finalHero = { 
		...hero,
		scrollTo: sections[0].id
	}

	return (
		<Page header={header} hero={finalHero} sections={sections} />
	)
}