import { header, hero, sections } from './page content/pageWbuContent'
import Page from './Page'

export default function PageFuelSwitch() {
	const finalHero = {
		...hero,
		leadCampaignId: '7013q000002HZM7AAO',
		staticCampaign: true,
		scrollTo: sections[0].id,
		// title: 'Ditch the Gas',
		// detailsText: '',
		// footnote: '',
		// backgroundSrc: '',
	}
    
	return (
		<Page header={header} hero={finalHero} sections={sections} />
	)
}