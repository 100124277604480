import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from "react-router-dom"
import { formatPhone, formatZip } from '../lib/helpers'
import { suffixifyState } from '../lib/suffixifyState'
import getGeoData from '../lib/getGeoData'
import validateField from '../lib/validateField'
import InputText from './modular/InputText'
import ThankYou from './ThankYouContractor'
import InputRadioBtnGroup from './modular/InputRadioBtnGroup'
import LoadingSpinner from './modular/LoadingSpinner'

export default function LeadForm({ content }) {
	const [leadFormSubmitted, setLeadFormSubmitted] = useState(false) 
	const currentPage = window.location.pathname.substring(1)

	/* ---------------- FORM DATA ---------------- */
	const [formData, setFormData] = useState({
		first_name: {value: '', valid: false},
		last_name: {value: '', valid: false},
		phone: {value: '', valid: false},
		email: {value: '', valid: false},
		zip: {value: '', valid: false},
		company: {value: '', valid: false},
	})

	const [formIsValid, setFormIsValid] = useState(false)	
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [userCity, setUserCity] = useState('')
	const [userState, setUserState] = useState('')
	const [isLicensed, setIsLicensed] = useState(undefined)
	const [searchParams, setSearchParams] = useSearchParams()

	const tokenField = useRef()

	/* ---------------- FIELD VALUES HANDLER ---------------- */
	const handleChange = (e) => {
		const field = e.target
		let { name, type, value, required } = field
		let checked
		
		if (name === 'phone' && value) {
			value = formatPhone(value)
		}
		
		if (name === 'zip' && value) {
			value = formatZip(value)
		}
		
		if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
			checked = e.target.checked
        }
		
		const isValid = validateField(type, name, required, value,)
		console.log('changed:', name, value, 'valid:', isValid)

		const newData = {
			[name]: {
				value: type === 'checkbox' ? checked : value,
				valid: isValid,
			}
		}

		console.log(newData)

		setFormData((prevFormData) => {
            return {
                ...prevFormData,
                ...newData
            }
        })
	}

	useEffect(() => {
		console.log(formData)
		const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
		setFormIsValid(allFieldsValid)
	}, [formData])

	useEffect(() => console.log(content), [content])

	const handleLicensedChange = (e) => setIsLicensed(e.target.value === 'true')
	
	const [welcomeMsg, setWelcomeMsg] = useState('')
	const [question, setQuestion] = useState('')

	/* ---------------- GEO ADDRESS FINDER ---------------- */
	const updateLocation = async () => {
		const userZip = formData.zip.value
		setWelcomeMsg('Searching...')
		const testZips = ['00111', '00112', '00113', '00114', '00115', '00116']

		if (testZips.includes(userZip)) {
			const finalPage = currentPage === '' ? 'home' : currentPage
			const currentPageCaps = finalPage.toUpperCase()
			setWelcomeMsg(`Welcome tester. For your convenience, I've pre-filled most of the required fields. Just add something unique under "First Name".`)
			setQuestion(content.licensedQuestion + '?')
			// setCompanyName(`${currentPageCaps} Test Company`)
			// radioYes.current.checked = 'true'
			setIsLicensed(true)
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					last_name: {
						value: `${currentPageCaps} Form Test`,
						valid: true,
					},
					email: {
						value: `${finalPage}@test.com`,
						valid: true,
					},
					phone: {
						value: formatPhone('1234567890'),
						valid: true,
					},
				}
			})
			setUserCity('Test City')
			setUserState('Georgia')
			return
		}

		const geoData = await getGeoData({ zip: userZip })

		if (!geoData.success) {
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					ZipCode: {
						value: '',
						valid: false,
					}
				}
			})
			setQuestion(`Sorry, '${userZip}' is not a valid U.S. zip code, please check for typos or try another.`)
			return
		}

		const userLocation = geoData.data

		// const geoCountry = address[findKeyIndex(address, 'country')].long_name
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				zip: {
					value: userLocation.zip,
					valid: true,
				},
			}
		})
		setUserCity(userLocation.city)
		setUserState(userLocation.state)
		setWelcomeMsg(`Welcome, ${suffixifyState(userLocation.state)}!`)
		setQuestion(`${content.licensedQuestion} in the state of ${userLocation.state}?`)
	}

	/* ---------------- UTM PARAMETERS ---------------- */
	let currentUrl = new URL(document.location)
	let params = currentUrl.searchParams
	let newId = params.get('campaign_id')
	let newSource = params.get('utm_source')

	/* ---------------- 'ENTER' KEY TRIGGERS ZIP SEARCH ---------------- */
	const [zipFocused, setZipFocused] = useState(false)

	const toggleZipFocus = () => setZipFocused(!zipFocused)
	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			updateLocation()
		}
	}

	/* ---------------- SUBMIT FORM DATA TO CRM ---------------- */
	const submitBtn = useRef() // submit button
	const handleFormSubmit = (e) => {
		e.preventDefault()
		submitBtn.current.setAttribute('disabled', true)
		formIsValid && window.grecaptcha.ready(() => {
			console.log('form is valid:', e.target)
			window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', {action: 'lead_form_submit'})
			.then((token) => {
				tokenField.current.value = token
				setIsSubmitting(true)

				// reconstruct form data
				const data = new FormData(e.target)
				data.set('token', token) // recaptcha token
				data.append('Campaign_ID', newId ? newId : content.leadCampaignId)
				data.append('lead_source', newSource ? newSource : 'Landing Page')
				data.append('00N3q00000GUtxs', 'true') // journey
				data.append('00N3q00000GUpoS', 'Lead') // lead type
				data.append('Caller_Type__c', 'HVAC Contractor') // caller type
				data.append('recordType', '012i0000000xpJ5') // record type
				data.delete('licensed')
				// log all final form data
				for (let [key, value] of data.entries()) { 
					console.log(key, value) 
				}
				// make fetch api call
				window.fetch('https://lead.tmehvac.com/sf/sfsubmit.php', {
					method: 'post',
					body: data
				})
				.then(function (response) { return response.text() })
				.then(function (text) { console.log(text) })
				.catch(function (error) { console.log(error) })

				// append URL with "thank-you" param
				const params = Object.fromEntries(searchParams)
				params['thank-you'] = true
				setSearchParams(params)
				setLeadFormSubmitted(true)
				setIsSubmitting(false)
			})
		})
	}

	if (isSubmitting) return (
		<div className={'formClass'} >
			<LoadingSpinner loadingMessage={'Sending your information...'} />
		</div>
	)

	if (leadFormSubmitted) return (
		<ThankYou
			firstName={formData.first_name.value}
			className='glass-panel dark'
			city={userCity}
			company={formData.company.value}
		/>
	)

	return (
		<form
			onSubmit={e => handleFormSubmit(e)}
			action='bottom_form'
			id='form contractors'
			className='glass-panel rebate-form-wrapper'
			style={{width: 'unset', backgroundColor: 'var(--black-30)', gridArea: 'img'}}
			autoComplete='on'
		>
			<input type='hidden' id='token' name='token' value='token' ref={tokenField} />
			<input type='hidden' id='city-input' name='city' value={userCity} />
			<input type='hidden' id='state-input' name='state' value={userState} />
			<input type='hidden' id='country-input' name='country' value='United States' />

			<div id='dealer-wrapper' style={{paddingBottom: 'var(--lg)'}}>
				<h3 className='h4' style={{marginTop: '0', textAlign: 'center'}}>
					{content.subBlurb.title}
				</h3>
				{content.subBlurb.listItems.map((item, index) => 
					<div key={'step '+index+1} className='steps-wrapper'>
						<span className='h6' style={{margin: 'var(--sm) 0', color: 'white'}}>Step {index+1}. {item}</span>
					</div>
				)}
			</div>
			
			<div id='lead-form-wrapper'>
				<div className='footnote' style={{margin: '0'}}>
					*Asterisk Denotes a Required Field
				</div>
				<div id='company-info-wrapper' style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 'var(--grid-gap)'}}>
					
					<InputText
						id='company-name'
						name='company'
						label='Company'
						placeholder='up to 36 characters'
						maxLength='36' //optional, default is '24'
						value={formData.company.value}
						isValid={formData.company.valid}
						onChange={handleChange}
						required
					/>
					
					<InputText
						id='zip'
						name='zip'
						label='Zip Code'
						placeholder='U.S. only'
						maxLength='5' //optional, default is '24'
						onFocus={toggleZipFocus}
						onBlur={toggleZipFocus}
						onKeyPress={handleEnterKey}
						onChange={handleChange}
						value={formData.zip.value}
						isValid={formData.zip.valid}
						required
					/>
					
				</div>
				<button 
					style={{gridArea: 'unset', width: '100px', margin: '0 auto', padding: 'var(--xs)'}} 
					className='btn'
					type='button'
					onClick={updateLocation}
				>
					Next
				</button>
				{ userState &&
					<>
						<div style={{paddingTop: 'var(--md)', borderTop: '1px solid var(--white-50)'}}>
							<h5 style={{margin: '0', marginBottom: 'var(--md)', textAlign: 'center'}}>
								{welcomeMsg}
							</h5>
							<InputRadioBtnGroup 
								id='input-wrapper'
								title={question}
								name='licensed'
								options={[true, false]}
								labels={['Yes', 'No']}
								handleChange={handleLicensedChange}
								selectedValue={isLicensed}
								required
							/>

						</div>
						{ isLicensed === false && 
							<div className='dark-mode' style={{backgroundColor: 'var(--black)', padding: 'var(--spc-md)', borderRadius: 'var(--rnd-md)'}}>
								<p style={{margin: '0'}}>
									Please visit <a href='https://www.mitsubishicomfort.com/' target='_blank' rel='noreferrer'>mitsubishicomfort.com</a> for more information on our products, or to <a href='https://www.mitsubishicomfort.com/find-a-contractor' target='_blank' rel='noreferrer'>find a licensed contractor</a> in your area.
								</p>
							</div>
						}
						{ isLicensed &&
							<>
								<div className='contact-info-wrapper'>
									<InputText
										id='first-name'
										name='first_name'
										label='First Name'
										type='text'
										placeholder='given name'
										value={formData.first_name.value}
										onChange={handleChange}
										isValid={formData.first_name.valid}
										required
									/>
									
									<InputText
										id='last-name'
										name='last_name'
										label='Last Name'
										type='text'
										placeholder='surname'
										value={formData.last_name.value}
										onChange={handleChange}
										isValid={formData.last_name.valid}
										required
									/>
									
									<InputText
										id='lead-email'
										name='email'
										label='Email'
										type='email'
										placeholder='name@domain.com'
										maxLength='42'
										value={formData.email.value}
										onChange={handleChange}
										isValid={formData.email.valid}
										required
									/>
									
									<InputText
										id='phone'
										name='phone'
										label='Phone No.'
										type='tel'
										placeholder='10-digit, U.S. numbers only'
										value={formData.phone.value}
										onChange={handleChange}
										isValid={formData.phone.valid}
										required
									/>
									
								</div>
								<div>
									<div className='final-form-checkbox-wrapper'>
										<div id='communications-wrapper'>
											<input id='00N3100000GpFb4' name='00N3100000GpFb4' type='checkbox' value='1' />
											<label className='visible' htmlFor='00N3100000GpFb4'>
												I would like to receive communications from Mitsubishi Electric Trane HVAC in the future.
											</label>
										</div>
										<button
											ref={submitBtn}
											className='g-recaptcha'
											type='submit'
											id='bu-form-submit contractors'
										>
											Submit Form
										</button>
									</div>
								</div>
							</>
						}
					</>
				}
			</div>
		</form>
	)
}
