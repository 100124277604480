// import lorem from './static-content/lorem'

export const hero = {
    id: 'hero',
    class: 'nav-below',
    backgroundSrc: '/images/lifestyle/exterior/modern-house-ext.webp',
    leadCampaignId: '',
    subscriberCampaignId: '',
}

export const sections = [
    // downloadSection,
    {
        id: 'Residential',
        class: 'new-products-section',
        title: 'New Residential Products',
        products: [
            // {
            //     id: 'deluxe',
            //     title: 'Deluxe Wall-Mounted',
            //     // name: 'The most feature-rich in our lineup',
            //     model: 'MSZ-FW / MUZ-FW',
            //     topImageSrc: '/images/new-products/DELUXE_2.webp',
            //     imageSrc: '/images/new-products/DELUXE_1.webp',
            //     description:
            //         'The MSZ-FW Deluxe Wall-mounted Indoor Unit features a 3D i-see Sensor® and offers dual vane operation. The updated 3D i-see Sensor scans the room, measuring temperature and occupant location. Indirect or direct airflow settings divert supply air away from or toward room occupants. The unit’s interior air duct/vane, coil, and fan feature Dual Barrier Coating, which maintains efficiency by keeping the inside clean. The MSZ-FW offers triple filtration, a backlit hand controller, and more premium features. The latest Deluxe Wall-mounted indoor unit is the first to include wireless capabilities built into the unit itself, you will not need to purchase additional equipment to add kumo cloud®.',
            //     features: [
            //         {
            //             icon: 'BCP_4',
            //             title: '3D i-see Sensor',
            //             description:
            //                 'Detects room occupancy, then adjusts temperature and airflow accordingly and saves energy when a room is empty. Features indirect or direct airflow for personalized comfort.',
            //         },
            //         {
            //             icon: 'h2i_sumo',
            //             title: 'H2i sumo™',
            //             description:
            //                 "The next generation of our Hyper-Heating INVERTER® technology. 100% heating performance even when it's -10° F outside with reliable heating even down to -22° F.",
            //         },
            //         {
            //             icon: 'Premier_7',
            //             title: 'Better Air Quality',
            //             description:
            //                 'Multi-stage filtration removes allergens, odors and contaminants to improve indoor air quality. Allergen filters are washable and deliver 10 years of reliable operation.',
            //         },
            //         {
            //             icon: 'Premier_4',
            //             title: 'Dual Barrier Coating',
            //             description:
            //                 'Mitsubishi Electric’s patented technology prevents the build up of dust, fibers, oil and smoke on system components resulting in a cleaner environment, higher efficiency and easier maintenance.',
            //         },
            //     ],
            // },
            {
                id: 'intelli-heat',
                title: 'intelli-HEAT™',
                productLogo: 'intelli-heat-logo.png',
                // name: 'Dual Fuel System',
                model: 'PAA',
                topImageSrc: '/images/new-products/intelli-HEAT_3.webp',
                imageSrc: '/images/new-products/intelli-HEAT_1.webp',
                description:
                    'The intelli-HEAT Dual Fuel System works seamlessly with your existing gas furnace*. Replace your outdoor condensing unit with a Mitsubishi Electric heat pump and enjoy year-round comfortable and efficient heating and cooling. intelli-HEAT mounts in-line with your existing furnace and ductwork. During periods of extreme cold, intelli-HEAT switches to the gas furnace based on capacity and economic balance points, creating a true dual fuel system. Add the benefits of true zoned comfort and fix hot and cold spots by adding additional indoor units.',
                footnote: 'Mitsubishi Electric air conditioner and heat pump systems should only be connected with ANSI-Z21.47/CSA2.3 certified furnaces.',
                features: [
                    {
                        icon: 'intelli-HEAT_4',
                        title: 'H2i® Hyper-heating',
                        description:
                            'The H2i® models provide 100% heating capacity down to 5° F outdoor ambient temperature and guaranteed operation down to -11° F. These units offer year-round comfort even in extreme climates.',
                    },
                    {
                        icon: 'intelli-HEAT_5',
                        title: 'Single- and Multi-zone Options',
                        description: 'intelli-HEAT connects to P-Series and MXZ outdoor units providing cooling only, heat pump, and Hyper-heating options.',
                    },
                    {
                        icon: 'intelli-HEAT_6',
                        title: 'Intelligent Switchover',
                        description: 'User-determined economic or capacity switchover balance points designed to save money and reduce Greenhouse Gases.',
                    },
                    {
                        icon: 'intelli-HEAT_7',
                        title: 'Flexible Install Options',
                        description: 'Vertical, downflow, and horizontal left or right installation options.',
                    },
                ],
            },
            {
                id: 'premier',
                title: 'Premier Wall-Mounted',
                // name: 'Indoor Unit',
                model: 'MSZ-GS/MSY-GS',
                topImageSrc: '/images/new-products/Premier_3.webp',
                imageSrc: '/images/new-products/Premier_1.webp',
                description:
                    'Our most flexible indoor solution offers a wide range of sizes, from 6 to 24 KBTU/H, and applications. The MSZ-GS is compatible with Mitsubishi Electric’s single-zone and multi-zone heat pumps or the H2i® systems. Its counterpart, the MSY-GS, is a single-zone air conditioner for climates where heating is not necessary.',
                features: [
                    {
                        icon: 'Premier_4',
                        title: 'Dual Barrier Coating',
                        description:
                            'Mitsubishi Electric’s patented technology prevents the build up of dust, fibers, oil and smoke on system components resulting in a cleaner environment, higher efficiency and easier maintenance.',
                    },
                    {
                        icon: 'Premier_5',
                        title: 'Smart Dry',
                        description: 'Continuous humidity removal without over cooling the space and while maintaining the desired humidity level.',
                    },
                    {
                        icon: 'Premier_6',
                        title: 'H2i® Hyper-heating',
                        description: 'The first Premier Wall-mounted Hyper-heat M-Series system with capacities including 9, 12, 15, 18, 24 KBTU/H.',
                    },
                    {
                        icon: 'Premier_7',
                        title: 'Enhanced Filtration',
                        description:
                            'Optional PM2.5 filter brings better indoor air quality by removing particulate matter (PM) with a diameter of less than 2.5 micrometers and other small dust particles.',
                    },
                ],
            },

            {
                id: 'smartMulti',
                title: 'SMART MULTI™',
                productLogo: 'SMARTMULTI-logo.png',
                logoHeight: '55px',
                model: 'MXZ-SM*TAM',
                topImageSrc: '/images/new-products/SMARTMULTI_3.webp',
                imageSrc: '/images/new-products/SMARTMULTI_1.webp',
                description:
                    'SMART MULTI™ is a new line of outdoor units that replace the PUMY and MXZ branch-box style outdoor units. SMART MULTI outdoor units are compatible with M-Series, P-Series, and CITY MULTI® indoor units. The new MXZ-SM*TAM SMART MULTI units are three-phase multi-zone systems with 6-, 8-, and 10-ton capacity options.',
                features: [
                    {
                        icon: 'SMARTMULTI_4',
                        title: 'Indoor Unit Compatibility',
                        description:
                            'SMART MULTI expands the indoor unit and controls offering by allowing connection to all M-Series, P-Series or CITY MULTI indoor units and controls.\n\nM-Series and P-Series:\nDeluxe Wall-mounted, Designer Wall-mounted, Premier Wall-mounted, Floor-mounted, EZ FIT® Recessed Ceiling Cassette, Multi-position Air Handler, Four-way Ceiling Cassette, Low- and Mid-static Horizontal-ducted.\n\nCITY MULTI:\nWall-mounted, Floor-mounted, Ceiling Cassette, High-static Horizontal-ducted, Four-way Ceiling Cassette, Ceiling-suspended, and Multi-position Air Handler.\n\nCITY MULTI indoor units cannot be combined with M- and P-Series indoor units on the same outdoor unit.',
                    },
                    {
                        icon: 'Heat20_7',
                        title: 'Improved Efficiency',
                        description: 'IEER target up to 24\nCOP target up to 4.19\nSHF target of <0.79.',
                    },
                ],
            },
            // {
            //     id: 'airHandler',
            //     title: '4- and 5-ton Air Handler',
            //     // model: 'model no. here',
            //     topImageSrc: '/images/new-products/air_handler.webp',
            //     imageSrc: '/images/new-products/air_handler2.webp',
            //     description:
            //         'Mitsubishi Electric is adding 4- and 5-ton Multi-position air handlers to our lineup, connecting to P-Series one-to-one with PVA units and SVZ units connecting to Universal and Multi-zone outdoor units.',
            //     features: [
            //         {
            //             icon: 'm_coil',
            //             title: 'M-coil',
            //             description:
            //                 'The new 4- and 5-ton multi-position air handlers feature an M-coil. This enables the overall height of the unit to be shorter. The coils are grooved, oxygen-free, and smaller in diameter for overall improved performance and longevity.',
            //         },
            //         {
            //             icon: 'pull_through',
            //             title: 'Pull-through Design',
            //             description: 'With the pull-through air handler design, we can have lower static pressure in the ductwork.',
            //         },
            //         {
            //             icon: 'heat_kit',
            //             title: 'Electric Heat Kit',
            //             description:
            //                 'Just behind the electronics panel is space for an optional slide-in Electric Heat Kit. This slide-in design helps with installation and helps to keep the overall height of the unit as small as possible.',
            //         },
            //     ],
            // },
        ],
    },

    {
        id: 'Commercial',
        class: 'new-products-section',
        title: 'New Commercial Products',
        products: [
            {
                id: 'heat2o',
                title: 'HEAT₂O™',
                productLogo: 'heat2o-logo.png',
                model: 'QAHV-N136TAU-HPB-(-BS)',
                topImageSrc: '/images/new-products/Heat2o_3.webp',
                imageSrc: '/images/new-products/Heat2o_1.webp',
                description:
                    'Heat2O is an all-electric heat pump water heater designed to produce high volume DHW for commercial facilities in any climate. Energy-efficient and environmentally friendly, Heat2O uses a natural CO2 refrigerant with a global warming potential (GWP) of one and an ozone depletion potential (ODP) of zero.',
                features: [
                    {
                        icon: 'Heat20_4',
                        title: 'Zero Emissions',
                        description: 'The perfect zero emission commercial solution for domestic hot water.',
                    },
                    {
                        icon: 'Heat20_5',
                        title: 'Twisted Spiral Gas Cooler',
                        description:
                            'Our patented twisted spiral gas cooler lives at the heart of Heat2O. Three connected refrigerant pipes are wound around a twisted water pipe, which maximizes heat transfer.',
                    },
                    {
                        icon: 'Heat20_6',
                        title: 'Natural Refrigerant',
                        description:
                            'CO₂ based, the HEAT₂O has a GWP of one, providing a reduced carbon footprint and a highly efficient means of producing 176° F temperature water in outdoor temperatures as low as -13° F.',
                    },
                    {
                        icon: 'Heat20_7',
                        title: 'High Efficiency',
                        description: 'COP up to 4.52, making this the most efficient water heating source in the industry.',
                    },
                ],
            },
            {
                id: 'hvrf',
                title: 'Hybrid VRF™',
                productLogo: 'hybrid-verf-logo.png',
                logoHeight: '100px',
                name: null,
                model: null,
                topImageSrc: '/images/new-products/Hybrid_VRF_3.webp',
                imageSrc: '/images/new-products/Hybrid_VRF_2.webp',
                description:
                    'The world’s only two-pipe Hybrid Variable Refrigerant Flow (HVRF) system that heats and cools simultaneously without the need for refrigerant in occupied spaces. Hybrid VRF can be tailored to fit many use requirements and is utilized in a wide variety of applications that require individual settings such as hotels, offices, hospitals, multi-family, schools, and senior living facilities.',
                features: [
                    {
                        icon: 'Hybrid_VRF_4',
                        title: 'Simultaneous Heating and Cooling',
                        description:
                            'Hybrid VRF uses CITY MULTI® N-Generation outdoor units. Air or water source units connect to the Hybrid Branch Controller (HBC) by a two-pipe refrigerant system, allowing for simultaneous heating and cooling.',
                    },
                    {
                        icon: 'Hybrid_VRF_5',
                        title: 'Hybrid BC Controller (HBC)',
                        description:
                            'HYBRID VRF exchanges heat between refrigerant and water using our unique HBC, which reduces overall refrigerant charge in the system and keeps the piping in the indoor space refrigerant-free.',
                    },
                    {
                        icon: 'Hybrid_VRF_6',
                        title: 'Air Source or Water Source',
                        description: 'Choose between air or water source units to achieve the highest efficiency.',
                    },
                    {
                        icon: 'Hybrid_VRF_7',
                        title: 'Exponentially Scalable',
                        description: 'Similar to traditional VRF, HVRF is scalable, making it a perfect fit for any size building.',
                    },
                ],
            },
            {
                id: 'precision-cooling',
                title: 'Precision Cooling',
                model: 'S-MEXT-G02-M1-DX',
                topImageSrc: '/images/new-products/IT Cooling_3.webp',
                imageSrc: '/images/new-products/IT Cooling_1.webp',
                description:
                    'Our split cooling package consists of the indoor S-MEXT high precision air handler connected to a P-Series outdoor unit. S-MEXT controls temperature and relative humidity with pinpoint accuracy, even in wide thermal variations. The result is a full INVERTER split system, designed according to the highest quality standards to meet the demands of the most reliable and essential I.T. environments.',
                features: [
                    {
                        icon: 'IT Cooling_4',
                        title: 'High Efficiency',
                        description:
                            'INVENTER-driven technology for highly efficient operation. Includes plug fans with EC electric motors and impeller which guarantees a reduction of power consumption.',
                    },
                    {
                        icon: 'IT Cooling_5',
                        title: 'Easy Maintenance',
                        description:
                            'Total front accessability including electrical panel and fan, long lasting washable air filters, and new maintenance-free electric fan motors make maintenance much easier.',
                    },
                    {
                        icon: 'IT Cooling_7',
                        title: 'Compact Design',
                        description:
                            'S-MEXT features a compact design resulting in a small footprint and making more valuable space available in the data center.',
                    },
                    {
                        icon: 'IT Cooling_6',
                        title: 'Compact Package',
                        description:
                            'Built-in control system with graphic display controls that monitors operation and alarm status. Offers a wide range of built-in and optional safety devices including water leakage detection, air flow loss alarm, and power disconnect in the case of fire or smoke alarms.',
                    },
                ],
            },
        ],
    },
    {
        id: 'Controls',
        class: 'new-products-section',
        title: 'New Controls',
        products: [
            {
                id: 'kumoCloud',
                title: 'kumo cloud®',
                productLogo: 'kumo-cloud-logo.webp',
                logoHeight: '60px',
                name: null,
                model: null,
                topImageSrc: '/images/new-products/kumo cloud_3.webp',
                imageSrc: '/images/new-products/kumo cloud_2.webp',
                description:
                    'The kumo cloud mobile app puts enhanced control of your home’s heating and cooling right at your fingertips, no matter where you are. Adjust the room temperature, set schedules, manage your zones, view alarms and much more, all from your smart device.',
                features: [
                    {
                        icon: 'kumo cloud_4',
                        title: 'Auto Changeover',
                        description:
                            'kumo cloud uses a patented algorithm to automatically change the system from cooling mode to heating mode and back based on a survey of each zone, all from a smart device.',
                    },
                    {
                        icon: 'kumo cloud_5',
                        title: 'Personalized Notifications',
                        description:
                            'Set up your own alert thresholds based on high or low indoor temperatures and decide whether to receive notifications for filter changes, battery performance, wireless connectivity and more.',
                    },
                    {
                        icon: 'kumo cloud_6',
                        title: 'Smart Home Device Compatible',
                        description:
                            'Control your comfort with Amazon Alexa or Google Home voice commands or integrate kumo cloud with other smart home devices using IFTTT.',
                        imgSrc: '/images/kumo-voice-services.webp',
                    },
                    {
                        icon: 'kumo cloud_7',
                        title: 'Control Third Party Equipment',
                        description:
                            'When paired with kumo station®, kumo cloud can manage third-party furnaces, boilers, heaters, humidifiers, dehumidifiers, and ventilators.',
                    },
                    {
                        icon: 'kumo_connect',
                        title: 'kumo connect™',
                        description:
                            'The kumo cloud app becomes more useful than ever with kumo connect. A new suite of alerts and self-help guides allow you to solve minor issues with your HVAC system yourself. You can send a Diamond Contractor® or Ductless Pro information about your problem, request service, or call them directly - all from within the app.',
                    },
                ],
            },
            {
                id: 'sdw',
                title: 'Simple Ductless Wired Controller',
                model: 'PAC-SDW01RC-1',
                topImageSrc: '/images/new-products/SDW-Controller-Front-Heat.webp',
                imageSrc: '/images/new-products/AHR_Photo_Blurb_019.webp',
                description:
                    'The SDW remote controller is simple and easy to use. The low-cost controller is the perfect choice for multi-family applications. It features a color screen and works with existing thermostat wire, making replacing controllers a valuable time-saver.',
                features: [
                    {
                        icon: 'AHR_Icon_012',
                        title: 'Programmable',
                        description:
                            'The remote is enabled with a programming feature for maximum convenience. The user has access to 7-day scheduling with custom presets.',
                    },
                    {
                        icon: 'AHR_Icon_011',
                        title: 'Temperature and Humidity Sensing',
                        description:
                            'Use the dual setpoint auto changeover and a customizable 4-digit PIN to adjust settings to fit the needs of each user. Stay informed of the changes in home temperature and humidity with helpful error codes and written descriptions.',
                    },
                    {
                        icon: 'AHR_Icon_09',
                        title: 'Auto-DRY Mode Function',
                        description:
                            'Control the humidity of the environment with the Auto-DRY mode function, which helps control the moisture level of the indoor air.',
                    },
                    {
                        icon: 'AHR_Icon_08',
                        title: 'Compatible with Standard Thermostat Wire',
                        description:
                            'When swapping out controls for the new remote, the SDW controller connects using existing thermostat wire for easy installation.',
                    },
                ],
            },
            {
                id: 'kenza',
                title: 'kenza cloud™',
                productLogo: 'kenza-cloud-logo.png',
                logoHeight: '110px',
                name: null,
                model: 'MCC-50A',
                topImageSrc: '/images/new-products/kenza cloud_3.webp',
                imageSrc: '/images/new-products/kenza cloud_2.webp',
                description:
                    'Introducing a cloud-based controls solutions for light to medium commercial spaces where a central controller is not typically needed, but cloud access for monitoring and basic control is required. Introducing kenza cloud, a simple MNET direct-to-cloud architecture to monitor your CITY MULTI® equipment.',
                features: [
                    {
                        icon: 'kenza cloud_4',
                        title: 'Four Easy Steps',
                        description:
                            'Simple gateway provisioning for reduced set up time on site. Confirm the gateway is the one you want to provision, select the subscription package, enter payment information and submit.',
                    },
                    {
                        icon: 'kenza cloud_5',
                        title: 'Monitor up to 50 Indoor Units',
                        description: 'Connect for easy access to monitor up to 50 indoor units.',
                    },
                    {
                        icon: 'kenza cloud_6',
                        title: 'Subscription Based',
                        description: 'Low cost monthly subscription-based service.',
                    },
                ],
            },
            {
                id: 'bcp200',
                title: 'Building Connect+ 200',
                productLogo: 'bcp-200-logo.png',
                logoHeight: '120px',
                name: null,
                model: 'BCP-200',
                topImageSrc: '/images/new-products/BCP_3.webp',
                imageSrc: '/images/new-products/BCP_2.webp',
                description:
                    'Our easy and reliable cloud-based platform allows you to monitor and control your CITY MULTI® and third-party equipment in one convenient web portal. Seamlessly connect up to 4 centralized controllers, 200 indoor units and 10 BACnet® devices. This Contractor-focused integration package reduces initial costs and commissioning times.',
                features: [
                    {
                        icon: 'BCP_4',
                        title: 'Self Discovery',
                        description: 'Auto-import functionality identifies and adds new units during startup and commissioning.',
                    },
                    {
                        icon: 'BCP_5',
                        title: 'Easy Access',
                        description: 'Local and remote user connectivity using computer, tablets, or mobile devices.',
                    },
                    {
                        icon: 'BCP_6',
                        title: 'Maintenance Tool Data',
                        description: 'Full set of Mitsubishi Electric CITY MULTI maintenance tool data for monitoring, troubleshooting, and analytics.',
                    },
                    {
                        icon: 'BCP_7',
                        title: 'Up to 200 Units',
                        description: 'Seamlessly connect up to 4 Central Controllers, 200 VRF indoor units, and 10 BACnet devices.',
                    },
                ],
            },
        ],
    },
]
