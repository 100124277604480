const SectionFrame = (props) => {
  return (
    <div
      style={{
        position: 'relative',
        height: `${props.pxHeight}px`,
        overflow:'hidden',
        // border: '1px dotted red'
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderWidth: '0',
        }}
        title={props.title}
        src={props.url}
        width='100%'
        scrolling='no'
        height={props.pxHeight}
      />
    </div>
  )
}

export default SectionFrame