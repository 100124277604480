import { useState, useEffect } from 'react'
import Icon from "./Icon"
import Link from './Link'
import Button from './Button'

export default function ContactSidebar(props) {
	const contacts = props.content
	const region = props.selectedRegion
	const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
	const [sidebarClass, setSidebarClass] = useState('contact-sidebar dark-mode')
	const [sidebarIcon, setSidebarIcon] = useState('talk')
	const [contactInfo, setContactInfo] = useState(null)
	const [contactButtonsVisible, setContactButtonsVisible] = useState(false)

	const toggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen)

	useEffect(() => {
		if (region) {
			// handle verticals with different contact person per BU
			const foundInfo = contacts.find((person) => {
				let info = person.bu === region.id
				// handle people that cover multiple BUs
				if(Array.isArray(person.bu)) {
					info = person.bu.find((bu) => bu === region.id)
				}
				return info
			})
			setContactInfo(foundInfo ? foundInfo : contacts[0])
			return
		}

		// prevent sidebar flashing primary contact if region param is present 
		const urlParams = new URL(document.location).searchParams
		const regionParam = urlParams.get('region')
		if (!regionParam) {
			setContactInfo(contacts[0])
		}
	}, [region, contacts])

	useEffect(() => {
		if (sidebarIsOpen) {
			setSidebarClass('contact-sidebar is-open dark-mode') 
			setSidebarIcon('x')
			return
		}
		setSidebarClass('contact-sidebar dark-mode')
		setSidebarIcon('talk')
	}, [sidebarIsOpen])

	const handleClick = () => setContactButtonsVisible(!contactButtonsVisible)
	
	const services = [
		'METUS single point of contact',
		'Engineer consult and design support',
		'Factory start-up assist',
		'Pre and post construction training',
		'179D, 45L tax credit assist',
		'Utility rebate assist',
		'10-yr extended warranties',
		'Full line of building automation products'
	]
	
	return (
		<>
			<aside className={sidebarClass}>
				{ contactInfo &&
					<>
						<h2 className='h4' style={{marginTop: '0'}}>
							{contactInfo.firstName} {contactInfo.lastName}
						</h2>
						<h3 className='h6'>{contactInfo.jobTitle}</h3>
						<img alt={`headshot portrait of ${contactInfo.firstName} ${contactInfo.lastName}, ${contactInfo.jobTitle} at Mitsubishi Electric Trane HVAC`} src={contactInfo.imgSrc} />
						<h3 className='h5'>{contactInfo.firstName} is ready to provide project support:</h3>
						<ul>
							{services.map((service, i) => <li key={i+service}>{service}</li>)}
						</ul>
						
						{ contactButtonsVisible 
							?	<div>
									<Link iconId='email' color='white' text={contactInfo.email} href={`mailto:${contactInfo.email}?subject=${contactInfo.vertical} Landing Page Inquiry&body=**please include your name, company, and any helpful information about your upcoming project(s) or general HVAC needs.**`}/>
									<Link iconId='phone' color='white' text={contactInfo.phone} href={`tel:+1${contactInfo.phone}`}/>
									<Link iconId='linkedin' color='white' text='LinkedIn' href={contactInfo.linkedIn} />
								</div>
							: 	<Button 
									text={`Contact ${contactInfo.firstName}`}
									iconId='talk'
									onClick={handleClick}
									center
								/>
						}
						{/* mobile button to toggle sidebar visibility */}
						<button className='open-sidebar-btn' onClick={toggleSidebar}>
							<Icon id={sidebarIcon} color='white' style={{position: 'unset'}} />
						</button>
					</>
				}
				
			</aside>
			<main>
				{props.children}
			</main>
		</>
	)
}