import altText from './altText'

export default function smartMulti({
    id = 'smart-multi',
    hasNavAnchor = false,
    title = 'Meet\nSMART MULTI®',
    color = 'gray'
}) {
    return {
        id: id,
        class: 'two-column-img-right',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'Our Three-phase\nHeat Pump',
        detailsText:
            'Three-phase SMART MULTI is a line of multi-zone outdoor units compatible with M-Series, P-Series, and CITY MULTI® indoor units. The lineup includes systems with 6-, 8-, and 10-ton capacity options. Connecting up to 30 CITY MULTI and up to 12 M- and P-Series indoor units, there are many options to choose from to suit your specific needs.',
        imgSrc: '/images/ME_MXZ-SM72_96_120TAM-U1_Right.webp',
        imgAlt: `${altText} interior bedroom mini-split recessed ceiling unit heating and cooling`,
        //ctaText: 'Watch Video',
        //videoSrc: 'skDqnK0JC-A',
        backgroundCol: color,
    }
}
