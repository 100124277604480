import Footer from './Footer'

const PageThankYou = () => {
  return (
    <>
      <div id="bg" class='fs-bg'></div>
      <header className='product-header'>
        <div></div>
        <div class="logo"></div>
        <div></div>
      </header>
      <div id="glass">
        <main>
          <section class='thank-you'>
            <h1>Thank You!</h1>
            <p>We've received your request for a consultation. An authorized contractor should be in touch with you within the next 48 hours. Ask your contractor to help you stack up the rebates from Mitsubishi and from your local utilities.</p>
            <h2 className="h4">Utility Rebate Information</h2>
            <p><a href='https://www.mitsubishicomfort.com/rebates-and-financing/rebates'>Click here</a> to find more detailed information on special utility rebates available in your area.</p>
            <h2 className="h4">Financing Your New System</h2>
            <p>Mitsubishi Electric and Synchrony Financial make it easier to afford the latest, most efficient heating and cooling systems available. With options like deferred interest and fixed payments with low interest rates, you can find the program that works best for you. Ask your contractor about financing options.</p>
            <p><a href='https://www.mitsubishicomfort.com/rebates-and-financing/financing'>Click here</a> for more information on financing.</p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )

}

export default PageThankYou