export const suffixifyState = (state) => {
  const suffixFriendlyState = (state) => {
    switch(state) {
      case 'Texas': return 'Texa'
      case 'Florida': return 'Florid'
      case 'Arkansas': return 'Arkansa'
      case 'Kansas': return 'Kansa'
      case 'Connecticut': return 'Constitution Stater'
      case 'Hawaii': return 'kamaaina'
      case 'Arizona': return 'Arizon'
      case 'Indiana': return 'Hoosier'
      case 'New Hampshire': return 'Granite Stater'
      case 'New Mexico': return 'New Mexic'
      case 'Ohio': return 'Buckeye'
      case 'North Carolina': return 'North Carolin'
      case 'South Carolina': return 'South Carolin'
      case 'Wisconsin': return 'Wisconsinite'
      case 'Wyoming': return 'Wyomingite'
      case 'Massachusetts': return 'Bay Stater'
      case 'Maine': return 'Mainer'
      case 'Vermont': return 'Vermonter'
      case 'Michigan': return 'Michigander'
      case 'Washington': return 'Washingtonian'
      default: return state
    }
  }
  const adjustedState = suffixFriendlyState(state)
  const stateLetters = [...adjustedState]
  const omittedStates = ['Hawaii', 'Indiana', 'Massachusetts', 'Maine', 'Connecticut', 'Vermont', 'New Hampshire', 'Ohio', 'Wisconsin', 'Wyoming', 'Michigan', 'Washington']
  let lastLetter = stateLetters[stateLetters.length-1]
  const getSuffix = () => {
    if (!omittedStates.includes(state)) {
      switch(lastLetter) {
        case 'a': return 'n'
        case 'o':
        case 'i':
        case 'e':
        case 'y':
        case 'h':
        case 'c': return 'an'
        case 'd':
        case 'k': return 'er'
        default: return 'ian'
      } 
    } else return ''
  }
  return adjustedState + getSuffix()
}