import { CopyBlock, dracula } from "react-code-blocks"

const Code = ({text, language, showLineNumbers}) => {

  return (
    <CopyBlock
      text={text}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={dracula}
      customStyle={{padding:'var(--spc-xl)', borderRadius: 'var(--rnd-md)'}}
      // wrapLongLines={true}
      // codeBlock
    />
  )
} 

export default Code