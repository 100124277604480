import altText from '../altText'
import lorem from '../lorem'

export default function download(color) {
	return {	
		id: 'Download',
		class: 'download-section',
		blurb: `download-section ${lorem.blurb}`,
		imgSrc: '/images/image-here.webp',
		imgUrl: '/Mitsubishi-Electric-2021-Consumer-Brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: color || 'gray',
	}
}
