import { useState } from 'react'
import hslaToHex from '../../lib/hslaToHex'

export default function GenerateShades({ color, levels }) {
    const opacityLevels = levels
    const [isCopied, setIsCopied] = useState(Array(opacityLevels).fill(false))

    const handleClick = (index, data) => {
        navigator.clipboard.writeText(data).then(() => {
            const copiedArray = [...isCopied]
            copiedArray[index] = true
            setIsCopied(copiedArray)
            setTimeout(() => {
                const resetArray = [...isCopied]
                resetArray[index] = false
                setIsCopied(resetArray)
            }, 3000) // Reset copied state after 3 seconds
        })
    }
    const array = []
    for (let i = 0; i < opacityLevels; i++) {
        const id = (opacityLevels+1) - (i+1)
        const name = (i === 0) ? `--${color}` : `--${color}-${id}0`
        const suffix = color.split('-')[1]
        const short = (suffix === 'lt') || (suffix === 'dk')
        const hex = hslaToHex(name)
        array.push(
            <div 
                key={name} 
                className={`color-swatch${short ? ' short' : ''}`} 
                data={hex} 
                style={{backgroundColor: `var(${name})`, color: hslaToHex(name, true)}}
                onClick={() => handleClick(i, hex)}
            >
                <p style={{color: hslaToHex(name, true)}} className='hex-label' onClick={() => handleClick(i, hex)}>
                    {isCopied[i] ? 'copied!' : `click to copy\n${hex}`}
                </p>
            </div>
        )
    }
    return array
}