import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/9/2024'
    const end = '11/4/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'mabu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kmXZAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/exterior/fall-neighborhood.webp',
        defaultImageAlign: 'top',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: '',
        utilityProduct: 'MXZ-SM48',
        whatsNext: defaults.whatsNext,
        // ME_Residential_MABU_Spring_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd000009OIeYIAW',
        campaignHero: 'rebate',
        // campaignCtaText: 'Get Started',
        campaignImage: '/images/lifestyle/exterior/fall-neighborhood.webp',
        campaignImageAlign: 'center',
        campaignTitle: defaults.title,
        campaignBlurb: 'Shift Seasons In Comfort',
        campaignDetails:
            'Mitsubishi Electric heating and air conditioning systems provide personalized, energy-efficient comfort no matter the weather outside. Learn more about our home heating and cooling solutions below.',
        campaignFootnote: `Instant rebate of $${amount} is valid only within NJ, PA, DE, MD, and select areas in NY, WV, and VA and varies based on system purchased. Qualifying system must be purchased from and installed by an eligible contractor in an owner-occupied residence between ${start} and ${end}.`,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
