import { useState, useEffect, useCallback } from 'react'
import { createKeyname, stringToNum, log } from '../../lib/helpers'
import { handleDucted } from '../../lib/productDataGenerator'
import Icon from './Icon'

export default function DynamicBtn ({ dataKey, dataValues, dataEnabledValues, reset, onClick, btnType, label, images, units }) {
	const [selectedValue, setSelectedValue] = useState(dataKey === 'brand' ? 'Mitsubishi Electric' : null)
	const [selectedIndoorUnits, setSelectedIndoorUnits] = useState([])

	// convert string (eg. ceiling (1-way)) to be keyname friendly (eg. ceiling_one_way)
	const finalKey = createKeyname(dataKey)

	const deselectIndoorUnit = useCallback((v) => {
		setSelectedIndoorUnits(prevUnits => prevUnits.filter(unit => unit !== v))
	}, [])
	
	// resets button to default when 'reset selections' button in parent is clicked
	useEffect(() => {
		if (reset === 'y') {
			setSelectedValue(dataKey === 'brand' ? 'Mitsubishi Electric' : null)
			setSelectedIndoorUnits([])
		}
	}, [reset, dataKey])
	
	const handleClick = (e) => {
		const key = e.target.getAttribute('data-key')
		const rawValue = stringToNum(e.target.getAttribute('data-value'))
		const value = key.includes('indoor') ? createKeyname(rawValue) : rawValue
		log(key, value)

		// if clicked button is already selected, deselect it
		if (rawValue === selectedValue) {
			setSelectedValue(null)
			if (selectedIndoorUnits.includes(rawValue)) {
				deselectIndoorUnit(rawValue)
			}
		// otherwise if button isn't a number
		} else if (isNaN(value)) {
			if (selectedIndoorUnits.includes(rawValue)) {
				deselectIndoorUnit(rawValue)
			} else {
				if (finalKey.includes('indoor')) {
					selectedIndoorUnits.push(rawValue)
				}
				setSelectedValue(rawValue)
			}
		} else {
			setSelectedValue(Number(value))
		}
		// pass key value pair to parent click function
		onClick && onClick(key, value)
	}

	const getImage = useCallback((images, value) =>
		images.findIndex(imageObject => imageObject.label === value)
	, [])

	// determine whether button should be enabled, values passed in from parent
	const isEnabledValue = useCallback((key, value) => {
		if (!dataEnabledValues) {
			return false
		}
		return dataEnabledValues.includes(value) || dataEnabledValues.includes(key) || (key && key === 'brand')
	}, [dataEnabledValues])

	const setBtnClass = (btnValue) => {
		if (isEnabledValue(btnValue)) {
			if (btnValue === selectedValue) {
				return `${btnType}-btn selected`
			} else if (isEnabledValue(btnValue, dataKey)) {
				if (finalKey.includes('indoor')) {
					if (selectedIndoorUnits.includes(btnValue)) {
						return `${btnType}-btn selected`
					} else {
						return `${btnType}-btn`
					}
				} else {
					return `${btnType}-btn`
				}
			}
		} else {
			return `${btnType}-btn disabled`
		}
	}
	
	// renders 1 of 2 button styles based on btnType, 'number' or 'image'
	return (
		<div className={`btn-row ${btnType}`}>
			{dataValues.map((value, index) => 
				<div
					key={`${value}-${index}`}
					id= {value}
					data-key={finalKey}
					data-value={value}
					onClick={isEnabledValue(value) ? handleClick : null}
					className={setBtnClass(value)}
				>
					{btnType === 'image' &&
						<div data-key={finalKey} data-value={value} style={label && {display:'flex'}}>
							{images
								? <img 
										data-key={finalKey}
										data-value={value}
										src={images[getImage(images, value)].imgSrc}
										alt={`${value} unit thumbnail` }
										style={label && {maxHeight:'85px'}}
									/>
								: <Icon 
										dataKey={finalKey} 
										dataValue={value} 
										id={`${value.toLowerCase()}-zone`}
									/>
							}
							{ finalKey !== 'brand' && 
								<h6 data-key={finalKey} data-value={value}>{handleDucted(value)}</h6> 
							}
						</div>
					}
					{btnType === 'number' && 
						<h6
							data-key={finalKey} 
							data-value={value}
						>
							{value}<span data-key={finalKey} data-value={value} className={units}>{units}</span>
						</h6>
					}
				</div>
			)}
		</div>
	)
}