import {useState, useEffect} from 'react'
import Modal from 'react-modal'
import createCsv from '../lib/createCsv'
import { createTitle, createKeyname, booleanToYesNo, handleValue } from '../lib/helpers'
import Icon from './modular/Icon'
import Button from './modular/Button'


export default function CompareResults({ brands, brandSelected, skipKeys, productData, text }) {
	const [modalIsOpen, setIsOpen] = useState(false)
	const [tableData, setTableData] = useState([])
	const [tableHeaders, setTableHeaders] = useState([])
	const [csvDownloadLink, setCsvDownloadLink] = useState('')
	const [sortParams, setSortParams] = useState({ column: '', ascending: false })

	const getBackgroundColor = (value) => {
		switch (value) {
			case 'Yes': return 'var(--green-50)'
			case 'Heat Pump': return 'var(--red-50)'
			case 'Hyper-heat':
			case 'Hyper-Heat': return 'var(--red)'
			case 'Cooling Only': return 'var(--blue-50)'
			default: return 'transparent'
		}
	}

	const getColor = (value) => {
		switch (value) {
			case 'Hyper-heat':
			case 'Hyper-Heat': return 'var(--white)'
			default: return 'var(--text-col)'
		}
	}

	const handleColumnClick = (header) => {
		const ascending = header === sortParams.column ? !sortParams.ascending : false
		setSortParams({ column: header, ascending: ascending })
		tableData.sort((a, b) => {
			let order = 0
			const thisProductValue = a[header]
			const nextProductValue = b[header]
			const isNum = typeof(thisProductValue) === 'number'
			
			if (thisProductValue > nextProductValue && !isNum) {
				order = -1
			}
			if (thisProductValue < nextProductValue && !isNum) {
				order = 1
			}
			if (isNum) {
				order = nextProductValue - thisProductValue
			}
			if (ascending) order = order * -1
			return order
		})
	}

	useEffect(() => {
		brands.forEach((brand) => {
			if (brand.label !== brandSelected) {
				skipKeys.push(`${createKeyname(brand.label).replace('_electric', '').trim()}_model_no`)
			}
		})
		setSortParams({})
		const products = productData
		
		const tableData = []
		products.forEach((product) => {
			const formattedProduct = {}
			Object.entries(product.attributes).forEach(([key, value]) => {
				if (!skipKeys.includes(key)) {
					((value === 'n/a') || (value === null)) && (value = -1000)
					formattedProduct[key] = value
				}
			})
			tableData.push(formattedProduct)
		})
		
		// console.log('tableData:', tableData)
		const firstProduct = tableData[0]

		const headers = []
		Object.keys(firstProduct).forEach((key) => {
			key.includes('model_no') && (key = 'model_no')
			key === 'ducted' && (key = 'horizontal_ducted')
			headers.push(key)
		})
		// console.log('result keynames:', headers)
		setCsvDownloadLink(URL.createObjectURL(createCsv(tableData)))

		setTableHeaders(headers)
		setTableData(tableData)
	}, [brands, productData, brandSelected, skipKeys])

	const openModal = () => setIsOpen(true)
	const afterOpenModal = () => {}
	const closeModal = () => setIsOpen(false)

	return (
		<>
			<Button onClick={openModal} text={text}/>
			<Modal
				className='modal glass-panel table'
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				contentLabel="Modal"
			>
				<Icon id='x' alt='close button' onClick={closeModal}>X</Icon>
				<section>
					
					<table className='results-table'>
						<thead>
							<tr>
								{tableHeaders.map((header) => 
									<th 
										key={header} 
										onClick={() => handleColumnClick(header)} 
										className={sortParams.column === header ? 'selected' : undefined}
									>
										{createTitle(header)}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{tableData.map((product, index) => 
								<tr key={index}>
									{Object.entries(product).map(([key, value]) => {
										value = booleanToYesNo(value)
										return (
											<td 
												key={`${key} - ${value}`} 
												style={{color: getColor(value), backgroundColor: getBackgroundColor(value)}}
											>
												{handleValue(key, value)}
											</td>
										)}
									)}
								</tr>
							)}
						</tbody>
						
					</table>
					<a 
						className='simple-link csv-download'
						href={csvDownloadLink} 
						download='METUS-matching-outdoor-units.csv' 
					>
						Download Results As CSV
					</a>
				</section>
			</Modal>
				
		</>
	)
}