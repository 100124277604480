import altText from './altText'

const applications = {
	id: 'Hero',
	class: 'roller',
	backgroundSrc: '/images/lifestyle/interior/summer-hero.webp',
	leadCampaignId: '7013q000001lFmDAAU',
	subscriberCampaignId: '7013q0000020To2AAE',
	menuItems: [
		{
			title: 'System Replacement',
			blurb: 'Is your existing ducted system not cutting it?',
			backgroundSrc: '/images/ducted-home.webp',
			imgSrc: '/images/product/lockup-svz.webp',
			imgAlt: `${altText}ducted air handler indoor and outdoor mini-split heat pump system`,
			product: 'Ducted Air Handler',
			detailsText: 'This ducted heat pump system features flexible installation options and a compact design which allows for it to be tucked away in a corner or even a closet. You can cool or heat multiple rooms to your desired temperature and it ties into existing ductwork. Say goodbye to uncomfortable and say hello to your comfort zone with the Ducted Air Handler: an ideal solution for replacing your existing system.',
			productFeatures: [
				{
					icon: 'smart-thermostat',
					text: 'Heating and air conditioning in a single system connected to your existing ductwork'
				},
				{
					icon: 'blueprint-design',
					text: 'Installed either vertically (standing tall) or horizontally (laying down)'
				},
				{
					icon: 'anti-allergy-filter',
					text: 'Dust and particle air filtration and anti-allergy enzyme filter (optional)'
				},
				{
					icon: 'multi-zone',
					text: 'Single- or multi-zone heat pump application'
				},
			]
		},
		{
			title: 'Finished Garage',
			blurb: 'Want a space that\'s comfortable all year long?',
			backgroundSrc: '/images/lifestyle/interior/finished-garage.webp',
			imgSrc: '/images/product/lockup-mlz.webp',
			imgAlt: `${altText} recessed ceiling cassette indoor and outdoor mini-split heat pump system`,
			product: 'EZ FIT® Recessed Ceiling Cassette',
			detailsText: 'This mini-split easily fits between standard 16" ceiling joists and is recessed into the ceiling, making it almost invisible. You can cool or heat the space to your desired temperature and it doesn’t require any ductwork. Say hello to a comfortable sunroom with the EZ FIT Recessed Ceiling Cassette: an ideal solution for spaces with less insulation.',
			productFeatures: [
				{
					icon: 'smart-thermostat',
					text: 'Heating and air conditioning in a single system, with no ductwork required'
				},
				{
					icon: 'blueprint-design',
					text: 'The indoor unit is mounted between standard 16" ceiling joists'
				},
				{
					icon: 'anti-allergy-filter',
					text: 'Dust and particle air filtration and anti-allergy enzyme filter (optional)'
				},
				{
					icon: 'multi-zone',
					text: 'Single- or multi-zone heat pump application'
				},
			]
		},
		{
			title: 'Detached Space',
			blurb: 'She Shed? Man Cave? Make it the coolest place in town.',
			backgroundSrc: '/images/lifestyle/exterior/she-shed.webp',
			imgSrc: '/images/product/lockup-wr.webp',
			imgAlt: `${altText}wall-mounted indoor and outdoor mini-split heat pump system`,
			product: '115V Wall Mount',
			detailsText: 'This mini-split mounts on the wall and uses 115 volts of electricity, making it an ideal system for detached spaces around your home because it requires limited electrical. You can cool or heat the space to your desired temperature and it doesn’t require any ductwork. Say goodbye to uncomfortable and say hello to your comfort zone with the wall-mounted indoor unit: an ideal solution for detached spaces.',
			productFeatures: [
				{
					icon: 'smart-thermostat',
					text: 'Heating and air conditioning in a single system, with no ductwork required'
				},
				{
					icon: 'single-zone-outdoor',
					text: '115 volt heat pump'
				},
				{
					icon: 'anti-allergy-filter',
					text: 'Dust and particle air filtration and anti-allergy enzyme filter (optional)'
				},
				{
					icon: 'single-zone',
					text: 'Single-zone heat pump application'
				},
			]
		},
		{
			title: 'Refinished Basement',
			blurb: 'Does your basement workout space feel more like a sauna?',
			backgroundSrc: '/images/lifestyle/interior/basement-workout.webp',
			imgSrc: '/images/product/lockup-gl.webp',
			imgAlt: `${altText}wall-mounted indoor and outdoor mini-split heat pump system`,
			product: 'GL Wall Mount',
			detailsText: 'This mini-split heat pump system features a deodorizing air filtration system and comes in a variety of sizes, making it an ideal system for a basement refinish. You can cool or heat the space to your desired temperature and it doesn’t require any ductwork. Say goodbye to uncomfortable and say hello to your comfort zone with the GL Wall Mount: an ideal solution for basements. We also offer a cooling only system for climates where heating is not necessary.',
			productFeatures: [
				{
					icon: 'smart-thermostat',
					text: 'Heating and air conditioning in a single system, with no ductwork required'
				},
				{
					icon: 'sustainable',
					text: 'ENERGY STAR® Certified'
				},
				{
					icon: 'nano-platinum-filter',
					text: 'Multi-layer nano platinum filtration kills bacteria and deodorizes air'
				},
				{
					icon: 'plus',
					text: 'Available in a range of sizes'
				},
			]
		},
		
	]
}

export { applications }
