// const image = '/images/image-needed.webp'
const metus = 'Mitsubishi Electric Heating & Air Conditioning'

const features = [
  {
    title: '3D i-see Sensor®',
    iconId: '3d-isee-sensor',
    detailsText: 'Monitor room occupancy and adjusts airflow and temperature.'
  },
  {
    title: 'Instant Hot Start',
    iconId: 'hot-start',
    detailsText: 'No more blasts of cold air at start-up.'
  },
  {
    title: 'H2i® Technology',
    iconId: 'hyper-heat',
    detailsText: '100% heating capacity down to 5° F with guaranteed heating down to -13° F.'
  },
  {
    title: 'Whisper Quiet',
    iconId: 'check',
    detailsText: 'Run as low as 19 decibels, or quieter than a human whisper.'
  },
  {
    title: 'Air Filtration',
    iconId: 'air-filter',
    detailsText: 'Continuously cleans the air of allergens, dust, viruses and bacteria.'
  },
  {
    title: 'Econo Cool',
    iconId: 'econo-cool',
    detailsText: 'Directs colder air towards occupants to cool as quickly as possible.',
  },
  {
    title: 'Swing Mode',
    iconId: 'swing-mode',
    detailsText: 'Indoor unit moves air throughout the room like an oscillating fan.',
  },
  {
    title: 'Nano Platinum Filter',
    iconId: 'nano-platinum-filter',
    detailsText: 'Nanometer-sized platinum-ceramic particles kill bacteria and deodorize air.',
  },
  {
    title: 'Anti-allergy Enzyme Filter',
    iconId: 'anti-allergy-filter',
    detailsText: 'Traps allergens such as molds and bacteria and decomposes them using enzymes.',
  },
  {
    title: 'Powerful Mode',
    iconId: 'powerful-mode',
    detailsText: 'Brings the room to your desired temperature more quickly.',
  },
  {
    title: 'Features Needed',
    iconId: 'check',
    detailsText: 'Still need to add features to this product.',
  },
  {
    title: 'Light Commercial',
    iconId: 'light-commercial',
    detailsText: 'Capable of being used in smaller commercial applications.',
  },
  {
    title: 'Multi-zone',
    iconId: 'multi-zone',
    detailsText: 'Designed for cooling and heating multiple spaces at different temperatures.',
  },
  {
    title: 'Multi-zone Only',
    iconId: 'multi-zone',
    detailsText: 'Heats and cools multiple spaces at different temperatures. Cannot be used in a single-zone system.',
  },
  {
    title: 'Ducted',
    iconId: 'check',
    detailsText: 'Designed to connect to your home\'s existing ductwork.',
  },
  {
    title: 'Intelligent Switchover',
    iconId: 'sustainable',
    detailsText: 'Determines the most efficient source of heat (gas or electricity) on cold days.',
  },
]

const isee = features[0]
// const hotStart = features[1]
const h2i = features[2]
// const whisper = features[3]
const filter = features[4]
const econo = features[5]
const swing = features[6]
const platinum = features[7]
const allergy = features[8]
const powerful = features[9]
// const featuresNeeded = features[10]
const lightCommercial = features[11]
const multiZone = features[12]
const multiZoneOnly = features[13]
const ducted = features[14]
const switchover = features[15]

const tabsIndoor = [
    {
      id: 'wall-mounted',
      title: 'Wall-mounted Indoor Unit',
      detailsText: metus+' wall-mounted indoor units sit high on the wall, above your line of sight, and independently manage the comfort of a single room and can maintain the precise temperature you set.',
      products: [
        {
          title: 'Deluxe',
          imageName: 'FS.webp',
          detailsText: 'The Deluxe Wall-mounted Indoor Unit features Dual Barrier Coating, triple filtration, a backlit hand controller, and a 3D i-see Sensor®, which measures temperature and occupant location and directs air away from or toward room occupants.',
          specs: ['Capacities: 6,000 to 18,000 BTU/H', 'Sound: as low as 20 dB(A)', 'SEER2: up to 32.2', 'HSPF2: up to 11.9', 'COP: up to 4.68', 'ENERGY STAR®: Yes'],
          features: [h2i, multiZone, isee, econo, filter, swing, powerful]
        },
        {
          title: 'Designer',
          imageName: 'EF_White.webp',
          detailsText: 'The Designer Wall-mounted Indoor Units combine sophisticated technology and design. With clean lines and three finish options (glossy black, matte silver, or glossy white), this indoor unit complements various decor and style preferences.',
          specs: ['Capacities: 9,000 to 18,000 BTU/H', 'Sound: as low as 21 dB(A)', 'SEER2, HSPF2,  and COP are dependent on connected outdoor unit', 'ENERGY STAR®: Most Systems'],
          features: [econo, platinum, allergy, swing, multiZoneOnly]
        },
        {
          title: 'Premier',
          imageName: 'GL.webp',
          detailsText: 'The Wall-mounted Indoor Unit offers a wide range of sizes providing the most application solutions. The indoor unit matches with single-zone, multi-zone, or H2i® Hyper-Heating INVERTER® heat pump systems.',
          specs: ['Capacities: 6,000 to 24,000 BTU/H', 'Sound: as low as 19 dB(A)', 'SEER2: up to 24.3', 'HSPF2: up to 10.90', 'COP: up to 4.44', 'ENERGY STAR®: Most Systems'],
          features: [multiZone, econo, platinum, allergy, powerful, swing]
        },
        {
          title: 'Large Capacity',
          imageName: 'GS_D30.webp',
          detailsText: 'The Large Capacity Wall-mounted Indoor Unit has wide airflow capabilities to ensure conditioned supply air reaches every corner of a room. The unit\'s interior air duct/vane, coil, and fan features Dual Barrier Coating, which maintains efficiency by keeping the inside clean.',
          specs: ['Capacities: 30,000 to 36,000 BTU/H', 'Sound: as low as 32 dB(A)'
          
          , 'COP: up to 2.86'],
          features: [multiZone, econo, swing, powerful, lightCommercial]
        },

      ]
    },
    {
      id: 'ceiling-cassettes',
      title: 'Ceiling Cassette',
      detailsText: `${metus}'s sleek and modern ceiling-mounted options include recessed ceiling cassettes that fit between standard joists, 4-way recessed ceiling cassettes, and ceiling-suspended units.`,
      products: [
        {
          title: 'EZ FIT®',
          imageName: 'MLZ.webp',
          detailsText: 'The EZ FIT® Ceiling Cassette recesses between the ceiling joists providing a clean flush-mount appearance. The slim body design fits into shallow ceiling cavities making the EZ FIT a popular selection for room upgrades or new construction projects. High/low ceiling airflow settings and automatic vane control personalize room comfort.',
          specs: ['Capacities: 6,000 to 18,000 BTU/H', 'Sound: as low as 21 dB(A)','SEER2: up to 22.9','HSPF2: up to 11.90','COP: up to 4.10','ENERGY STAR®: Yes'],
          features: [h2i, multiZone, econo, swing]
        },
        {
          title: 'Four-way',
          imageName: 'SLZ.webp',
          detailsText: 'The Four-way Ceiling Cassette features customizable airflow and an optional 3D i-see Sensor®. These recessed ceiling cassettes mount flush with the ceiling and fit into a 2’ x 2’ suspended ceiling grid. The optional 3D i-see Sensor scans the room, measuring temperature, determining occupant location, and directing air away from or toward room occupants. Each of the four vanes is fully customizable to provide 72 unique airflow patterns.',
          specs: ['Capacities: 9,000 to 18,000 BTU/H', 'Sound: as low as 24 dB(A)','SEER2: up to 20.80','HSPF2: up to 10.70','COP: up to 3.9','ENERGY STAR®: Most Systems'],
          features: [h2i, multiZone, isee, econo, swing, lightCommercial]
        },
        {
          title: 'Four-way+',
          imageName: 'PLA_isee.webp',
          detailsText: 'The Four-way+ Ceiling Cassette offers an extensive lineup with capacities ranging from 12 to 42 KBTU/H. The unit includes the Draft Reduction vane setting which adjusts airflow direction more horizontal than the standard vane setting this reducing drafts dramatically. The Four-way Ceiling Cassette provides an astonishing 72 unique airflow patterns to accommodate different room layouts. Select any combination of 2, 3, or 4 vanes to deliver air into the area.',
          specs: ['Capacities: 12,000 to 42,000 BTU/H', 'Sound: as low as 27 dB(A)','SEER2: up to 26.90','HSPF2: up to 10.90','COP: up to 4.94','ENERGY STAR®: Most Systems'],
          features: [h2i, multiZone, isee, econo, swing, lightCommercial]
        },
        {
          title: 'Ceiling-suspended',
          imageName: 'PCA_PCFY.webp',
          detailsText: 'The Ceiling-suspended Indoor Unit is ideal for larger retail stores, commercial kitchens, classrooms, and office spaces. The unit features automatic airspeed adjustment and high/low ceiling modes. An optional 3d i-see Sensor scans the room temperature and occupant locations to deliver additional airflow control.',
          specs: ['Capacities: 24,000 to 42,000 BTU/H', 'Sound: as low as 33 dB(A)','SEER2: up to 21.00','HSPF2: up to 9.50','COP: up to 4.62','ENERGY STAR®: Some Systems'],
          features: [h2i, multiZone, isee, swing, lightCommercial]
        },
      ]
    },
    {
      id: 'floor-mounted',
      title: 'Indoor Unit',
      detailsText: `Installed low on the wall, just a few inches above the floor, this ${metus} solution features multi-flow vanes allow for fast warm up and efficient operation.`,
      products: [
        {
          title: 'Floor-mounted',
          imageName: 'KJ.webp',
          detailsText: 'The Floor-mounted Indoor Unit mounts low on the wall and can be mounted partially recessed. This indoor unit features rapid heating capability that quickly warms a space to the desired temperature and a multi-flow vane distributing airflow throughout the room, preventing uneven temperatures.',
          specs: ['Capacities: 9,000 to 18,000 BTU/H', 'Sound: as low as 21 dB(A)','SEER2: up to 28.70','HSPF2: up to 11.30','COP: up to 4.30','ENERGY STAR®: Yes'],
          features: [h2i, multiZone, isee, econo, filter, powerful, swing]
        },
      ]
    },
    {
      id: 'ducted',
      title: 'Indoor Unit',
      detailsText: `Our all-electric air handler and ducted units provide powerful, quiet and efficient cooling and heating solutions for your home or business. Using less ductwork than traditional systems, this ${metus} solution is easily hidden above the ceiling or below the floor.`,
      products: [
        {
          title: 'intelli-HEAT™ Hybrid HVAC',
          imageName: 'intelli-HEAT.webp',
          detailsText: 'Upgrade your current HVAC system to a Hybrid Dual Fuel Heat Pump and significantly reduce gas usage and CO2 emissions. Our intelli-HEAT system consists of 2 units: an exterior heat pump, which replaces your existing air conditioning condenser, and an interior unit that is installed on your existing furnace*. This smart system not only improves air conditioning efficiency on hot days, but also determines the best source of heat (gas or electricity) on cold days, so your HVAC system is always running at peak efficiency and comfort.',
          specs: ['Capacities: 18,000 to 36,000 BTU/H', 'Sound: as low as 24 dB(A)','SEER2: up to 16.5','HSPF2: up to 9.00','COP: up to 3.55','ENERGY STAR®: Some Systems'],
          features: [h2i, multiZone, ducted, switchover]
        },
        {
          title: 'Multi-position Air Handler',
          imageName: 'SVZ.webp',
          detailsText: 'The Multi-position Air Handler boasts best-in-class construction with a durable black ZAM finish, 1" R4.2 insulation, and low cabinet leakage. The highly efficient EC motor features three different static pressure settings. Optional electric heat kits are available. This air handler unit features a built-in humidifier, ERV, and auxiliary heat control inputs. The Multi-position Air Handler is an ideal replacement for traditional forced-air systems or adding to new additions.',
          specs: ['Capacities: 12,000 to 36,000 BTU/H', 'Sound: as low as 29 dB(A)','SEER2: up to 20.70','HSPF2: up to 10.70','COP: up to 4.20','ENERGY STAR®: Most Systems'],
          features: [h2i, multiZone, ducted,]
        },
        {
          title: 'Multi-position Air Handler+',
          imageName: 'PVA.webp',
          detailsText: 'The Multi-position Air Handler+ is an ideal solution for projects requiring higher heating capacity and a longer distance between it and the outdoor unit. The unit boasts best-in-class construction with a durable black ZAM finish, 1" R4.2 insulation, and low cabinet leakage. The highly efficient EC motor features three different static pressure settings. Optional electric heat kits are available. This air handler features a built-in humidifier, ERV, and auxiliary heat control inputs.',
          specs: ['Capacities: 12,000 to 42,000 BTU/H', 'Sound: as low as 24 dB(A)','SEER2: up to 22.00','HSPF2: up to 10.00','COP: up to 4.1','ENERGY STAR®: Some Systems'],
          features: [h2i, lightCommercial, ducted,]
        },
        {
          title: 'Low Static Horizonal-ducted',
          imageName: 'SEZ.webp',
          detailsText: 'Available as a single-zone or multi-zone unit, these low static units are designed to cool and heat one or two rooms through a short duct run. These ducted units may be mounted in the attic, hidden in the ceiling or beneath the floor, or concealed behind a bulkhead.',
          specs: ['Capacities: 9,000 to 15,000 BTU/H', 'Sound: as low as 23 dB(A)','SEER2: up to 22.0','HSPF2: up to 13.10','COP: up to 4.00','ENERGY STAR®: Yes'],
          features: [h2i, multiZone, ducted, isee, econo, filter, swing, powerful]
        },
        {
          title: 'Mid Static Horizonal-ducted',
          imageName: 'PEAD.webp',
          detailsText: 'The Mid Static Horizontal-ducted Indoor Unit offers low capacities suitable for Passive Houses up to larger capacities ideal for light commercial uses such as offices, retail, and restaurants. These units are capable of serving large spaces or multiple rooms and are typically mounted in the attic, hidden in the ceiling or beneath the floor, or concealed behind a bulkhead.',
          specs: ['Capacities: 9,000 to 42,000 BTU/H', 'Sound: as low as 24 dB(A)','SEER2: up to 20.70','HSPF2: up to 11.40','COP: up to 4.62','ENERGY STAR®: Most Systems'],
          features: [h2i, multiZone, ducted, isee, econo, filter, swing, powerful]
        },
      ]
    },
  ]


export default tabsIndoor