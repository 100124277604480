import altText from './static-content/altText'
// import lorem from './static-content/lorem'

const buPhone = '888-495-0283'

const hero = {
	class: 'two-column-img-right',
	blurb: 'Build with Purpose',
	title: 'Let\'s Build Better Together',
	detailsText: 'Today\'s homebuyers are demanding homes that reduce energy usage, improve comfort and contribute to their family\'s good health. Address all of these demands and get the support and products you need with industry-leading HVAC systems.',
	backgroundCol: 'blue-dp',
	// has unique parent campaign ID: 7013q000002GUERAA4
	leadCampaignId: '7013q000002GUERAA4',
	// uses sbu subscriber ID
	subscriberCampaignId: '7013q000001knTGAAY',
	ctaText: 'Contact Us',
	ctaUrl: 'form-section',
	videoId: 'Er1_Cu23IkA'
}

const sections = [
	{
		id: 'Why Partner?',
		class: 'one-column-list',
		title: 'Why Partner With Us?',
		listItems: [
			{
				title: '#1 Selling Heat Pump In the U.S.',
				description: '“I want a Mitsubishi.” If you\'ve heard that before, you know we are #1 in homeowners\' minds for our ductless technology. Our extensive regional and national advertising efforts raise brand awareness across the country.'
			},
			{
				title: '35+ Years As the Industry Leader',
				description: 'Mitsubishi Electric brought air-source heat pumps to the United States in 1982 and has been the market leader ever since.'
			},
			{
				title: 'Local Technical Support',
				description: 'Headquartered in Suwanee, GA, our Customer Care Team and 3 tier technical support is experienced and ready to help on a job, when needed.'
			},
			{
				title: 'Industry Leader In HVAC Innovation',
				description: 'We manufacture the widest range of indoor unit styles and size capacities, ensuring every customer’s unique application, aesthetic preference and design challenge can be met.'
			},
			{
				title: 'Performance Construction Team',
				description: 'The Mitsubishi Electric Performance Builder Team is comprised of industry experts who work with project teams to ensure homes are designed with an integrative mechancial system.'
			},
		],
		backgroundCol: 'gray',
	},
	{
		id: 'form-section',
		class: 'two-column-img-right form dark',
		form: 'recruitment',
		leadCampaignId: hero.leadCampaignId,
		blurb: 'Learn How to Build Better with High-Performance Heat Pumps',
		detailsText: 'Our Performance Construction Team can help you address today\'s demanding homebuyers\' concerns with products and support.',
		licensedQuestion: 'Are you a licensed builder',
		backgroundCol: 'blue-dp',
		subBlurb: {
			title: 'Ready to get started?',
			listItems: [
				'Complete this survey',
				'Your local Performance Construction Manager will contact you with more information',
				'Attend training for Performance Builders',
			]
		}
	},
	{
		id: 'Builder Spotlight',
		class: 'two-column-img-left',
		blurb: 'Performance Builder Community Spotlight',
		detailsText: `Matt Risinger is host of the “build show” and a nationally recognized expert in building science and high performance construction. We caught up with Matt at the International Builder's Show and discussed how he first came to appreciate high-performance heat pumps.`,
		imgSrc: '/images/risinger-interview.webp',
		imgAlt: `${altText} performance builder spotlight - Matt Risinger interview`,
		ctaText: 'Watch Video',
		videoSrc: 'Qq-S8XIk1wE',
	},
	{
		id: 'Why Our Products?',
		class: 'one-column-list',
		title: 'Why Use Our Products?',
		listItems: [
			{
				iconId: 'smart-thermostat',
				title: 'Comfort',
				description: 'Occupant comfort is critical in the modern home. Zoned heating and air-conditioning systems allow for precise temperature control to meet each individual\'s comfort preferences.'
			},
			{
				iconId: 'energy',
				title: 'Efficiency',
				description: 'A home using all-electric mini- and multi-split heat pumps can be up to 40% more efficient than homes using conventional HVAC technology.'
			},
			{
				iconId: 'health',
				title: 'Wellness',
				description: 'All-electric homes promote occupant wellness by prioritizing indoor environmental quality and its components: indoor air quality (IAQ), health, comfort, and wellness.'
			},
			{
				iconId: 'sustainable',
				title: 'Durability',
				description: 'The modern home is future proof: designed for sustainability and resilience and built to meet homeowners\' needs today, tomorrow and beyond.'
			},
			{
				iconId: 'price',
				title: 'Attainability',
				description: 'Whether market-rate or luxury, net-zero, high-quality and high-performance homes can be attainable for anyone.'
			},
		],
		backgroundCol: 'gray',
	},
	// {
	//   id: 'Performance Team',
	//   class: 'two-column-img-left',
	//   blurb: 'Performance Construction Team',
	//   detailsText: lorem.three,
	//   imgSrc: '/images/performance-builder-team.webp',
	//   imgAlt: `${altText} performance builder team`,
	//   ctaText: 'Watch Video',
	//   videoSrc: 'RkaxXzerVUY',
	//   backgroundCol: 'gray',
	// },
	// {
	// 	id: 'Our Distribution',
	// 	class: 'map',
	// 	title: 'Nationwide Distribution',
	// 	data: 'distributors',
	// 	options: {
    //         centerCoords: { lat: 44.133715, lng:  -115.357053 },
    //         zoomLevel: 4,
    //         zoomControl: false,
    //         mapTypeControl: false,
    //         scaleControl: false,
    //         streetViewControl: false,
    //         rotateControl: false,
    //         fullscreenControl: false,
    //         clustering: true
    //     }
	// },
	{
		id: 'Our Brands',
		class: 'three-column-cards-w-image brands',
		blurb: 'Family of Brands Supported By Mitsubishi Electric Trane HVAC US',
		detailsText: 'Established in 2018, Mitsubishi Electric Trane HVAC US (METUS) is a leading provider of ductless and VRF systems in the United States and Latin America. As a 50/50 joint venture between Trane Technologies and Mitsubishi Electric US, Inc., the company provides innovative products, systems and solutions capable of cooling and heating any application from a home to a large commercial building.',
		contentBoxes: [
			{
				imgSrc: '/images/logos/ME-logo-primary.svg',
				imgAlt: `${altText}brand logo`,
			},
			{
				imgSrc: '/images/logos/Trane-ME-vt-primary.svg',
				imgAlt: `${altText}brand trane logo`,
			},
			{
				imgSrc: '/images/logos/AS-ME-vt-primary.svg',
				imgAlt: `${altText}american standard brand logo`,
			},
		],
		backgroundCol: 'gray',
	}
]

const header = {
	pageTitle: 'Build better with Mitsubishi Electric',
	pageDesc: 'Today\'s homebuyers demand homes that reduce energy usage, improve comfort and contribute to their family\'s good health. Address all of these concerns and get the support and products you need with industry-leading HVAC systems.',
	pageImg: '/images/ductless-house-cutaway.webp',
	class: 'dark-mode-header',
	branding: 'metus',
	phone: buPhone,
	ctaUrl: hero.ctaUrl,
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
