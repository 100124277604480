import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from "react-router-dom"
import { formatPhone, formatZip } from '../lib/helpers'
import validateField from '../lib/validateField'
import InputText from './modular/InputText'
import ThankYou from './ThankYouContractor'
import InputRadioBtnGroup from './modular/InputRadioBtnGroup'
import LoadingSpinner from './modular/LoadingSpinner'

export default function LeadForm(props) {
	const [leadFormSubmitted, setLeadFormSubmitted] = useState(false)
    const [allContractors, setAllContractors] = useState(null)

	/* ---------------- FORM DATA ---------------- */
	const [formData, setFormData] = useState({
		companyName: {value: '', valid: false},
        companyId: {value: '', valid: false},
        companyPin: {value: '', valid: false},
        usesFieldTool: {value: '', valid: false},
        currentFieldTool: {value: '', valid: false},
        hasConsented: {value: false, valid: false}
	})

	const [formIsValid, setFormIsValid] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [searchParams, setSearchParams] = useSearchParams()

	const tokenField = useRef()

	/* ---------------- FIELD VALUES HANDLER ---------------- */
    const handleChange = (e, makeInvalid) => {
        console.log('parent form handleChange triggered')
		const field = e.target
		let { name, type, value, required } = field
		let checked

        if (name === 'companyPin') {
            value = value.replace(/\D/g, '').slice(0, 10)
        }

		if (name === 'Phone' && value) {
			value = formatPhone(value)
		}
		
		if (name === 'ZipCode' && value) {
			value = formatZip(value)
		}
		
		if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
			checked = e.target.checked
        }
		
		const isValid = makeInvalid ? false : validateField(type, name, required, value,)
		console.log('changed:', name, value, 'valid:', isValid)

		setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: type === 'checkbox' ? checked : value,
                    valid: isValid,
                }
            }
        })
	}

	const handleSuggestionSelected = (suggestion) => {
		const newData = {
			companyName: {
				value: suggestion.name,
				valid: true,
			},
            companyId: {
				value: suggestion.id,
				valid: true,
			}
		}

		setFormData((prevFormData) => {
            return {
                ...prevFormData,
                ...newData
            }
        })
	}

    const handleConfirmPin = () => {
        if (formData.companyPin.valid) {
            const url = `https://staging.d28mnh9p7akn7p.amplifyapp.com/api/salesforce/v1.0/dispatch-opt-in/${formData.companyId.value}?opt=true&pin=${formData.companyPin.value}`
            console.log(url)
        }
    }


	useEffect(() => {
		console.log('formData updated:', formData)
		const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
		setFormIsValid(allFieldsValid)
	}, [formData])

	useEffect(() => {
        const fetchContractorRecords = async () => {
            const res = await fetch('https://staging.d28mnh9p7akn7p.amplifyapp.com/api/salesforce/v1.0/accounts/contractors')
            const data = await res.json()
            setAllContractors(data.data)
        }
        fetchContractorRecords()
    }, [])

	/* ---------------- UTM PARAMETERS ---------------- */
	let currentUrl = new URL(document.location)
	let params = currentUrl.searchParams
	let newId = params.get('campaign_id')
	let newSource = params.get('utm_source')

	/* ---------------- SUBMIT FORM DATA TO CRM ---------------- */
	const submitBtn = useRef() // submit button
	const handleFormSubmit = (e) => {
		e.preventDefault()
		submitBtn.current.setAttribute('disabled', true)
		formIsValid && window.grecaptcha.ready(() => {
			window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', {action: 'lead_form_submit'})
			.then((token) => {
				tokenField.current.value = token
				setIsSubmitting(true)

				// reconstruct form data
				const data = new FormData(e.target)
				data.set('token', token) // recaptcha token
				data.append('Campaign_ID', newId ? newId : props.leadCampaignId)
				data.append('lead_source', newSource ? newSource : 'Landing Page')
				data.append('00N3q00000GUtxs', 'true') // journey
				data.append('00N3q00000GUpoS', 'Lead') // lead type
				data.append('Caller_Type__c', 'HVAC Contractor') // caller type
				data.append('recordType', '012i0000000xpJ5') // record type
				data.delete('licensed')
				// log all final form data
				for (let [key, value] of data.entries()) { 
					console.log(key, value) 
				}
				// make fetch api call
				window.fetch('https://lead.tmehvac.com/sf/sfsubmit.php', {
					method: 'post',
					body: data
				})
				.then(function (response) { return response.text() })
				.then(function (text) { console.log(text) })
				.catch(function (error) { console.log(error) })

				// append URL with "thank-you" param
				const params = Object.fromEntries(searchParams)
				params['thank-you'] = true
				setSearchParams(params)
				setLeadFormSubmitted(true)
				setIsSubmitting(false)
			})
		})
	}

    if (!allContractors) return (
		<div className={'formClass'} >
			<LoadingSpinner loadingMessage={'Loading Form Data...'} />
		</div>
	)

	if (isSubmitting) return (
		<div className={'formClass'} >
			<LoadingSpinner loadingMessage={'Sending your information...'} />
		</div>
	)

	if (leadFormSubmitted) return (
		<ThankYou
			className='glass-panel dark'
			company={formData.companyName.value}
		/>
	)

	return (
		<form
			onSubmit={e => handleFormSubmit(e)}
			action='bottom_form'
			id='form contractors'
			className='glass-panel rebate-form-wrapper pad-btm-xl'
			style={{width: 'unset', backgroundColor: 'var(--black-30)', gridArea: 'img'}}
			autoComplete='on'
		>
			<input type='hidden' id='token' name='token' value='token' ref={tokenField} />

			<div id='dealer-wrapper' style={{paddingBottom: 'var(--lg)'}}>
				<h3 className='h4' style={{marginTop: '0', textAlign: 'center'}}>
					{props.subBlurb.title}
				</h3>
				{props.subBlurb.listItems.map((item, index) => 
                    <h4 key={index} className='h6' style={{margin: 'var(--sm) 0'}}>
                        {item.text}
                    </h4>
                )}
			</div>
			
			<div id='lead-form-wrapper'>
				<div className='footnote' style={{margin: '0'}}>
					*Asterisk Denotes a Required Field
				</div>
				<div id='company-info-wrapper' style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 'var(--grid-gap)'}}>
					<InputText 
                        id='company-name'
                        name='companyName'
                        suggestions={allContractors}
                        value={formData.companyName.value}
                        isValid={formData.companyName.valid}
                        label={'Company Name'}
                        placeholder={'Start Typing'}
                        onChange={handleChange}
                        handleSuggestionSelected={handleSuggestionSelected}
                        required
                    />					
				</div>
				{ formData.companyName.valid &&
					<>
					
                        <div className='contact-info-wrapper'>
                            <InputText
                                id='company-pin'
                                name='companyPin'
                                label='Now Enter Your Pin'
                                type='text'
                                placeholder={'10 digits'}
                                value={formData.companyPin.value}
                                onChange={handleChange}
                                isValid={formData.companyPin.valid}
                                required
                            />									
                        {/* <button
                            className='secondary mar-y-0'
                            type='button'
                            id='bu-form-submit contractors'
                            onClick={handleConfirmPin}
                            disabled={!formData.companyPin.valid}
                        >
                            Confirm Pin
                        </button> */}
                        </div>
                        {formData.companyPin.valid &&
                            <>
                                <InputRadioBtnGroup 
                                    id='input-wrapper'
                                    title='Do you already use a field service management tool?'
                                    name='usesFieldTool'
                                    options={['Yes', 'No']}
                                    handleChange={handleChange}
                                    selectedValue={formData.usesFieldTool.value}
                                    required
                                />
                                <div>
                                    <div className='final-form-checkbox-wrapper'>
                                        <div id='communications-wrapper'>
                                            <input id='00N3100000GpFb4' name='00N3100000GpFb4' type='checkbox' value='1' />
                                            <label className='visible' htmlFor='00N3100000GpFb4'>
                                                I would like to receive communications from Mitsubishi Electric Trane HVAC in the future.
                                            </label>
                                        </div>
                                        <button
                                            ref={submitBtn}
                                            className='g-recaptcha'
                                            type='submit'
                                            id='bu-form-submit contractors'
                                        >
                                            Submit Form
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
			</div>
		</form>
	)
}
