import altText from './static-content/altText'
import lorem from './static-content/lorem'

// const buPhone = '123-456-7890'

const hero = {
	id: 'hero',
	class: 'two-column-img-left',
	backgroundSrc:'/images/lifestyle/exterior/COMM-rooftop.webp',
	title: lorem.title,
	blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
	detailsText: lorem.three,
}

const sideBar = {
	headline: 'Sidebar Title',
	imgSrc: '/images/image-here.webp',
	firstName: 'Firstname',
	lastName: 'Lastname',
	jobTitle: 'Sr. Manager, Business Development, (vertical market)',
}

const sections = [
	{
		id: 'Download',
		class: 'download-section',
		blurb: 'Download our brochure for more information on (vertical specific copy here).',
		imgSrc: '/images/image-here.webp',
		imgUrl: '/assisted-living-brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: 'gray',
	},
	{
		id: 'Why VRF?',
		class: 'one-column-list',
		title: 'Why VRF In Vertical?',
		listItems: [
			{
				iconId: 'money',
				title: lorem.title,
				description: lorem.six
			},
			{
				iconId: 'fresh-air-intake',
				title: lorem.title,
				description: lorem.four
			},
			{
				iconId: 'thermostat',
				title: lorem.title,
				description: lorem.three
			},
			{
				iconId: 'sustainable',
				title: lorem.title,
				description: lorem.five
			},
		],
		backgroundCol: 'gray'
	},
	{
		id: 'Products',
		class: 'one-column-info no-padding-bottom',
		title: `Latest in Innovative Technology`,
		blurb: `As the leader in VRF technology, we continue to advance technologies that reduce waste output and promote sustainable resource cycles, while increasing energy efficiency and eliminating dependence on fossil fuels`
	},
	{
		id: 'Hybrid VRF',
		class: 'two-column-img-left no-padding-bottom',
		title: 'Hybrid VRF',
		blurb: 'Released 2022',
		detailsText: `Hybrid VRF is a unique 2-Pipe Heat Recovery VRF System that replaces refrigerant with water between the Hybrid Branch Circuit Controller and the indoor units. This revolutionary design minimizes the need for expensive and on-going leak detection servicing and is specifically designed for occupied spaces where quiet, energy efficient, simultaneous heating and cooling is valued.`,
		imgSrc: '/images/product/ME_N-Gen_PURY-P120YNU-LC.png',
		imgAlt: `${altText} Hybrid VRF unit`,
		ctaText: 'Watch Video',
		videoSrc: 'VK3nAXnWKdo',
	},
	{
		id: 'HEAT₂O',
		class: 'two-column-img-left',
		title: 'HEAT₂O',
		blurb: 'The Future of Water Heating',
		detailsText: `HEAT₂O is an extremely efficient all-electric commercial water heating solution that not only delivers on the promise of a better future but ensures highly reliable performance while reducing your operational costs.`,
		imgSrc: '/images/product/heat2o.png',
		imgAlt: `${altText} HEAT₂O unit`,
		ctaText: 'Watch Video',
		videoSrc: 'TucxqPXYcuw',
	},
	{
		id: 'Projects',
		class: 'mapped-projects',
		title: 'Our Projects By Region',
		vertical: '(Vertical Name Here)'
	},
	{
		id: 'Product Range',
		class: 'two-column-img-right',
		blurb: 'Any Home. Any Building. Anywhere.',
		detailsText: `As a leader in advanced HVAC technologies, including Ductless and Ducted Mini-split and Variable Refrigerant Flow (VRF) heat pump and air-conditioning systems, we have a solution for any home, any building, anywhere. We continually innovate around efficiency(), comfort and wellness in buildings or homes of all shapes and sizes by providing industry-leading products, design and technical training, and unmatched end-to-end support.`,
		imgSrc: '/images/product/pro_products.webp',
		imgAlt: `${altText} range of professional products`
	},
	{
		id: 'Case Studies',
		class: 'three-column-cards-w-image',
		title: 'Case Studies',
		detailsText: `Review some of our most recent case studies to see how Mitsubishi Electric Trane HVAC products are benefitting the (this vertical) industry.`,
		contentBoxes: [
			
			{
				title: 'Case Study 1',
				blurb: 'Project Location',
				detailsText: `"Quote or testimonial from customer about how the vertical team assisted them with the project, made the project planning easier, and helped make it a success"`,
				imgSrc: '/images/image-here.webp',
				imgAlt: `${altText} Claiborne senior assisted living building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			{
				title: 'Case Study 2',
				blurb: 'Project Location',
				detailsText: `"Quote or testimonial from customer about how the vertical team assisted them with the project, made the project planning easier, and helped make it a success"`,
				imgSrc: '/images/image-here.webp',
				imgAlt: `${altText} Aljoya senior assisted living building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			{
				title: 'Case Study 3',
				blurb: 'Project Location',
				detailsText: `"Quote or testimonial from customer about how the vertical team assisted them with the project, made the project planning easier, and helped make it a success"`,
				imgSrc: '/images/image-here.webp',
				imgAlt: `${altText} Brentwood senior assisted living building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			
		],
		backgroundCol: 'gray',
	},
]

const header = {
	class: 'side-bar dark-mode-header',
	branding: 'metus',
	pageSections: sections.map((section) => section),
}

export { header, sideBar, hero, sections }
