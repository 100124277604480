import efficiency from './static-content/efficiency'
import health from './static-content/health'
import comfort from './static-content/comfort'
import allElectric from './static-content/allElectric'
import boiler from './static-content/boiler'
import ira25C from './static-content/ira-25c'
import support from './static-content/support'
import campaign from './campaigns/single-zone'

const buPhone = '866-419-2513'

const hero = campaign()

const sections = [
    ira25C({ hasNavAnchor: true }),
    boiler({}),
    allElectric({}),
    efficiency({ hasNavAnchor: true }),
    health({ color: 'yellow', hasNavAnchor: true }),
    comfort({}),
    support({ phone: buPhone }),
]

const header = {
    pageTitle: "Mitsubishi Electric's Single-Zone Heat Pumps - Rebates & Tax Credits",
    pageDesc:
        "Experience year-round comfort and energy efficiency with Mitsubishi Electric's single-zone heat pumps. Take advantage of exclusive utility rebates and tax credits now!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
