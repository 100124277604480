import altText from './altText'

export default function efficiency({
    id = 'efficiency',
    hasNavAnchor = false,
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-left',
		hasNavAnchor: hasNavAnchor,
		title: 'Efficiency',
		blurb: 'Leads to Savings',
		detailsText: 'Conventional systems are either all on or off. However, every home is different and every room can be unique. The variable speed compressors in our mini-split systems have built-in intelligence to ramp up or down depending on the needs of the room. Variable speed precision maintains your desired temperature, providing up to 40% more efficient operation compared to conventional systems. Plus our all-electric HVAC systems do not burn fossil fuels which is better for the environment and most qualify for ENERGY STAR® rating.',
		imgSrc: '/images/heat-pump-bedroom.webp',
		imgAlt: `${altText} interior to exterior mini-split heat pump flow`,
		backgroundCol: color
	}
}
