import altText from './static-content/altText'
import productRange from './static-content/product-range'

const vertical = 'Indoor Grow'
const mainImg = '/images/indoor-grow-hero.webp'

const hero = {
	class: 'two-column-img-left dark-mode',
	backgroundSrc: mainImg,
	title: 'VRF Solutions for Indoor Grow',
	blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
	detailsText: 'VRF technology\'s modular design, redundant system configuration, precise temperature control and easy integration with 3rd-party equipment such as dehumidification, make these systems a superior choice for indoor grow facilities.',
}

const sideBar = [
	{
		vertical: vertical,
		imgSrc: '/images/headshot-mike-hampton.webp',
		firstName: 'Mike',
		lastName: 'Hampton',
		jobTitle: 'Sr. Manager, Strategic Programs - Commercial',
		phone: '(470) 201-8669',
		email: 'mhampton@hvac.mea.com',
		linkedIn: 'https://www.linkedin.com/in/mike-hampton-58a75923/'
	}
]

const sections = [
	{
		id: 'Why VRF?',
		class: 'one-column-list',
		hasNavAnchor: true,
		title: '7 Benefits of Using Mitsubishi Electric VRF for Indoor Grow',
		listItems: [
			{
				iconId: 'shipping',
				title: 'Quicker to market',
				description: 'Mitsubishi Electric VRF systems ship from inventory unlike custom built equipment which take 6-9 months for delivery.'
			},
			{
				iconId: 'service',
				title: 'Easier installation with flexibility',
				description: 'Mitsubishi Electric VRF outdoor units can be set on roof or the ground, close by or significantly far away with piping lengths up to 540 feet; outdoor units are 30% lighter than other technologies which means less structural steel needed for support; indoor units can be mounted inside flower/veg rooms or outside and ducted into flower/veg room.'
			},
			{
				iconId: 'check',
				title: 'Quiet',
				description: 'Mitsubishi Electric VRF outdoor units have dB(A) ratings as low as conversation level - helps keep the neighbors happy!'
			},
			{
				iconId: 'versatile-flexible',
				title: 'Redundancy',
				description: 'Mitsubishi Electric VRF can be implemented using multiple smaller systems which provides back-up should there be a component failure (fan, compressor, expansion valve, etc…); since flower/veg rooms don’t have excess space our compact indoor units can be mounted to ceiling/wall.'
			},
			{
				iconId: 'thermostat',
				title: 'On-board controls',
				description: 'Mitsubishi Electric VRF systems come standard with networking and temperature averaging for more accurate room control; our built-in controls can network up to 200 indoor units without the expense of 3rd party control systems and paying controls contractor for integration services; remote access is available via Building Connect+, a cloud-based platform with fully customizable user interface offering personalized system overview, alarm screen, scheduling, trend monitoring, and real-time Maintenance Tool data.'
			},
			{
				iconId: 'hot',
				title: 'Reliability',
				description: 'Mitsubishi Electric VRF systems are installed and operating in cultivation facilities throughout US where extreme outside conditions are present: desert region where temps exceed 115 degrees in summer; northern region where winter temps go below 0 degrees; our low ambient cooling capabilities keep your grow rooms operating during the most extreme conditions.'
			},
			{
				iconId: 'energy',
				title: 'Less Energy Use',
				description: 'Mitsubishi Electric VRF systems have inverter compressors which keep crops growing 24/7/365 while using less energy than other technologies. The inverter compressor varies its speed to match the cooling or heating demand and consumes only the energy required. No other compressor design can match the efficient performance. This means more profit to your bottom line every month!'
			},
		],
		backgroundCol: 'gray'
	},
	{
		id: 'innovation',
		class: 'one-column-info no-padding-bottom',
		hasNavAnchor: true,
		title: `Leader in Innovative Technology`,
		blurb: `As the industry leader in VRF technology, Mitsubishi Electric advances technologies that reduce waste output and promote sustainable resource cycles, while increasing energy efficiency and eliminating dependence on fossil fuels.`
	},
	{
		id: 'gallery',
		class: 'three-column-cards-w-image pad-top-0',
		contentBoxes: [
			{
				imgSrc: '/images/indoor-grow-1.webp',
				imgAlt: `${altText} indoor grow application interior photo of hvac system`,
			},
			{
				imgSrc: '/images/indoor-grow-2.webp',
				imgAlt: `${altText} indoor grow application interior photo of hvac system`,
			},
			{
				imgSrc: '/images/indoor-grow-3.webp',
				imgAlt: `${altText} indoor grow application interior photo of hvac system`,
			}
		]
	},
	productRange({}),
]

const header = {
	pageTitle: 'HVAC Solutions for Indoor Grow Applications',
	pageDesc: 'VRF technology’s modular design, redundant system configuration, precise temperature control and easy integration with 3rd-party equipment such as dehumidification, make these systems a superior choice for indoor grow facilities.',
	pageImg: mainImg,
	class: 'side-bar dark-mode-header',
	branding: 'metus',
	pageSections: sections.map((section) => section),
	accentCol: 'green',
	vertical: vertical,
}

export { header, sideBar, hero, sections }
