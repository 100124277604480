import altText from './altText'

export default function boiler({
    id = 'boiler',
    hasNavAnchor = false,
    title = 'Classic Home?',
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-left',
		hasNavAnchor: hasNavAnchor,
		title: title,
		blurb: 'Enjoy Modern Comfort',
		detailsText: 'As heating and cooling technology has evolved, homeowners in your area are often challenged with how to incorporate modern comfort and efficiency, without disturbing the character of their homes. Whether you are looking for a single system that can provide air conditioning and effective heating down to -13° F, or you want to eliminate hot or cold spots in specific rooms, we have a solution just right for you.',
		imgSrc: '/images/radiator.webp',
		imgAlt: `${altText} classic interior radiator heater`,
		ctaText: 'Watch Video',
		videoSrc: 'JSRADx3a9SI',
		backgroundCol: color
	}
}
