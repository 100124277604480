import altText from './static-content/altText'
import hybridVrf from './static-content/hybridVrf'
import productRange from './static-content/product-range'
import innovativeProducts from './static-content/innovative-products'
import downloadIra from './static-content/download-ira'
import kenza from './static-content/kenza'

const vertical = 'Multi-Family'
const mainImg = '/images/hero-multi-family.webp'

const sideBar = [
    {
        vertical: vertical,
        imgSrc: '/images/john_bart_headshot.webp',
        firstName: 'John',
        lastName: 'Bart',
        jobTitle: 'Regional Sales Manager, Vertical Markets',
        phone: '(678) 446-4107',
        email: 'jbart@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/john-bart-420aa694/'
    }
]

const hero = {
    class: 'two-column-img-left dark-mode',
    backgroundSrc: mainImg,
    title: `HVAC Solutions For ${vertical} Applications`,
    detailsText: 'Mitsubishi Electric\'s VRF HVAC technology is an optimal solution for multi-family buildings, offering individual zoning for tailored comfort, efficient operation for cost savings, and superior air filtration for a healthier environment, all in an eco-friendly package.',
    videoSrc: 'Wc_9JHqBcUY',
    ctaText: 'Watch Video',
}

const sections = [
    {
        id: 'Why VRF?',
        class: 'one-column-list',
        hasNavAnchor: true,
        title: `Why Use VRF In Commercial ${vertical} Projects?`,
        listItems: [
            {
                iconId: 'power-bill-invoice',
                title: 'Reduced Footprint and Costs',
                description: 'Our all-electric VRF systems do not need gas lines, flue vents, fire dampers, or ductwork, simplifying installation and maintenance. Reduced electrical switch gear and wiring complexity also makes for a more straightforward setup. These improvements all result in a reduced HVAC footprint with higher safety and savings in the long term.'
            },
            {
                iconId: 'speed-fast',
                title: 'Benefits to Your Tenants',
                description: 'Enhanced Precision Control enables individual room zoning, adapting comfort to your tenants\' specific needs. With triple air filtration, we improve indoor air quality (IAQ) and minimize noise both indoors and outdoors for a peaceful, healthier environment. Our VRF systems are all-electric and environmentally friendly, making them an ideal solution for those seeking sustainable living.'
            },
            {
                iconId: 'city-multi',
                title: 'Proven Technology',
                description: 'Mitsubishi Electric\'s VRF technologies, employed globally for decades, have consistently demonstrated a reduction in overall operating costs compared to traditional heating and air conditioning systems.'
            },
            {
                iconId: 'sustainable',
                title: 'Improved Sustainability',
                description: 'With the highest number of Energy Star® certified products in the HVAC industry, Mitsubishi Electric VRF systems significantly support sustainability objectives. They reduce your carbon footprint and feature 80% recyclable materials, reinforcing our dedication to environmental responsibility.'
            },
        ],
        backgroundCol: 'gray'
    },
    // {
	// 	id: 'Projects',
	// 	class: 'mapped-projects',
	// 	title: `${vertical} Projects By Region`,
	// 	vertical: vertical,
	// 	projects: `${vertical.toLowerCase()}-projects`
	// },
    innovativeProducts({ hasNavAnchor: true, }),
    hybridVrf({}),
    kenza({}),
    productRange({}),
    {
		id: 'case-studies',
		class: 'three-column-cards-w-image',
        hasNavAnchor: true,
		title: 'Case Studies',
		detailsText: `Review some of our most recent case studies to see how Mitsubishi Electric HVAC products are benefitting building owners, managers, and tenants in the ${vertical} housing industry.`,
		contentBoxes: [
		
			{
				title: 'South Winds Apartments',
				blurb: 'Fall River, MA',
				detailsText: `"Residents have enjoyed the new [Mitsubishi Electric] systems installed. They’ve provided a significant amount of feedback on the improved heating and cooling over the past two years."`,
				quoteSource: 'Adam Clarke, director of asset management, Taurus (Owner)',
				imgSrc: '/images/south-winds-apts.webp',
				imgAlt: `${altText} South Winds Apartments multi-family project building exterior`,
			},
			{
				title: 'Stackhouse Apartments',
				blurb: 'Seattle, WA',
				detailsText: `"Beyond just achieving LEED Platinum, these apartments are among the most energy-efficient in the Pacific Northwest. It’s an important, ambitious program, and we’re proud to have met it. The Mitsubishi Electric system was of course a part of that success."`,
				quoteSource: 'Jon Heller, PE, president, Ecotope (Engineering)',
				imgSrc: '/images/stackhouse-apts.webp',
				imgAlt: `${altText} Stackhouse Apartments multi-family project building exterior`,
			},
			{
				title: 'Lofts at Empire Yards',
				blurb: 'Macon, GA',
				detailsText: `“We’ve received great feedback on how quickly the units heat the building in the winter and cool it on warm days. The tenants and building management have expressed they are happy with performance and speed.”`,
				quoteSource: 'Clay Etheridge, owner, Etheridge Heating and Air (Installer)',
				imgSrc: '/images/lofts-empire-yards.webp',
				imgAlt: `${altText} Lofts at Empire Yards multi-family project building exterior`,
			},
		],
		backgroundCol: 'gray',
	},
	downloadIra({ color: 'transparent' }),
]

const header = {
	pageTitle: 'HVAC Solutions For Multi-Family Applications',
	pageDesc: 'Mitsubishi Electric\'s VRF HVAC technology is an optimal solution for multi-family buildings, offering individual zoning for tailored comfort, along with efficient operation for cost savings.',
    pageImg: mainImg,
    class: 'side-bar dark-mode-header',
    branding: 'metus',
    pageSections: sections.map((section) => section),
    accentCol: 'blue',
    vertical: vertical,
}

export { header, sideBar, hero, sections }
