import { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Section from './Section'
import NavBu from './NavBu'
import ScrollLink from './modular/ScrollLink'
// import DownArrow from './modular/DownArrow'
import { header, hero, sections } from './page content/pageDispatchContent'

export default function PageDispatch() {
	useEffect(() => {
		window.root.style.setProperty('--accent', `var(--${header.accentCol})`)
		window.root.style.setProperty('--site-bg', `var(--dispatch-gray)`)
		window.root.style.setProperty('--bg', `var(--dispatch-gray)`)
  }, [header.accentCol])
    return (
        <>
            <Header
                pageTitle={header.pageTitle}
                pageDesc={header.pageDesc}
                pageImg={header.pageImg}
                class={header.class}
                phone={header.phone}
                branding={header.branding}
                sections={header.pageSections}
                ctaUrl={header.ctaUrl}
            />
            <main>
                <section id={hero.id} className={`section ${hero.class}`} style={{ background: `var(--dispatch-gray)` }}>
                    <div className="section-content-wrapper" style={{ maxWidth: '1600px' }}>
                        <div className="no-glass dark-mode">
                            <div className="panel-content">
                                {hero.blurb && (
                                    <h1 className="h4" style={{ margin: '0' }}>
                                        {hero.blurb}
                                        {hero.footnote && <div className="superscript">1</div>}
                                    </h1>
                                )}
                                <h2 className="h1" style={{ margin: '0' }}>
                                    {hero.title}
                                </h2>
                                {hero.detailsText && <p style={{ paddingBottom: 'var(--xl)' }}>{hero.detailsText}</p>}

                                {hero.ctaText && (
                                    <ScrollLink
                                        to={`#${hero.ctaUrl}`}
                                        className="btn"
                                        style={{ textTransform: 'capitalize', boxShadow: 'var(--neumorph-md-dk)' }}
                                    >
                                        {hero.ctaText}
                                    </ScrollLink>
                                )}
                                <span style={{ color: 'var(--white)', paddingLeft: 'var(--xs)' }}>
                                    {' '}
                                    or{' '}
                                    <ScrollLink className="on-accent-col" to="#why-dispatch">
                                        learn more
                                    </ScrollLink>
                                </span>
                                {/* <p style={{ marginTop: 'var(--xl3)', marginBottom: '0', fontSize: 'smaller' }}>
                                    Already a member?{' '}
                                    <a className="on-accent-col" href="https://meta-hvac.my.site.com/Contractor/s/" target="_blank" rel="noreferrer">
                                        Click here
                                    </a>
                                </p> */}
                            </div>

                            {hero.footnote && (
                                <div className="footnote-bar">
                                    <p className="footnote">
                                        <span className="superscript big">1</span> {hero.footnote}
                                    </p>
                                </div>
                            )}
                        </div>
                        <img src={hero.imgSrc} alt={hero.imgAlt} style={{ objectFit: 'cover' }} />
                    </div>
                </section>
                {sections.map((section, index) => (
                    <Section key={index + section.id} content={section} />
                ))}
            </main>
            <NavBu id="mobile-nav" sections={header.pageSections} />

            <Footer branding={header.branding} />
        </>
    )
}
