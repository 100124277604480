import { useState, useEffect } from 'react'
import { booleanToYesNo, createTitle, handleValue, getLogoSrc, log } from '../lib/helpers'
import { generateData, indoorUnitButtons } from '../lib/productDataGenerator'
import LoadingSpinner from './modular/LoadingSpinner'
import altText from './page content/static-content/altText'
import Header from './Header'
import TitleWithTooltip from './modular/TitleWithTooltip'
import Button from './modular/Button'
import ScrollLink from './modular/ScrollLink'
import DynamicBtn from './modular/DynamicBtn'
// import InputSlider from './modular/InputSlider'
import Icon from './modular/Icon'
import Link from './modular/Link'
import ProductCard from './ProductCard'
import { getFeatures } from '../lib/getFeatures'
import CompareResults from './CompareResults'
import { fetchOutdoorUnits, fetchIndoorUnits } from '../lib/queries'
import InputText from './modular/InputText';

let selectedFeatures = {}
let lastSelectedFeatures = {}

export default function PageDiamondFinder() {
	const [buttonsLoaded, setButtonsLoaded] = useState(false)
	const [outdoorUnits, setOutdoorUnits] = useState([])
	const [indoorUnits, setIndoorUnits] = useState([])
	const [modelIndex, setmodelIndex] = useState([])

	useEffect(() => { 
		fetchOutdoorUnits().then((data) => {
			const products = generateData(data)
			// log('outdoor units:', products)
			indoorUnitButtons.sort((a, b) => a.order - b.order)
			setAllButtonOptions(products)
			setOutdoorUnits(products)
			setButtonsLoaded(true)
		})

		fetchIndoorUnits().then((data) => {
			const products = data
			setIndoorUnits(products)
		})
	}, [])

	useEffect(() => {
		if (outdoorUnits || indoorUnits) {
			const productIndex = []
			outdoorUnits.forEach((unit) => {
				unit = unit.attributes
				productIndex.push({
					meModel: unit.mitsubishi_model_no,
					trModel: unit.trane_model_no,
					asModel: unit.american_standard_model_no,
					meLink: unit.me_specs,
					trLink: unit.trane_specs,
					asLink: unit.as_specs
				})
			})
			indoorUnits.forEach((unit) => {
				unit = unit.attributes
				productIndex.push({
					meModel: unit.service_reference_number,
					trModel: unit.trane_model_no,
					asModel: unit.american_standard_model_no
				})
			})
			setmodelIndex(productIndex)
		}
	}, [outdoorUnits, indoorUnits])

	const [showResults, setShowResults] = useState(false)
	
	const [allZoneOptions, setAllZoneOptions] = useState([])
	const [enabledZoneOptions, setEnabledZoneOptions] = useState([])
	
	const [allHeatingMinOptions, setAllHeatingMinOptions] = useState([])
	const [enabledHeatingMinOptions, setEnabledHeatingMinOptions] = useState([])
	
	const [allCoolingMinOptions, setAllCoolingMinOptions] = useState([])
	const [enabledCoolingMinOptions, setEnabledCoolingMinOptions] = useState([])
	
	const [allCapacityOptions, setAllCapacityOptions] = useState([])
	const [enabledCapacityOptions, setEnabledCapacityOptions] = useState([])
	
	const [allMaxHtDiffOptions, setAllMaxHtDiffOptions] = useState([])
	const [enabledMaxHtDiffOptions, setEnabledMaxHtDiffOptions] = useState([])
	
	const [allIndoorUnitOptions, setAllIndoorUnitOptions] = useState([])
	const [enabledIndoorUnitOptions, setEnabledIndoorUnitOptions] = useState([])
	
	const [matchingProducts, setMatchingProducts] = useState([])
	const [brandBtnSelected, setBrandBtnSelected] = useState('Mitsubishi Electric')
	const [reset, setReset] = useState('n')  
	
	const tip = 'Click on a button once to select it, then click again to deselect it.'
	
	const brands = [
		{
			label: 'Mitsubishi Electric',
			abbr: 'me',
			imgSrc: getLogoSrc('ME-primary.svg'),
			imgAlt: `${altText}brand logo`,
		},
		{
			label: 'Trane',
			abbr: 'tr',
			imgSrc: getLogoSrc('TR-ME-vertical.svg'),
			imgAlt: `${altText}trane brand logo`,
		},
		{
			label: 'American Standard',
			abbr: 'as',
			imgSrc: getLogoSrc('AS-ME-vertical.svg'),
			imgAlt: `${altText}american standard brand logo`,
		},
	]

	const setAllButtonOptions = (allProducts) => {
		const features = getFeatures(allProducts)
		// console.log(`all features:`)
		// console.log(features)
		setAllZoneOptions(features[1].options)
		setEnabledZoneOptions(features[1].options)
		setAllCapacityOptions(features[3].options)
		setEnabledCapacityOptions(features[3].options)
		setAllMaxHtDiffOptions(features[4].options)
		setEnabledMaxHtDiffOptions(features[4].options)
		setAllCoolingMinOptions(features[5].options)
		setEnabledCoolingMinOptions(features[5].options)
		setAllHeatingMinOptions(features[6].options)
		setEnabledHeatingMinOptions(features[6].options)
		setAllIndoorUnitOptions(features[7].options)
		setEnabledIndoorUnitOptions(features[7].options)
	}

	const skipKeys = ['id', 'cu_size', 'capacity_outdoor', 'createdAt', 'updatedAt', 'publishedAt', 'compatible_indoor_units', 'compatible_indoor_unit_types', 'me_specs', 'trane_specs', 'as_specs', 'heating', 'front_image_me', 'front_image_tr', 'front_image_as', 'capacity_outdoor', 'seer', 'eer','hspf', 'last_updated_date']
	
	// with each button click, update list of available button options
	const setEnabledOptions = (matchingProducts, clickedFeature) => {
		const filteredProductFeatures = getFeatures(matchingProducts)

		const skipFeature = (featureNameToSkip) => {
			// now see if clicked feature is found within currently selected features
			const filterFeatures = (clickedFeature) => {
				for (let featureName in clickedFeature) {
					const clickedEntry = clickedFeature[featureName]
					const selectedEntry = lastSelectedFeatures[featureName]
					if (clickedEntry !== selectedEntry) return false
				}
				return true
			}
			
			// see if the clicked button's featureName matches the row of buttons we want to skip
			const sameRowClicked = Object.keys(clickedFeature)[0] === featureNameToSkip
			
			if (sameRowClicked) {
				if (filterFeatures(clickedFeature)) {
					return false
				} else {
					return true
				}
			}
		}
		
		if (!skipFeature('zones')) setEnabledZoneOptions(filteredProductFeatures[1].options)
		if (!skipFeature('capacity')) setEnabledCapacityOptions(filteredProductFeatures[3].options)
		if (!skipFeature('max_height_diff_ft')) setEnabledMaxHtDiffOptions(filteredProductFeatures[4].options)
		if (!skipFeature('cooling_min')) setEnabledCoolingMinOptions(filteredProductFeatures[5].options)
		if (!skipFeature('heating_min')) setEnabledHeatingMinOptions(filteredProductFeatures[6].options)
		setEnabledIndoorUnitOptions(filteredProductFeatures[7].options)
	}
	
	const handleFeature = (key, value) => {
		// when user clicks a button (feature), add feature to or remove it from selectedFeatures object
		const setSelectedFeatures = () => {
			// if user clicked a button that was already selected, delete the feature and break
			if (selectedFeatures[key] === value || value === false) {
				delete selectedFeatures[key]
				return
			}

			if (key.includes('indoor')) {
				if (selectedFeatures[value]) {
					delete selectedFeatures[value]
					return
				}
				selectedFeatures[value] = true
				return
			}

			// for experimental temp slider
			if (key === 'min_temp') {
				selectedFeatures['cooling_min'] = value
				key = 'heating_min'
			}
			
			selectedFeatures[key] = value
		}

		const filterProducts = (filterObject) => {
			return (productObject) => {
				for (let keyName in filterObject) {
					const productValue = productObject.attributes[keyName]
					const filterValue = filterObject[keyName]

					if (productValue === 'n/a') return false

					// handle fields where values must match exactly
					if (keyName !== 'heating_min' && keyName !== 'cooling_min' && productValue !== filterValue) return false

					// handle cooling and heating min where product values can be less than or equal
					if (productValue > filterValue) return false
				}
				return true
			}
		}

		setSelectedFeatures()

		const newMatchingProducts = outdoorUnits.filter(filterProducts(selectedFeatures))

		if (newMatchingProducts.length === outdoorUnits.length) {
			log('no buttons selected')
			handleResetButtonClick()
			return
		}

		// if at least 1 product matches
		if (newMatchingProducts[0]) {
			setEnabledOptions(newMatchingProducts, { [key] : value })
			setMatchingProducts(newMatchingProducts)
			lastSelectedFeatures = {...selectedFeatures}
			setShowResults(true)
			return
		}

		// if no products match
		log('no matching products')
		handleResetButtonClick()
	}

	const handleResetButtonClick = () => {
		// window.navigator.vibrate(300)
		selectedFeatures = {}
		setAllButtonOptions(outdoorUnits)
		setBrandBtnSelected('Mitsubishi Electric')
		setMatchingProducts(outdoorUnits)
		setReset('y')
		setShowResults(false)
	}
	
	// listen for reset to change, then reset the reset
	useEffect(() => { reset === 'y' && setReset('n') }, [reset])
	
	const handleBrandBtnClick = (e) => {
		// window.navigator.vibrate(100)
		const clickedBtnValue = e.target.getAttribute('data-value')
		clickedBtnValue && setBrandBtnSelected(clickedBtnValue)
	}

	const [modelNoInputValue, setModelNoInputValue] = useState('')
	const [modelNoSearchResults, setModelNoSearchResults] = useState(null)
	const [modelNoSearchMsg, setModelNoSearchMsg] = useState('')

	const handleModelNoInputChange = (e) => setModelNoInputValue(e.target.value)

	const lookUpModelNo = () => {
		if (!modelNoInputValue || modelNoInputValue === '') {
			setModelNoSearchResults(null)
			setModelNoSearchMsg('')
			return
		}
		console.log(modelIndex)
		const modelsFound = []

		modelIndex.forEach((model) => {
			const searchLower = modelNoInputValue.toLowerCase()
			const meLower = model.meModel.toLowerCase()
			const asLower = model.asModel.toLowerCase()
			const trLower = model.trModel.toLowerCase()
			if (meLower.includes(searchLower) || asLower.includes(searchLower) || trLower.includes(searchLower)) {
				modelsFound.push(model)
			}
		})
		
		
		if (modelsFound[0]) {
			const numModels = modelsFound.length
			const multiple = numModels > 1
			const modelToDisplay = modelsFound[0]
			setModelNoSearchResults([
				{
					displayName: 'Mitsubishi Electric',
					modelNo: modelToDisplay.meModel,
					mldLink: modelToDisplay.meLink
				},
				{
					displayName: 'Trane M.E.',
					modelNo: modelToDisplay.trModel,
					mldLink: modelToDisplay.trLink
				},
				{
					displayName: 'American Std M.E.',
					modelNo: modelToDisplay.asModel,
					mldLink: modelToDisplay.asLink
				},
			])
			setModelNoSearchMsg(`${numModels} model${multiple ? 's' : ''} found matching "${modelNoInputValue}".${multiple ? ` Showing first result.` : ''} Click a model number to copy it to your clipboard:`)
		} else {
			setModelNoSearchResults(null)
			setModelNoSearchMsg(`"${modelNoInputValue}" Not Found`)
		}
	}

	const handleEnterKey = (e) => e.key === 'Enter' && lookUpModelNo()

	const handleModelNoClick = (e) => {
		const modelNo = e.target.innerHTML
		navigator.clipboard.writeText(modelNo)
	}
	
	return (
		<>
			<Header
				pageTitle='Find the Right Mitsubishi Electric M&P Heat Pump for the Job'
				pageDesc='Diamond Product Finder is a tool to help you choose the right Mitsubishi Electric, Trane, or American Standard outdoor heat pump units based on the needs of your project.'
				pageImg='/images/DPF-device-lockup-ME.webp'
				class='no-phone'
				branding='metus'
				headerTitle='Diamond Product Finder'
				height='var(--spc-xl3-h)'
				symbol='™'
				noCta
			/>
			<main className='product-selector-page'>
				<section className='product-selector-wrapper' style={{width:'100%', height: '100%'}}>
					{ !buttonsLoaded 
						? <LoadingSpinner infinite loadingMessage={'Loading Product Data'} />
						:
							<section id='product-selector-form'>
							
							{/* ----------------------------ZONES---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Zoning'
									tooltip={`Single-zone outdoor condensing units can connect to 1 indoor unit at a time. Multi-zone outdoor condensing units can connect to many indoor units at a time. ${tip}`}
								/>
								<DynamicBtn 
									btnType='image'
									dataKey='zones'
									dataValues={allZoneOptions}
									dataEnabledValues={enabledZoneOptions}
									onClick={handleFeature}
									reset={reset}
								/>
							</div>
							
							{/* ----------------------------CAPACITY---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Capacity'
									tooltip={`Nominal heating and cooling capacity in BTU/H. ${tip}`}
								/>
								<DynamicBtn 
									btnType='number'
									units='k'
									dataKey='capacity'
									dataValues={allCapacityOptions}
									dataEnabledValues={enabledCapacityOptions}
									onClick={handleFeature}
									reset={reset}
								/>
							</div>

							{/* ----------------------------MIN OUTDOOR TEMP---------------------------- */}
							{/* <div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Min. Outside Temperature'
									tooltip={`The lowest outside air temperature (in fahrenheit) for the area where the system will be used. ${tip}`}
								/>
								<InputSlider 
									units='°'
									dataKey='min_temp'
									dataValues={allHeatingMinOptions.concat(allCoolingMinOptions)}
									onClick={handleFeature}
									reset={reset}
								/>
							</div> */}
							
							{/* ----------------------------HEATING_MIN---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Heating Min. Temperature'
									tooltip={`Minimum outside air temperature (in fahrenheit) that the system will provide heat. ${tip}`}
								/>
								<DynamicBtn 
									btnType='number'
									units='°'
									dataKey='heating_min'
									dataValues={allHeatingMinOptions}
									dataEnabledValues={enabledHeatingMinOptions}
									onClick={handleFeature}
									reset={reset}
								/>
								{/* <InputSlider 
									units='°'
									dataKey='heating_min'
									dataValues={allHeatingMinOptions}
									dataEnabledValues={enabledHeatingMinOptions}
									onClick={handleFeature}
									reset={reset}
								/> */}
							</div>
							
							{/* ----------------------------COOLING_MIN---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Cooling Min. Temperature'
									tooltip={`Minimum outside air temperature (in fahrenheit) that the system will provide cooling. ${tip}`}
								/>
								<DynamicBtn 
									btnType='number'
									units='°'
									dataKey='cooling_min'
									dataValues={allCoolingMinOptions}
									dataEnabledValues={enabledCoolingMinOptions}
									onClick={handleFeature}
									reset={reset}
								/>
								{/* <InputSlider 
									units='°'
									dataKey='cooling_min'
									dataValues={allCoolingMinOptions}
									dataEnabledValues={enabledCoolingMinOptions}
									onClick={handleFeature}
									reset={reset}
								/> */}
							</div>
							
							{/* ----------------------------INDOOR UNITS---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Indoor Unit Types'
									tooltip={`Indoor unit form factors compatible with selected outdoor condensing unit. ${tip}`}
								/>
								<DynamicBtn
									btnType='image'
									dataKey='compatible_indoor_unit_types'
									dataValues={allIndoorUnitOptions}
									dataEnabledValues={enabledIndoorUnitOptions}
									dataSelected={selectedFeatures}
									onClick={handleFeature}
									images={indoorUnitButtons}
									reset={reset}
								/>
							</div>
							
							{/* ----------------------------MAX_HEIGHT_DIFF_FT---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Max Height Difference'
									tooltip={`Maximum vertical distance (in feet) between outdoor condensing unit and indoor unit. ${tip}`}
								/>
								<DynamicBtn 
									btnType='number'
									units='ft'
									dataKey='max_height_diff_ft'
									dataValues={allMaxHtDiffOptions}
									dataEnabledValues={enabledMaxHtDiffOptions}
									onClick={handleFeature}
									reset={reset}
								/>
							</div>
							
							
							
							{/* ----------------------------BRAND---------------------------- */}
							<div className='product-selector-input-wrapper'>
								<TitleWithTooltip
									title='Brand'
									tooltip={`Select your preferred brand (default Mitsubishi Electric). ${tip}`}
								/>
								<div onClick={handleBrandBtnClick}>
									<DynamicBtn 
										btnType='image'
										dataKey='brand'
										dataValues={brands.map((brand) => brand.label)}
										dataEnabledValues={brands.map((brand) => brand.label)}
										images={brands}
										reset={reset}
									/>
								</div>
							</div>
						</section>
					}
					{ showResults 
						? <div className='mobile-bar' id='mobile-btns'>
								<ScrollLink 
									id='product-jump-to-results'
									className='results'
									to='#product-selector-results'
									style={{margin: '0'}}
								>
									View {matchingProducts.length} Match{matchingProducts.length>1 && 'es'} <Icon id='down-arrow' color='white' style={{display:'inline', width: 'var(--lg)'}}/>
								</ScrollLink>
								<div>
									<ScrollLink onClick={handleResetButtonClick} to='#' className='btn secondary reset'>
										Reset Filters
									</ScrollLink>
								</div>
							</div>
						: <div className='mobile-bar' id='mobile-instructions'>
								<p style={{fontFamily:'var(--condensed-font)'}}>
									<span style={{color: 'var(--accent)'}}>HOW IT WORKS:</span> Select options above to begin filtering outdoor units, which will be listed below as 'cards'. Click a product card to view more information.
								</p>
							</div>
					}
				</section>
				
				

					{ !showResults &&
						<div id='desktop-instructions'>
							<h2 className='h4' style={{color: 'var(--accent)'}}>How It Works</h2>
							<p style={{fontFamily:'var(--condensed-font)', fontSize: '.8em'}}><span style={{fontFamily: 'var(--main-font-bold)'}}>Important note:</span> Please verify the accuracy of any results before sharing or recommending them, and especially before purchasing equipment.</p>
							<p><strong>Diamond Product Finder</strong> is a tool to help you choose the right Mitsubishi Electric, Trane, or American Standard outdoor heat pump units based on the needs of your project.</p>
							<p>Select options (to the left) to begin filtering outdoor units, which will be listed in this column as 'cards'. Click any selected option again to deselect it. Click a product card to view more information.</p>
							<h3 className='h5' style={{marginTop: 'var(--spc-xl-h)'}}><span style={{color: 'var(--accent)'}}>*New* </span>Model Number Lookup</h3>
							<p>Already know a unit's model number? Enter it below to search for its corresponding Mitsubishi Electric, Trane, and American Standard co-branded models.</p>

							<div className='grid-2-col-1-auto' style={{ alignItems: 'center', width: '100%'}}>
								<InputText 
									name='model-no'
									label='Model No'
									placeholder='not case sensitive'
									onChange={handleModelNoInputChange}
									onKeyPress={handleEnterKey}
									value={modelNoInputValue}
								/>
								<Button className='secondary' text='Search' onClick={lookUpModelNo}/>
							</div>
							{modelNoSearchMsg &&
								<div>
									<p className='center'>{modelNoSearchMsg}</p>
								</div>
							}
							{modelNoSearchResults &&
								<div className='model-no-search-results'>
									{modelNoSearchResults.map((model, i) =>
										<div className='grid-2-col-2-3' key={`${i}-${model.modelNo}`}>
											<h6 className='no-margin'>{model.displayName}</h6>
											<div className='grid-parent gap-xs align-right'>
												<Link 
													text={model.modelNo} 
													iconId='clipboard-add' 
													iconSize='tiny'
													title='click to copy' 
													onClick={handleModelNoClick} 
												/>
												{model.mldLink &&
													<Link 
														iconSize='tiny' 
														iconId='link-ext' 
														href={model.mldLink} 
														text='MyLinkDrive'
													/>
												}
											</div>
										</div>
									
									)}
								</div>
							}
						</div>
					}

					{ showResults &&
						<section id='product-selector-results'>
							<div id='desktop-bar'>
								<h2 className='h4'>{matchingProducts.length} Matching Units:</h2>
								<div className="results-buttons-wrapper">
									<CompareResults
										productData={matchingProducts}
										text='Compare Units'
										brandSelected={brandBtnSelected}
										brands={brands}
										skipKeys={skipKeys}
									/>
									<Button 
										onClick={handleResetButtonClick} 
										className='btn secondary' 
										text='Reset Filters'
									/>
								</div>
							</div>
							
							<div id='filters-bar'>
								<h6 style={{display: 'inline'}}>Filters:</h6>
								{Object.entries(selectedFeatures).map(([key, value]) => {
									value = booleanToYesNo(value)
									return (
										<p key={`${key}:${value}`} className='product-filter'>
											{`${createTitle(key)}: ${handleValue(key, value)}`}
										</p>
									)}
								)}
							</div>
							<div className='product-listing-wrapper'>
								{matchingProducts.map((product, index) =>
									<ProductCard
										key={index}
										skipKeys={skipKeys}
										brandBtnSelected={brandBtnSelected}
										productData={product}
										logos={brands}
									/>
								)}
							</div>
						</section>
					}
			</main>
		</>
	)
}