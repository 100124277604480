import { useState } from 'react'
import '../styles/header.css'
import '../styles/fs/sections.css'
import Logo from './modular/Logo'
import Icon from './modular/Icon'
import ScrollLink from './modular/ScrollLink'
import YouTube from 'react-youtube'
import LeadFormStd from './LeadFormStd'
import Footer from './Footer'

export default function PageFs() {
  // When the user scrolls down, resize header
  const [headerBtnClass, setHeaderBtnClass] = useState('btn')
  const [headerTopPadding, setHeaderTopPadding] = useState('')

  window.onscroll = () => {
    if (window.scrollY > 50 || document.documentElement.scrollTop > 50) {
      console.log(window.scrollY)
      setHeaderTopPadding('var(--spc-lg-h)')
    } else {
      setHeaderTopPadding('var(--spc-sm-h)')

    }
    (window.scrollY > 580 || document.documentElement.scrollTop > 580) && (window.scrollY < 3100 || document.documentElement.scrollTop < 3100)
      ? setHeaderBtnClass('btn fade-down')
      : setHeaderBtnClass('btn')
  }
  return (
    <>
      <div id="bg" className='fs-bg'></div>
      <header className='header product-header' style={{paddingTop: headerTopPadding}}>
        <ScrollLink
          id="header-scroll-btn" 
          className={headerBtnClass} 
          style={{padding: 'var(--xs2) var(--md)'}} 
          to="#form-section"
        >
          Request a Consultation
        </ScrollLink>
        <Logo 
          logoClass='logo' 
          width={'var(--logo-width)'} 
          height={'var(--logo-ht)'}
          transform={'none'}
        />
      </header>
      <div id='glass' className='product'>
        <main>
          <section className='hero-product'>
            <div className="left-panel">
              <h1 className='h4'>Introducing Our</h1>
              <h2 className='h1' id='hero-product-headline'>Deluxe Wall-mounted System</h2>
              <ScrollLink
                className='btn'
                to="#form-section"
              >
                Request a Consultation
              </ScrollLink>
            </div>
            <div className="right-panel">
              <img src="/images/product/ME-FS_FH_SIDE-RT-600.webp" alt="mitsubishi electric heat pump indoor fs unit"/>
              <p>Our latest M-Series addition, the <strong>Deluxe Wall-mounted Single-zone System</strong>, features the industry's first <strong>Dual Barrier Coating</strong>, 3D i-see Sensor<span className='superscript'>®</span>, Triple Action Filtration and new Hyper-Heating INVERTER<span className='superscript'>®</span> (H2i plus<span className='superscript'>™</span>) technology.</p>
            </div>
          </section>

          <section className="dbc">
            <div id="dbc-headline">
              <h2>Dual Barrier &#8226; Dual Benefits</h2>
            </div>
            <div id="dbc-blurb">
              <img src="/icons/DBC-logo-primary.png" alt="mitsubishi electric dual barrier coating logo"/>
              <p>Mitsubishi Electric’s Dual Barrier Coating protects fans, heat exchangers and vanes from harmful build-up. Having cleaner internal components mean easier maintenance, higher efficiency and better performance.</p>
            </div>

            <img id="dbc-img" loading="lazy" alt="mitsubishi electric mini split indoor fs unit dual barrier coating comparison" src='/images/product/DBC-Split-1200.webp' />

            <p id="dbc-without-text" className="dbc-bracket-text"><strong>10 Years Without<br/> Dual Barrier Coating</strong></p>
            <p id="dbc-with-text" className="dbc-bracket-text"><strong>10 Years With<br /> Dual Barrier Coating</strong></p>
            <YouTube
              videoId={'dc_BuTY1drg'}
              id={'dbc-video'}
              containerClassName={'dbc-video-wrapper'}
              opts={
                {
                  playerVars: {
                    rel: 0,
                    controls: 1, // 0 removes controls
                    modestbranding: 1,
                  }
                }
              }
            />
          </section>

          <section className="features">
            <h2>Cutting-edge Technology</h2>
            <div className="features-columns">
              <div className="features-left-column">
                <img loading="lazy" alt="mitsubishi electric heat pump indoor fs unit" src='/images/product/fs-lock-up.webp'/>
              </div>

              <div className="features-boxes-wrapper">

                <div className="feature-box">
                  <Icon id='hyper-heat-plus'/>
                  <div className="feature-text">
                    <h4>H2i plus<span className='superscript'>™</span></h4>
                    <ul>
                      <li>
                        100% heating performance even when it's -5° F outside
                      </li>
                      <li>
                        Reliable heating even down to -13° F
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="feature-box">
                  <Icon id='3d-isee-sensor'/>
                  <div className="feature-text">
                    <h4>3D i-see Sensor<span className='superscript'>®</span></h4>
                    <ul>
                      <li>
                        Detects room occupancy, adjusts temperature and airflow accordingly
                      </li>
                      <li>
                        Absence detection for energy-saving mode
                      </li>
                      <li>
                        Indirect or direct airflow for personalized comfort
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="feature-box">
                  <Icon id='sustainable'/>
                  <div className="feature-text">
                    <h4>Efficient and Quiet</h4>
                    <ul>
                      <li>
                        Up to 33.1 SEER
                      </li>
                      <li>
                        ENERGY STAR<span className='superscript'>®</span> certified
                      </li>
                      <li>
                        As low as 20 d(B)A operating sound level (quieter than a whisper)
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <section className="benefits">
            <div className="benefits-top-row">
              <h2>Breathe a Sigh of Relief</h2>
            </div>

            <div className="benefit-boxes-wrapper">
              <div className="benefit-box">
                <Icon id="air-purifying-filter"/>
                <div className="benefit-text">
                  <h4>Better Air Quality</h4>
                  <p>Multi-stage filtration removes allergens, odors and contaminants to improve indoor air quality. Allergen filters are washable and deliver 10 years of reliable operation.</p>
                </div>
              </div>
              <div className="benefit-box">
                <Icon id='mobile-phone-wifi'/>
                <div className="benefit-text">
                  <h4>Smart Controls</h4>
                  <p>Adjust your temperature settings remotely using a back-lit, handheld controller or our kumo cloud<span className='superscript'>®</span> mobile app. Our kumo cloud<span className='superscript'>®</span> app is compatible with popular smart home assistants.</p>
                </div>
              </div>
              <div className="benefit-box">
                <Icon id='service'/>
                <div className="benefit-text">
                  <h4>Easy Installation</h4>
                  <p>Your licensed HVAC Contractor simply connects the ductless indoor unit to the outdoor unit with refrigerant lines, and wiring for power and communication, via three-inch opening in your wall or ceiling.</p>
                </div>
              </div>
            </div>
          </section>
          <section style={{placeContent:'center'}} id='form-section'>
            <LeadFormStd
              formClass='standard-form-wrapper'
              formInstructions='Fill out this form to request a consultation with one of our independent HVAC contractors. Up to three contractors may contact you to answer your questions and schedule a consultation.'
              thankYou='Up to three contractors may contact you to answer your questions and schedule a consultation.'
              campaignId='7013q000001kj0XAAQ'
            />
          </section>
          <section className="warranty-wrapper">
            <div className="warranty-left">
              <img loading="lazy" src="/icons/12icon-red.png" alt="Mitsubishi Electric 12 years parts/12 years compressor warranty icon"/>
            </div>
            <div className="warranty-right">
              <h4>Our Absolute BEST Warranty</h4>
              <p>We stand behind our systems and want you to be <strong>100% satisfied</strong>. When you work with a participating authorized contractor - independent contractors who are certified by Mitsubishi Electric to find and install the best solution for your home - you are entitled to our limited <strong>12 years parts/12 years compressor</strong> warranty.</p>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}