export default function innovativeProducts({
    id = 'innovation',
    hasNavAnchor = false,
    color = 'transparent'
}) {
    return {
        id: id,
        class: 'one-column-info no-padding-bottom',
        hasNavAnchor: hasNavAnchor,
        title: `Latest in Innovative Technology`,
        blurb: `As the leader in VRF technology, we continue to advance technologies that reduce waste output and promote sustainable resource cycles, while increasing energy efficiency and eliminating dependence on fossil fuels.`,
        backgroundCol: color
    }
} 