// import altText from '../altText'
import lorem from '../lorem'

const threeColumnInfo2 = {
  id: 'Estimate',
  class: 'three-column-cards-w-image',
  blurb: 'three-column (variant)',
  detailsText: lorem.five,
  footnote: `*${lorem.three}`,
  contentBoxes: [
    {
      id: 1,
      title: lorem.title,
      blurb: lorem.title2,
      iconList: {
        title:'List Title Here',
        items: [
          {
            iconId: 'single-zone',
            text:'Quam id leo'
          }, 
          {
            iconId: 'wall-unit',
            text:'In vitae turpis'
          }, 
          {
            iconId: 'single-zone-outdoor',
            text:'Massa sed elementum'
          }, 
          {
            iconId: 'strategy',
            text:'Vitae turpis massa sed elementum tempus'
          }
        ]
      },
      footerText: lorem.title,
    },
    {
      id: 2,
      title: lorem.title,
      blurb: lorem.title2,
      iconList: {
        title:'List Title Here',
        items: [
          {
            iconId: 'multi-zone',
            text:'Multiple Rooms'
          }, 
          {
            iconId: 'ceiling-unit',
            text:'3 Indoor Ductless/Ducted Units'
          }, 
          {
            iconId: 'multi-zone-outdoor',
            text:'30,000 BTU Outdoor Unit'
          }, 
          {
            iconId: 'strategy',
            text:'150ft Refrigerant Line'
          }
        ]
      },
      footerText: 'Estimate: $14,168',
    },
    {
      id: 3,
      title: lorem.title,
      blurb: lorem.title2,
      iconList: {
        title:'List Title Here',
        items: [
          {
            iconId: 'multi-zone', 
            text:'Multiple Rooms'
          }, 
          {
            iconId: 'air-filter', 
            text:'2 Indoor Ducted Units'
          }, 
          {
            iconId: 'multi-zone-outdoor', 
            text:'30,000 BTU Outdoor Unit'
          }, 
          {
            iconId: 'strategy', 
            text:'100ft Refrigerant Line'
          }
        ]
      },
      footerText: 'Estimate: $15,638',
    }
  ]
  // backgroundCol: 'gray'
}

export default threeColumnInfo2
