import Header from './Header'
import Footer from './Footer'

export default function PageKumoSupport() {
  return (
    <>
      <Header metusLogo={true} headerTitle='kumo cloud' symbol='®' phone=''/>
      <main style={{margin:'200px 0'}}>
        <h1 style={{textAlign: 'center'}} className='h4'>The kumo cloud Support Page Is Now Located Here:</h1>
        <a style={{textAlign: 'center'}} href='https://help.mitsubishicomfort.com/'>https://help.mitsubishicomfort.com/</a>
      </main>
      <Footer />
    </>
  )
}