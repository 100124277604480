import altText from '../altText'
import lorem from '../lorem'

const twoColumnImgLeft = {
  id: 'two-column-img-left',
  class: 'two-column-img-left',
  // title: lorem.title,
  blurb: 'two-column-img-left (variant)',
  imgSrc: '/images/image-here.webp',
  detailsText: lorem.four,
  detailsImgSrc: '/images/alexa-kumo.png',
  detailsImgAlt: `${altText} alternative text here`,
  imgAlt: `${altText} additional img description`,
  footnote: lorem.two,
  backgroundCol: 'gray'
}

export default twoColumnImgLeft
