export default function convertToTitle(input) {
    if (input === 'intelli-HEAT') {
        return input
    }
    // replace hyphens and underscores with spaces
    const replacedString = (input === 'all-electric' || input === 'all-climate' || input === 'cold-climate' || input === 'smart-multi') ? input : input.replace(/[-_]/g, " ")

    // convert to title case
    let titleCaseString = replacedString.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1))
    }).join(' ')

    // check for cases to make uppercase
    if (titleCaseString.startsWith("Ui")) {
        titleCaseString = "UI" + titleCaseString.substring(2);
    }
    if (titleCaseString.includes("Vrf")) {
        titleCaseString = titleCaseString.replace('Vrf', 'VRF')
    }

    return titleCaseString
}