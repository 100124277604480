import { HashLink } from 'react-router-hash-link'

export default function ScrollLink (props) {
	/* ---------------- UTM PARAMETERS ---------------- */
	let utm = new URL(document.location).search

	return (
		<HashLink
			smooth
			id={props.id && props.id}
			className={props.className && props.className}
			style={props.style && props.style}
			to={`${utm ? utm : ''}${props.to}`}
			onClick={props.onClick && props.onClick}
			onScroll={props.onScroll && props.onScroll}
			aria-label={props.label && props.label}
		>
			{props.children}
		</HashLink>
	)
}