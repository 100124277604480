import { useState, useEffect } from "react"
import useBranding from "../../hooks/useBranding"

export default function Logo(props) {
	const [logoSrc, setLogoSrc] = useState('')
	const urlBranding = useBranding()
	const branding = props.branding ? props.branding : urlBranding

	useEffect(() => {
		// update logo based on branding and user device's color mode
		const getBranding = (name) => {
			switch (name) {
				case 'tr':
				case 'trane': return 'TR-ME'
				case 'as': 
				case 'american-standard': return 'AS-ME'
				case 'metus': return 'METUS'
				default: return 'ME'
			}
		}

		const userDarkModeIsOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

		const fileName = `${getBranding(branding)}-primary.svg`
		let logoFolder = 'black' 
		
		if (userDarkModeIsOn || props.darkMode) { logoFolder = 'white' }
		
		const path = `/images/logos/${logoFolder}/${fileName}`
		setLogoSrc(path)
	}, [branding, props.branding, props.darkMode])
	
	return (
		<img
			id={props.id}
			className={`logo lazy${props.className ? ` ${props.className}` : ''}`}
			src={logoSrc}
			style={props.style}
			onClick={props.onClick}
			alt={props.id + ' logo'}
			data-id={props.dataId}
			data-key={props.dataKey}
			data-value={props.dataValue}
			title={props.title ? props.title : props.id}
		/>
	)
}