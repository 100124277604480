import altText from '../altText'
import lorem from '../lorem'

const twoColumnImgLeft = {
  id: 'two-column-img-left',
  class: 'two-column-img-left',
  title: lorem.title,
  blurb: 'two-column-img-left',
  imgSrc: '/images/image-here.webp',
  detailsText: lorem.four,
  imgAlt: `${altText} additional img description`,
  ctaText: 'Watch Video',
  videoSrc: 'https://www.youtube.com/embed/JSRADx3a9SI'
}

export default twoColumnImgLeft
