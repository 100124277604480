import altText from './altText'

export default function solutions({
	id = 'solutions',
	hasNavAnchor = false,
	title = 'Indoor Solutions',
	ctaText = 'Learn More',
	color = 'transparent',
}) {
	return {
		id: id,
		class: 'four-column',
		imgBg: true,
		hasNavAnchor: hasNavAnchor,
		title: title,
		ctaText: ctaText,
		backgroundCol: color,
		columns: [
			{
				imgSrc: '/images/product/wall-mounted.webp',
				imgAlt: `${altText}wall-mounted indoor mini-split`,
				title: 'Wall-mounted',
				text: 'These mini-split units are mounted high up on the wall and out of your way. Wall-mounted units are great for living room and bedrooms, where personalized comfort is paramount.',
			},
			{
				imgSrc: '/images/product/ceiling-cassette.webp',
				imgAlt: `${altText}recessed ceiling cassette indoor mini-split`,
				title: 'Ceiling-recessed',
				text: 'This modern, low-profile, recessed mini-split ceiling-cassette design takes up zero wall space, blends with your ceiling, and is perfect in the kitchen, where wall space is usually scarce.',
			},
			{
				imgSrc: '/images/product/intelli-HEAT_200px.webp',
				imgAlt: `${altText}horizontal-ducted indoor mini-split`,
				title: 'Hybrid HVAC',
				text: 'Installed on your existing furnace, this smart system determines the best source of heat (gas or electricity) on cold days and even improves air conditioning efficiency on hot days.',
			},
			{
				imgSrc: '/images/product/floor-mounted.webp',
				imgAlt: `${altText}floor-mounted indoor mini-split`,
				title: 'Floor-mounted',
				text: 'Installed low on the wall, near the floor, these floor-mounted mini-split units make a great alternative to radiators and can fit into spaces with limited upper-wall space, like sunrooms or attics.',
			},
		],
	}
}