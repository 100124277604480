import lorem from '../lorem'

const oneColumnImage = {
  id: 'one-column-image',
  class: 'one-column-image',
  title: lorem.title,
  blurb: 'one-column-image',
  detailsText: lorem.three,
  imgSrc: '/images/image-here.webp',
  backgroundCol: 'gray'
}

export default oneColumnImage