import altText from './altText'

const financing = {
	iconSrc: 'power-bill-invoice',
	iconAlt: `${altText} payment and financing options`,
	blurb: 'Financing Options',
	detailsText: 'Mitsubishi Electric and Synchrony Financial make it easier to afford the latest, most efficient heating and cooling systems.',
	ctaText: 'Learn More',
	modalContent: {
		title: 'Financing Options Are Available',
		text: 'Mitsubishi Electric and Synchrony Financial make it easier to afford the latest, most efficient heating and cooling systems available. With options like deferred interest and fixed payments with low interest rates, you can find the program that works best for you.',
		imgSrc: '/images/synchrony-logo.webp',
		imgAlt: 'Synchrony financing logo',
		// ctaText: 'Explore Financing',
		// ctaUrl: 'https://www.mitsubishicomfort.com/financing-tax-information',
		footnote: 'Subject to credit approval. Minimum monthly payments required. See an authorized contractor for details.'
	}
}

export default financing