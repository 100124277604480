import { header, hero, sections } from './page content/pageCbuContent'
import Page from './Page'

export default function PageAep() {
	const finalHero = { 
		...hero,
		leadCampaignId: '7013q000001rWsyAAE',
		staticCampaign: true,
		scrollTo: sections[0].id
	}
    
	return (
		<Page header={header} hero={finalHero} sections={sections} />
	)
}