import altText from "./altText"

export default function heat2O({
    id = 'heat2O',
    hasNavAnchor = false,
    title = 'HEAT₂O',
    color = 'transparent'
}) {
    return {
        id: id,
        class: 'two-column-img-left',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'The Future of Water Heating',
        detailsText: `HEAT₂O is an extremely efficient all-electric commercial water heating solution that not only delivers on the promise of a better future but ensures highly reliable performance while reducing your operational costs.`,
        imgSrc: '/images/product/heat2o.png',
        imgAlt: `${altText} HEAT₂O unit`,
        ctaText: 'Watch Video',
        videoSrc: 'TucxqPXYcuw',
        backgroundCol: color
    }
}