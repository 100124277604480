import altText from './altText'

export default function kj({ id = 'kj', hasNavAnchor = false, title = 'MFZ-KJ Floor-mounted Indoor Unit', color = 'transparent' }) {
    return {
        id: id,
        class: 'two-column-img-right',
        hasNavAnchor: hasNavAnchor,
        title: title,
        detailsText:
            'The Floor-mounted Indoor Unit mounts low on the wall and can be mounted partially recessed. This indoor unit features rapid heating capability that quickly warms a space to the desired temperature and a multi-flow vane distributing airflow throughout the room, preventing uneven temperatures.',
        imgSrc: '/images/lifestyle/interior/Enso_2023_Indoor_Floor Mount_2.webp',
        imgAlt: `${altText} interior floor-mounted, low-wall-mounted mini-split heat pump heating and cooling`,
        ctaText: 'How it works',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: color,
    }
}
