import Header from './Header'
import Footer from './Footer'
import { header, hero } from './page content/pageSandboxTestContent'
import HeroSection from './HeroSection'

export default function PageSandboxTest() {
	return (
		<>
			<Header
				phone={header.phone}
				ctaText={header.ctaText}
				ctaUrl= {header.ctaUrl}
			/>
			<main>
				<HeroSection content={hero} />
			</main>
			<Footer />
		</>
	)
}