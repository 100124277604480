import { useEffect } from 'react'
import Header from './Header'
import HeroSection from './HeroSection'
import Footer from './Footer'
import Section from './Section'
import NavBu from './NavBu'

export default function Page({ header, hero, sections }) {
    useEffect(() => {
        if (header.accentCol) {
            window.root.style.setProperty('--accent', `var(--${header.accentCol})`)
        }
  }, [header.accentCol])
    return (
        <>
            <Header
                pageTitle={header.pageTitle}
                pageDesc={header.pageDesc}
                pageImg={header.pageImg}
                phone={header.phone}
                hours={header.hours}
                ctaText={header.ctaText}
                ctaUrl={header.ctaUrl}
                sections={header.pageSections}
                class={header.class}
                noCta={header.noCta || false}
                branding={header.branding}
            />
            <main>
                <HeroSection content={hero} />
                {sections.map((section, index) => (
                    <Section key={index + section.id} content={section} />
                ))}
            </main>
            <NavBu id="mobile-nav" sections={header.pageSections} />
            <Footer darkMode />
        </>
    )
}
