// import altText from '../altText'
import lorem from '../lorem'

const oneColumnInfo = {
	id: 'one-column-info',
	class: 'one-column-info',
	title: 'one-column-info',
	detailsText: lorem.four,
	// backgroundCol: 'gray',
}

export default oneColumnInfo
