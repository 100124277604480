import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/16/2024'
    const end = '10/28/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'mabu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: defaults.subscriberCampaignId,
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/me_gs_doctors_office.webp',
        defaultTitle: '',
        defaultBlurb: `HVAC Solutions For Light Commercial Applications`,
        defaultDetails: `Mitsubishi Electric's all-climate heat pump HVAC technology is an optimal solution for Light Commercial applications, offering individual zoning for tailored comfort and efficient operation for cost savings.`,
        defaultFootnote: defaults.footnote,
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        // ME_Commercial_MABU_Fall_2024_Light_Commercial_Ferguson_Homans_LSK_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        staticCampaign: true,
        campaignId: 'NEED CAMPAIGN ID',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/me_gs_doctors_office.webp',
        campaignTitle: '',
        campaignBlurb: `HVAC Solutions For Light Commercial Applications`,
        campaignDetails: `Mitsubishi Electric's all-climate heat pump HVAC technology is an optimal solution for Light Commercial applications, offering individual zoning for tailored comfort and efficient operation for cost savings.`,
        campaignFootnote: '',
        hideContractorNumSelector: false,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }
    return getCampaignData(data)
}
