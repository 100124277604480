import altText from "./altText"

export default function allElectric({
    id = 'all-electric',
    hasNavAnchor = false,
    title = 'All-electric Solutions',
    color = 'gray'
}) {
    return {
        id: id,
        class: 'two-column-img-left',
        hasNavAnchor: hasNavAnchor,
        title: title,
        // blurb: 'For Fuel-free Heating and Air Conditioning',
        detailsText: 'The world is gradually moving to cleaner electrical energy to run HVAC systems. Electric HVAC systems provide more comfort and flexibility for your home. Thanks to Mitsubishi Electric, you now have options to help make your home safer and more energy efficient, reduce your carbon footprint and impact on the climate.',
        imgSrc: '/images/strategic-electrification.webp',
        imgAlt: `${altText} strategic electrification ecosystem`,
        ctaText: 'Watch Video',
        videoSrc: 'ljkDJbnvwqs',
        backgroundCol: color,
    }
}