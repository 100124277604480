import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Header from './Header'
import Footer from './Footer'
import Section from './Section'
import NavBu from './NavBu'
import ContactSidebar from './modular/ContactSidebar'
import useRegions from '../hooks/useRegions'

export default function PageVerticalsTemplate({ header, sideBar, hero, sections }) {
	const [selectedRegion, setSelectedRegion] = useState(null)

	const regionsData = useRegions()
	const navigate = useNavigate()

	// set selected region based on url param
	useEffect(() => {
		const urlParams = new URL(document.location).searchParams
		const regionParam = urlParams.get('region')
		const getRegion = async(id) => {
			if (!id) return console.log('no url param')
			const foundRegion = regionsData.find((region) => region.id === id)
			setSelectedRegion(foundRegion)
		}

		regionsData && getRegion(regionParam)
	}, [regionsData, selectedRegion])  

	useEffect(() => {
	  	window.root.style.setProperty('--accent', `var(--${header.accentCol})`)
	}, [header.accentCol])

	const handleClick = (e) => {
		const regionObj = {id: e.target.id, name: e.target.dataset.name}
		handleSelectedRegion(regionObj)
	}

	// change selectedRegion based on map region clicked, change url param
	const handleSelectedRegion = (region) => {
		setSelectedRegion(region)
		const url = new URL(document.location)
		url.searchParams.set('region', region.id)
		navigate(url.pathname+url.search)
	}
	
	return (
		<>
			<ContactSidebar
				content={sideBar}
				selectedRegion={selectedRegion}
			>
				<Header
					pageTitle={header.pageTitle}
					pageDesc={header.pageDesc}
					pageImg={header.pageImg}
					class={header.class}
					ctaText={header.ctaText}
					ctaUrl={header.ctaUrl}
					branding={header.branding}
					sections={header.pageSections}
					noCta
					sideBar
				/>
				<Section
					id='hero'
					content={hero}
					style={{
						padding: 'var(--xl6) 0',
						minHeight: '80vh',
						backgroundPosition:'center',
						backgroundImage: `
							radial-gradient(circle at 20% 40%, var(--yellow-20), transparent 10%), 
							radial-gradient(ellipse at 35% 80%, var(--${header.accentCol}-40), transparent 50%), 
							radial-gradient(ellipse at 10% 50%, var(--purple-50), transparent 50%), 
							radial-gradient(ellipse at bottom center, var(--black-80), var(--black-50)), url(${hero.backgroundSrc})
						`
					}}
				/>
				{sections.map((section, index) =>
					<Section
						key={index+section.id}
						content={section}
						selectedRegion={selectedRegion}
						handleClick={handleClick}
					/>
				)}
				<NavBu
					id='mobile-nav'
					sections={header.pageSections}
					noCta
				/>

				<Footer branding={header.branding} darkMode />
			</ContactSidebar>
		</>
	)
}