import Icon from './modular/Icon'
import YouTube from './modular/YouTube'

const SectionVideo = (props) => {
	const isDark = props.class.includes('dark')
	return (
		<div
			className='video-section'
			style={props.style}
		>
			<Icon id='x' alt='close button' color={isDark && 'white'} onClick={props.closeVideo}>X</Icon>
			{props.videoSrc.includes('/videos/')
				?
					<video width="800" controls>
						<source src={props.videoSrc} type="video/webm" />
						Your browser does not support HTML video.
					</video>
				:
					<YouTube
						videoSrc={props.videoSrc}
						autoPlay
						controls
					/>
			}
			
		</div>
	)
}

export default SectionVideo
