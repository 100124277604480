import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Tooltip from './modular/Tooltip'
import Icon from './modular/Icon'
import { booleanToYesNo, createTitle, handleValue } from '../lib/helpers'

const IndoorUnits = ({ units, brand }) => {
	// console.log(units)
	units = units.sort((a,b) => 
		(a.attributes.indoor_unit_type.toLowerCase() > b.attributes.indoor_unit_type.toLowerCase()) 
			? 1 
			: (a.attributes.indoor_unit_type.toLowerCase() === b.attributes.indoor_unit_type.toLowerCase()) 
				? ((a.attributes.service_reference_number > b.attributes.service_reference_number) 
					? 1 
					: -1) 
				: -1 
	)

	const getModelNo = (unit) => {
		if (brand === 'me') return unit.service_reference_number
		if (brand === 'tr') return unit.trane_model_no
		if (brand === 'as') return unit.american_standard_model_no
	}

	const formatName = (name) => {
		let newName
		name.includes('Indoor Unit')
			? newName = name.replace('Indoor Unit','')
			: newName = name
		name.includes('mounted') && (newName = newName.replace('mounted', 'mount'))
		return newName
	}
	
	return (
		<>
			<h6 className='indoor-units-title' style={{marginBottom: '0', color: 'unset'}}>
				{units[0] && units.length} Compatible Indoor Unit{units.length > 1 && 's'}:
			</h6>
			<div className='indoor-units-wrapper'>
				{units.length > 0
					? units.map((unit) => {
							unit = unit.attributes 
							const modelNo = getModelNo(unit)
							return (
								<p style={{fontSize:'var(--std)'}} key={modelNo}>
									<span>&#8226; </span>{formatName(unit.model_name)} ({modelNo})
								</p>
							)
						})
					: <p style={{fontSize:'14px'}}>We're making improvements and expect to have compatible indoor units visible in the next few days. In the meantime, view compatibility in MyLinkDrive by clicking this unit's model no. above.</p>
				}
			</div>
		</>
	)
}

const CardContent = ({ product, brand, logo, preview, onClick, style }) => {
	const [imgPath, setImgPath] = useState()
	const [imgPreviewPath, setImgPreviewPath] = useState()
	const [imgAltText, setImgAltText] = useState()
	const [modelNo, setModelNo] = useState()
	const [MLDLink, setMLDLink] = useState()

	useEffect(() => {
		let baseImgPath
		if (brand === 'me') {
			baseImgPath = product.front_image_me.data.attributes
			setModelNo(product.mitsubishi_model_no)
			setMLDLink(product.me_specs)
		}
		if (brand === 'tr') {
			baseImgPath = product.front_image_tr.data.attributes
			setModelNo(product.trane_model_no)
			setMLDLink(product.trane_specs)
		}
		if (brand === 'as') {
			baseImgPath = product.front_image_as.data.attributes
			setModelNo(product.american_standard_model_no)
			setMLDLink(product.as_specs)
		}
		setImgPath(baseImgPath.formats.medium.url ? baseImgPath.formats.medium.url : baseImgPath.formats.medium)
		setImgPreviewPath(baseImgPath.formats.small.url ? baseImgPath.formats.small.url : baseImgPath.formats.small)
		setImgAltText(`${baseImgPath.alternativeText} ${modelNo}`)
	}, [product, brand, modelNo])
	
	return (
		<div 
			className={`product-card-header${preview ? ' preview' : ''}`}
			onClick={onClick && onClick} 
			style={style}
		>
			<img 
				src={preview ? imgPreviewPath : imgPath}
				alt={imgAltText}
			/>
			<div>
				<img className='product-card-logo' src={logo} alt={`${logo} logo`}/>
				{ preview 
					? <>
							<h6>{modelNo}</h6>
							<p style=
								{ product.heating_type.includes('Cooling')
									? {color: 'var(--blue)'}
									: product.heating_type.includes('HyperHeat')
										? {color: 'var(--red)'}
										: {color: 'unset'}
								}
							>
								{product.heating_type}
							</p>
							<p>SEER Rating: {product.seer}</p>
							<p>Max. Pipe Length: {product.max_pipe_length_ft}ft</p>
							<p>Max. Height Diff.: {product.max_height_diff_ft}ft</p>
						</>
					: <>
							<h6>
								<a 
									style={{marginBottom: 'var(--base)'}} 
									href={MLDLink}
									target='_blank'
									rel='noreferrer'
								>
									{modelNo} <span style={{fontSize: 'var(--base)'}}>(click for more Info)</span>
								</a>
							</h6>
							<IndoorUnits brand={brand} units={product.compatible_indoor_units.data}/>
						</>
				}
			</div>
		</div>
	)
}

export default function ProductCard ({ productData, brandBtnSelected, logos, skipKeys }) {
	const [modalIsOpen, setIsOpen] = useState(false)
	const [product, setProduct] = useState(productData.attributes)

	useEffect(() => {
		// console.log(product)
		skipKeys.push('mitsubishi_model_no')
		skipKeys.push('trane_model_no')
		skipKeys.push('american_standard_model_no')
		setProduct(productData.attributes)
	}, [skipKeys, productData])

	const brand = brandBtnSelected

	const openModal = () => setIsOpen(true)
	const afterOpenModal = () => {}
	const closeModal = () => setIsOpen(false)

	const meLogo = logos[0].imgSrc
	const trLogo = logos[1].imgSrc
	const asLogo = logos[2].imgSrc

	return (
		<div className='product-card'>
			<Tooltip 
				tooltip='Click this product card to view specs, install guides, manuals, accessories, engineering specs, submittals, and much more.'
			/>
			{ brand === 'Mitsubishi Electric' &&
				<CardContent 
					product={product}
					brand='me'
					logo={meLogo}
					onClick={openModal}
					preview
				/>
			}
			{ brand === 'Trane' &&
				<CardContent 
					product={product}
					brand='tr'
					logo={trLogo}
					onClick={openModal}
					preview
				/>
			}
			{ brand === 'American Standard' &&
				<CardContent 
					product={product}
					brand='as'
					logo={asLogo}
					onClick={openModal}
					preview
				/>
			}
			
			<Modal
				className='modal glass-panel financing-modal'
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				contentLabel="Modal"
			>
				<Icon id='x' alt='close button' onClick={closeModal}>X</Icon>
				{ brand === 'Mitsubishi Electric' &&
					<CardContent 
						product={product}
						brand='me'
						logo={meLogo}
					/>
				}
				{ brand === 'Trane' &&
					<CardContent 
						product={product}
						brand='tr'
						logo={trLogo}
					/>
				}
				{ brand === 'American Standard' &&
					<CardContent 
						product={product}
						brand='as'
						logo={asLogo}
					/>
				}
				<div className='unit-specs'>
					{Object.entries(product)
						.map(([key, value]) => {
							if (!skipKeys.includes(key) && !key.includes('model_no')) {
								return (
									<p key={`${key}:${value}`} >
										{`${createTitle(key)}: `} <strong>{booleanToYesNo(handleValue(key, value))}</strong>
									</p>
								)
							}
							return null
						}
					)}
				</div>
			</Modal>
			
		</div>
	)
}