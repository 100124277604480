import tabs from './page content/static-content/tabsGeneral'
import SectionTabs from './SectionTabs'

export default function PageFrameGeneral() {
	return (			
		<main>
			<SectionTabs
				tabs={tabs}
				title={'Explore Our Technology'}
				iframe
			/>
		</main>
	)
}