import Header from './Header'
import HeroSection from './HeroSection'

export default function PageDemoTruck() {
    const hero = {
        class: 'hero',
        form: 'standard',
        formTitle: 'Demo Truck Leads',
        // title: 'Be Proactive',
        // blurb: '',
        // detailsText: 'Life can be unpredictable, so now is the time to ready your home for when extreme weather strikes. Mitsubishi Electric Heating and Air Conditioning high-efficiency heat pumps can be powered by a generator when the power is out.',
        formInstructions: 'After a lead has been submitted, click "Reset Form" to reload the form and submit another lead.',
        footnote: '',
        whatsNext: 'here\'s what\'s next',
        // ctaText: 'cta text',
        backgroundSrc: '/images/demo-truck.webp',
        leadCampaignId: '7013q000001QVpxAAG',
        hideDownArrow: true,
        hideContractorNumSelector: true,
        hideTimeframeSelector: true,
        hideContactMethodSelector: true,
        hideLocationSelectors: true,
        hideSystemSelector: true,
        hideCommunicationsSelector: true,
        showResetButton: true,
        leadSource: 'Demo Truck'
    }
	return (
		<>
			<Header />
			<main>
                <HeroSection 
                    content={hero}
                />

			</main>
		</>
	)
}