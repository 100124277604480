import { useState } from 'react'
import Icon from './modular/Icon'
import Details from './Details'
import ContentBox from './ContentBox'
import SectionVideo from './SectionVideo'
import SectionFourColumn from './SectionFourColumn'
import SubscriberForm from './SubscriberForm'
import LeadFormRecruitment from './LeadFormRecruitment'
import LeadFormDispatch from './LeadFormDispatch'
import Download from './Download'
import SectionMap from './SectionMap'
// import SectionHeatmap from './SectionHeatmap'
import SectionRoller from './SectionRoller'
import SectionTabs from './SectionTabs'
import SectionProducts from './SectionProducts'
import SectionIndoorSolutions from './SectionIndoorSolutions'
import SectionMapProjects from './SectionMapProjects'

const Section = ({ content, style, selectedRegion, handleClick }) => {
    const [videoIsHidden, toggleVideo] = useState(true)

    const handleVideo = () => toggleVideo(!videoIsHidden)

    const getBgGradient = () => {
        const color = content.backgroundCol
        let finalColor
        // if one of these colors, just set the color, unformatted
        switch (color) {
            case 'blue-dp':
            case 'accent':
            case 'yellow-purple':
            case 'gray':
            case 'dk':
            case 'white':
                finalColor = color
                break
            default:
                break
        }
        // then return it
        if (finalColor) {
            return finalColor
        }
        // otherwise
        finalColor = color
        // if position is specified, put it there
        if (content.backgroundPos) return `${finalColor}-${content.backgroundPos}`
        // otherwise, put it at bottom left
        return `${finalColor}-bottom-left`
    }

    switch (content.class) {
        case 'roller':
            return <SectionRoller id={content.id} class={content.class} ctaText={content.ctaText} menuItems={content.menuItems} />
        case 'tabs':
            return <SectionTabs id={content.id} title={content.title} blurb={content.blurb} tabs={content.tabs} />
        case 'products':
            return <SectionProducts id={content.id} class={content.class} />
        default:
            return (
                <section
                    id={content.id}
                    className={`section ${content.class}`}
                    style={
                        style ||
                        (content.backgroundSrc && { backgroundImage: `url(${content.backgroundSrc})` },
                        content.backgroundCol && { background: `var(--bg-gradient-${getBgGradient()})` })
                    }
                >
                    <div className="section-content-wrapper">
                        {content.videoSrc && !videoIsHidden && <SectionVideo class={content.class} videoSrc={content.videoSrc} closeVideo={handleVideo} />}
                        {content.class === 'four-column' && (
                            <SectionFourColumn
                                id={content.id}
                                title={content.title}
                                imgBg={content.imgBg}
                                columns={content.columns}
                                ctaText={content.ctaText}
                            />
                        )}
                        {content.class !== 'four-column' && content.class !== 'tabs-indoor-solutions' && (
                            <Details
                                id={content.id}
                                class={content.class}
                                videoIsHidden={videoIsHidden}
                                openVideo={handleVideo}
                                title={content.title}
                                blurb={content.blurb}
                                detailsText={content.detailsText}
                                detailsImgSrc={content.detailsImgSrc}
                                detailsImgAlt={content.detailsImgAlt}
                                iconList={content.iconList}
                                ctaText={content.ctaText}
                                ctaUrl={content.ctaUrl}
                                videoSrc={content.videoSrc}
                                // subBlurb={content.subBlurb}
                                listItems={content.listItems}
                                footnote={content.footnote}
                                download={content.download}
                            />
                        )}

                        {content.class === 'tabs-indoor-solutions' && <SectionIndoorSolutions />}

                        {content.class === 'download-section' && (
                            <Download class={content.class} imgUrl={content.imgUrl} imgSrc={content.imgSrc} imgAlt={content.imgAlt} />
                        )}

                        {content.class === 'mapped-projects' && (
                            <SectionMapProjects
                                vertical={content.vertical}
                                projects={content.projects}
                                selectedRegion={selectedRegion}
                                handleClick={handleClick}
                            />
                        )}

                        {content.id?.toLowerCase() === 'subscribe' && <SubscriberForm content={content} />}

                        {content.imgSrc && !content.imgUrl && (
                            <img
                                className={content.class.includes('two-col') ? 'img-zoom' : undefined}
                                style={{
                                    display: videoIsHidden ? 'block' : 'none',
                                    maxHeight: content.class.includes('reduced-image-height') ? '400px' : 'auto',
                                }}
                                src={content.imgSrc}
                                alt={content.imgAlt}
                                loading="lazy"
                            />
                        )}

                        {content.form === 'recruitment' && 
                            <LeadFormRecruitment content={content} />
                        }

                        {content.form === 'dispatch' && <LeadFormDispatch subBlurb={content.subBlurb} leadCampaignId={content.leadCampaignId} />}

                        {content.class.includes('three-column-cards') && (
                            <div className="content-box-container">
                                {content.contentBoxes.map((box, index) => (
                                    <ContentBox key={index} id={content.id.toLowerCase() + '-box-' + (index + 1)} content={box} />
                                ))}
                            </div>
                        )}

                        {content.class === 'one-column-list' && (
                            <div className="list-wrapper">
                                {content.listItems.map((item, index) => (
                                    <div className="list-item" key={index}>
                                        <Icon id={item.iconId ? item.iconId : 'check'} />
                                        <div>
                                            <h3 style={{ color: 'var(--accent)' }}>{item.title}</h3>
                                            <h4 className="h6">{item.description}</h4>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {content.class === 'map' && <SectionMap data={content.data} options={content.options} />}
                        {/* {content.class === 'heatmap' && <SectionHeatmap data={content.data} options={content.options} />} */}
                    </div>

                    {content.footnote && content.class.includes('three-column-cards') && (
                        <p className="footnote" style={{ paddingTop: '40px' }}>
                            {content.footnote}
                        </p>
                    )}
                </section>
            )
    }
}

export default Section
