export default function conversions(leadType, userInfo, reset) {
    const userInfoKeys = ['name', 'email', 'city', 'state', 'zip', 'loc_physical_ms', 'network', 'device']
    const localStorageKeys = ['userName', 'userEmail', 'userCity', 'userState', 'userZip', 'userLocation', 'userNetwork', 'userDevice']
    const currentPage = window.location.pathname.substring(1)
    const formKey = `${currentPage}_${leadType}FormSubmitted`
    console.log(formKey)

    if (reset) {
        localStorage.removeItem(formKey)
        localStorageKeys.forEach((key) => localStorage.removeItem(key))
        return
    }

    if (!userInfo || typeof userInfo !== 'object') {
        console.error('User information must be an object.')
        return
    }

    if (!localStorage.getItem(formKey)) {
        // add form submit info to local storage
        localStorage.setItem(formKey, true)

        userInfoKeys.forEach((key, index) => {
            const value = userInfo[key]
            value && localStorage.setItem(localStorageKeys[index], value)
        })

        window.dataLayer.push({
            event: `Form_submit`,
            enhanced_conversion_data: {
                email: userInfo.email,
                // "loc_physical_ms": userInfo.loc_physical_ms,
                // "network": userInfo.network,
                // "device": userInfo.device,
                // "city": userInfo.city,
                // "region": userInfo.state,
                // "postal_code": userInfo.zip,
                // "phone_number": 'userInfo.phone',
                // "street": 'userInfo.street',
                // "country": 'userInfo.country'
            },
        })

        console.log(window.dataLayer)
    }
}
