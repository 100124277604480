// const formatModelNo = (model) => {
//   const newModel = model.replace(/\.|\(|\)/g , '').replace(/-/g, '_')
//   return newModel
// }

const imgPath = '/images/product/'
const wallUnitImg = imgPath + 'wall-mounted-outline.webp'
const ceilingOneWayUnitImg = imgPath + 'ceiling-cassette-outline.webp'
const ceilingFourWayUnitImg = imgPath + 'ceiling-4-way-outline.webp'
const airHandlerUnitImg = imgPath + 'air-handler-outline.webp'
const horizontalDuctedUnitImg = imgPath + 'horizontal-ducted-outline.webp'
const ceilingSuspendedUnitImg = imgPath + 'ceiling-suspended-outline.webp'
const floorUnitImg = imgPath + 'floor-mounted-outline.webp'
const intelliHeatImg = imgPath + 'intelli-HEAT_200px.webp'

export const indoorUnitButtons = [
	{ label: 'wall', imgSrc: wallUnitImg, order: 1 },
	{ label: 'ceiling (1-way)', imgSrc: ceilingOneWayUnitImg, order: 2 },
	{ label: 'air handler', imgSrc: airHandlerUnitImg, order: 3 },
	{ label: 'dual fuel cased coil', imgSrc: intelliHeatImg, order: 8 },
	{ label: 'floor', imgSrc: floorUnitImg, order: 4 },
	{ label: 'ceiling (4-way)', imgSrc: ceilingFourWayUnitImg, order: 5 },
	{ label: 'ceiling suspended', imgSrc: ceilingSuspendedUnitImg, order: 6 },
	{ label: 'ducted', imgSrc: horizontalDuctedUnitImg, order: 7 },
]

export const handleDucted = (value) => {
	if (value === 'ducted') return 'horizontal ducted'
	return value
}

export const generateData = (list) => {
	list.forEach((product) => {
		// product.heating_type === 'Cooling only' ? product.heating = false : product.heating = true
		product = product.attributes
		if (product.heating_type === 'Cooling only') {
			product.heating_min = 'n/a'
			product.heating_max = 'n/a'
			product.heating_restart = 'n/a'
			product.heating_thermal_lockout = 'n/a'
			product.cooling_restart = 'n/a'
			product.cooling_thermal_lockout = 'n/a'
		}
		product.capacity = product.cu_size
		product.capacity_outdoor = product.cu_size
		// generate array of titles and images for indoor units
		product.compatible_indoor_unit_types = []
		const pushUnit = (i) => product.compatible_indoor_unit_types.push(indoorUnitButtons[i])
		product.wall && pushUnit(0)
		product.floor && pushUnit(4)
		product.ducted && pushUnit(7)
		product.air_handler && pushUnit(2)
		product.ceiling_suspended && pushUnit(6)
		product.ceiling_one_way && pushUnit(1)
		product.ceiling_four_way && pushUnit(5)
		product.dual_fuel_cased_coil && pushUnit(3)
	})
	return list
}