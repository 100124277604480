import altText from "./altText"

export default function hybridVrf({
    id = 'hybrid-vrf',
    hasNavAnchor = false,
    title = 'Hybrid VRF™',
    color = 'transparent'
}) {
    return {
        id: id,
        class: 'two-column-img-left no-padding-bottom',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'Simultaneous Heating and Cooling',
        detailsText: `Hybrid VRF is a unique 2-Pipe Heat Recovery VRF System that replaces refrigerant with water between the Hybrid Branch Circuit Controller and the indoor units. This revolutionary design minimizes the need for expensive and on-going leak detection servicing and is specifically designed for occupied spaces where quiet, energy efficient, simultaneous heating and cooling is valued.`,
        imgSrc: '/images/hybrid-vrf-branch-controller.webp',
        imgAlt: `${altText} Hybrid VRF unit`,
        ctaText: 'Watch Video',
        videoSrc: 'VK3nAXnWKdo',
        backgroundCol: color
    }
} 