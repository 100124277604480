import YouTube from 'react-youtube'

export default function YouTubeVideo({
    videoSrc,
    autoPlay = false,
    controls = false,
    loop = false,
    fullScreen,
    isBackground,
    overlay
}) {
    const onPlayerReady = (event) => {
        console.log('start that video')
        event.target.playVideo()
    }

    const options = {
        width: '100%',
        height: '100%',
        playerVars: {
            autoplay: Number(autoPlay), // start playing on load
            cc_lang_pref: 'en', // two-letter ISO 639 lang code: https://www.loc.gov/standards/iso639-2/php/code_list.php
            color: 'white', // red or white progress bar
            controls: Number(controls), // 0 removes controls
            disablekb: 0, // 1 disables user keyboard controls
            fs: Number(fullScreen), // 0 disables full screen button
            loop: Number(loop),
            playsinline: 0, // play inline (1) or fullscreen (0) on iOS
            rel: 0, // related videos, 0 narrows to same channel
            mute: isBackground ? 1 : 0
        }
    }
    return (
        <div className={isBackground ? 'background-video' : 'full-width-video'}>
            {!controls && <div className='background-video-overlay' style={overlay}></div>}
            <YouTube
                id={!isBackground && 'youtube-video'}
                videoId={videoSrc}
                containerClassName={`youtube-video`}
                opts={options}
                onReady={onPlayerReady}
            />
        </div>
    )
}