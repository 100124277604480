import Footer from "./Footer"
import Header from "./Header"
import lorem from './page content/static-content/lorem'
import altText from "./page content/static-content/altText"
import Section from './Section'
import SectionColors from "./SectionColors"

export default function PageColors() {
    const core = ['accent', 'cooling', 'heating', 'success', 'error']
    const chromatic = ['red', 'orange', 'yellow', 'green', 'blue', 'purple']
    const neutral = ['blue-dp', 'white', 'off-white', 'lt-gray', 'gray', 'dk-gray', 'black']

    const getPos = (index) => {
        const corners = ['top-left', 'bottom-right', 'bottom-left', 'top-right']
        const cornerIndex = index % 4
        return corners[cornerIndex]
    }

    return (
        <>
            <Header noCta/>
            <section className='color-grid-wrapper'>
                <h1>METUS Color Definitions</h1>
                <p>These colors make up the universal METUS color pallette. Each primary color includes a lighter and a darker shade, all in descending degrees of opacity.</p>
                <SectionColors colors={chromatic} hasShades={true} title='Chromatic' />
                <SectionColors colors={core} hasShades={true} title='Applied' />
                <SectionColors colors={neutral} title='Neutral' />
            </section>
            <Section 
                content={{
                    class: 'two-column-img-left',
                    title: 'Two Column Section',
                    blurb: 'On clear background',
                    detailsText: lorem.three,
                    imgSrc: '/images/image-here.webp',
                    imgAlt: `${altText} strategic electrification ecosystem`,
                    ctaText: 'Button CTA',
                    videoSrc: '',
                    backgroundCol: 'transparent',
                }} 
            />
            <Section 
                content={{
                    class: 'two-column-img-left',
                    title: 'Two Column Section',
                    blurb: 'On gray background',
                    detailsText: lorem.three,
                    imgSrc: '/images/image-here.webp',
                    imgAlt: `${altText} strategic electrification ecosystem`,
                    ctaText: 'Button CTA',
                    videoSrc: '',
                    backgroundCol: 'gray',
                }} 
            />
            {chromatic.map((color, i) => 
                <Section key={`section-${i}-${color}`}
                    content={{
                        class: 'two-column-img-left',
                        title: 'Two Column Section',
                        blurb: `On ${color} background`,
                        detailsText: lorem.three,
                        imgSrc: '/images/image-here.webp',
                        imgAlt: `${altText} strategic electrification ecosystem`,
                        ctaText: 'Button CTA',
                        videoSrc: '',
                        backgroundCol: color,
                        backgroundPos: getPos(i)
                    }} 
                />
            )}
            
            {/* <Section 
                content={{
                    id: 'Projects',
                    class: 'heatmap',
                    title: 'Contractor Heatmap',
                    detailsText: ``,
                    data: 'config',
                    centerCoords: {lat: 38.501001, lng: -97.357053}
                }}
           /> */}
            <Footer />
        </>
    )
}