import campaign from './campaigns/wbu'
import altText from './static-content/altText'
import efficiency from './static-content/efficiency'
import health from './static-content/health'
import comfort from './static-content/comfort'
import control from './static-content/control'
import solutions from './static-content/solutions'
import financing from './static-content/contentBoxFinancing'
import warranty from './static-content/contentBoxWarranty'
import allElectric from './static-content/allElectric'
import ira25C from './static-content/ira-25c'
import intelliHeat from './static-content/intelli-heat'

const buPhone = '855-995-2824'

const hero = campaign()

const sections = [
	{
		id: 'subscribe',
		subscriberCampaignId: hero.subscriberCampaignId,
		class: 'download-section subscribe',
		blurb: 'Subscribe to download our brochure to learn why Mitsubishi Electric systems are a better way to heat and cool any space.',
		imgSrc: '/images/brochure-download-poster.webp',
		imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: 'gray',
	},
	ira25C({ hasNavAnchor: true }),
	intelliHeat({}),
	allElectric({ hasNavAnchor: true }),
	solutions({ color: 'transparent', ctaText: 'Request a Quote' }),
	efficiency({ hasNavAnchor: false }),
	health({ color: 'purple' }),
	comfort({ hasNavAnchor: true }),
	control({}),
	{
		id: 'Support',
		class: 'three-column-cards-w-image',
		title: 'The Support You Need',
		contentBoxes: [
			financing,
			warranty,
			{
				iconSrc: 'handshake',
				iconAlt: `${altText} call us for support`,
				blurb: 'Contact Us',
				detailsText: 'Have questions? Reach out to our Customer Care team at',
				phone: buPhone
			},
		],
		backgroundCol: 'accent',
	},
]

const header = {
	pageTitle: 'Unlock Energy Savings in the West with Mitsubishi Electric Heat Pumps',
	pageDesc: 'Discover unbeatable utility rebates and tax incentives in the Western U.S. when you switch to Mitsubishi Electric\'s all-electric heat pump systems. Elevate your home\'s comfort and efficiency today.',
	pageImg: hero.backgroundSrc,
	phone: buPhone,
	ctaText: 'Get Started',
  	ctaUrl: 'form-section',
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
