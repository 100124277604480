import tabs from './page content/static-content/tabsIndoor'
import SectionTabs from './SectionTabs'

export default function PageFrameIndoor() {
	return (
		<main>
			<SectionTabs
				tabs={tabs}
				title='Indoor Solutions'
				iframe
			/>
		</main>
	)
}