import altText from "./altText"

export default function downloadIra({
    id = 'download',
    hasNavAnchor = false,
    color = 'gray',
}) {
    return {	
        id: id,
        class: 'download-section',
        hasNavAnchor: hasNavAnchor,
        blurb: 'Learn more about how Mitsubishi Electric Trane HVAC US systems can help you earn rebates, tax credits and tax deductions.',
        imgSrc: '/images/ira_guide_poster.webp',
        imgUrl: '/ira_contractorguide_091522_v1.pdf',
        imgAlt: `${altText} click to download our guide on Inflation Reduction Act heat pump HVAC federal tax credits and incentives for homeowners, building owners, and contractors`,
        backgroundCol: color
    }
}