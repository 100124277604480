import campaign from './campaigns/new-york'
import altText from './static-content/altText'
import solutions from './static-content/solutions'
import subscribe from './static-content/subscribe'
import warranty from './static-content/contentBoxWarranty'
import financing from './static-content/contentBoxFinancing'
import download from './static-content/download'
import allElectric from './static-content/allElectric'

const buPhone = '833-977-0252'

const hero = campaign()

const sections = [
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        // title: '4 Reasons',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    {
        id: 'features',
        class: 'three-column-cards-w-image',
        blurb: 'Mitsubishi Electric Systems Are...',
        contentBoxes: [
            {
                title: 'Severe Weather-Proof',
                detailsText:
                    'Our high-efficiency heat pumps are ready for when extreme weather strikes. By being able to run on a portable invertor-driven generator, our mini-split systems will keep your family comfortable even when the power is out.',
                imgSrc: '/images/generator_house_simple_2022_final.svg',
                imgAlt: `${altText} product row`,
            },
            {
                title: 'Not Window Units',
                detailsText:
                    "Mitsubishi Electric ductless heating and cooling systems consist of an outdoor heat pump connected to an indoor unit mounted on your ceiling or wall. That means our mini-split units do not block windows and become a more stable - and attractive - fixture in your home, while also ensuring a peaceful night's sleep with their whisper-quiet operation.",
                imgSrc: '/images/not-a-window-unit.svg',
                imgAlt: `${altText} interior bedroom mini-split wall unit heat pump vs window unit comparison`,
            },
            {
                title: 'The Future of Comfort',
                detailsText:
                    'We are constantly thinking of ways to make all of your moments special. Comfort should be seamless and innovative, which is why we have features like our 3D i-see sensor to automatically adjust the output of the unit and ensure your desired temperature.',
                imgSrc: '/images/3D-i-see.webp',
                imgUrl: '',
                imgAlt: `${altText} father daughter mini-split living room clean healthy air`,
            },
        ],
        backgroundCol: 'blue',
    },
    solutions({ color: 'transparent', ctaText: 'Request a Quote', hasNavAnchor: true }),
    {
        id: 'incentives',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        title: 'Up to $2,000\nFederal Tax Credit',
        imgSrc: '/images/couple-budget-winter.webp',
        imgAlt: 'young couple smiling and looking at laptop screen',
        detailsText:
            'Under the Inflation Reduction Act*, homeowners are now eligible for a tax credit** of 30% of the cost, up to $2,000, for heat pumps, and/or heat pump water heaters, in accordance with section 25C of the US tax code. Only certain models qualify for the tax credit, and you must have a tax liability from which to reduce your taxes. Consult your Mitsubishi Electric contractor to learn which models qualify for the manufacturer rebate. Contact your tax advisor for tax advice.',
        iconList: {
            title: 'Benefits beyond savings',
            items: [
                { text: 'Slim Profile' },
                { text: 'Lower Utility Bill' },
                { text: 'Zero Carbon Emissions' },
                { text: 'Improved Air Quality' },
                { text: 'Whisper Quiet Sound Levels' },
            ],
        },
        download: download && 'View list of qualifying products',
        footnote:
            '*The Inflation Reduction Act of 2022 (IRA) is the largest ever climate investment by the Federal Government in American history, projected to reduce greenhouse gas (GHG) by 31% to 44% below the 2005 levels by 2030.\n\n**Filed in 2025 for 2024 tax year.',
        backgroundCol: 'orange',
    },
    download({}),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Resources You Need',
        contentBoxes: [
            {
                iconSrc: 'power-bill-invoice',
                iconAlt: `${altText} calculate the cost of HVAC`,
                blurb: 'HVAC Cost Calculator',
                detailsText: `Use ConEdison's helpful HVAC cost calculator tool to compare system types.`,
                ctaText: 'Try It Out',
                ctaUrl: 'https://stage.apogee.net/mvc/home/hes/land/el?utilityname=coned&spc=hcc',
            },
            warranty,
            financing,
        ],
        backgroundCol: 'transparent',
    },
    subscribe({ campaignId: hero.subscriberCampaignId }),
]

const header = {
    pageTitle: 'Save Thousands Through New York State Utility Providers on Qualifying Mitsubishi Electric HVAC Systems',
    pageDesc:
        "Stay comfortable year round with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where exceptional comfort meets energy efficiency. Enter your zip code now to unlock local incentives!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
