export default async function getIRAIncentives({ 
    zip, // string, exactly 5 digits
    ownerStatus, // string, 'renter' or 'homeowner'
    householdIncome, // string of an integer, no commas, eg. '50000'
    taxFiling, // string, 'joint', 'single', 'hoh', 'married_filing_separately'
    householdSize // number, max of 8, min of 1
}) {
    const route = 'https://master.d28mnh9p7akn7p.amplifyapp.com/api/incentives/v1.0/residence'
    const params = `?zip=${zip}&status=${ownerStatus}&income=${householdIncome}&filing=${taxFiling}&size=${householdSize}`
    const url = route + params

    const response = await fetch(url)

    if (response.ok) {
        const data = await response.json()
        console.log('Rewiring America Response:', data.data)
        return data.data
    } else {
        console.error('Failed to load data', response.status)
        return response.status
    }
}