import { useState, useEffect } from 'react'

export default function ThankYou(props) {
	const [thankYou, setThankYou] = useState('')

	const userName = localStorage.getItem('userName')

	const headline = props.headline ? props.headline : userName ? `Thank You, ${userName}!` : 'Thank You!'

	const handleReload = () => {
		props.onClick()
		window.location.reload()
	}

	useEffect(() => {
		props.thankYou
			? setThankYou(props.thankYou)
			: setThankYou('You can expect to hear from your selected number of contractors within the next 2-3 business days.')
	},[props.thankYou])
	
	return (
		<div id='thank-you-wrapper' className={props.className} style={{paddingLeft: 'var(--spc-xl-w)', paddingRight: 'var(--spc-xl-w)', borderRadius: 'var(--rnd-lg)', margin: 'var(--xl2) auto'}} >
			<h3 style={{textAlign:'center'}} onClick={props.onClick}>
				{headline}
			</h3>
			<p>We've received your request for a consultation. {thankYou}</p>
			<h4 style={{marginTop: '40px'}} className="h5">Utility Rebate Information</h4>
			<p><a href='https://www.mitsubishicomfort.com/rebates-and-financing/rebates' rel='noreferrer' target='_blank'>Click here</a> to find more detailed information on special utility rebates available in your area.</p>
			<h4 className="h5">Financing Your New System</h4>
			<p>Mitsubishi Electric and Synchrony Financial make it easier to afford the latest, most efficient heating and cooling systems available. With options like deferred interest and fixed payments with low interest rates, you can find the program that works best for you. Ask your contractor about financing options.</p>
			<p><a href='https://www.mitsubishicomfort.com/rebates-and-financing/financing' rel='noreferrer' target='_blank'>Click here</a> for more information on financing.</p>
			{props.showResetButton && 
				<button onClick={handleReload}>Reset Form</button>
			}
		</div>
	)
}
