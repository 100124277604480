import altText from '../altText'
import lorem from '../lorem'

const threeColumnInfo3 = {
    id: 'Support',
    class: 'three-column-cards-w-image',
    title: 'three-column (variant)',
    contentBoxes: [
      {
        iconSrc: 'power-bill-invoice',
        iconAlt: `${altText} payment options`,
        blurb: lorem.title,
        detailsText: lorem.one,
        ctaText: 'open modal',
        modalContent: {
          title: lorem.title,
          text: lorem.four,
          imgSrc: '/images/image-here.webp',
          imgAlt: 'alternative text here',
          footnote: lorem.three
        }
      },
      {
        iconSrc: 'warranty',
        iconAlt: `${altText} alternative text here`,
        blurb: lorem.title,
        detailsText: lorem.one,
        ctaText: 'open modal',
        modalContent: {
          title: lorem.title,
          text: lorem.four,
          imgSrc: '/images/image-here.webp',
          imgAlt: `${altText} alternative text here`
        }
      },
      {
        iconSrc: 'call',
        iconAlt: `${altText} call us for support`,
        blurb: lorem.title,
        detailsText: lorem.one,
        phone: '123-456-7890'
      },
    ],
    backgroundCol: 'accent',
  }
  
  export default threeColumnInfo3