import LogoProduct from './LogoProduct'
import Icon from './modular/Icon'
import ScrollLink from './modular/ScrollLink'
import altText from './page content/static-content/altText'

export default function SectionProduct ({ id, className, title, products }) {
	return (
		<section id={id} className={className}>
			<h2>{title}</h2>
			<div className='product-nav-bar'>
				{products.map((product, i) => 
					<ScrollLink 
						key={product.title}
						className='product-nav-link' 
						to={`#${product.id}`}>{product.title}
					</ScrollLink>
				)}
			</div>
			{products.map((product, i) => 
				<section key={product.title} id={product.id} className='new-product-card-wrapper'>
					<div className='card-content'>
						<div>
							{product.productLogo 
								? 	<LogoProduct 
										productLogo={product.productLogo} 
										logoHeight={product.logoHeight}
										altText={altText + product.title+' product branding/logo'}
									/>
								: 	<h2>{product.title}</h2>
							}
							
							<h3>{product.name}</h3>
							<img className='mobile-only' src={product.topImageSrc} alt={`${altText} ${product.name}`}/>
							<h4 className='model'>{product.model}</h4>
							<p>
								{product.description}{product.model === 'QAHV-N136TAU-HPB-(-BS)' && <a href='https://www.heat2o.com/' target='_blank' rel='noreferrer'> Click here for a more in-depth, interactive overview of HEAT₂O.</a>}
							</p>
							{product.features.map((feature) => 
								<div key={feature.icon} className='feature-item'>
									<Icon id={feature.icon} format='png'/>
									<div>
										<h4 className='h5'>{feature.title}</h4>
										<p style={feature.imgSrc && {marginBottom: 'var(--spc-xs-h)'}}>
											{feature.description}
										</p>
										{feature.imgSrc && <img style={{marginBottom: 'var(--spc-sm-h)'}} src={feature.imgSrc} alt={feature.description}/>}
									</div>
								</div>
							)}
							{product.ctaText &&
								<ScrollLink 
									key={product.title}
									className='btn'
									style={{marginTop: 'var(--spc-lg-h)'}}
									to={product.ctaUrl ? product.ctaUrl : '#form-section'}
								>
									{product.ctaText}
								</ScrollLink>
							}
						</div>
						<div className='images-column'>
							<img src={product.topImageSrc} alt={`${altText} ${product.name}`}/>
							<img style={{width:'clamp(300px, 20vw, 450px)', display: 'flex', alignSelf: 'start'}} src={product.imageSrc} alt={`${altText} ${product.name}`}/>
						</div>
						{product.footnote &&
							<p className='footnote'>*{product.footnote}</p>
						}
					</div>
				</section>
			)}
		</section>
	)}