export default function ira25C({ id = 'incentives', title = 'Up to $2,000 Federal Tax Credit', color = 'green', download = false, hasNavAnchor = false }) {
    return {
        id: id,
        class: 'two-column-img-right',
        hasNavAnchor: hasNavAnchor,
        title: title,
        imgSrc: '/images/lifestyle/interior/couple-doing-budget.webp',
        imgAlt: 'alt text',
        detailsText:
            'Under the Inflation Reduction Act*, homeowners are now eligible for a tax credit** of 30% of the cost, up to $2,000, for heat pumps, and/or heat pump water heaters, in accordance with section 25C of the US tax code. Only certain models qualify for the tax credit, and you must have a tax liability from which to reduce your taxes. Consult your tax advisor for advice.',
        iconList: {
            title: 'Benefits beyond savings',
            items: [
                { text: 'More energy efficient' },
                { text: 'No greenhouse gas emissions' },
                { text: 'Quieter than traditional HVAC systems' },
                { text: 'Individual room temperature control for personalized comfort' },
            ],
        },
        download: download && 'View list of qualifying products',
        footnote:
            '*The Inflation Reduction Act of 2022 (IRA) is the largest ever climate investment by the Federal Government in American history, projected to reduce greenhouse gas (GHG) by 31% to 44% below the 2005 levels by 2030.\n\n**Filed in 2025 for 2024 tax year.',
        backgroundCol: color,
    }
}
