import altText from './altText'

export default function hotelMarcel({
    id = 'hotel-marcel',
    hasNavAnchor = false,
    title = 'Hotel MARCEL',
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-right',
		hasNavAnchor: hasNavAnchor,
		title: title,
		blurb: 'Case Study Video',
		detailsText: 'Watch this in-depth case study featuring the restoration of the historic building using Mitsubishi Electric\'s all-electric HVAC.',
		imgSrc: '/images/hotel-marcel-ext.webp',
		imgAlt: `${altText} hotel MARCEL case study on HVAC VRF systems by Mitsubishi Electric`,
		ctaText: 'Watch Video',
		videoSrc: 'EDMkno2lkBU',
		backgroundCol: color
	}
}