import { useState, useEffect } from 'react'
import altText from './page content/static-content/altText'
import Icon from './modular/Icon'

export default function TabContentSlider({ brand, containImg, tabSelected }) {
	const [slideSelected, setSlideSelected] = useState(tabSelected.slider[0])

	const getBrandedText = (text) => {
		if (text[0] === '$') {
			const parsedText = text.slice(1)
			switch(brand) {
				case 'trane': return `Trane + Mitsubishi Electric${parsedText}`
				case 'american-standard': return `American Standard + Mitsubishi Electric${parsedText}`
				default : return `Mitsubishi Electric${parsedText}`
			}
		}
		return text

	}

	const getImageSrc = (name) => `/images/product/${brand}/${name}`

	const getSliderImgClass = (slide) => {
		let className = 'photo-img'
		const isProduct = slide.imageName 
		const isIcon = slide.iconId && slide.iconId ? true : false
		isProduct && (className = 'product-img')
		isIcon && (className = 'icon-wrapper')
		slide.title === slideSelected.title && (className = className + ' selected')
		return className
	}

	const handleSliderBtnClick = (e) => setSlideSelected(tabSelected.slider[e.target.getAttribute('data-id')])

	useEffect(() => setSlideSelected(tabSelected.slider[0]), [tabSelected])

	return (
		<div className='tab-content-wrapper'>
			<div>
				<h1 id='tab-title' className='h3' style={{marginTop:'0'}}>{tabSelected.title}</h1>
				<p id={tabSelected.slider[1] && 'tab-p'}>{getBrandedText(tabSelected.detailsText)}</p>
			</div>
			<div className='slider-wrapper'>
				{slideSelected.imageName &&
					<img 
						style={containImg ? { objectFit: 'contain' } : undefined}
						src={getImageSrc(slideSelected.imageName)} 
						alt={`${altText}${slideSelected.title}`} 
						className={getSliderImgClass(slideSelected)}
					/>
				}
				{slideSelected.imageSrc &&
					<img 
						style={containImg ? { objectFit: 'contain', height: 'auto' } : undefined}
						src={slideSelected.imageSrc} 
						alt={`${altText}${slideSelected.title}`} 
						className={getSliderImgClass(slideSelected)}
					/>
				}
				{slideSelected.iconId &&
					<div className='icon-wrapper'>
						<Icon id={slideSelected.iconId} />
					</div>
				}
				{slideSelected.title && <h4 style={{textAlign: 'center', textTransform: 'uppercase'}}>{slideSelected.title}</h4>}
				{slideSelected.detailsText && <p style={{minHeight: '76px'}}>{slideSelected.detailsText}</p>}
				{ tabSelected.slider[1] && 
					<div className='slider-btn-row'>
						{tabSelected.slider.map((slide, index) => {
							if (slide.iconId) {
								return (
									<div 
										key={index} 
										data-id={index} 
										className={getSliderImgClass(slide)} 
										onClick={handleSliderBtnClick}
									>
										<Icon 
											dataId={index}
											id={slide.iconId}
											alt={`${altText}${slide.title}`}
										/>
									</div>
							)}
							return (
								<img 
									key={index} 
									data-id={index}
									src={slide.imageName ? getImageSrc(slide.imageName) : slide.imageSrc} 
									alt={`${altText}${slide.title}`}
									className={getSliderImgClass(slide)}
									onClick={handleSliderBtnClick}
								/>
								)
							}
						)}
					</div>
				}
			</div>
		</div>
	)
}