export default function hslaToHex(name, getTextColor) {
    const hslaVariable = getComputedStyle(document.documentElement).getPropertyValue(name)
    const hslaArray = hslaVariable.substring(hslaVariable.indexOf('(') + 1, hslaVariable.lastIndexOf(')')).split(',')
    let h = parseInt(hslaArray[0])
    let s = parseInt(hslaArray[1].slice(0, -1))
    let l = parseInt(hslaArray[2].slice(0, -1))
    let a = parseFloat(hslaArray[3])
    if (getTextColor) {
        if (l >= 50 || a <= .5) return 'var(--text-col)'
        return 'var(--site-bg)'
    }
    h /= 360
    s /= 100
    l /= 100
    let r, g, b
    if (s === 0) {
        r = g = b = l
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }
    const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }
    const hexString = `#${toHex(r)}${toHex(g)}${toHex(b)}`
    if (a !== undefined && a !== 1) {
        const alphaHex = Math.round(a * 255).toString(16)
        return `${hexString}${alphaHex.length === 1 ? '0' : ''}${alphaHex}`
    }
    return hexString
}