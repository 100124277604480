import Header from './Header'
import Footer from './Footer'
import Section from './Section'
import NavBu from './NavBu'
import ScrollLink from './modular/ScrollLink'
import YouTube from 'react-youtube'
import { header, hero, sections } from './page content/pageBuildersContent'

export default function PageBuilders() {
	return (
		<>
			<Header
				pageTitle={header.pageTitle}
				pageDesc={header.pageDesc}
				pageImg={header.pageImg}
				class={header.class}
				phone={header.phone}
				branding={header.branding}
				sections={header.pageSections}
				ctaUrl={header.ctaUrl}
			/>
			<main>
				<section
					id={hero.id}
					className={`section ${hero.class}`}
					style={{
						padding: 'var(--xl6) 0',
						minHeight: '100vh',
						background: `radial-gradient(ellipse at bottom center, var(--blue-dp-80), var(--black-80)), url('/images/ductless-house-cutaway.webp')`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'top',
						backgroundSize: 'cover'
					}}
				>
					<div className="section-content-wrapper" style={{gridTemplateColumns:'3fr 5fr',padding: 'var(--xl)', maxWidth: '1600px'}}>
						<div className='no-glass dark-mode'>
						<div className='panel-content'>
							{hero.blurb &&
								<h1 className='h4' style={{margin:'0'}}>{hero.blurb}
									{hero.footnote && <div className='superscript'>1</div>}
								</h1>
							}
							<h2 className='h1' style={{margin:'0'}}>{hero.title}</h2>
							{hero.detailsText &&
								<p style={{whiteSpace: 'pre-wrap', paddingBottom: 'var(--xl)'}}>
									{hero.detailsText}
								</p>
							}

							{hero.ctaText &&
								<ScrollLink
									to={`#${hero.ctaUrl}`}
									className='btn'
									style={{textTransform:'capitalize', boxShadow: 'var(--neumorph-md-dk)'}}
								>
									{hero.ctaText}
								</ScrollLink>
							}
							<span style={{color:'var(--white)', paddingLeft: 'var(--xs)'}}> or <a className='on-accent-col' download href='ME_PR_1927_PB-Brochure_V7.pdf' target='_blank'>download our brochure</a></span>
						</div>

						{hero.footnote &&
							<div className="footnote-bar">
								<p className="footnote"><span className='superscript big'>1</span> {hero.footnote}</p>
							</div>
						}
						</div>
						<YouTube
							videoId={hero.videoId}
							id={'youtube-video'}
							containerClassName={'full-width-video'}
							opts={{
								width: '100%',
								height: '100%',
								playerVars: {
									rel: 0,
									controls: 1, // 0 removes controls
									modestbranding: 1,
									allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
									allowFullScreen: 1
								}
							}}
						/>
						{/* <img src={hero.imgSrc} alt={hero.imgAlt} style={{objectFit: 'cover'}}/> */}
					</div>
				</section>
				{sections.map((section, index) =>
					<Section
						key={index+section.id}
						content={section}
					/>
				)}
			</main>
			<NavBu
				id='mobile-nav'
				sections={header.pageSections}
			/>

			<Footer branding={header.branding}/>
		
		</>
	)
}