import { useState } from 'react'
import tabs from './page content/static-content/tabsIndoor'
import Tabs from './Tabs'
import TabContentProducts from './TabContentProducts'
import useBranding from '../hooks/useBranding'

export default function PageFrameIndoor() {
	const logo = useBranding()
	const [tabSelected, setTabSelected] = useState(tabs[0])

	const handleTabClick = (e) => setTabSelected(tabs[e.target.id])

	return (
		<>
			<h2>Explore Indoor Solutions</h2>
			<Tabs
				company={logo}
				tabs={tabs}
				tabSelected={tabSelected}
				handleTabClick={handleTabClick}
			>
				<TabContentProducts 
					brand={logo}
					tabSelected={tabSelected}
				/>
			</Tabs>
		</>
	)
}
