export default function validateField(type, name, isRequired, value) {
    let isValid = true

    if (isRequired) {
        // console.log(name)
        if (value === '') {
            isValid = false
        }

        if (name === 'companyPin') {
            const reg = /^\d{10}$/
            isValid = reg.test(String(value).toLowerCase())
            console.log('VALIDATE:', value, isValid)
        }

        if (name === 'Income') {
            const reg = /^\d{1,9}$/
            isValid = reg.test(String(value).toLowerCase())
            console.log('VALIDATE:', value, isValid)
        }
    
        if (type === 'email') {
            const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            isValid = reg.test(String(value).toLowerCase())
        }
    
        if (name && name.toLowerCase().includes('zip')) {
            const reg = /^\d{5}$/
            isValid = reg.test(String(value))
        }
    
        if (type === 'select' && value.includes('Select')) {
            isValid = false
        }
    
        if (type === 'tel') {
            const reg = /^\(\d{3}\) \d{3}-\d{4}$/
            isValid = reg.test(String(value).toLowerCase())
        }
    }

    return isValid
}
