import lorem from './lorem'

const intelliHeat = [
    {
        id: 'efficiency',
        title: 'Complete Comfort, Eco-Efficiency',
        detailsText: '$\'s intelli-HEAT Dual Fuel System delivers cost-effective, eco-efficient, year-round heating and cooling. Variable speed technology and smart controls significantly reduce fossil fuel usage and greenhouse gas emissions. Experience complete indoor comfort and a quieter outdoor environment in ambient temperatures as low as -11° F.',
        slider: [
            {
                title: '',
                imageSrc: '/images/product/intelli-heat-system.webp',
                detailsText: ''
            }
        ]
    },
    {
        id: 'cooling',
        title: 'More Efficient Cooling',
        detailsText: '$ heat pumps\' INVERTER-driven compressors provide superior cooling performance during summer months, reliable heating even in winter temperatures as low as -11° F, and significant energy savings all year long.',
        slider: [
            {
                title: '',
                imageSrc: '/images/product/intelli-heat-cooling.webp',
                detailsText: ''
            }
        ]
    },
    {
        id: 'heating',
        title: 'Fossil Fuel-free Heating',
        detailsText: 'Using the all-electric INVERTER-driven heat pump, your intelli-HEAT™ system can match the heat load of your home for up to 98% of the heating season even in colder climates, significantly reducing direct and indirect carbon emissions.',
        slider: [
            {
                title: '',
                imageSrc: '/images/product/intelli-heat-heating.webp',
                detailsText: ''
            }
        ]
    },
    {
        id: 'smart',
        title: 'Intelligent Switchover',
        detailsText: 'intelli-HEAT incorporates a control box with built-in intelligence that will determine the optimum switchover point from heat pump to gas furnace. Weather your goal is financial or environmental, the intelligent switchover will help to maximize the performance and efficiency of your all-electric heat pump and gas furnace system.',
        slider: [
            {
                title: '',
                imageSrc: '/images/product/intelli-heat-control.webp',
                detailsText: ''
            }
        ]
    },
]

export default intelliHeat