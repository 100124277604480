import altText from '../altText'
import lorem from '../lorem'

const twoColumnImgRight = {
  id: 'two-column-img-right',
  class: 'two-column-img-right',
  title: lorem.title,
  blurb: 'two-column-img-right',
  detailsText: lorem.four,
  imgSrc: '/images/image-here.webp',
  imgAlt: `${altText} classic interior radiator`,
  ctaText: 'Watch Video',
  videoSrc: 'https://www.youtube.com/embed/JSRADx3a9SI'
}

export default twoColumnImgRight