import { useState, useRef, useEffect } from 'react'
import InputText from "./modular/InputText"
import InputSelect from "./modular/InputSelect"
import InputRadioBtnGroup from "./modular/InputRadioBtnGroup"
import Button from "./modular/Button"
import Link from "./modular/Link"
import validateField from '../lib/validateField'
import getGeoData from '../lib/getGeoData'
import LoadingSpinner from './modular/LoadingSpinner'
import ThankYouLead from './ThankYouLead'
import conversions from '../lib/conversions'
import getIRAIncentives from '../lib/getIRAIncentives'
import parseIncentives from '../lib/parseIncentives'
import { formatPhone, formatZip, formatInteger, formatHouseholdSize, numWithCommas } from '../lib/helpers'

export default function LeadFormIRA({ content, formClass }) {
    const formElement = useRef(null)
    const tokenField = useRef(null)
    const currentYear = new Date().getFullYear()
    const nextYear = currentYear + 1

    const [formIsValid, setFormIsValid] = useState(false)
	// const [campaignId, setCampaignId] = useState(content.leadCampaignId)
    const [userState, setUserState] = useState('')
	const [userCity, setUserCity] = useState('')
    const [searchReady, setSearchReady] = useState(false)
    const [isSearchingIncentives, setIsSearchingIncentives] = useState(false)
    const [incentives, setIncentives] = useState(null)
    const [isLMI, setIsLMI] = useState(false)
    const [leadFormVisible, setLeadFormVisible] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [leadFormSubmitted, setLeadFormSubmitted] = useState(false)
    const [thankYouHeadline, setThankYouHeadline] = useState('')

    const [formData, setFormData] = useState({
		FirstName: {value: ''},
		LastName: {value: ''},
		Phone: {value: ''},
		Email: {value: ''},
		ZipCode: {value: ''},
		Street: {value: ''},
        OwnerStatus: {value: ''},
        TaxFiling: {value: ''},
        HouseholdSize: {value: ''},
        HouseholdIncome: {value: ''},
		HVACneeds: {value: '', valid: true},
		Rooms: {value: '', valid: true},
		PlanToPurchase: {value: '', valid: true},
		NoOfContractor: {value: '', valid: true},
		BestWayToReachYou: {value: '', valid: true},
		CustomerComments: {value: '', valid: true},
		I_Am_A: {value: 'New Customer', valid: true},
		ReceiveEmailCommunication: {value: '', valid: true},
	})

    const GTMTrigger = content.GTMTrigger ? content.GTMTrigger : 'hero_form'

    const currentPage = window.location.pathname.substring(1)

    /* ---------------- FIELD VALUES HANDLER ---------------- */
	const handleChange = (e) => {
		const { name, type, value, required } = e.target
        let finalValue = value
		let checked

        if (name === 'Income' && value) {
			finalValue = formatInteger(value)
		}

        if (name === 'HouseholdSize' && value) {
			finalValue = formatHouseholdSize(value)
		}
		
		if (name === 'Phone' && value) {
			finalValue = formatPhone(value)
		}
		
		if (name === 'ZipCode' && value) {
			finalValue = formatZip(value)
		}
		
		if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
			checked = e.target.checked
        }
		
		const isValid = validateField(type, name, required, finalValue, name === 'ZipCode')

		// console.log('changed:', name, finalValue, 'valid:', isValid)

		setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: type === 'checkbox' ? checked : finalValue,
                    valid: isValid,
                }
            }
        })
	}

    /* ---------------- LOCAL STORAGE HANDLER ---------------- */
	const handleStorageReset = () => {
		console.log(`--> lead form resetting `)
		// handle conversion and storage
		conversions('lead', null, true)
		setThankYouHeadline('Form reset, now reload page')
	}

	useEffect(() => {
		// handle local storage items and thank-you
		const userName = localStorage.getItem('userName')
		const isSubmitted = localStorage.getItem(`${currentPage}_leadFormSubmitted`)
		const Email = localStorage.getItem('Email')

		if (Email && (!formData.Email || formData.Email.value !== Email)) {
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					Email: {
						value: Email,
						valid: true,
					}
				}
			})
		}

		isSubmitted === 'true' && setLeadFormSubmitted(true)

		if (userName && (!formData.FirstName || formData.FirstName.value !== userName)) {
			setFormData((prevFormData) => {
				return {
					...prevFormData,
					FirstName: {
						value: userName,
						valid: true,
					}
				}
			})
		}
	}, [formData.Email, formData.FirstName, currentPage])

	const handleZipSearch = async() => {
        const geoData = await getGeoData({zip: formData.ZipCode.value})
        setUserCity(geoData.data.city)
        setUserState(geoData.data.state)
    }

	useEffect(() => {
        console.log(formData)
        const searchFields = [
            formData.Street, 
            formData.ZipCode, 
            formData.OwnerStatus, 
            formData.TaxFiling, 
            formData.HouseholdSize, 
            formData.HouseholdIncome
        ]
        const searchFieldsValid = searchFields.every((field) => field.valid)
		const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
		setFormIsValid(allFieldsValid)
        setSearchReady(searchFieldsValid)
	}, [formData, userCity])

    const handleIRASearch = async(e) => {
        e.preventDefault()
        setIncentives(null)
        setIsSearchingIncentives(true)

        if (!userCity) {
            handleZipSearch()
        }

        const queryData = {
            zip: formData.ZipCode.value,
            ownerStatus: formData.OwnerStatus.value,
            householdIncome: formData.HouseholdIncome.value,
            taxFiling: formData.TaxFiling.value,
            householdSize: formData.HouseholdSize.value,
        }

        const incentiveData = await getIRAIncentives(queryData)
        const parsedIncentives = parseIncentives(incentiveData)
        setIsLMI(parsedIncentives.isLowIncome || parsedIncentives.isModerateIncome)
        console.log('Parsed Incentives:', parsedIncentives)
        
        setIncentives(parsedIncentives)

        setIsSearchingIncentives(false)
    }

    const handleIncentivesReset = () => {
        setIncentives(null)
    }

    const showLeadForm = () => {
        setLeadFormVisible(true)
    }

    const handleLeadFormSubmit = (e) => {
        e.preventDefault()
        if (formIsValid) {
            setIsSubmitting(true)
            console.log('submit that form', formData)
        }
        setIsSubmitting(false)
    }

    if (isSubmitting) return (
		<div className={formClass} >
			<LoadingSpinner loadingMessage={'Sending your information...'} />
		</div>
	)

	if (leadFormSubmitted) return (
		<ThankYouLead
			onClick={handleStorageReset}
			headline={thankYouHeadline}
			className='glass-panel'
			thankYou='You can expect to hear from a licensed HVAC contractor within 2 business days.'
			Email={formData.Email.value}
		/>
	)

    const incentivesForm = () => {
        return (
            <div className="contact-info-wrapper">
                <InputText
                    id="street"
                    name="Street"
                    label="Street Address"
                    type="text"
                    placeholder="House number & street only"
                    value={formData.Street.value}
                    onChange={handleChange}
                    isValid={formData.Street.valid}
                    required
                />
                <InputText
                    id="zip"
                    name="ZipCode"
                    label="Zip Code"
                    type="text"
                    maxLength="5"
                    placeholder="U.S. Only"
                    onChange={handleChange}
                    onBlur={handleZipSearch}
                    value={formData.ZipCode.value}
                    isValid={formData.ZipCode.valid}
                    errorMsg="Invalid Zip Code"
                    required
                />
                <InputText
                    id="income"
                    name="HouseholdIncome"
                    label="Household Income"
                    type="text"
                    maxLength="9"
                    placeholder="Gross income/year"
                    onChange={handleChange}
                    value={formData.HouseholdIncome.value}
                    isValid={formData.HouseholdIncome.valid}
                    errorMsg="Household Income Required"
                    required
                />
                <InputText
                    id="householdSize"
                    name="HouseholdSize"
                    label="Household Size"
                    type="text"
                    maxLength="1"
                    placeholder="Number, 1-8"
                    onChange={handleChange}
                    value={formData.HouseholdSize.value}
                    isValid={formData.HouseholdSize.valid}
                    errorMsg="Household Size Required"
                    required
                />
                <InputSelect
                    id="input-wrapper"
                    name="OwnerStatus"
                    options={['homeowner', 'renter']}
                    displayedOptions={['Owner', 'Renter']}
                    label="Rent or Own"
                    onChange={handleChange}
                    value={formData.OwnerStatus.value}
                    isValid={formData.OwnerStatus.valid}
                    errorMsg="Household Size Required"
                    required
                />
                <InputSelect
                    id="input-wrapper"
                    name="TaxFiling"
                    options={['joint', 'married_filing_separately', 'hoh', 'single']}
                    displayedOptions={['Married, filing jointly', 'Married, filing separately', 'Head of household', 'Single']}
                    label="Tax Filing Status"
                    onChange={handleChange}
                    value={formData.TaxFiling.value}
                    isValid={formData.TaxFiling.valid}
                    errorMsg="Household Size Required"
                    required
                />
            </div>
        )
    }

    return (
        <form onSubmit={handleLeadFormSubmit} action={GTMTrigger} id="form" className={formClass} autoComplete="on" ref={formElement}>
            {!incentives && (
                <section id="ira-form-wrapper">
                    <h5 className="center mar-btm-0">
                        How Much Can You Save?<sup className="h6">#</sup>
                    </h5>
                    <h6 className="center mar-top-0">Enter your household information to see what you may qualify for under the Federal Inflation Reduction Act.</h6>
                    {incentivesForm()}
                    <Button
                        type="button"
                        text="Estimate Savings"
                        className="mar-y-0 mar-auto"
                        onClick={handleIRASearch}
                        disabled={!searchReady}
                    />
                </section>
            )}
            {isSearchingIncentives && <LoadingSpinner infinite loadingMessage={'Searching for Incentives...'} />}

            {incentives && (
                <section id="lead-form-wrapper mar-top-0">
                    {!leadFormVisible && (
                        <div>
                            <h5 className="center mar-btm-lg">
                                Congratulations! Your home in {userCity}, {userState} could qualify for up to ${incentives.totalAmountFormatted}
                                <sup className="footnote">#</sup> in incentives:
                            </h5>
                            {incentives.rebates[0] && (
                                <div className="ira-incentive-info-box">
                                    <h5>Instant Rebate: ${numWithCommas(incentives.rebates[0].amount)}</h5>
                                    <ul>
                                        <li>
                                            <span className="bold">Covered:</span> {incentives.isLowIncome ? 100 : 50}% of equipment purchase and installation
                                            costs toward a new Mitsubishi Electric heat pump air conditioning/heating system, up to $
                                            {numWithCommas(incentives.rebates[0].amount)}.{' '}
                                            <Link
                                                iconId="link-ext"
                                                iconSize="tiny"
                                                iconAfter
                                                text="More information"
                                                href={`https://www.rewiringamerica.org${incentives.rebates[0].more_info_url}`}
                                            />
                                        </li>
                                        <li>
                                            <span className="bold">Program:</span> {incentives.rebates[0].program}
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {incentives.taxCredits[0] && (
                                <div className="ira-incentive-info-box">
                                    <h5>Tax Credit: ${numWithCommas(incentives.taxCredits[0].amount)}</h5>
                                    <ul>
                                        <li>
                                            <span className="bold">Covered:</span> Equipment purchase and installation costs toward a new Mitsubishi Electric
                                            heat pump air conditioning/heating system. Filed in {nextYear} for the {currentYear} tax year.{' '}
                                            <Link
                                                iconId="link-ext"
                                                iconSize="tiny"
                                                iconAfter
                                                text="More information"
                                                href={`https://www.rewiringamerica.org${incentives.taxCredits[0].more_info_url}`}
                                            />
                                        </li>
                                        <li>
                                            <span className="bold">Program:</span> {incentives.taxCredits[0].program}
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <Button type="button" center simpleLink iconSize="tiny" text="Edit My Information" iconId="pencil" onClick={handleIncentivesReset} />
                            <Button type="button" center text="Stay Up-to-Date" onClick={showLeadForm} />
                        </div>
                    )}
                    {leadFormVisible && (
                        <div>
                            <h5 className="center mar-btm-lg">
                                Don't miss out on ${incentives.totalAmountFormatted}
                                <sup className="footnote">#</sup> in incentives for a new heat pump. Enter your information below to receive updates on when{' '}
                                {userState} will release funding.
                            </h5>
                            <div className="contact-info-wrapper mar-btm-lg">
                                <InputText
                                    id="first-name"
                                    name="FirstName"
                                    label="First Name"
                                    type="text"
                                    placeholder="given name"
                                    value={formData.FirstName.value}
                                    onChange={handleChange}
                                    isValid={formData.FirstName.valid}
                                    required
                                />

                                <InputText
                                    id="last-name"
                                    name="LastName"
                                    label="Last Name"
                                    type="text"
                                    placeholder="surname"
                                    value={formData.LastName.value}
                                    onChange={handleChange}
                                    isValid={formData.LastName.valid}
                                    required
                                />

                                <InputText
                                    id="lead-email"
                                    name="Email"
                                    label="Email"
                                    type="email"
                                    placeholder="name@domain.com"
                                    maxLength="42"
                                    value={formData.Email.value}
                                    onChange={handleChange}
                                    isValid={formData.Email.valid}
                                    required
                                />

                                <InputText
                                    id="phone"
                                    name="Phone"
                                    label="Phone No."
                                    type="tel"
                                    placeholder="10-digit, U.S. numbers only"
                                    value={formData.Phone.value}
                                    onChange={handleChange}
                                    isValid={formData.Phone.valid}
                                    required
                                />
                            </div>
                            {!isLMI && (
                                <>
                                    <InputRadioBtnGroup
                                        id="needs-wrapper"
                                        title="What is the scope of your HVAC needs?"
                                        name="Rooms"
                                        options={['Single Room', 'Multiple Rooms', 'Entire System Upgrade', 'Other']}
                                        handleChange={handleChange}
                                        selectedValue={formData.Rooms.value}
                                        required
                                    />
                                    {formData.Rooms.value === 'Other' && (
                                        <InputText
                                            textArea
                                            id="needs-other"
                                            name="HVACneeds"
                                            label="Describe your HVAC needs"
                                            placeholder="Describe your current HVAC system"
                                            onChange={handleChange}
                                            value={formData.HVACneeds.value}
                                            isValid={formData.HVACneeds.valid}
                                            required
                                        />
                                    )}
                                    {!content.hideTimeframeSelector && (
                                        <InputRadioBtnGroup
                                            id="timeframe-wrapper"
                                            title="When are you planning to purchase?"
                                            name="PlanToPurchase"
                                            options={['Immediately', '0-3 months', '3-6 months', '6-12 months', '12+ months']}
                                            labels={['Immediately', '0-3 Months', '3-6 Months', '6-12 Months', '12+ Months']}
                                            handleChange={handleChange}
                                            selectedValue={formData.PlanToPurchase.value}
                                            required
                                        />
                                    )}

                                    {!content.hideContractorNumSelector && (
                                        <InputRadioBtnGroup
                                            id="contractors-wrapper"
                                            title="How many contractors would you like to be matched with?"
                                            name="NoOfContractor"
                                            options={['1', '2', '3']}
                                            handleChange={handleChange}
                                            selectedValue={formData.NoOfContractor.value}
                                            required
                                        />
                                    )}
                                    {!content.hideContactMethodSelector && (
                                        <InputRadioBtnGroup
                                            id="contact-wrapper"
                                            title="How would you prefer to be contacted?"
                                            name="BestWayToReachYou"
                                            options={['Email', 'Call', 'Text']}
                                            handleChange={handleChange}
                                            selectedValue={formData.BestWayToReachYou.value}
                                            footnote="Data rates from your wireless provider may apply"
                                            required
                                        />
                                    )}
                                </>
                            )}
                            
                            <div className='border border-rad-sm pad-md mar-btm-md'>
                                <p className='mar-top-0 mar-btm-md main-bold'>Please review and confirm accuracy:</p>
                                {incentivesForm()}
                            </div>

                            <InputText
                                id="CustomerComments"
                                name="CustomerComments"
                                label="Additional Comments?"
                                textArea
                                placeholder="Please add any additional information here"
                                onChange={handleChange}
                                value={formData.CustomerComments.value}
                                isValid={formData.CustomerComments.valid}
                            />

                            <Button
                                id="bu-form-submit"
                                type="submit"
                                center
                                className="g-recaptcha mar-top-lg"
                                text="Subscribe"
                                disabled={!formIsValid || isSubmitting}
                            />
                            <p className='center main-italic' htmlFor="ReceiveEmailCommunication">
                                "By submitting my information, I agree to receive email and communications from Mitsubishi Electric Trane HVAC in the future"
                            </p>
                        </div>
                    )}
                </section>
            )}
            <div>
                <div>
                    <div>
                        <input type="hidden" id="token" name="token" value="token" ref={tokenField} />
                        <input type="hidden" id="city-input" name="city" value={userCity} />
                        <input type="hidden" id="state-input" name="state" value={userState} />
                        <input type="hidden" id="country-input" name="Country" value="United States" />
                    </div>
                </div>
            </div>
            <p className="footnote" style={{ marginBottom: '20px' }}>
                <sup>*</sup>Asterisk Denotes a Required Field
            </p>
            <p className="footnote">
                <sup>#</sup>{content.footnote}
            </p>
        </form>
    )
}