import { useState, useEffect } from 'react'

export default function useBranding () {
  	const [brand, setBrand] = useState('mitsubishi-electric')

  	// change branding based on url params
	const urlParams = new URL(document.location).searchParams

	useEffect(() => {
		const acceptedBrands = ['me', 'tr', 'as', 'mitsubishi-electric', 'trane', 'american-standard']
		let branding = brand
		let brandingParam = urlParams.get('branding')
		if (brandingParam && acceptedBrands.includes(brandingParam)) {
			branding = brandingParam
			switch(brandingParam) {
				case 'tr': branding = 'trane'
					break
				case 'as': branding = 'american-standard'
					break
				case 'me': branding = 'mitsubishi-electric'
					break
				default: branding = brandingParam
			}
		}
		setBrand(branding)
	}, [brand, urlParams])

  	return brand
}