import GenerateShades from "./modular/GenerateShades"

export default function SectionColors({ colors, hasShades, title, body }) {
    const opacityLevels = 10
    const opacityLabels = []

    for (let i = 1; i < opacityLevels; i++) {
        opacityLabels.unshift(i*10)
        if (i+1 === opacityLevels) opacityLabels.unshift(100)
    }
    const ShadeLabel = ({ num }) => <div className='color-swatch short'>{num}%</div>
    const fontColor = (color) => {
        switch (color) {
            case 'black':
            case 'dk-gray':
            case 'gray':
            case 'accent':
            case 'purple':
            case 'blue-dp': return 'var(--white)'
            default: return 'var(--black)'
        }
    }

    return (
        <div className='color-grid' id={colors}>
            <h2>{title} Colors</h2>
            <p>{body}</p>
            <div className='color-row' style={{marginBottom: '0'}}>
                <div className="color-title-wrapper no-shadow">
                    <p className='color-title'></p>
                </div>
                <div className="swatch-group">
                    <div className='swatch-row'>
                        <p></p>
                        {opacityLabels.map((num) => 
                            <ShadeLabel key={num} num={num} />
                        )}
                    </div>
                </div>
            </div>
            {colors.map((color, i) => 
                <div key={i+color} className='color-row'>
                    <div className="color-title-wrapper" style={{backgroundColor: `var(--${color})`}}>
                        <p className='color-title' style={{color: fontColor(color)}}>{color}</p>
                    </div>
                    <div className="swatch-group">
                        {hasShades &&
                            <div className='swatch-row'>
                                <p>muted</p>
                                <GenerateShades color={color+'-lt'} levels={opacityLevels} />
                            </div>
                        }
                        <div className='swatch-row'>
                            <p style={{fontFamily: 'var(--main-font-bold)'}}>
                                {hasShades ? 'primary' : '...'}
                            </p>
                            <GenerateShades color={color} levels={opacityLevels} />
                        </div>
                        {hasShades &&
                            <div className='swatch-row'>
                                <p>bold</p>
                                <GenerateShades color={color+'-dk'} levels={opacityLevels} />
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}
