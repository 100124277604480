import { header, hero, sections } from './page content/pageMabuContent'
import Page from './Page'

export default function PageMabu() {
	const finalHero = { 
		...hero,
		scrollTo: sections[0].id
	}
	
	return (
		<Page header={header} hero={finalHero} sections={sections} />
	)
}