import { useState, useEffect } from 'react'
import altText from './page content/static-content/altText'
import Icon from './modular/Icon'

export default function TabContentProducts({ brand, tabSelected }) {
	const products = tabSelected.products
	const [productSelected, setProductSelected] = useState(products[0])

	const getImageSrc = (name) => `/images/product/${brand}/${name}`

	const handleProductBtnClick = (e) => setProductSelected(products[e.target.getAttribute('data-id')])

	const getSliderImgClass = (product) => product.title === productSelected.title ? ' selected' : ''
	
	// reset selected product when tab changes
	useEffect(() => { setProductSelected(products[0]) }, [tabSelected, products])

	return (
		<div className='tab-content-wrapper products'>
			<nav>
				{products.map((product, i) => 
					<button 
						key={i}
						className={'product-btn-wrapper'+getSliderImgClass(product)}
						data-id={i}
						onClick={handleProductBtnClick}
					>
						<h6 data-id={i}>{product.title}</h6>
						<img 
							data-id={i}
							src={getImageSrc(product.imageName)} 
							alt={`${altText}${product.title}`}
						/>
					</button>
				)}
			</nav>
			<div className='product-wrapper'>
				<h3 className='h4 product-title'>
					{productSelected.title} {!productSelected.title.includes('Handler') && tabSelected.title}
				</h3>
				<img src={getImageSrc(productSelected.imageName)} alt={`${altText}${productSelected.title}`} className='product-img'/>
				<p className='product-details'>{productSelected.detailsText}</p>
				<div className='product-specs'>
					<h5 style={{marginTop: '0'}}>Key Specs</h5>
					<ul>
						{productSelected.specs.map((spec, i) => <li key={i}>{spec}</li>)}
					</ul>
				</div>
				{ productSelected.features[0] &&
					<div className='product-features'>
						<h5 style={{marginTop: '0'}}>Key Features</h5>
						<div className='features-wrapper'>
							{productSelected.features.map((feature, i) => 
								<div key={i} className='feature-wrapper'>
									<Icon id={feature.iconId}/>
									<div className='feature-text'>
										<h6 className='feature-name'>{feature.title}</h6>
										<span className='feature-details'>{feature.detailsText}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				}
			</div>
		</div>
	)
}