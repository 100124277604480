import altText from '../altText'
import lorem from '../lorem'

const threeColumnInfo = {
	id: 'three-column',
	class: 'three-column-cards-w-image',
	title: 'three-column',
	contentBoxes: [{
			title: lorem.title,
			blurb: lorem.blurb,
			detailsText: lorem.two,
			imgSrc: '/images/image-here.webp',
			imgAlt: `${altText} interior bedroom mini-split wall unit heat pump flow`,
		},
		{
			title: lorem.title,
			blurb: lorem.blurb,
			detailsText: lorem.two,
			imgSrc: '/images/image-here.webp',
			imgAlt: `${altText} product row`,
		},
		{
			title: lorem.title,
			blurb: lorem.blurb,
			detailsText: lorem.two,
			imgSrc: '/images/image-here.webp',
			imgUrl: '',
			imgAlt: `${altText} father daughter mini-split living room clean healthy air`,
		},
	],
	backgroundCol: 'gray',
}

export default threeColumnInfo
