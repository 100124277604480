import { useState, useEffect, useRef } from 'react'
import LoadingSpinner from './LoadingSpinner'

export default function InputText({
	id,
	name,
	type,
	label,
	value,
	placeholder,
	required,
	className,
	isValid,
	errorMsg,
	suggestions,
	handleSuggestionSelected,
	textArea,
	rows,
	onFocus,
	onBlur,
	onChange,
	onKeyPress,
	onPointerDown,
	onClick,
	minLength,
	maxLength,
}) {
	const [inputClassName, setInputClassName] = useState('')
	const [fieldLabel, setFieldLabel] = useState(label)
	const [isFocused, setIsFocused] = useState(false)
    const [wasFocused, setWasFocused] = useState(false)
	const [isSearchingSuggestions, setIsSearchingSuggestions] = useState(false)
	const [suggestionsShown, setSuggestionsShown] = useState([])

	const inputRef = useRef()
	const inputMinLength = 3
	const labelClassName = `${required && 'required'} offset-label ${className ? className : ''}`

	// listen for autofill changes 
	useEffect(() => {
		let interval
		if (!isFocused) {
			interval = setInterval(() => {
				if (inputRef.current && inputRef.current.value !== '') {
					setWasFocused(true)
					clearInterval(interval)
				}
			}, 1000) // polling every second
		}
		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [isFocused])

	useEffect(() => {
        if (wasFocused) {
			// console.log(`"${label}" field has been focused`)
            if (!isValid && required) {
				// console.log(`"${label}" field value: ${value} is NOT VALID`)
                setInputClassName('invalid')
                setFieldLabel(errorMsg ? errorMsg : textArea ? label : `${suggestions ? 'Select' : 'Enter'} a Valid ${label}`)
            } else {
				// console.log(`"${label}" field value: ${value} IS valid`)
                setInputClassName('valid')
                setFieldLabel(label)
            }
        }
		if (!suggestions) {
			console.log('no suggestions for this input')
			return
		}
		if (!value) {
			console.log(`"${label}" field value is empty`)
			setSuggestionsShown([])
			return
		}
		if (value.length >= inputMinLength) {
			console.log(`"${label}" field value is at least ${inputMinLength} chars`)
			setIsSearchingSuggestions(true)
			const filteredSuggestions = suggestions.filter((option) =>
				option.name.toLowerCase().includes(value.toLowerCase())
			)
			console.log('filteredSuggestions:', filteredSuggestions)
			setSuggestionsShown(filteredSuggestions)
		}
    }, [wasFocused, errorMsg, label, textArea, value, isValid, required])

	useEffect(() => {
		if (suggestionsShown) {
			setIsSearchingSuggestions(false)
		}
	}, [suggestionsShown])

	const handleFocus = (e) => {
		setIsFocused(true)
		onFocus && onFocus(e)
	}

	const handleBlur = (e) => {
		setIsFocused(false)
		setWasFocused(true)
		onBlur && onBlur(e)
	}

	const handleChange = (e) => {
		if (suggestions?.length > 0) {
			return onChange(e, true)
		}
		onChange(e)
	}

	const handleSuggestionClick = (suggestion) => {
		console.log('handleSuggestionClick triggered')
        handleSuggestionSelected(suggestion)
        setSuggestionsShown([])
    }

	return (
		<>
			{ !textArea
				?	<div id={`${id}-wrapper`} className='input-wrapper'>
						<input 
							ref={inputRef}
							id={id}
							className={inputClassName}
							name={name}
							placeholder={placeholder}
							value={value}
							minLength={minLength ? minLength : undefined}
							maxLength={maxLength ? maxLength : '24'}
							aria-labelledby={`${id}-label`}
							type={type ? type : 'text'}
							onChange={handleChange}
							onFocus={onFocus ? onFocus : handleFocus}
							onBlur={handleBlur}
							onKeyPress={onKeyPress ? onKeyPress : null}
							onPointerDown={onPointerDown ? onPointerDown : null}
							onClick={onClick ? onClick : null}
							list={name}
							required={required}
							aria-required={required}
						/>
						<label 
							id={`${id}-label`}
							htmlFor={id}
							className={labelClassName}
						>
							{fieldLabel}
						</label>
						{suggestions &&
							<>
								{(isSearchingSuggestions || ((suggestionsShown.length > 0) && (!isValid))) && (
									<div id={name} className='suggestions-wrapper'>
										{isSearchingSuggestions
											? <LoadingSpinner loadingMessage={'Finding company name...'}/>
											: <ul>
												{suggestionsShown.map((suggestion, i) => (
													<li
														key={suggestion.id}
														onClick={() => handleSuggestionClick(suggestion, i)}
													>
														{suggestion.name}
													</li>
												))}
											</ul>
										}
									</div>
								)}
								{!isSearchingSuggestions && (suggestionsShown.length === 0) && (value.length >= inputMinLength) && (
									<div className='suggestions-wrapper'>
										<p>No contractors matching "{value}"</p>
									</div>
								)}
							</>
						}
					</div>
				:	<div id={`${id}-wrapper`} className='input-wrapper'>
						<textarea 
							ref={inputRef}
							id={id}
							className={inputClassName}
							name={name}
							maxLength='254'
							rows={rows ? rows : '2'}
							wrap='soft' 
							placeholder={placeholder}
							value={value}
							onChange={onChange ? onChange : null}
							onFocus={onFocus ? onFocus : handleFocus}
							onBlur={handleBlur}
							required={required}
							aria-required={required}
						/>
						<label 
							id={`${id}-label`}
							htmlFor={id}
							className={labelClassName}
						>
							{fieldLabel}
						</label>
					</div>
			}
		</>
	)
}
