export const isCampaignRunning = (start, end) => {
  const today = new Date()
  const didStart = today > start
  const didEnd = today > end 
  if (didStart && !didEnd) {
    return true
  }
  return false
}

export const isCampaignOver = (end) => {
  const today = new Date()
  const didEnd = today > end 
  if (didEnd) {
    return true
  }
  return false
}

export const doMeetingsConflict = (startA, endA, startB, endB) => {
  // ">" = is after
  // "<" = is before
  // check - does A start on or after B starts but before B ends?
  if (startA >= startB && startA < endB) { return true }

  // check - does A end after B starts but before B ends?
  if (endA > startB && endA <= endB) { return true }
  return false
}