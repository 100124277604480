import campaign from './campaigns/nebu'
import subscribe from './static-content/subscribe'
import benefits from './static-content/benefits'
import solutions from './static-content/solutions'
import support from './static-content/support'
import altText from './static-content/altText'
import ira25C from './static-content/ira-25c'
import intelliHeat from './static-content/intelli-heat'
import download from './static-content/download'

const buPhone = '855-897-9524'
const buHours = 'Mon-Fri, 8AM-5PM EST'

const hero = campaign()

const sections = [
    ira25C({ download: true, hasNavAnchor: true, color: 'orange' }),
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        // title: '4 Reasons',
        blurb: 'How Our Heat Pumps Heat AND Cool',
        detailsText:
            'Our all-climate heat pumps heat and cool any room, anywhere, in any season. Maybe you have a room that is unbearably cold during the winter, or maybe a space that requires you to drop the temperature of your entire house just to get that area comfortable during the summer. Your home should feel comfortable year-round.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    {
        id: 'ductless',
        class: 'two-column-img-left',
        title: 'No Ducts?\nNo Problem',
        detailsText:
            "All-electric, all-climate heat pumps are designed for energy-efficiency and flexibility to solve your heating and cooling needs. Our systems are designed to fit any application, delivering personalized temperature control for one room or a whole home, with or without ducts. Read on to discover how to elevate your home's comfort.",
        imgSrc: '/images/nebu-spring-hero-img.webp',
        imgAlt: `${altText} happy family, man and woman in living room smiling and playing with child. to the right is an outdoor heat pump unit`,
        ctaText: 'Learn More',
        ctaUrl: 'form-section',
        backgroundCol: 'yellow',
    },
    solutions({ ctaText: 'Request a Quote', hasNavAnchor: true }),
    intelliHeat({}),
    {
        id: 'features',
        class: 'three-column-cards-w-image',
        blurb: 'Mitsubishi Electric Systems Are...',
        contentBoxes: [
            {
                title: 'Severe Weather-Proof',
                detailsText:
                    'Our high-efficiency heat pumps are ready for when extreme weather strikes. By being able to run on a portable invertor-driven generator, our mini-split systems will keep your family comfortable even when the power is out.',
                imgSrc: '/images/generator_house_simple_2022_final.svg',
                imgAlt: `${altText} product row`,
            },
            {
                title: 'Not Window Units',
                detailsText:
                    "Mitsubishi Electric ductless heating and cooling systems consist of an outdoor heat pump connected to an indoor unit mounted on your ceiling or wall. That means our mini-split units do not block windows and become a more stable - and attractive - fixture in your home, while also ensuring a peaceful night's sleep with their whisper-quiet operation.",
                imgSrc: '/images/not-a-window-unit.svg',
                imgAlt: `${altText} interior bedroom mini-split wall unit heat pump vs window unit comparison`,
            },
            {
                title: 'The Future of Comfort',
                detailsText:
                    'We are constantly thinking of ways to make all of your moments special. Comfort should be seamless and innovative, which is why we have features like our 3D i-see sensor to automatically adjust the output of the unit and ensure your desired temperature.',
                imgSrc: '/images/3D-i-see.webp',
                imgUrl: '',
                imgAlt: `${altText} father daughter mini-split living room clean healthy air`,
            },
        ],
        backgroundCol: 'site',
    },
    subscribe({ campaignId: hero.subscriberCampaignId }),
    support({ phone: buPhone, hours: buHours }),
    download({}),
]

const header = {
    pageTitle: 'Save Big and Stay Cozy in the Northeastern Cold: Rebates & Winter Performance',
    pageDesc:
        "Stay comfortable year round with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where exceptional comfort meets energy efficiency. Enter your zip code now to unlock local incentives!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    hours: buHours,
    ctaText: 'Get Connected',
    // ctaUrl: 'subscriber-form',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
