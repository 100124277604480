import altText from '../altText'
import lorem from '../lorem'

const fourColumn = {
  id: 'four-column',
  class: 'four-column',
  imgBg: true,
  title: 'four-column',
  columns: [
    {
      imgSrc: '/images/image-here.webp',
      imgAlt: `${altText}wall-mounted indoor mini-split`,
      title: lorem.title,
      text: lorem.blurb,
    },
    {
      imgSrc: '/images/image-here.webp',
      imgAlt: `${altText}recessed ceiling cassette indoor mini-split`,
      title: lorem.title,
      text: lorem.blurb,
    },
    {
      imgSrc: '/images/image-here.webp',
      imgAlt: `${altText}horizontal-ducted indoor mini-split`,
      title: lorem.title,
      text: lorem.blurb,
    },
    {
      imgSrc: '/images/image-here.webp',
      imgAlt: `${altText}floor-mounted indoor mini-split`,
      title: lorem.title,
      text: lorem.blurb,
    },
  ],
  ctaText: 'button jumps to form',
  backgroundCol: 'gray'
}

export default fourColumn