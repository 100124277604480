import altText from './altText'

export default function comfort({
    id = 'comfort',
    hasNavAnchor = false,
    title = 'Comfort',
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-left',
		hasNavAnchor: hasNavAnchor,
		title: title,
		blurb: 'For Everyone In Your Home',
		detailsText: 'You can make each room in your home the perfect temperature, since our systems focus on individual living spaces rather than treating every room the same. A system consists of at least one indoor unit and one outdoor unit. Indoor units come in a variety of elegant styles to meet your design needs. Our whisper-quiet outdoor units, or heat pumps, provide both heating and cooling even when it’s below zero. We have solutions for individual rooms or your entire home.',
		imgSrc: '/images/lifestyle/interior/RES-EZ_FIT_BEDROOM.webp',
		imgAlt: `${altText} interior bedroom family mini-split recessed ceiling unit heat pump`,
		backgroundCol: color
	}
}