import altText from './altText'

export default function kenza({
    id = 'kenza-cloud',
    hasNavAnchor = false,
    title = 'Kenza Cloud™',
    color = 'transparent'
}) {
	return {
        id: id,
        class: 'two-column-img-left no-padding-bottom',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'Monitor Up To 50 Indoor Units',
        detailsText: `kenza cloud is a control solution for light to medium commercial spaces where a central controller is not typically needed, but cloud access for monitoring and basic control is required. This streamlined controller uses a simple MNET direct-to-cloud architecture to monitor your CITY MULTI® equipment.`,
        imgSrc: '/images/new-products/kenza cloud_3.webp',
        imgAlt: `${altText} kenza cloud app on mobile tablet`,
        iconList: {
            title: 'Features + Benefits',
            items: [
                {
                    iconId: 'check',
                    text: 'Simple, easy, 4-step setup'
                },
                {
                    iconId: 'check',
                    text: 'Monitor your system from a desktop computer or tablet'
                },
                {
                    iconId: 'check',
                    text: 'Low-cost, monthly subscription-based service'
                },
                {
                    iconId: 'check',
                    text: 'Web-based, real-time system monitoring'
                },
            ]
        },
        backgroundCol: color
    }
}