const lorem = {
  title: 'Lorem ipsum',
  title2: 'Dolore et Labore',
  blurb: 'Quam id leo in vitae turpis massa sed elementum tempus',
  blurb2: 'Placerat vestibulum lectus mauris ultrices',
  one: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
  two: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero nunc consequat interdum varius sit amet mattis vulputate.',
  three: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tincidunt nunc pulvinar sapien et ligula ullamcorper. Dignissim suspendisse in est ante.',
  four: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Placerat vestibulum lectus mauris ultrices. Ut diam quam nulla porttitor massa id neque. Viverra tellus in hac habitasse platea dictumst vestibulum.',
  five: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam id leo in vitae turpis massa sed elementum tempus. Purus in massa tempor nec feugiat nisl. Risus at ultrices mi tempus imperdiet nulla. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat.',
  six: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi leo urna molestie at elementum eu facilisis. Pharetra massa massa ultricies mi quis hendrerit dolor magna eget. Elementum nisi quis eleifend quam adipiscing vitae. Tristique risus nec feugiat in fermentum posuere urna nec tincidunt. Venenatis tellus in metus vulputate.',
  seven: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et tortor at risus viverra. Sit amet nulla facilisi morbi tempus iaculis urna. Diam maecenas ultricies mi eget mauris pharetra. Dui vivamus arcu felis bibendum ut tristique et egestas. Cras semper auctor neque vitae tempus. Mauris ultrices eros in cursus turpis massa tincidunt dui.',
  eight: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu cursus euismod quis viverra. Adipiscing at in tellus integer. Bibendum ut tristique et egestas quis ipsum suspendisse. Nec ultrices dui sapien eget mi proin sed libero enim. Aliquet bibendum enim facilisis gravida neque convallis a. Aliquam ut porttitor leo a. Pellentesque dignissim enim sit amet venenatis.',
  nine: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius quam quisque id diam vel quam elementum. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Vitae suscipit tellus mauris a diam maecenas sed enim. In pellentesque massa placerat duis ultricies lacus sed turpis tincidunt. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Pellentesque habitant morbi tristique senectus et netus et malesuada. Neque aliquam vestibulum morbi blandit cursus risus at. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum.',
  ten: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempus urna et pharetra pharetra massa massa ultricies mi. Cras sed felis eget velit. Facilisis leo vel fringilla est. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Nunc id cursus metus aliquam eleifend mi in nulla posuere. In fermentum et sollicitudin ac orci. Ac turpis egestas sed tempus urna et. Vitae purus faucibus ornare suspendisse sed nisi lacus sed. Tellus integer feugiat scelerisque varius morbi enim nunc. Felis bibendum ut tristique et egestas. In pellentesque massa placerat duis ultricies lacus sed turpis tincidunt. Cursus turpis massa tincidunt dui ut ornare lectus sit amet.',
  eleven: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pulvinar etiam non quam lacus suspendisse faucibus. Nullam ac tortor vitae purus faucibus ornare. Ac ut consequat semper viverra nam. Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique. Integer enim neque volutpat ac tincidunt vitae semper. Nullam eget felis eget nunc lobortis mattis aliquam. Lacus viverra vitae congue eu consequat ac. Justo eget magna fermentum iaculis eu non diam. Vitae purus faucibus ornare suspendisse sed nisi. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Mattis vulputate enim nulla aliquet porttitor. Elit sed vulputate mi sit amet. Id donec ultrices tincidunt arcu non sodales neque sodales. Adipiscing at in tellus integer feugiat scelerisque. Duis at consectetur lorem donec massa sapien. Amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus. Consequat mauris nunc congue nisi vitae suscipit tellus mauris.',
  twelve: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in. Mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor. Sed cras ornare arcu dui vivamus. Massa tempor nec feugiat nisl pretium fusce id. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Et malesuada fames ac turpis. Quisque non tellus orci ac auctor augue mauris. In ante metus dictum at tempor commodo. Massa placerat duis ultricies lacus sed. Interdum consectetur libero id faucibus nisl tincidunt eget. Venenatis a condimentum vitae sapien pellentesque habitant morbi. Id semper risus in hendrerit gravida rutrum quisque non. Iaculis at erat pellentesque adipiscing commodo elit. Proin libero nunc consequat interdum varius sit amet mattis vulputate. Est lorem ipsum dolor sit amet consectetur adipiscing. Vitae ultricies leo integer malesuada. Rhoncus urna neque viverra justo nec ultrices. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Venenatis lectus magna fringilla urna porttitor rhoncus dolor. Lacus vel facilisis volutpat est velit egestas dui. Pellentesque elit ullamcorper dignissim cras tincidunt.',
}

export default lorem