export default function ThankYou(props) {
	return (
		<div id='thank-you-wrapper' className={props.className} style={{padding: 'var(--xl2)'}} >
			<h3 style={{textAlign:'center', marginTop: '0'}}>Thank You, {props.firstName}!</h3>
			<p style={{maxWidth:'600px', margin:'auto'}}>We've received your request for {props.company} to join the Mitsubishi Electric Trane pro Community.</p>
			<p style={{maxWidth:'600px', margin:'auto', marginTop:'var(--lg)'}}>After we've reviewed your information, a local representative in the {props.city} area will contact you with more information and provide next steps.</p>
			{/*Pixel Name: CONV - MITUS_21561_CONV - Pixel ID: 1506292 DO NOT MODIFY*/}
      		<img alt='pixel' style={{display:'none', width:'1px'}} src="https://secure.adnxs.com/px?id=1506292&t=2" width="1" height="1" />
		</div>
	)
}