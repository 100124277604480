import { numWithCommas, log } from "./helpers"
import { fetchEcoRebates } from "./queries"
import { defaults } from "../components/page content/campaigns/_defaults"

/*
EcoRebates Tracking:
uiContext=PLP
PLP: product list
PDP: product details
*/

export const findEcoRebates = async (zip, preferredProduct) => {
	const finalRebate = { footnotes: []}
	const rebates = await fetchEcoRebates(zip)
	const allRebates = rebates.productRebateDetails
	let utilityRebateRecord = allRebates[0]

	// if BU has a specific product to show, search all rebates for sku, and replace rebate record
	if (preferredProduct) {
		const skuToFind = preferredProduct
		const foundRecord = allRebates.find((rebate) => rebate.product.displaySku.includes(skuToFind))
		foundRecord && (utilityRebateRecord = foundRecord)
	}
	finalRebate.foundProduct = utilityRebateRecord.product.displaySku
	
	const maxAmount = utilityRebateRecord.maxRebate.amount
	const ids = utilityRebateRecord.maxRebate.programIds

	ids.forEach((id) => {
		let programDetails
		
		const rebate = utilityRebateRecord.rebatePrograms.find((program) => program.id === id)
		console.log('FOUND REBATE:', rebate)
		const amount = rebate.rebateLimit ? rebate.rebateLimit : rebate.purchaseRebate.amount
		const provider = rebate.name
		const type = rebate.offerTypes[0] === 'residential' ? 'Utility rebate' : 'Tax credit'
		
		rebate.importantDetails.forEach((detail) => {
			detail = detail.trim()
			const lastChar = detail.charAt(detail.length-1)
			lastChar !== '.' && (detail = `${detail}.`)
			!programDetails 
				? (programDetails = `${detail}`) 
				: (programDetails = `${programDetails} ${detail}`)
		})

		const currentYear = new Date().getFullYear()

		const taxCreditMessage = (type === 'Tax credit') ? (` for ${currentYear} tax year (filed in ${currentYear+1})`) : ''

		finalRebate['footnotes'].push(
			`${type}${taxCreditMessage} of up to $${numWithCommas(amount)} offered ${provider.includes('Federal') ? 'as a' : 'through'} ${provider}. ${programDetails}`
		)
	})
	finalRebate.amount = maxAmount
	console.log('final utility Rebate:', finalRebate)
	return finalRebate
}

export const findInstantRebates = (userZip, state, staticCampaign, pageCampaignId) => {
	let foundRebateZips, foundRebateAmount, foundRebateTerms, foundRebateCampaign, finalRebate = {}
	// if state has multiple BU regions (like Virginia),
	// search regions for zip code, 
	// then set rebate data from REGION level
	if (state.regions) {
		let foundRegion = state.regions.find((region) => region.zips.includes('all') || region.zips.includes(userZip))
		
		// no rebates available for that zip, so break out of function
		if (!foundRegion) return log(`no region with instant rebate in ${userZip}`)

		foundRebateZips = foundRegion.zips
		foundRebateCampaign = foundRegion.bu
		foundRebateAmount = foundRegion.amount
		foundRebateTerms = foundRegion.footnote
	// otherwise set rebate data from STATE level
	} else {
		console.log('update instant rebate data')
		foundRebateZips = state.zips
		foundRebateCampaign = state.bu
		foundRebateAmount = state.amount
		foundRebateTerms = state.footnote
	}

	const foundRebateCampaignId = foundRebateCampaign.leadCampaignId
	log('static campaign?', staticCampaign ? 'YES' : 'NO')

	// ONLY reassign campaign ID if:
	// --> page's campaign is NOT set to static, OR
	// --> found rebate's campaign is different from page's current campaign
	if (!staticCampaign && foundRebateCampaignId !== pageCampaignId) {
		log('found local campaign:', foundRebateCampaign)
		if (foundRebateCampaign.noOutsideLeads) {
			log(`${foundRebateCampaign.bu} requested no outside leads, reassign to Comfort Master:`, defaults.leadCampaignId)
			finalRebate.campaignId = defaults.leadCampaignId
		} else {
			log(`reassigning lead to local ${foundRebateCampaign.bu} campaign:`, foundRebateCampaign.leadCampaignId)
			finalRebate.campaignId = foundRebateCampaign.leadCampaignId
		}
	}

	// search state zips field for 'all' or user zip
	if (foundRebateZips.includes('all') || foundRebateZips.includes(userZip)) {
		// log(`found user zip, ${userZip}, in participating zips, ${foundRebateZips}`)
		finalRebate.amount = foundRebateCampaign.isRunning ? foundRebateAmount : 0
		// log(`${state.name} ${state.bu.data.name.toUpperCase()} campaign, ${foundRebateCampaign.leadCampaignId} IS${!foundRebateCampaign.isRunning ? ' NOT' : ''} running`)
		finalRebate.footnote = foundRebateTerms
		finalRebate.amount > 0 && (finalRebate.campaignId = foundRebateCampaign.leadCampaignId)
	} else {
		log(`no participating zips found`)
		finalRebate.amount = 0
	}
	console.log('final instant Rebate:', finalRebate)
	return finalRebate
}
