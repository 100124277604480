const buPhone = '123-456-7890'

const hero = {
	class: 'hero',
	form: 'standard',
	sandbox: true,
	title: 'Sandbox Test',
	blurb: '',
	detailsText: '',
	ctaText: '',
	ctaUrl: 'form-section',
	footnote: '',
    formTitle: 'Leads Testing',
	formInstructions: 'Test leads submitted via this form should be visible in sandbox MEUSHVACP1.',
	whatsNext: '',
	backgroundSrc: '/images/lifestyle/interior/construction-2000px.webp',
	leadCampaignId: '7013q000002NtISAA0',
	subscriberCampaignId: '',
	// subscriberCta: 'Download Our Brochure',
  	hideContractorNumSelector: false,
	hideTimeframeSelector: false,
	hideContactMethodSelector: false,
	hideLocationSelectors: false,
	hideSystemSelector: false,
	hideCommunicationsSelector: false,
	hideDownArrow: false,
}

const header = {
	phone: buPhone,
	ctaText: '',
	ctaUrl: 'form-section',
	// pageSections: sections.map((section) => section),
}

export { header, hero }
