import altText from './altText'

const rebates = {
	iconSrc: 'sale-discount-rebate',
	iconAlt: `${altText} rebates and tax incentives`,
	blurb: 'Utility Rebates',
	detailsText: 'View local rebates that can help offset the cost of your new high efficiency HVAC system.',
	ctaText: 'Explore Rebates',
	ctaUrl: 'https://www.mitsubishicomfort.com/rebates'
}

export default rebates