import Header from './Header'
import HeroSection from './HeroSection'
import Footer from './Footer'
import Section from './Section'
import SectionProduct from './SectionProduct'
import NavBu from './NavBu'
import {header, hero, sections, products} from './page content/pageNebuWebinar2023Content'

export default function PageNebuWebinar2023() {		
	return (
		<>
			<Header
				pageTitle={header.pageTitle}
				pageDesc={header.pageDesc}
				pageImg={header.pageImg}
				phone={header.phone}
				hours={header.hours}
				ctaText={header.ctaText}
				ctaUrl={header.ctaUrl}
				sections={header.pageSections}
			/>
			<main>
				<HeroSection content={hero} />
				{sections.map((section, index) =>
					<Section
						key={index+section.id}
						content={section}
					/>
				)}
				{ products.map((section) => 
					<SectionProduct 
						key={section.id}
						id={section.id}
						className={section.class}
						title={section.title}
						products={section.products}
					/>
				)}
			</main>
			<NavBu
				id='mobile-nav'
				sections={header.pageSections}
			/>
			<Footer />
		</>
	)
}