import altText from './static-content/altText'
import heat2O from './static-content/heat2O'
import hybridVrf from './static-content/hybridVrf'
import productRange from './static-content/product-range'
import innovativeProducts from './static-content/innovative-products'
// import download from './static-content/download'
// import lorem from './static-content/lorem'

const vertical = 'Senior Living'
const mainImg = '/images/lifestyle/exterior/senior-couple.webp'

const hero = {
	class: 'two-column-img-left dark-mode',
	backgroundSrc: mainImg,
	title: `Seniors Deserve Comfort`,
	blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
	detailsText: 'No matter whether your patients are short-term or permanent residents, keeping them comfortable and their air clean is critical to their quality of life. VRF can help achieve both goals.',
}

const sideBar = [
	{
		vertical: vertical,
		imgSrc: '/images/randall-towb-headshot.webp',
		firstName: 'Randall',
		lastName: 'Towb',
		jobTitle: 'Sr. Manager, Business Development, Senior Living',
		phone: '(469) 275-6846',
		email: 'rtowb@hvac.mea.com',
		linkedIn: 'https://www.linkedin.com/in/randall-towb-36307620/'
	}
]

const sections = [
	{
		id: 'Download',
		class: 'download-section',
		blurb: 'Learn more about how Mitsubishi Electric Trane HVAC US systems can help you keep residents and patients comfortable.',
		imgSrc: '/images/seniors-brochure-placeholder.webp',
		imgUrl: '/assisted-living-brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: 'gray',
	},
	{
		id: 'Why VRF?',
		class: 'one-column-list',
		hasNavAnchor: true,
		title: `Why Use VRF In ${vertical}?`,
		listItems: [
			{
				iconId: 'money',
				title: 'Lower Lifecycle Cost',
				description: 'With ease of design and installation, minimal required maintenance, superior energy efficiency, and long-lasting equipment, your client\'s total lifecycle costs are less than they might have thought.'
			},
			{
				iconId: 'fresh-air-intake',
				title: 'Outside Air and Ventilation',
				description: 'Energy recovery ventilators (ERVs) exhaust outside air to rid your building of toxins, odors and viruses, bacteria and other potentially harmful contaminants. They also improve HVAC system efficiency by preheating or cooling incoming outside air with available energy from the exhaust air.'
			},
			{
				iconId: 'thermostat',
				title: 'Precision Control',
				description: 'Precise zoning with independent control gives patients, residents and staff control over their own comfort. Indoor units can sense temperature, humidity, lighting, and occupancy to improve comfort control and system efficiency.'
			},
			{
				iconId: 'sustainable',
				title: 'Quiet Operation',
				description: 'Even though your patients, residents, and staff will barely hear them, Mitsubishi Electric Trane HVAC US VRF systems are working hard to maintain the ideal comfort conditions. Indoor units are whisper quiet and outdoor units operate at levels as low as 58 dB(A).'
			},
		],
		backgroundCol: 'gray'
	},
	innovativeProducts({ hasNavAnchor: true, }),
	hybridVrf({}),
	heat2O({}),
	{
		id: 'projects',
		class: 'map',
		hasNavAnchor: true,
		title: `${vertical} Projects`,
		detailsText: `Randall and his team have provided support on many Senior and Assisted Living projects across the United States. See a mapping below of projects using Mitsubishi Electric Trane HVAC products in ${vertical} applications.`,
		data: 'senior-living-projects',
		options: {
            centerCoords: {lat: 38.501001, lng: -97.357053},
            zoomLevel: 4,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
			clustering: true
        }
	},
	productRange({}),
	{
		id: 'case-studies',
		class: 'three-column-cards-w-image',
		hasNavAnchor: true,
		title: 'Case Studies',
		detailsText: `Review some of our recent case studies to see how Mitsubishi Electric HVAC products are benefitting owners, facility managers, and residents in the senior assisted living industry.`,
		contentBoxes: [
			{
				title: 'Aljoya Mercer Island',
				blurb: 'Seattle, Washington',
				detailsText: `"Residents were given the flexibility to change their floor plans during construction. With the outdoor unit already sized, the indoor air handlers could be easily upsized to accommodate a larger living space than originally intended."`,
				quoteSource: 'Bill Stanley, Facility Manager for Aljoya',
				imgSrc: '/images/aljoya_hero.webp',
				imgAlt: `${altText} Aljoya senior assisted living building exterior`,
				// ctaText: `Download Case Study`,
				// downloadUrl: '/Case Study_Aljoya House.pdf'
			},
			{
				title: 'Capitol Seniors Housing',
				blurb: 'NY, NJ, IL, MA, and MD',
				detailsText: '"Improved comfort, low operating costs and support from Mitsubishi Electric and their Diamond Commercial Contractors are clear positives."',
				quoteSource: 'Jim May, Director of Construction, Capitol Seniors Housing',
				imgSrc: '/images/capitol_seniors_housing.webp',
				imgAlt: `${altText} Capitol Seniors Housing senior assisted living building exterior`,
				// ctaText: `Download Case Study`,
				// downloadUrl: '/_____________.pdf'
			},
			{
				title: 'Claiborne at Shoe Creek',
				blurb: 'Central, Louisiana',
				detailsText: `Whisper quiet HVAC systems from Mitsubishi Electric Trane HVAC were installed throughout the luxurious Claiborne senior living community building, blending seamlessly into a tranquil indoor environment. Our systems also reduce cost by heating or cooling only the spaces in use.`,
				imgSrc: '/images/clairborne_hero.webp',
				imgAlt: `${altText} Claiborne senior assisted living building exterior`,
				// ctaText: `Download Case Study`,
				// downloadUrl: '/Case Study_Claiborne at Shoe Creek_v2.pdf'
			},
		],
		backgroundCol: 'gray',
	},
]

const header = {
	pageTitle: 'HVAC Solutions For Senior Living Applications',
	pageDesc: 'No matter whether your patients are short-term or permanent residents, keeping them comfortable and their air clean is critical to their quality of life. VRF can help achieve both goals.',
    pageImg: mainImg,
	class: 'side-bar dark-mode-header',
	branding: 'metus',
	pageSections: sections.map((section) => section),
	accentCol: 'blue',
	vertical: vertical,
}

export { header, sideBar, hero, sections }
