import altText from './static-content/altText'
import { defaults as bu } from './campaigns/_defaults'
import designedForLife from './static-content/designedForLife'
import smartMulti from './static-content/smartMulti'

// const buPhone = '866-419-2513'

const hero = {
    class: 'two-column-img-left dark-mode header--margin-fix',
    staticCampaign: true,
    blurb: `HVAC Solutions For Light Commercial Applications`,
    detailsText:
        "Mitsubishi Electric's all-climate heat pump HVAC technology is an optimal solution for Light Commercial applications, offering individual zoning for tailored comfort and efficient operation for cost savings.",
    formInstructions: bu.formInstructions,
    backgroundSrc: '/images/me_gs_doctors_office.webp',
    subscriberCampaignId: bu.subscriberCampaignId,
    hideDownArrow: false,
}

const sections = [
    smartMulti({ hasNavAnchor: true, }),
    {
        id: 'city-multi-connections',
        class: 'two-column-img-left pad-y-lg',
        title: 'CITY MULTI',
        blurb: 'System Connections',
        detailsText:
            'Three-phase outdoor units can be connected to up to 30 indoor units.',
        imgSrc: '/images/CM_three-phase.webp',
        imgAlt: `${altText} interior bedroom mini-split recessed ceiling unit heating and cooling`,
        //ctaText: 'Watch Video',
        //videoSrc: 'skDqnK0JC-A',
        backgroundCol: 'red',
    },
    {
        id: 'three-phase-connections',
        class: 'two-column-img-left pad-y-lg',
        title: 'M- and P-Series',
        blurb: 'System Connections',
        detailsText:
            'Up to 12 indoor units can be connect to three-phase outdoor units via branch boxes.',
        imgSrc: '/images/MP_three-phase.webp',
        imgAlt: `${altText} interior bedroom mini-split recessed ceiling unit heating and cooling`,
        //ctaText: 'Watch Video',
        //videoSrc: 'skDqnK0JC-A',
        backgroundCol: 'green',
    },
    {
        id: `why-three-phase-multi-zone`,
        class: 'one-column-list',
        title: `Why Three-Phase Multi-Zone?`,
        listItems: [
            {
                iconId: 'thermostat',
                title: 'Proven Technology',
                description:
                    "Mitsubishi Electric's all-climate heat pump technologies, employed globally for decades, have consistently demonstrated a reduction in overall operating costs compared to traditional heating and air conditioning systems.",
            },
            {
                iconId: 'light-commercial',
                title: 'Zoned Comfort in an Office Setting',
                description:
                    'Multi-zone units provide temperature management of multiple zones within an office space, retail space, restaurant space, multi family unit, religious buildings, or any light commercial application. This function eliminates hot and cold spots in the in the indoor environment and allows for energy to be used only in the zones that require it.',
            },
            {
                iconId: 'sustainable',
                title: 'Improved Sustainability',
                description:
                    'We promote environmental sustainability through the electrification of commercial heating and cooling products. We continue to advance technologies that reduce waste, promote sustainable resources, while increasing energy efficiency and eliminating dependence on fossil fuels. We have an ongoing commitment to improving energy efficiency in all of our operations.',
            },
            {
                iconId: 'energy',
                title: 'Reduced Footprint and Costs',
                description:
                    'Our all-electric, all-climate heat pump systems do not need gas lines, flue vents, or fire dampers, simplifying installation and maintenance. Reduced electrical switch gear and wiring complexity also makes for a more straightforward setup. These improvements all result in a reduced HVAC footprint with higher safety and savings in the long term.',
            },
        ],
        backgroundCol: 'transparent',
    },
    {
		id: 'applications',
		class: 'four-column',
        hasNavAnchor: true,
		title: 'Three-phase Applications',
		columns: [
			{
				imgSrc: '/images/lifestyle/interior/CS_Asbury_United_Methodist_05.webp',
				imgAlt: `${altText}application: religious building interior`,
				title: 'Places of Worship'
			},
			{
				imgSrc: '/images/lifestyle/interior/ME_PFFY_CM_Lifestyle_PFFY_Front desk.webp',
				imgAlt: `${altText}application: religious building interior`,
				title: 'Office Spaces'
			},
            {
				imgSrc: '/images/lifestyle/interior/TR_TPCFYP_PCFY-Trane Mech-Industrial kitchen.webp',
				imgAlt: `${altText}application: religious building interior`,
				title: 'Kitchens/Restaurants'
			},
            {
				imgSrc: '/images/lifestyle/interior/TR_TPKA_CM_PKA_round_Est-clinic.webp',
				imgAlt: `${altText}application: religious building interior`,
				title: 'Medical Offices'
			},
		],
		backgroundCol: 'transparent'
	},
    {
		id: 'case-studies',
		class: 'three-column-cards-w-image',
        hasNavAnchor: true,
		title: 'Case Studies',
		detailsText: `Review some of our most recent case studies to see how Mitsubishi Electric's all-climate heat pump technology is benefitting real-world light commercial applications.`,
		contentBoxes: [
			{
				title: 'Drummond Medical Center',
				blurb: 'Drummond Island, Michigan',
				detailsText: `“It was important for us to be able to turn the temperature down or up in individual rooms. The dentist is here just two times a month, so there’s no need to heat her office if she’s not there.”`,
                quoteSource: 'Anne Stadler, chairman of the Medical Center Board',
				imgSrc: '/images/Drummond Medical Center_1260x510.webp',
				imgAlt: `${altText} Drummond Medical Center building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			{
				title: 'Hollis Montessori School',
				blurb: 'Hollis, New Hampshire',
				detailsText: `"The availability of hyper-heating was really important given the rough northeast winters. We also like the track record of performance and reliability with Mitsubishi Electric... [and] wouldn’t need backup heat."`,
                quoteSource: 'Jordan Goldman, principal, ZeroEnergy Design',
				imgSrc: '/images/Zero Energy Hollis school 5 13_0822.webp',
				imgAlt: `${altText} Hollis Montessori School building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			{
				title: 'The Inn at Willow Grove',
				blurb: 'Orange, Virginia',
				detailsText: `"It’s a boutique hotel and historic place... [the owners] wanted a heating and cooling system where you could not see the equipment... [and] the ability for individual temperature control in each room."`,
                quoteSource: 'Joe Smith, installing mechanical contractor, Duct-Rite',
				imgSrc: '/images/CS_TheInnAtWillowGrove_15_1250x510.webp',
				imgAlt: `${altText} Inn at Willow Grove building exterior`,
				ctaText: ``,
				downloadUrl: ''
			},
			
		],
		backgroundCol: 'gray',
	},
    designedForLife({}),
]

const header = {
    // phone: buPhone,
    pageTitle: 'Mitsubishi Electric Heat Pumps - Light Commercial',
    pageDesc: 'Stay up to date and discover how our high-efficiency heat pumps are improving the way we live and the communities we live in.',
    pageImg: '/images/discover-site-preview.webp',
    ctaUrl: null,
    ctaText: null,
    noCta: true,
    pageSections: sections.map((section) => section),
    class: 'dark-mode-header',
    branding: 'metus',
}

export { header, hero, sections }
