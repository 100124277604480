import Button from "./modular/Button"

export default function Tabs({ iframe, tabs, tabSelected, handleTabClick, children }) {
	const sectionStyle = () => {
		if (iframe) return {
			marginTop: '0', 
			borderTopLeftRadius:'0', 
			borderTopRightRadius:'0'
		}
	}
	return (
		<section 
			className='tabs' 
			style={sectionStyle()}
		>
			<nav>
				{tabs.map((tab, index) =>
					<Button
						key={index}
						id={index}
						className={tab.id === tabSelected.id ? `btn-tab selected` : `btn-tab`}
						onClick={handleTabClick}
						text={tab.id.toUpperCase()}
					/>
				)}
			</nav>
			{ children }
		</section>
	)
}