import altText from './static-content/altText'
import contractor from './static-content/contractor'

const buPhone = '888-495-0283'
const mainImg = '/images/image-needed.webp'

const hero = {
	class: 'two-column-img-right',
	blurb: 'Free for Diamond Contractors®',
	title: 'Streamline Your Jobs with Dispatch',
	detailsText: 'Mitsubishi Electric Trane has partnered with Dispatch to allow you to integrate your job management tool and automate your workflow.',
	backgroundCol: 'dispatch-gray',
	// has unique parent campaign ID: 7013q000002F8HZAA0
	// for testing only: 7013q000002FFyyAAG
	leadCampaignId: '7013q000002F8HZAA0',
	// but uses mabu subscriber ID
	subscriberCampaignId: '7013q000001kmXZAAY',
	ctaText: 'Get Started',
	ctaUrl: 'form-section',
	imgSrc: mainImg,
	imgAlt: `${altText}professional contractor installation exterior heat pump unit`
}

const sections = [
	{
		id: 'why-dispatch',
		class: 'one-column-list',
		title: 'Why Dispatch?',
		listItems: [
			{
				title: 'Spend Less Time on the Phone',
				description: 'Using status updates to your jobs from the warranties or brands you work with can help reduce back and forth status calls about your jobs.'
			},
			{
				title: 'Manage all Your Work in One Place',
				description: 'You can use Dispatch to schedule and manage your own retail work. No more updating jobs across multiple portals and tools! You can see your entire schedule including both your retail and enterprise work, increasing efficiency of your operations.'
			},
			{
				title: 'Keep Tabs on Your Team in the Field',
				description: 'Track and locate all of your team members in the field who are using the Dispatch Field mobile app, right on the Schedule page on the Dispatch web app.'
			},
			{
				title: 'Build a Better Brand',
				description: 'When a job is marked as ‘Complete’ in Dispatch, the customer automatically receives a survey to rate and review their experience. Once that survey is submitted, you’ll immediately be notified in real-time which gives you the opportunity to mitigate and remediate any bad reviews.'
			},
			{
				title: 'A Tool That’s Free',
				description: 'Dispatch is valued at $1200+/year. Because Mitsubishi Electric has partnered with Dispatch, we\'re covering all of the costs to use our tools! That means you can use Dispatch for free.'
			},
            {
				title: 'Paperwork Reduction',
				description: 'Stay on task by uploading notes and photos from the field. Utilize our paperless billing and never worry about losing another paper invoice again!'
			},
            {
				title: 'Customer Context',
				description: 'Field staff can view customer history and previous work done on-site before their appointments, giving them more context and sense of preparation when entering a customer’s home.'
			},
		],
		backgroundCol: 'gray',
	},
	{
		id: 'form-section',
		class: 'two-column-img-right form dark',
		form:'dispatch',
		leadCampaignId: hero.leadCampaignId,
		blurb: 'Electric Solutions, Exclusive Rewards',
		detailsText: 'Take advantage of our hands-on training courses, from beginner to advanced. Receive world-class marketing support and presence on our MitsubishiComfort.com dealer locator. Join our lead generation and consumer financing programs and utilize our in-home selling tools. Gain access to our premier points program featuring a varied catalog of merchandise, event tickets, and travel options.',
		backgroundCol: 'blue-dp',
		subBlurb: {
			title: 'Ready to get started?',
			listItems: [
				{
					text: 'Step 1. Find your company'
				},
				{
					text: 'Step 2. Enter your contractor pin'
				},
				{
					text: 'Step 3. Follow instructions for integrating with Dispatch'
				},
			]
		}
	},

]

const header = {
    accentCol: 'dispatch-green',
	pageTitle: 'Join the Mitsubishi Electric HVAC Contractor Community',
	pageDesc: 'The demand for our all-electric heat pumps has never been greater. So there is no better time to join our community of premier contractors and grow your business.',
	pageImg: mainImg,
	class: 'dark-mode-header',
	branding: 'metus',
	phone: buPhone,
	ctaUrl: hero.ctaUrl,
	pageSections: sections.map((section) => section),
}

export { header, hero, sections }
