import ScrollLink from './modular/ScrollLink'

export default function PanelHero({ content, panelClass }) {
	// console.log('-------> panelHero content:', content)
	return (
		<div className={panelClass}>
			{content.panelClass === 'shard-panel' &&
				<div className='glass-panel shard'></div>
			}
			<div className='panel-content'>
				{content.title && <h1>{content.title}</h1>}
				{content.blurb &&
					<h2 className='h4'>
						{content.blurb}
						{content.footnote && <div className='superscript'>1</div>}
					</h2>
				}

				{content.detailsText &&
					<h3 className='h6' style={{paddingBottom: 'var(--lg)'}}>
						{content.detailsText}
						{content.footnote && <div className='superscript'>1</div>}
					</h3>
				}

				{content.ctaText &&
					<ScrollLink
						to={`#${content.ctaUrl}`}
						className='btn'
						style={{textTransform:'capitalize'}}
					>
						{content.ctaText}
					</ScrollLink>
				}

				{content.subscriberCta &&
					<ScrollLink
						to={`#Subscribe`}
						className='simple-link'
						style={{marginTop:'var(--lg)', placeContent: 'unset'}}
					>
						or {content.subscriberCta}
					</ScrollLink>
				}
			</div>
		</div>
	)
}