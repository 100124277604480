export default function Download(props) {
	const getClass = () => props.class.includes('contractors') ? 'contractors' : null
	return (
		<a target='_blank' rel='noreferrer' className={getClass()} href={props.imgUrl} >
			<img
				className={getClass()}
				style={{ backgroundColor:'transparent' }}
				src={props.imgSrc}
				alt={props.imgAlt}
			/>
		</a>
	)
}