import { useState } from 'react'
import useBranding from '../hooks/useBranding'
import HeaderFrame from './HeaderFrame'
import Tabs from './Tabs'
import TabContentSlider from './TabContentSlider'
import TabContentProducts from './TabContentProducts'

export default function SectionTabs({ id, tabs, iframe, title, blurb, containImg }) {
	const brand = useBranding()
	const [tabSelected, setTabSelected] = useState(tabs[0])

	const handleTabClick = (e) => setTabSelected(tabs[e.target.id])

	return (
        <section id={id} className={`pad-0 mar-y-lg section${iframe ? ' iframe' : ''}`}>
            {iframe && <HeaderFrame logo={brand} title={title} />}
            {!iframe && title && 
                <h2 className='center mar-top-xl mar-btm-0 pad-top-xl'>
                    {title}
                </h2>
            }
            {!iframe && blurb && 
                <h3 className='h5 center'>
                    {blurb}
                </h3>
            }
            <Tabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTabClick={handleTabClick}
                iframe={iframe}
            >
                {tabs[0].slider &&
                    <TabContentSlider
                        brand={brand}
                        containImg={containImg}
                        tabSelected={tabSelected}
                    />
                }
                {tabs[0].products && 
                    <TabContentProducts 
                        brand={brand}
                        tabSelected={tabSelected}
                    />
                }
            </Tabs>
        </section>
	)
}