export default function getUserDevice() {
    const userAgent = navigator.userAgent || window.opera
    let isMobileDevice = false
    let userDevice = 'Unknown Device'
    console.log(' ')
    console.log('-------------> userAgent:', userAgent)
    console.log(' ')
    // Android
    if (/android/i.test(userAgent)) {
        isMobileDevice = true
        userDevice = 'Android Device'
    }

    // iOS - iPhone/iPod/iPad
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        isMobileDevice = true
        userDevice = /iPad/.test(userAgent) ? 'iPad' : /iPhone/.test(userAgent) ? 'iPhone' : 'iPod'
    }

    // Windows Phone
    if (/windows phone/i.test(userAgent)) {
        isMobileDevice = true
        userDevice = 'Windows Phone'
    }

    // Windows OS
    if (/Windows NT/.test(userAgent)) {
        isMobileDevice = false
        userDevice = 'Windows PC'
    }

    // Mac OS
    if (/Mac OS X/.test(userAgent)) {
        isMobileDevice = false
        userDevice = 'Mac OS Device'
    }

    // Linux
    if (/Linux/.test(userAgent)) {
        isMobileDevice = false
        userDevice = 'Linux Device'
    }

    return {
        userDevice,
        isMobileDevice,
    }
}
