import { createTitle } from "./helpers"
import { indoorUnitButtons } from "./productDataGenerator"

export const getFeatures = (products) => {
	let newFeaturesArr = []
	const includedFeatures = new Set([
		'heating_type', 
		'zones', 
		'ducted', 
		'cu_size', 
		'max_height_diff_ft', 
		'cooling_min', 
		'heating_min',
		'compatible_indoor_unit_types'
	])

	const getFeatureOptions = (feature) => {
		let allOptionsSet = new Set()
		products
			.filter(product => product.attributes[feature] !== null)
			.forEach((product) => {
				product = product.attributes

				// handle compatible indoor units
				if (typeof product[feature] === 'object') {
					product[feature].forEach(option => {
						allOptionsSet.add(option.label)
					})
					return
				}
				
				// handle all fields except compatible indoor units
				if (product[feature] !== 'n/a') {
					allOptionsSet.add(product[feature])
				}
			})

		// sort numerical options ascending
		let allOptions = Array.from(allOptionsSet)
		allOptions.sort((a, b) => a - b)

		if (feature.includes('indoor')) {
			const orderMap = new Map()
			indoorUnitButtons.forEach(btn => {
				orderMap.set(btn.label, btn.order)
			})

			allOptions.sort((a, b) => {
				return orderMap.get(a) - orderMap.get(b)
			})
		}

		return allOptions
	}

	Object.keys(products[0].attributes).forEach(feature => {
		if (includedFeatures.has(feature)) {
			const newFeature = {
				feature: feature,
				title: createTitle(feature),
				options: getFeatureOptions(feature),
			}
			newFeaturesArr.push(newFeature)
		}
	})
	return newFeaturesArr
}
