import { useState, useEffect } from "react"
import testProjects from "./page content/static-content/test-projects"
import MapBu from "./modular/MapBu"
import { fetchLocalData } from '../lib/queries'

export default function SectionMapProjects({ vertical, projects, selectedRegion, handleClick }) {
	const [allProjects, setAllProjects] = useState(null)
	const [projectsToDisplay, setProjectsToDisplay] = useState(null)
	
	// parse projects data
	useEffect(() => {
		const getProjectsData = async() => {
			if (projects) {
				const projectsData = await fetchLocalData(`${projects}.json`)
				// console.log('projectsData:', projectsData)
				return setAllProjects(projectsData)
			}
			setAllProjects(testProjects)
		}
		getProjectsData()
	}, [projects])

	// set projects based on region selected
	useEffect(() => {
		if (selectedRegion) {
			console.log(allProjects[selectedRegion.id])
			setProjectsToDisplay(allProjects[selectedRegion.id])
		}
	}, [allProjects, selectedRegion])

	const qt = (source) => source ? '"' : ''

	return (
		<div className="map-projects-wrapper">
			<div className='projects-wrapper dark-mode'>
			{selectedRegion && projectsToDisplay 
				?
					<>
						<h4 style={{paddingBottom: 'var(--spc-sm-h)', marginBottom: 'var(--spc-md-h)', borderBottom: '1px solid white'}}>{selectedRegion.name} Region</h4>
						<h4 className='h5' style={{fontFamily: 'var(--main-font-bold)', margin:'0 auto'}}>Featured Project:</h4>
						{projectsToDisplay.map((project, i) => 
							<div key={`${i}-${project.name}`} style={{margin: 'var(--spc-md-h) 0'}}>
								{project.image && 
									<img style={{maxHeight: 'calc(var(--spc-xl3-h)*1.2)', objectFit:'cover'}} src={project.image} alt={project.name}/>
								}
								<h5>{project.name}</h5>
								{project.location && 
									<h6>{project.location}</h6>
								}
								{project.summary && 
									<p>
										{qt(project.source)}{project.summary}{qt(project.source)}
									</p>
								}
								{project.source && 
									<p>
										- {project.source}
									</p>
								}
								{project.url && 
									<a href={project.url} target='_blank' rel="noreferrer">
										visit website
									</a>
								}
							</div>
						)}
					</>
				: 
					<>
						<h4>Select A Region</h4>
						<p>Click a region on the map to view a featured project using Mitsubishi Electric HVAC products in the {vertical} industry.</p>
					</>
			}
			</div>
			<MapBu 
				vertical={vertical} 
				handleClick={handleClick} 
				allProjects={allProjects} 
				selectedRegion={selectedRegion?.id} 
			/>
		</div>
		
	)
}