import Header from './Header'
import HeroSection from './HeroSection'
import Footer from './Footer'
import Section from './Section'
import LeadForm from './LeadFormStd'

export default function PageFormTest() {
    const sectionContent = {
        id: 'Subscribe',
        class: 'download-section subscribe',
        blurb: 'Stay up to date and discover how our high-efficiency heat pumps are improving the way we live and the communities we live in.',
        imgSrc: '/images/brochure-download-poster.webp',
        imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
        imgAlt: 'click to download our brochure',
        backgroundCol: 'gray',
    }
    return (
        <>
            <Header phone="123-456-7890" ctaText="Form Testing" ctaUrl="Testing Forms" />
            <main>
                <HeroSection
                    content={{
                        sandbox: true,
                        title: 'Form Testing',
                        form: 'test',
                        test: true,
                        class: 'hero',
                        leadCampaignId: '70131000001yHC6AAM',
                        backgroundSrc: '/images/lifestyle/exterior/modern-house-ext.webp',
                    }}
                />
                <Section content={sectionContent} />
                <section style={{ placeContent: 'center', background: 'var(--bg-gradient-gray)' }} id="form-section">
                    {/* <LeadForm
						formClass='standard-form-wrapper'
						leadCampaignId='70131000001yHC6AAM'
						GTMTrigger='bottom_form'
					/> */}
                </section>
            </main>
            <Footer />
        </>
    )
}
