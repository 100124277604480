import { useState, useEffect } from 'react'
import { handleDetail, parseSymbols } from '../../lib/helpers'
import { fetchIpData, fetchEcoRebates } from '../../lib/queries'
import InputText from './InputText'
import Button from './Button'

export default function RebateFinder(props) {
    const [userZip, setUserZip] = useState(props.userZip)
    const [rebateZip, setRebateZip] = useState(null)
    const [productRebates, setProductRebates] = useState([])
    const [gotProductRebates, setGotProductRebates] = useState(false)
    const [rebateTitle, setRebateTitle] = useState('Search For Rebates & Tax Credits')
    
    useEffect(() => {
        const getZipAndSearch = async() => {
            let zip = props.userZip
            if (!zip) {
                const ipData = await fetchIpData()
                if (ipData.postal && ipData.postal !== '') {
                    zip = ipData.postal
                    setUserZip(zip)
                }
            }
            runRebateSearch(zip)
        }
        getZipAndSearch()
    }, [props.userZip])

    const runRebateSearch = async(zip) => {
        const productRebateData = await fetchEcoRebates(zip)

        if (productRebateData.productRebateDetails[0]) {
            const productRebatePrograms = productRebateData.productRebateDetails
            setProductRebates(productRebatePrograms)
            setRebateTitle(`Showing ${productRebatePrograms.length} Rebate${productRebatePrograms[1] && 's'}/Tax Credit${productRebatePrograms[1] && 's'} in ${zip}`)
            setGotProductRebates(true)
        }
        setRebateZip(zip)
    }   

    const handleChange = (e) => setUserZip(e.target.value)
    const handleSubmit = (e) => {
        e.preventDefault()
        runRebateSearch(userZip)
    }

    const handleClick = (e) => {
        const id = e.target.id
        const rebateList = document.getElementById(`rebate ${id}`)
        const currentClass = rebateList.classList[1]
        let newClass
        switch (currentClass) {
            case 'closed': newClass = 'open'
                break
            case 'open': newClass = 'closed'
                break
            default: newClass = 'closed'
        }
        rebateList.classList = 'rebate-list-wrapper ' + newClass

        //update button text
        switch (newClass) {
            case 'closed': e.target.innerText = `Show Details`
                break
            case 'open': e.target.innerText = `Hide Details`
                break
            default: e.target.innerText = `Show Details`
        }
    }

    // if (!gotProductRebates) return <div style={{paddingTop:'var(--header-ht)'}}><LoadingSpinner loadingMessage={'Loading Utility Rebates'} /></div>

    return (
        <section style={{background: 'var(--bg-gradient-gray)', overflowY:'hidden'}}>
            <div className='section-content-wrapper' style={{paddingTop:'var(--header-ht)', paddingBottom:'var(--spc-xl2-h)'}}>
                <h1 className='h3' style={{fontFamily: 'var(--headline-font)', marginTop: 'var(--xl3)'}}>{rebateTitle}</h1>
                <form onSubmit={e => handleSubmit(e)}>
                    <InputText
                        id='zip-code'
                        name='zip_code'
                        label='Zip Code'
                        placeholder='U.S. Only'
                        value={userZip}
                        onChange={handleChange}
                    />
                    <Button
                        type='submit'
                        text='Find Rebates'
                        style={{marginTop: 'var(--xl)'}}
                    />
                </form>
                {gotProductRebates && productRebates.map((rebateData, i) => 
                    <div key={`rebate${i+1}`} className='rebate-preview-wrapper'>
                        <div>
                            <img src={rebateData.product.imageUrl} alt={rebateData.product.shortName} />
                            <div>
                                <h3 className='h5' style={{fontFamily: 'var(--headline-font)'}}>
                                    {rebateData.maxRebateAmountLabel} In Potential Savings
                                </h3>
                                <h4 className='h6' style={{fontFamily: 'var(--headline-font)'}}>
                                    {rebateData.product.shortName}
                                </h4>
                                <h4 className='h6 condensed' style={{opacity: '.6'}}>
                                    {rebateData.product.efficiencyLevelLabel} | Model #: {rebateData.product.displaySku} | AHRI Reference #: {rebateData.product.ahriNumber}
                                </h4>
                                <Button 
                                    className='simple-link' 
                                    id={i} 
                                    onClick={handleClick}
                                    text='Show Details'
                                />
                            </div>
                        </div>

                        <div id={`rebate ${i}`} className='rebate-list-wrapper closed'>
                            {rebateData.rebatePrograms.map((rebate, j) => 
                                <div key={`${j+1} - ${rebate.name}`}>
                                    <div>
                                        <div style={{display: 'inline-block'}}>
                                            <p className='condensed center' style={{margin: '0'}}>Amount</p>
                                            <h5 style={{fontFamily: 'var(--headline-font)', marginTop:'10px'}} className=' center'>
                                                {rebate.amountLabel}
                                            </h5>
                                        </div>
                                        <div>
                                            <p className='condensed' style={{margin: '0'}}>Details</p>
                                            <h5 style={{fontFamily: 'var(--headline-font)', marginTop:'10px'}}>
                                                {rebate.name} <a href={rebate.homeURL} target='_blank' rel='noreferrer' style={{fontSize:'.7em'}}> visit website</a>
                                            </h5>
                                            <p style={{margin: '0'}} className='condensed'>
                                                {rebate.offerTypes?.includes('tax_credit') ? 'Tax Credit' : 'Rebate'} offer is valid for {rebate.offerTypes[1] ? rebate.offerTypes[1] : rebate.offerTypes[0]} applications in <span className='condensed-bold'>{`${rebateZip}`}</span>.
                                            </p>
                                            {rebate.purchaseDates.buyAfter &&
                                                <p style={{margin: '0', marginTop: 'var(--spc-sm-h)'}} className='condensed'>
                                                    Purchase on or after {rebate.purchaseDates.buyAfter}
                                                </p>
                                            }
                                            {rebate.purchaseDates.buyBefore && 
                                                <p style={{margin: '0'}} className='condensed'>
                                                    Purchase on or before <span className='condensed-bold'>{rebate.purchaseDates.buyBefore}</span>
                                                </p>
                                            }
                                            {rebate.purchaseDates.claimBy &&
                                                <p style={{margin: '0'}} className='condensed'>
                                                    Must be claimed by {rebate.purchaseDates.claimBy}
                                                </p>   
                                            }
                                            
                                            <Button
                                                style={{
                                                    display: 'inline-block', 
                                                    textAlign: 'left', 
                                                    placeContent:'unset', 
                                                    margin:'var(--spc-md-h) 0'
                                                }}
                                                href={rebate.claimFormURL} 
                                                text={rebate.getFormLabel}
                                            />
                                            <p className='footnote' style={{textAlign: 'left'}}>
                                            {rebate.importantDetails.map((detail) => {
                                                detail = handleDetail(detail) 
                                                return <span key={detail}>{detail} </span>
                                            })}
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <ul>
                                        {rebate.performanceCriteria.map((criteria, i) => 
                                            <li key={i+criteria}>{parseSymbols(criteria)}</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}