
export default function HeaderFrame({ logo, title }) {
	const getLogo = () => {
		switch(logo) {
			case 'trane': return 'Trane-ME-reverse'
			case 'american-standard': return 'AS-ME-reverse'
			default : return 'ME-logo-reverse'
		}
	}

	return (
		<header className={`iframe-header ${title ? 'with-title' : undefined}`}>
			{title && <h1 className='h4 iframe-header-title'>{title}</h1>}
			<img 
				src={`/images/logos/${getLogo()}.svg`}
				className={`iframe-header-logo`}
				alt={`${logo} logo`}
			/>
		</header>
	)
}