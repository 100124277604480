import altText from "./altText"
import financing from "./contentBoxFinancing"
import warranty from "./contentBoxWarranty"

export default function support({
	id = 'support',
	title = 'The Support You Need',
	phone = '',
	hours = '',
	color = 'blue',
	hasNavAnchor = false
}) {
    return {
		id: id,
		class: 'three-column-cards-w-image',
		hasNavAnchor: hasNavAnchor,
		title: title,
		contentBoxes: [
			financing,
			warranty,
			{
				iconSrc: 'handshake',
				iconAlt: `${altText} call us for support`,
				blurb: 'Contact Us',
				detailsText: `Have questions? Reach out to our Customer Care team${hours ? ' '+hours : ''} by calling:`,
				phone: phone
			},
		],
		backgroundCol: color,
	}
}