import { header, hero, sections } from './page content/pageWbuContent'
import Page from './Page'

export default function PageColorado() {
    const start = '4/25/2024'
    const end = '8/31/2024'
    const finalHero = {
        ...hero,
        leadCampaignId: '701Pd000008injaIAA',
        startDate: new Date(start),
        endDate: new Date(end),
        staticCampaign: true,
        scrollTo: sections[0].id,
    }

    return <Page header={header} hero={finalHero} sections={sections} />
}
