import { useState } from 'react'
import Icon from "./Icon"

export default function Link({ href, text, iconId, iconSize, iconAfter, color, onClick, title }) {
    const [icon, setIcon] = useState(iconId)

    const className = icon ? `icon-link ${iconSize && iconSize} ${onClick && 'icon-after'}` : 'link'
    const externalLink = href?.includes('http')

    const handleClick = (e) => {
        if (onClick && !href) {
            e.preventDefault()
            onClick(e)
            setIcon('check')
            setTimeout(() => {
                setIcon(iconId)
            }, 3000) // Reset copied state after 3 seconds
        }
    }

    const iconElement = <Icon id={icon} color={color} className={iconSize && iconSize} />

    return (
        <a 
            className={`${className} ${href && 'accent'} ${iconAfter && 'icon-after'}`} 
            href={href && href} 
            target={externalLink ? '_blank' : '_self'}
            rel={externalLink ? 'noreferrer' : undefined}
            onClick={handleClick}
            title={title}
        >
            {icon && !onClick && !iconAfter && iconElement}
            <span style={color ? {color: `var(--${color})`} : undefined}>{text}</span>
            {(onClick || iconAfter) && iconElement}
        </a>
    )
}