import altText from './altText'

export default function svz({ id = 'svz', color = 'transparent', title = 'Did You Know?', hasNavAnchor = false, ctaUrl = null }) {
    return {
        id: id,
        class: 'two-column-img-left',
        hasNavAnchor: hasNavAnchor,
        title: title,
        blurb: 'We Offer a Ducted Heat Pump Solution.',
        detailsText:
            'Our ducted air handler converts your ducted, heating-only system to include central air conditioning. Perfect for replacing your inefficient hot-air furnace, the ducted air handler is all-electric so it does not burn fossil fuels. The unit provides the powerful, quiet, and efficient cooling and heating solution your family deserves.',
        imgSrc: '/images/product/svz-living-room.webp',
        imgAlt: `${altText} heat pump ducted air handler superimposed on a comfortable, modern living room`,
        ctaText: ctaUrl ? 'Learn more' : 'Watch Video',
        ctaUrl: ctaUrl,
        videoSrc: ctaUrl ? null : 'NXUVVbS9CpE',
        backgroundCol: color,
    }
}
