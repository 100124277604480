import { useState, useRef } from 'react'
import ScrollLink from './modular/ScrollLink'
import useBranding from '../hooks/useBranding'
import HeaderFrame from './HeaderFrame'
import Icon from './modular/Icon'

export default function SectionRoller({ content, title, iframe }) {
	const { id, menuItems } = content

	const brand = useBranding()

	const [visibleContent, setVisibleContent] = useState('title')
	const [selectedItem, setSelectedItem] = useState(menuItems[0])
	const [ctaText, setCtaText] = useState('See Our Solution')

	const rollerBtn = useRef()

	const toggleContent = (e) => {
		if (visibleContent === 'title') {
			setVisibleContent('details')
			rollerBtn.current.className ='roller-btn close'
			setCtaText('')
		}
		if (visibleContent === 'details') {
			setVisibleContent('title')
			rollerBtn.current.className = 'roller-btn'
			setCtaText('See Our Solution')
		}
	}

	const handleMenuItemClick = (e) => setSelectedItem(menuItems[e.target.id])

	return (
		<section id={id} className={`mar-y-lg ${iframe ? 'iframe roller-iframe-wrapper' : undefined}`}>
			<div
				className={`roller ${iframe ? 'iframe' : ''}`}
				style={{backgroundImage: `url(${selectedItem.backgroundSrc})`}}
			>
				{iframe && <HeaderFrame logo={brand} title={title} />}
				<div className='roller-menu'>
					{menuItems.map((item, index) =>
						<h6
							key={index}
							id={index}
							className={item.title === selectedItem.title ? 'roller-menu-title selected-item' : 'roller-menu-title'}
							onClick={e => handleMenuItemClick(e)}
						>
							{item.title}
						</h6>
					)}
				</div>
				<div className={visibleContent === 'title' ? 'section-content-wrapper' : 'section-content-wrapper details-visible'}>
					<div className='roller-title-wrapper'>
						<h1>{selectedItem.title}</h1>
						<h2 className='h4'>{selectedItem.blurb}</h2>
					</div>
					<div className="roller-details-wrapper">
						<img className='roller-details-img' src={selectedItem.imgSrc} alt={selectedItem.imgAlt}/>
						<div className='roller-details-headline'>
							<h3 className='h5'>We recommend our</h3>
							<h4 className='h3'>{selectedItem.product}</h4>
						</div>
						<p className='roller-details-text'>{selectedItem.detailsText}</p>
						{ctaText && 
							<div className='roller-cta-wrapper'>
								<ScrollLink
									className='btn'
									to={`#form-section`}
								>
									Request a Consultation
								</ScrollLink>
							</div>
						}
						<div className='roller-details-list'>
							{selectedItem.productFeatures.map((feature, index) =>
								<div key={index} className='icon-list-item'>
									<Icon color='white' id={feature.icon} alt={feature.icon} />
									<p>{feature.text}</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<div onClick={toggleContent} ref={rollerBtn} className='roller-btn'>
					<h5>{ctaText}</h5>
					<Icon color='white' id={visibleContent === 'title' ? 'down-arrow' : 'x'}/>
				</div>
				
			</div>

		</section>
	)
}