import { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import useBranding from '../hooks/useBranding'
import IEMessage from './IEMessage'
import PageFs from './PageFs'
import PageHome from './PageHome'
import PageSwbu from './PageSwbu'
import PageMabu from './PageMabu'
import PageNebu from './PageNebu'
import PageWbu from './PageWbu'
import PageSbu from './PageSbu'
import PageCbu from './PageCbu'
import PageAep from './PageAep'
import PageColorado from './PageColorado'
import PageNewYork from './PageNewYork'
import PageThankYou from './PageThankYou'
import PageSingleZone from './PageSingleZone'
import PageContractors from './PageContractors'
import PageHospitality from './PageHospitality'
import PageDiamondFinder from './PageDiamondFinder'
import PageNewProducts from './PageNewProducts'
import PageGenerator from './PageGenerator'
import PageBuilders from './PageBuilders'
import PageKumoSupport from './PageKumoSupport'
import PageSectionLayouts from './PageSectionLayouts'
import PageFrameApplications from './PageFrameApplications'
import PageFrameGeneral from './PageFrameGeneral'
import PageFrameIndoor from './PageFrameIndoor'
import PageFrameIntelliHeat from './PageFrameIntelliHeat'
import PageFrames from './PageFrames'
import PageSeniorLiving from './PageSeniorLiving'
import PageRebates from './PageRebates'
import PageVerticals from './PageVerticals'
import PageLightCommercial from './PageLightCommercial'
import PageThreePhase from './PageThreePhase'
import PageIndoorGrow from './PageIndoorGrow'
import PageFormTest from './PageFormTest'
import PageEducation from './PageEducation'
import PageModularConstruction from './PageModularConstruction'
import PageColors from './PageColors'
import PageDemoTruck from './PageDemoTruck'
import PageSandboxTest from './PageSandboxTest'
import PageMultiFamily from './PageMultiFamily'
import PageNebuWebinar2023 from './PageNebuWebinar2023'
import PageIraIncentives from './PageIraIncentives'
import PageIntelliHeat from './PageIntelliHeat'
import PageFuelSwitch from './PageFuelSwitch'
import PageDispatch from './PageDispatch'
import PageKeepKcCool from './PageKeepKcCool'
import PageMabuFerguson from './PageMabuFerguson'

import TagManager from 'react-gtm-module'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

LogRocket.init('tiz8xv/discover')
setupLogRocketReact(LogRocket)

// GTM IDs ---> prod:KPDXS3X  staging: MQGHQQ5
const tagManagerArgs = { gtmId: 'GTM-KPDXS3X' }
TagManager.initialize(tagManagerArgs)

export default function App() {
    // detect if user is on Internet Explorer & show custom message
    const [isUsingInternetExplorer, setIsUsingInternetExplorer] = useState(false)
    useEffect(() => window.document.documentMode && setIsUsingInternetExplorer(true), [])

    window.dataLayer.push({ event: 'pageview' })

    // change site branding colors based on url params
    const branding = useBranding()

    useEffect(() => {
        const anchor = window.location.hash
        if (anchor) {
            const element = document.querySelector(anchor)
            if (element) {
                element.scrollIntoView()
            }
        }
        if (branding === 'trane' || branding === 'tr') {
            window.root.style.setProperty('--accent', 'var(--trane-orange)')
            window.root.style.setProperty('--accent-50', 'var(--trane-orange-50)')
            window.root.style.setProperty('--red', 'var(--trane-orange)')
            window.root.style.setProperty('--red-50', 'var(--trane-orange-50)')
            window.root.style.setProperty('--red-dk', 'var(--trane-orange-dk)')
            window.root.style.setProperty('--red-dk-50', 'var(--trane-orange-dk-50)')
            window.root.style.setProperty(
                '--bg-gradient-accent',
                'linear-gradient(var(--site-bg-30), var(--trane-orange-50)), radial-gradient(ellipse at bottom center, var(--site-bg-50), var(--trane-orange))',
            )
            return
        }
        if (branding === 'american-standard' || branding === 'as') {
            window.root.style.setProperty('--accent', 'var(--as-blue)')
            window.root.style.setProperty('--accent-50', 'var(--as-blue-50)')
            window.root.style.setProperty('--red', 'var(--as-blue)')
            window.root.style.setProperty('--red-50', 'var(--as-blue-50)')
            window.root.style.setProperty('--red-dk', 'var(--as-blue-dk)')
            window.root.style.setProperty('--red-dk-50', 'var(--as-blue-dk-50)')
            window.root.style.setProperty(
                '--bg-gradient-accent',
                'linear-gradient(var(--site-bg-30), var(--as-blue-50)), radial-gradient(ellipse at bottom center, var(--site-bg-50), var(--as-blue))',
            )
        }
    }, [branding])

    return (
        <div className="App">
            <div id="top"></div>
            {isUsingInternetExplorer ? (
                <IEMessage />
            ) : (
                <Routes>
                    <Route exact path="/" element={<PageHome />} />
                    <Route path="/multizone" element={<PageHome />} />
                    <Route path="/wbu" element={<PageWbu />} />
                    <Route path="/colorado" element={<PageColorado />} />
                    <Route path="/mabu" element={<PageMabu />} />
                    <Route path="/sbu" element={<PageSbu />} />
                    <Route path="/swbu" element={<PageSwbu />} />
                    <Route path="/cbu" element={<PageCbu />} />
                    <Route path="/aep" element={<PageAep />} />
                    <Route path="/nebu" element={<PageNebu />} />
                    <Route path="/Deluxe-Wall-Mount/" element={<PageFs />} />
                    <Route path="/thank-you" element={<PageThankYou />} />
                    <Route path="/singlezone" element={<PageSingleZone />} />
                    <Route path="/newyork" element={<PageNewYork />} />
                    <Route path="/contractors" element={<PageContractors />} />
                    <Route path="/hotels" element={<PageHospitality />} />
                    <Route path="/hospitality" element={<PageHospitality />} />
                    <Route path="/product-selector" element={<PageDiamondFinder />} />
                    <Route path="/diamond-finder" element={<PageDiamondFinder />} />
                    <Route path="/diamond-product-finder" element={<PageDiamondFinder />} />
                    <Route path="/new-products" element={<PageNewProducts />} />
                    <Route path="/ahr2023/products" element={<PageNewProducts />} />
                    <Route path="/generator" element={<PageGenerator />} />
                    <Route path="/builders" element={<PageBuilders />} />
                    <Route path="/kumo-cloud-support" element={<PageKumoSupport />} />
                    <Route path="/iframe-applications" element={<PageFrameApplications />} />
                    <Route path="/iframe-indoor-models" element={<PageFrameIndoor />} />
                    <Route path="/iframe-general" element={<PageFrameGeneral />} />
                    <Route path="/iframe-intelli-heat" element={<PageFrameIntelliHeat />} />
                    <Route path="/iframes" element={<PageFrames />} />
                    <Route path="/rebates" element={<PageRebates />} />
                    <Route path="/incentives" element={<PageRebates />} />
                    <Route path="/senior-living" element={<PageSeniorLiving />} />
                    <Route path="/section-layouts" element={<PageSectionLayouts />} />
                    <Route path="/verticals-template" element={<PageVerticals />} />
                    <Route path="/light-commercial" element={<PageLightCommercial />} />
                    <Route path="/three-phase" element={<PageThreePhase />} />
                    <Route path="/threephase" element={<PageThreePhase />} />
                    <Route path="/indoor-grow" element={<PageIndoorGrow />} />
                    <Route path="/form-test" element={<PageFormTest />} />
                    <Route path="/education" element={<PageEducation />} />
                    <Route path="/modular-construction" element={<PageModularConstruction />} />
                    <Route path="/colors" element={<PageColors />} />
                    <Route path="/mabu-demo-truck" element={<PageDemoTruck />} />
                    <Route path="/sandbox-test" element={<PageSandboxTest />} />
                    <Route path="/multi-family" element={<PageMultiFamily />} />
                    <Route path="/nebu-webinar-2023" element={<PageNebuWebinar2023 />} />
                    <Route path="/ira-incentives" element={<PageIraIncentives />} />
                    <Route path="/intelli-heat" element={<PageIntelliHeat />} />
                    <Route path="/fuel-switch" element={<PageFuelSwitch />} />
                    <Route path="/dispatch" element={<PageDispatch />} />
                    <Route path="/keep-kc-cool" element={<PageKeepKcCool />} />
                    <Route path="/mabu-ferguson" element={<PageMabuFerguson />} />
                </Routes>
            )}
        </div>
    )
}
