// import altText from './static-content/altText'
import heat2O from './static-content/heat2O'
import productRange from './static-content/product-range'
import innovativeProducts from './static-content/innovative-products'
import r2 from './static-content/r2'
import downloadIra from './static-content/download-ira'

const vertical = 'Modular Construction'
const mainImg = '/images/modular-building.webp'

const hero = {
    class: 'two-column-img-left dark-mode',
    backgroundSrc: mainImg,
    title: `HVAC Solutions For ${vertical}`,
    blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
    detailsText: 'VRF technology’s flexible design options and ease of installation in a factory setting make it a great fit for modular manufacturers. The energy efficiency and reliability of the products make it a win for owners too.',
}

const sideBar = [
    {
        vertical: vertical,
        imgSrc: '/images/john_tienken_headshot.webp',
        firstName: 'John',
        lastName: 'Tienken',
        jobTitle: 'Manager, Business Development II',
        phone: '(678) 697-6769',
        email: 'jtienken@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/john-tienken-8164682/'
    }
]

const sections = [
    {
        id: 'why-VRF?',
        class: 'one-column-list',
        hasNavAnchor: true,
        title: `Why VRF In Commercial ${vertical}?`,
        listItems: [
            {
                iconId: 'city-multi',
                title: 'Less Space For Us, More Space For You',
                description: 'Our modular system design eliminates the need for mechanical rooms and bulky equipment on your rooftop. When compared to large trunks of ductwork, our refrigerant lines save a lot of valuable space. Plus, our wall-mounted units require no ceiling space at all, so your tenants can enjoy more living space.'
            },
            {
                iconId: 'speed-fast',
                title: 'Maximum Energy Efficiency',
                description: 'Mitsubishi Electric VRF zoning systems are up to 40% more efficient when compared to conventional systems. Our variable speed technology uses only the minimum amount of energy necessary to maintain comfort levels while adjusting to partial-load conditions, which occur most of the time.'
            },
            {
                iconId: 'versatile-flexible',
                title: 'Design and System Flexibility',
                description: 'Eliminating the need for bulky runs on ductwork, VRF systems are easier to design or modify when compared to conventional systems. With little space required, these VRF zoning systems are compact and can be conveniently located in existing building envelopes.'
            },
            {
                iconId: 'power-bill-invoice',
                title: 'Lower Lifecycle Costs',
                description: 'You can count on your Mitsubishi Electric VRF system for greater savings down the road, and rest assured systems can include energy apportionment software for accurate tracking of utility usage. Our system’s total installed costs are competitive with conventional systems, but our higher product quality means they’ll last twice as long.'
            },
        ],
        backgroundCol: 'gray'
    },
    innovativeProducts({ hasNavAnchor: true }),
    heat2O({}),
    r2({}),
    productRange({}),
    downloadIra({})
]

const header = {
	pageTitle: 'HVAC Solutions For Modular Construction',
	pageDesc: 'Mitsubishi Electric\'s VRF technology features flexible design options and ease of installation in a factory setting make it a great fit for modular manufacturers.',
	pageImg: mainImg,
    class: 'side-bar dark-mode-header',
    branding: 'metus',
    pageSections: sections.map((section) => section),
    accentCol: 'blue',
    vertical: vertical,
}

export { header, sideBar, hero, sections }
