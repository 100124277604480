import altText from '../altText'
import lorem from '../lorem'

const subscribe = {
  id: 'Subscribe',
  class: 'download-section subscribe',
  blurb: 'subscribe-section ' +lorem.one,
  imgSrc: '/images/brochure-download-poster.webp',
  imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
  imgAlt: `${altText} click to download our brochure`,
  backgroundCol: 'gray',
}

export default subscribe