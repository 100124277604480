import React from 'react'
import {createRoot} from 'react-dom/client'
import './styles/index.css'
import './styles/utility.css'
import App from './components/App'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

const root = createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();