import { findKeyIndex } from "./helpers"
import getUserDevice from "./getUserDevice"

export default function parseGeoData(geoData) {
    if (!geoData) {
        return
    }
    const parsedGeoData = {}
    const address = geoData.address_components
    const userDevice = getUserDevice()
	const isMobileDevice = userDevice.isMobileDevice
    console.log('userDevice isMobileDevice', userDevice, isMobileDevice)

    parsedGeoData['city'] = address[findKeyIndex(address, 'sublocality', 'neighborhood', 'locality')].long_name
    parsedGeoData['zip'] = address[findKeyIndex(address, 'postal_code')].long_name
    parsedGeoData['state'] = address[findKeyIndex(address, 'administrative_area_level_1')].long_name
    parsedGeoData['country'] = address[findKeyIndex(address, 'country')].long_name
    
    if (isMobileDevice) {
        const houseNum = address[findKeyIndex(address, 'street_number')].long_name
        const street = address[findKeyIndex(address, 'route')].long_name
        if (houseNum && street) {
            parsedGeoData['houseNum'] = houseNum
            parsedGeoData['street'] = street
        }
    }

    return parsedGeoData
}