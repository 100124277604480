import altText from './altText'
// import lorem from './lorem'

const contractor = {
  id: 'contractor-spotlight',
  class: 'two-column-img-left',
  blurb: 'Contractor Community Spotlight',
  detailsText: `Discover why Legacy Comfort Systems in Harrisburg, PA built their business with a focus around Mitsubishi Electric HVAC systems. Legacy Comfort Systems is proud to be a Diamond Contractor and offer their customers innovative HVAC solutions that can enhance comfort and lifestyle.`,
  imgSrc: '/images/contractor_spotlight_legacy_comfort.webp',
  imgAlt: `${altText} diamond contractor spotlight - Legacy Comfort Systems`,
  ctaText: 'Watch Video',
  videoSrc: 'L1kYP1VbeM0',
}

export default contractor