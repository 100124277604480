export default function Icon(props) {
	let iconFolder = 'black'
	let iconSrc
	const format = props.format ? props.format : 'svg'

	if (format === 'svg' && !props.color && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		// dark mode
		iconFolder = 'white'
	}
	
	if (props.color) { iconFolder = props.color }
	if (format === 'png') { iconFolder = 'png' }
	
	iconSrc = `/icons/${iconFolder}/${props.id}.${format}`
	
	return (
		<img
			id={props.id}
			data-id={props.dataId}
			className={`icon lazy${props.className ? ` ${props.className}` : ''}`}
			src={iconSrc}
			style={props.style}
			onClick={props.onClick}
			alt={props.id + ' icon'}
			data-key={props.dataKey}
			data-value={props.dataValue}
		/>
	)
}
