import { useState, useEffect } from 'react'
import { fetchIndoorUnits } from '../lib/queries'

const SectionProducts = (props) => {
  const [unitsByType, setUnitsByType] = useState([])

  const addUnitType = (newType) => {
    setUnitsByType(existingTypes => [...existingTypes, newType])
  }

  useEffect(() => {
    fetchIndoorUnits('indoor_unit_type=Wall-mounted').then((data) => {
      const obj = { type: 'wall-mounted', units: data}
      addUnitType(obj)
    })
    fetchIndoorUnits('indoor_unit_type=Floor-mounted').then((data) => {
      const obj = { type: 'floor-mounted', units: data}
      addUnitType(obj)
    })
    fetchIndoorUnits('indoor_unit_type=Ceiling-mounted').then((data) => {
      const obj = { type: 'ceiling-mounted', units: data}
      addUnitType(obj)
    })
  }, [])

  return (
    <section id={props.id} className={props.class}>
      <nav>{unitsByType.map(unit => <h6>{unit.type}</h6>)}</nav>
    </section>
  )
}

export default SectionProducts