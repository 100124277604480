import React from 'react';
import { createSlug } from '../../lib/helpers';

export default function InputRadioBtnGroup(props) {
    const footnoteSymbol = '#'
    return (
        <fieldset id={props.id} className='input-wrapper'>
            <legend className={props.required ? 'radio-title required' : 'radio-title'}>
                {props.title}
            </legend>
            <div className='radio-group'>
                {props.options.map((option, i) => {
                    const id = `radio_${createSlug(String(option))}`
                    const label = props.labels ? props.labels[i] : option;
                    return (
                        <div key={id}>
                            <label>
                                <input 
                                    type='radio' 
                                    id={id} 
                                    name={props.name}
                                    value={option} 
                                    onChange={props.handleChange}
                                    checked={props.selectedValue === option}
                                    required={props.required}
                                    className='visually-hidden'
                                />
                                {label}
                            </label>
                        </div>
                    )
                })}
            </div>
            {props.footnote &&
                <p className='footnote mar-top-sm'>
                    <sup>{footnoteSymbol}</sup>{props.footnote}
                </p>
            }
        </fieldset>
    )
}
