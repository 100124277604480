import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/6/2024'
    const end = '3/31/2025'
    const amount = 0

    const data = {
        // defaults
        bu: 'mabu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kmXZAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/split-inside-office-outside-lake.webp',
        defaultImageAlign: 'top',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: '',
        utilityProduct: 'MXZ-SM48',
        whatsNext: defaults.whatsNext,
        // ME_Residential_MABU_2024_Ferguson_NE_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd00000FQ11SIAT',
        campaignHero: 'rebate',
        // campaignCtaText: 'Get Started',
        campaignImage: '/images/lifestyle/Football_Scene.webp',
        campaignImageAlign: 'center',
        campaignTitle: 'Score better comfort',
        campaignBlurb: 'With zone-to-zone coverage',
        campaignDetails:
            'Elevate your home’s comfort with all-electric, all-climate heat pumps, designed for energy-efficiency and flexibility to solve your home’s unique heating and cooling needs. Mitsubishi Electric systems deliver zone-by-zone personalized temperature control and whole-home ducted solutions.',
        campaignFootnote: ``,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
