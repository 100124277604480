import Icon from "./modular/Icon"

export default function ThankYou(props) {
	const handleReload = () => {
		props.onClick()
		window.location.reload()
	}
	
	return (
		<div id='thank-you-wrapper' className={props.className} style={{paddingLeft: 'var(--spc-xl-w)', paddingRight: 'var(--spc-xl-w)', borderRadius: 'var(--rnd-lg)', margin: 'var(--xl2) auto'}} >
            <div style={{display: 'grid', gridTemplateColumns: '40px auto', gridGap: 'var(--spc-xs-w)'}}>
				<Icon id='check' style={{gridArea: 'unset', boxShadow: 'none'}} />
                <h5 onClick={props.onClick}>
                    {props.headline ? props.headline : 'You\'re Now Registered!'}
                </h5>
			</div>
			<p>{props.thankYou ? props.thankYou : 'We\'ve received your registration information and will follow up with more information via email.'}</p>
			
			{props.showResetButton && 
				<button onClick={handleReload}>Reset Form</button>
			}
		</div>
	)
}
