import altText from './altText'

export default function download({
	id = 'download',
	hasNavAnchor = false,
	color = 'gray',
}) {
	return {	
		id: id,
		class: 'download-section',
		hasNavAnchor: hasNavAnchor,
		blurb: 'Download our brochure for more features and benefits of our heat pump systems.',
		imgSrc: '/images/brochure-download-poster.webp',
		imgUrl: '/ME_RS_1061_1-22_Consumer Brochure.pdf',
		imgAlt: `${altText} click to download our brochure`,
		backgroundCol: color,
	}
}
