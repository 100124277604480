import altText from "./altText"

export default function productRange({
    id = 'product-range',
    hasNavAnchor = false,
    color = 'transparent'
}) {
	return {
		id: id,
		class: 'two-column-img-right',
		hasNavAnchor: hasNavAnchor,
		blurb: 'Designed for life,\ninside and out.',
		detailsText: `As a leader in advanced HVAC technologies, including Ductless and Ducted Mini-split and Variable Refrigerant Flow (VRF) heat pump and air-conditioning systems, we have a solution for any home, any building, anywhere. We continually innovate around efficiency, comfort and wellness in buildings or homes of all shapes and sizes by providing industry-leading products, design and technical training, and unmatched end-to-end support.`,
		imgSrc: '/images/product/pro_products.webp',
		imgAlt: `${altText} range of professional products`,
		backgroundCol: color
	}
} 